
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useReportContext } from "../ReportDataContext";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function CampReportRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState("");
  const reportContext = useReportContext();

  // useEffect(() => {
  //   reportOpen && fetchStudentsInReport();
  // }, [reportOpen]);


  return (
    <Fragment>
      <TableRow key={`${row.student_id}${row.course_abbr}${row.camp_week}`} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.camp_week}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.camp_type}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.camp_times}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
        {row.course_abbr}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.delivery_type}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.student_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.student_name}
        </TableCell>
        {/* <TableCell align="center" component="th" scope="row">
        {row.parent_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
        {row.phone ? row.phone : "No Number Listed"}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
        {row.email ? row.email : "No Email Listed"}
        </TableCell> */}
        {showActions && (
          <TableCell align="center">
            {/* <ReportTableActions
              row={row}
              openEditReportPage={reportContext.openEditReportPage}
              openDeleteReportDialog={reportContext.openDeleteReportDialog}
              openActivateReportDialog={reportContext.openActivateReportDialog}
              openReportShowDialog={reportContext.openReportShowDialog}
              openDuplicateReportPage={reportContext.openDuplicateReportPage}
              openReportHideDialog={reportContext.openReportHideDialog}
            /> */}
          </TableCell>
        )}
      </TableRow>
      {/* <TableRow key={"details" + row.report_family}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Container>
                <Row>
                  <Col>
                    <ReportCoachTable instructors={row.instructor} />
                  </Col>
                  <Col>
                    <ReportStudentTable students={studentList} />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </Fragment>
  );
}
