import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { TimesheetList } from "./user/TimesheetList";
import { SubmissionList } from "./approver/SubmissionList";
import { TimesheetContextProvider } from "./Components/TimesheetDataContext";

export default function TimesheetPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const timesheetUIEvents = {
    // newTimesheetButtonClick: () => {
    //   history.push("/timesheets/add-page");
    // },
    // openDuplicateTimesheetPage: (id) => {
    //   history.push(`/timesheets/${id}/duplicate-timesheet/`);
    // },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <TimesheetContextProvider timesheetUIEvents={timesheetUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/timesheet" to="/timesheet/time" />}

          <ContentRoute path="/timesheet/time" component={TimesheetList} />

          <ContentRoute path="/timesheet/submissions" component={SubmissionList} />
        </Switch>
      </Suspense>
    </TimesheetContextProvider>
  );
}
