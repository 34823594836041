/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const CouponTableActions = ({
  row,
  openEditCouponPage,
  openDeleteCouponDialog,
  openActivateCouponDialog,
  openCouponShowDialog,
  openCouponHideDialog,
  openDuplicateCouponPage,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit coupon</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            //console.log(row);
            openEditCouponPage(row.coupon_id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      {row.active_id === 1 ? (
        <span>
          {/* Asad remember to add stuff to reactivate */}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-delete-tooltip">Deactivate Coupon</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => openDeleteCouponDialog(row.coupon_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-power-off text-success"></i>
              </span>
            </a>
          </OverlayTrigger>
          {row.showing !== 0 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark Unavailable for Website
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => openCouponHideDialog(row.coupon_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i className="fa fa-eye text-primary"></i>
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark Available for Website
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                onClick={() => openCouponShowDialog(row.coupon_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i className="fa fa-eye-slash text-danger"></i>
                </span>
              </a>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-duplicate-tooltip">
                Duplicate Coupon
              </Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => openDuplicateCouponPage(row.coupon_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="far fa-copy text-success"></i>
              </span>
            </a>
          </OverlayTrigger>
        </span>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Activate Coupon</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openActivateCouponDialog(row.coupon_id)}
          >
            <i className="fa fa-power-off"></i>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};
