/* eslint eqeqeq: "off" */
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const BATCH_TYPE_URL = "api/camps/batch-type";
const DELIVERY_TYPE_URL = "api/camps/delivery-type";
const PROGRAM_TYPE_URL = "api/camps/program-type";
const ACTIVE_URL = "api/camps/active";
const COURSE_URL = "api/camps/course/";

export const OFFERED = [
  { id: 1, value: "Yes" },
  { id: 0, value: "No" },
];

export const SHOW = [
  { id: 1, value: "Yes" },
  { id: 0, value: "No" },
];

const CampContext = createContext();

export function useCampContext() {
  return useContext(CampContext);
}

export const CampConsumer = CampContext.Consumer;

export function CampContextProvider({ campUIEvents, children }) {
  const [BATCH_TYPE, SETBATCH_TYPE] = useState([]);
  const [DELIVERY_TYPE, SETDELIVERY_TYPE] = useState([]);
  const [PROGRAM_TYPE, SETPROGRAM_TYPE] = useState([]);
  const [ACTIVE, SETACTIVE] = useState([]);
  const [COURSE, SETCOURSE] = useState([]);
  const [SELECT_COURSE_OPTIONS, SETSELECT_COURSE_OPTIONS] = useState([]);
  // const [refetch, setRefetch] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_camp = await axios(BATCH_TYPE_URL);
        res_camp.data.results = res_camp.data.results.filter(
          (result) => result.id !== 1
        );
        SETBATCH_TYPE(res_camp.data.results);
        let res_delivery = await axios(DELIVERY_TYPE_URL);
        SETDELIVERY_TYPE(res_delivery.data.results);
        let res_program = await axios(PROGRAM_TYPE_URL);
        SETPROGRAM_TYPE(res_program.data.results);
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
        let res_course = await axios(COURSE_URL + branch.branch_id);
        SETCOURSE(res_course.data.results);
        let selectOptions = [];
        res_program.data.results.forEach((pt) => {
          let option = {
            label: pt.value,
            options: res_course.data.results
              .filter((c) => {
                return c.program_type == pt.id;
              })
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map((a) => {
                return { value: a.value, label: `${a.value} - ${a.abbr}` };
              }),
          };
          selectOptions.push(option);
        });
        SETSELECT_COURSE_OPTIONS(selectOptions);
        //console.log("results", res_course.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
    // eslint-disable-next-line
  }, []);

  const campContext = {
    newCampButtonClick: campUIEvents.newCampButtonClick,
    openEditCampPage: campUIEvents.openEditCampPage,
    openDeleteCampDialog: campUIEvents.openDeleteCampDialog,
    openActivateCampDialog: campUIEvents.openActivateCampDialog,
    openDuplicateCampPage: campUIEvents.openDuplicateCampPage,
    openPointsDialog: campUIEvents.openPointsDialog,
    openEmailDialog: campUIEvents.openEmailDialog,
    refetch: campUIEvents.refetch,
    setRefetch: campUIEvents.setRefetch,
    TYPES: {
      BATCH_TYPE,
      SETBATCH_TYPE,
      DELIVERY_TYPE,
      SETDELIVERY_TYPE,
      PROGRAM_TYPE,
      SETPROGRAM_TYPE,
      ACTIVE,
      SETACTIVE,
      COURSE,
      SETCOURSE,
      OFFERED,
      SHOW,
      SELECT_COURSE_OPTIONS,
    },
  };

  return (
    <CampContext.Provider value={campContext}>{children}</CampContext.Provider>
  );
}
