import React, { useMemo, useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useFeatureContext } from "./FeatureDataContext";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
const prepareFilter = (values) => {
  const {
    idFilter,
    activeFilter,
  } = values;
  const filter = {};
  
  filter.id = idFilter !== "" ? idFilter : "";
  filter.active_id = activeFilter !== "" ? activeFilter : "";


  return filter;
};

export function FeatureFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const DROPDOWN_TYPE = useFeatureContext().TYPES;
  const [dataLoaded, setDataLoaded] = useState(false);
  const context = useFeatureContext();
  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };
  const useStyles = makeStyles({
    btnCircleSm: {
      width: "30px",
            height: "30px",
            padding: "0px 7px",
            margin: "2px 1px 1px 1px",
            borderRadius: "15px",
            fontSize: "4px",
            textAlign: "center",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const initialValues = {
      idFilter: "",
      activeFilter: "1",
    };
    applyFilter(initialValues);
  }, [origData]);

  //fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={{
          idFilter: "",
          activeFilter: "1",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
            <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Status"
                  name="activeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("activeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.activeFilter}
                >
                  <option value="">All</option>

                  {DROPDOWN_TYPE.ACTIVE &&
                    DROPDOWN_TYPE.ACTIVE.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>
              
              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
