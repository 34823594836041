import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const ACTIVE_URL = "api/workshops/active";


const FeatureContext = createContext();

export function useFeatureContext() {
  return useContext(FeatureContext);
}

export const FeatureConsumer = FeatureContext.Consumer;

export function FeatureContextProvider({ featureUIEvents, children }) {
  const [ACTIVE, SETACTIVE] = useState([]);

  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const featureContext = {
    newFeatureButtonClick: featureUIEvents.newFeatureButtonClick,
    openEditFeaturePage: featureUIEvents.openEditFeaturePage,
    openActivateFeatureDialog: featureUIEvents.openActivateFeatureDialog,
    openDeactivateFeatureDialog: featureUIEvents.openDeactivateFeatureDialog,
    refetch: featureUIEvents.refetch,
    setRefetch: featureUIEvents.setRefetch,
    TYPES: {
      ACTIVE,
      SETACTIVE,
    },
  };

  return (
    <FeatureContext.Provider value={featureContext}>
      {children}
    </FeatureContext.Provider>
  );
}
