import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { Button } from "@material-ui/core";
import { useUserContext } from "./UserDataContext";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import { Field } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { CertificationTableRow } from "./CertificationTableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  campIcon: {
    color: "green",
  },
  workshopIcon: {
    color: "yellow",
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  inPersonClass: {
    color: "#06b0d6",
  },
  virtualClass: {
    color: "#7c06d6",
  },
  hybridClass: {
    color: "orange",
  },
  table: {
    minWidth: 750,
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));
const headRows = [
  {
    id: "course",
    numeric: false,
    disablePadding: true,
    label: "Stream",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "trained_date",
    numeric: false,
    disablePadding: true,
    label: "Training Completion Date",
  },
  {
    id: "certified_date",
    numeric: false,
    disablePadding: true,
    label: "Certification Date",
  },
  {
    id: "proctor",
    numeric: false,
    disablePadding: false,
    label: "Proctor",
  },
];
export function CertificationTable({ values, setFieldValue, touched, error }) {
  const DROPDOWN_CERTIFICATES = useUserContext().CERTIFICATE;
  const access = useSelector((state) => state.auth.access, shallowEqual);

  const addRow = () => {
    Array.isArray(values.certificate) && values.certificate.length > 0
      ? setFieldValue("certificate", [
          ...values.certificate,
          {
            course: "",
            status: "",
            trained_date: "",
            certified_date: "",
            proctor: "",
          },
        ])
      : setFieldValue("certificate", [
          {
            course: "",
            status: "",
            trained_date: "",
            certified_date: "",
            proctor: "",
          },
        ]);
  };

  const classes = useStyles();
  return (
    <>
      {" "}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headRows.map((row, index) => (
                    <TableCell key={index} align="center">
                      {row.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(values.certificate) &&
                values.certificate.length > 0 ? (
                  values.certificate.map((row, index) => {
                    return values.certificate[index].status == "Certified" &&
                      access["certification"] != 0 ? (
                      <TableRow key={index}>
                        <TableCell align="center">{DROPDOWN_CERTIFICATES.CERTIFICATES.filter((cert) => {return cert.value == values.certificate[index].course})[0].title}</TableCell>
                        <TableCell align="center">{values.certificate[index].status}</TableCell>
                        <TableCell align="center">{values.certificate[index].trained_date}</TableCell>
                        <TableCell align="center">{values.certificate[index].certified_date}</TableCell>
                        <TableCell align="center">{values.certificate[index].proctor}</TableCell>
                      </TableRow>
                    ) : (
                      <CertificationTableRow
                        key={index}
                        i={index}
                        values={values}
                        touched={touched}
                        error={error}
                        setFieldValue={setFieldValue}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>Please add a row</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Button
                      className="btn btn-secondary font-weight-bolder font-size-sm"
                      onClick={addRow}
                    >
                      <i className="ki ki-plus icon-sm"></i>
                      <span>Add New Row</span>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </div>
    </>
  );
}
