/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { EventEditForm } from "./Components/EventEditForm";
import { useSubheader } from "../../../_metronic/layout";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import moment from "moment";
import { checkAndReplace } from "../../helpers/Formatter";

const DEFAULT_IMAGES = [
  {
    label: "Default Robotics Image",
    value: "https://websites-events.s3.us-east-2.amazonaws.com/IMG-20210929-WA0003.jpg"
  },
  {
    label: "Default Coding Image",
    value: "https://websites-events.s3.us-east-2.amazonaws.com/hourOfCode.png"
  }
];

const initEvent = {
  branch_id: 0,
  active_id: "1",
  fee: 0,
  image: DEFAULT_IMAGES[0],
  description: "",
  max_students: 0,
  grade_min_nbr: 1,
  grade_max_nbr: 12,
  startdate: "",
  enddate: "",
  workshop_name: "",
  form_link: "",
  full_ind: "0",
  start_time: "",
  end_time: "",
  price_subheader: ""
};

export function EventEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [eventsToAdd, setEventsToAdd] = useState([]);
  const [values, setValues] = useState();
  const btnRef = useRef();
  

  initEvent.branch_id = branch.branch_id;

  useEffect(() => {
    const getEventInfo = async () => {
      const event_info_url = `api/workshops/edit/${id}`;
      const res = await axios(event_info_url);
      let event = res.data.results[0]
      setData(event);

    };
    id && getEventInfo();
  }, [id]);

  useEffect(() => {
    const newValues = data && id ? data : initEvent;
    
    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Event";
    if (id && duplicate) {
      _title = `Duplicate Event`;
    } else if (id) {
      _title = `Edit Event - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [id]);

  //Get list of event in branch
  useEffect(() => {
    const getEvents = async () => {
      try {
        const events_url = `api/workshops/workshops/${branch.branch_id}`;
        const res = await axios(events_url);

        dest_id
          ? setEventsToAdd(
              res.data.results.map((e) => {
                return e.workshop_id;
              })
            )
          : setEventsToAdd(eventsToAdd);
      } catch (e) {
        console.log("events in branch: ", e);
      }
    };
    id && getEvents();
  }, [id, dest_id]);

  const saveEvent = async (values) => {
    if (!id) {
      //save new event post
      const eventsUrl = "api/workshops";
      values.description = checkAndReplace(values.description)
      values.workshop_name = checkAndReplace(values.workshop_name)
      values.price_subheader = checkAndReplace(values.price_subheader)
      
      const result = await axios.post(eventsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/events/event-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && !dest_id) {
      //save new duplicated event post
      const eventsUrl = "api/workshops";
      
      const result = await axios.post(eventsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/events/event-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && dest_id) {
      values.branch_id = dest_id;
      
      //edit duplicate event patch
      const editEventUrl = `api/workshops/edit/`;
      const result = await axios.patch(editEventUrl + id, values);
      
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/events/event-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    } else {
      
      //edit event patch
      const editEventUrl = `api/workshops/edit/`;
      const result = await axios.patch(editEventUrl + id, values);
      
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/events/event-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveEventClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveEvent(null);
    }
  };

  const backToEventList = () => {
    history.push(`/events`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the event successfully"
              : editSuccessOpen
              ? "Edited the event successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToEventList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveEventClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Event info
              </a>
            </li>
            
          </ul>
          <div className="mt-5">
            {tab === "basic" && values && (
              <EventEditForm
                event={values}
                btnRef={btnRef}
                saveEvent={saveEvent}
                defaultImages={DEFAULT_IMAGES}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
