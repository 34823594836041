/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useBatchContext } from "./BatchDataContext";

export function BatchActivateDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const context = useBatchContext();
  // Batch Context

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const activateBatch = async () => {
    const activateUrl = "api/batches/activate/";

    //edit batch post
    const result = await axios.patch(activateUrl + id);
    if (result.data === "success") {
      //setEditSuccessOpen(true);
      // console.log("activated");
      context.setRefetch(true)
      setTimeout(() => {
        history.push("/batches/batch-list");
      }, 1000);
    } else {
      //setEditErrorOpen(true);
    }
    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Batch Activate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to activate this batch?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={activateBatch}
            className="btn btn-primary btn-elevate"
          >
            Activate
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
