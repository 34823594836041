import React, { useMemo, useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useWebTransactionsContext } from "./WebTransactionsDataContext";
const prepareFilter = (values) => {
  const {
    idFilter,
    parentNameFilter,
    studentNameFilter,
    statusFilter,
  } = values;
  const filter = {};

  filter.web_transaction_parent_id = idFilter !== "" ? idFilter : "";
  filter.parent_name = parentNameFilter !== "" ? parentNameFilter : "";
  filter.student_name = studentNameFilter !== "" ? studentNameFilter : "";
  filter.status = statusFilter !== "" ? statusFilter : "";

  return filter;
};

export function WebTransactionsFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const { COURSE } = useWebTransactionsContext().TYPES;
  const [dataLoaded, setDataLoaded] = useState(false);
  // const [showMoreFilters, setShowMoreFilters] = useState(false);
  const context = useWebTransactionsContext();

  const stats = ["Abandoned", "New", "Enrolled"];

  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };
  const useStyles = makeStyles({
    btnCircleSm: {
      width: "30px",
      height: "30px",
      padding: "0px 7px",
      margin: "2px 1px 1px 1px",
      borderRadius: "15px",
      fontSize: "4px",
      textAlign: "center",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const initialValues = {
      parentNameFilter: "",
      studentNameFilter: "",
      statusFilter: "New",
      idFilter: "",
    };
    applyFilter(initialValues);
  }, [origData]);

  //fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={{
          parentNameFilter: "",
          studentNameFilter: "",
          statusFilter: "New",
          idFilter: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          /* fix me */
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Transaction ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Parent Name"
                  name="parentNameFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("parentNameFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.parentNameFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by Parent
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Status"
                  name="statusFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("statusFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.statusFilter}
                >
                  <option key={-1} value={""}>
                    {"All"}
                  </option>
                  {stats.map((status, index) => {
                    return (
                      <option key={index} value={status}>
                        {status}
                      </option>
                    );
                  })}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>

              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />

              {/*<button
                className={`btn btn-sm btn-circle btn-light ${classes.btnCircleSm}`}
                onClick={(e) => setShowMoreFilters(!showMoreFilters)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i
                    className={`fa text-${
                      showMoreFilters ? `secondary fa-minus` : `primary fa-plus`
                    }`}
                  ></i>
                </span>
              </button>*/}
            </div>
            {/*showMoreFilters && (
              <div className="form-group row">
                <div className="col-lg-3">
                  <input
                    className="form-control"
                    placeholder="Student Name"
                    name="studentNameFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("studentNameFilter", e.target.value);
                      handleSubmit();
                    }}
                    value={values.studentNameFilter}
                  ></input>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Student
                  </small>
                </div>
              </div>
            )*/}
          </form>
        )}
      </Formik>
    </>
  );
}
