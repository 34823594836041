/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint eqeqeq: "off" */
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { CourseListSelection } from "../../SelectionWidgets/CourseListSelection";
import { InstructorListSelection } from "../../SelectionWidgets/InstructorListSelection";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { CampEditForm } from "./CampComponents/CampEditForm";

const initCamp = {
  startdate: "",
  start_time: "",
  end_time: "",
  maximum_student: "",
  branch_id: "",
  active_id: "1",
  camp_type_id: "2",
  delivery_type_id: "1",
  program_type_id: "1",
  enddate: "",
  half_day_am_end_time: "",
  extended_care_end_time: "",
  short_week_ind: 0,
  full_day_full_ind: 0,
  am_full_ind: 0,
  pm_full_ind: 0,
  full_day_show_ind: 0,
  am_show_ind: 0,
  pm_show_ind: 0,
  half_day_pm_end_time: "",
  half_day_pm_start_time: "",
  times: [
    { type: "Full Day", offered: 0, show: 0, start_time: "", end_time: "" },
    { type: "Morning", offered: 0, show: 0, start_time: "", end_time: "" },
    { type: "Afternoon", offered: 0, show: 0, start_time: "", end_time: "" },
    {
      type: "Extended Care",
      offered: 0,
      show: 0,
      start_time: "",
      end_time: "",
    },
  ],
};

export function CampEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  //camp data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [campCourses, setCampCourses] = useState([]);
  const [campMissingCourses, setCampMissingCourses] = useState([]);
  const [coursesToRemove, setCoursesToRemove] = useState([]);
  const [campInstructors, setCampInstructors] = useState([]);
  const [campMissingInstructors, setCampMissingInstructors] = useState([]);
  const [coursesToAdd, setCoursesToAdd] = useState([]);
  const [coachesToRemove, setCoachesToRemove] = useState([]);
  const [coachesToAdd, setCoachesToAdd] = useState([]);
  const btnRef = useRef();
  //getbranch
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  initCamp.branch_id = branch.branch_id;

  useEffect(() => {
    //TODO: fetch details for editing camp here
    const getCampInfo = async () => {
      const camp_info_url = `api/camps/${id}`;
      const res = await axios(camp_info_url);
      setData(res.data.results[0]);
    };
    id && getCampInfo();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "New Camp";
    if (id && duplicate) {
      _title = `Duplicate Camp ${dest_id ? `- ${dest_id}` : ""}`;
    } else if (id) {
      _title = `Edit Camp - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dest_id]);

  //Get List of courses not in camp
  useEffect(() => {
    const getMissingCourses = async () => {
      try {
        const courses_url = `api/camps/courses/missing/${id}/${branch.branch_id}`;
        const res = await axios(courses_url);
        setCampMissingCourses(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getMissingCourses();
  }, [id]);

  //Get list of courses in Camp
  useEffect(() => {
    const getCourses = async () => {
      try {
        const courses_url = `api/camps/courses/${id}`;
        const res = await axios(courses_url);
        setCampCourses(res.data.results);
        dest_id
          ? setCoursesToAdd(
              res.data.results.map((c) => {
                return { id: c.course_id, show_ind: c.show_ind };
              })
            )
          : setCoursesToAdd(coursesToAdd);
      } catch (e) {
        console.log(e);
      }
    };
    id && getCourses();
  }, [id, dest_id]);

  //Get List of instructors not in camp
  useEffect(() => {
    const getMissingInstructors = async () => {
      try {
        const instructors_url = `api/camps/instructors/missing/${id}`;
        const res = await axios(instructors_url);
        setCampMissingInstructors(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getMissingInstructors();
  }, [id]);

  //Get list of instructors in Camp
  useEffect(() => {
    const getInstructors = async () => {
      try {
        const instructors_url = `api/camps/instructors/${id}`;
        const res = await axios(instructors_url);
        setCampInstructors(res.data.results);
        dest_id
          ? setCoachesToAdd(
              res.data.results.map((c) => {
                return c.instructor_id;
              })
            )
          : setCoursesToAdd(coursesToAdd);
      } catch (e) {
        console.log(e);
      }
    };
    id && getInstructors();
  }, [id, dest_id]);

  const saveCamp = async (values) => {
    values = values ? values : data;
    values.am_full_ind =
      values.am_full_ind === 1 || values.am_full_ind === true ? 1 : 0;
    values.pm_full_ind =
      values.pm_full_ind === 1 || values.pm_full_ind === true ? 1 : 0;
    values.full_day_full_ind =
      values.full_day_full_ind === 1 || values.full_day_full_ind === true
        ? 1
        : 0;

    values.end_time =
      values.times[0].offered == 1 || values.times[0].offered == "1"
        ? values.end_time
        : null;
    values.half_day_am_end_time =
      values.times[1].offered == 1 || values.times[1].offered == "1"
        ? values.half_day_am_end_time
        : null;
    values.half_day_pm_end_time =
      (values.times[2].offered == 1 || values.times[2].offered == "1") &&
      values.half_day_pm_end_time &&
      values.half_day_pm_end_time.length > 0
        ? values.half_day_pm_end_time
        : (values.times[2].offered == 1 || values.times[2].offered == "1") &&
          values.end_time &&
          values.end_time.length > 0
        ? values.end_time
        : null;
    values.half_day_pm_start_time =
      values.times[2].offered == 1 || values.times[2].offered == "1"
        ? values.half_day_pm_start_time
        : null;
    values.extended_care_end_time =
      values.times[3].offered == 1 || values.times[3].offered == "1"
        ? values.extended_care_end_time
        : null;
    if (!id) {
      //save new camp post
      const campsUrl = "api/camps";

      const result = await axios.post(campsUrl, values);
      if (result.data != "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/camps/${result.data}/edit-camp`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && !dest_id) {
      //save new duplicated camp post w/ coaches and courses
      const campsUrl = "api/camps/duplicate";
      const result = await axios.post(campsUrl, values);
      if (result.data != "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/camps/${result.data}/edit-camp`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else {
      const send_data = {
        remove_courses: coursesToRemove,
        add_courses: coursesToAdd,
        edit_courses: campCourses,
        remove_inst: coachesToRemove,
        add_inst: coachesToAdd,
        values: values,
      };
      //edit camp post
      const editCampUrl = `api/camps/`;
      const result = await axios.patch(editCampUrl, send_data);
      if (result.data != "failed") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/camps/camp-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveCampClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveCamp(null);
    }
  };

  const backToCampList = () => {
    history.push(`/camps`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the camp successfully"
              : editSuccessOpen
              ? "Edited the camp successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToCampList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveCampClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Basic info
              </a>
            </li>
            {((id && !duplicate) || (id && duplicate && dest_id)) && (
              <>
                {" "}
                <li className="nav-item" onClick={() => setTab("coaches")}>
                  <a
                    className={`nav-link ${tab === "coaches" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "coaches").toString()}
                  >
                    Coaches
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("courses")}>
                  <a
                    className={`nav-link ${tab === "courses" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "courses").toString()}
                  >
                    Streams
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="mt-5">
            {tab === "basic" && (
              <CampEditForm
                //actionsLoading={actionsLoading}
                //Pass in the edit camp OR new camp
                camp={data ? data : initCamp}
                btnRef={btnRef}
                saveCamp={saveCamp}
              />
            )}
            {tab === "courses" && id && (
              <div>
                {" "}
                <CourseListSelection
                  id={id}
                  type={"camp"}
                  setCoursesToAdd={setCoursesToAdd}
                  coursesToAdd={coursesToAdd}
                  setCoursesToRemove={setCoursesToRemove}
                  coursesToRemove={coursesToRemove}
                  campCourses={campCourses}
                  setCampCourses={setCampCourses}
                  campMissingCourses={campMissingCourses}
                  setCampMissingCourses={setCampMissingCourses}
                />{" "}
              </div>
            )}
            {tab === "coaches" && id && (
              <div>
                {" "}
                <InstructorListSelection
                  id={id}
                  type={"camp"}
                  setCoachesToAdd={setCoachesToAdd}
                  coachesToAdd={coachesToAdd}
                  coachesToRemove={coachesToRemove}
                  setCoachesToRemove={setCoachesToRemove}
                  campInstructors={campInstructors}
                  setCampInstructors={setCampInstructors}
                  campMissingInstructors={campMissingInstructors}
                  setCampMissingInstructors={setCampMissingInstructors}
                />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
