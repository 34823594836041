import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useWebTransactionsContext } from "./WebTransactionsDataContext";
import {WebTransactionsHeaders} from "./WebTransactionCSVHeaders";
import { CSVLink } from "react-csv";
import { date } from "yup";
import moment from "moment";

export function CSVDownloadDialog({
  show,
  onHide,
  setDownloadSuccessOpen,
  setDownloadErrorOpen,
}) {
  const context = useWebTransactionsContext();
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const [CSVData, setCSVData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState({});
  const [endDate, setEndDate] = useState("");
  const downloadBtnRef = useRef();

  const handleDownload = async (requirements) => {
    const response = await axios(`${context.URL.transactionURL}csv-data/${branch.branch_id}/${startDate}/${endDate}`);
    if (response.status === 200) {
      setDownloadSuccessOpen(true);
    } else {
      setDownloadErrorOpen(false);
    }
    setCSVData(response.data.results);
  };

  let headers = WebTransactionsHeaders;

  useEffect(() => {
    if (CSVData.length > 0) {
      downloadBtnRef.current.link.click();
    }
  }, [CSVData]);

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Download Leads</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <p>
            Select the start and end dates for the Web Transactions you wish to download.
          </p>
          <>
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                className={`form-control`}
                placeholder="Date"
                name="startDate"
                onChange={(e) => {
                  if (endDate) {
                    let a = moment(e.target.value);
                    let b = moment(endDate);
                    error.endDate =
                      Math.abs(a.diff(b, "months")) > 6
                        ? "Dates must be within 6 months of each other"
                        : "";
                  }
                  setTouched(true);
                  setStartDate(e.target.value);
                }}
                value={startDate}
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                className={`form-control`}
                placeholder="Date"
                name="endDate"
                onChange={(e) => {
                  if (startDate) {
                    let a = moment(startDate);
                    let b = moment(e.target.value);
                    error.endDate =
                      Math.abs(a.diff(b, "months")) > 6
                        ? "Dates must be within 6 months of each other"
                        : "";
                  }
                  setTouched(true);
                  setEndDate(e.target.value);
                }}
                value={endDate}
              />
            </div>
          </>

          {error.endDate && (
            <div className="text-danger small">{error.endDate}</div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleDownload}
          disabled={
            !touched || error.endDate || startDate == "" || endDate == ""
          }
        >
          <span>
            <i className="fa fa-solid fa-download"></i>
            Download
          </span>
        </Button>
        {CSVData && CSVData.length > 0 && (
          <CSVLink
            filename={
              `Web_Transactions_${
                branch.branch_name
              }_${
                startDate.replaceAll("-", "_")
              }_${
                endDate.replaceAll("-", "_")}.csv`
            }
            headers={headers}
            ref={downloadBtnRef}
            data={CSVData}
          ></CSVLink>
        )}
      </Modal.Footer>
    </Modal>
  );
}
