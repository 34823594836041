/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useEventContext } from "./EventDataContext";

export function EventNotFullDialog({
  history,
  id,
  show,
  onHide,
  setNotFullSuccessOpen,
  setNotFullErrorOpen,
}) {
  const context = useEventContext();
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  const notFullEvent = async () => {
    const notFullUrl = "api/workshops/notfull/";
    const result = await axios.patch(notFullUrl + id);
    if (result.data === "success") {
      setNotFullSuccessOpen(true);
      context.setRefetch(true);

      setTimeout(() => {
        history.push("/events/event-list");
      }, 1000);
    } else {
      setNotFullErrorOpen(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Event Not Full</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to mark this event as not full?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={notFullEvent}
            className="btn btn-delete btn-elevate"
          >
            Mark Not Full
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
