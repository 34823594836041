export const WebTransactionsHeaders = [
    {
      key: "branch_id",
      label: "Branch Id",
    },
    {
      key: "transaction_date",
      label: "Transaction Date",
    },
    {
      key: "parent_fullname",
      label: "Parent Fullname",
    },
    {
      key: "parent_phone",
      label: "Parent Phone",
    },
    {
      key: "parent_email",
      label: "Parent Email",
    },
    {
      key: "student_fullname",
      label: "Student Fullname",
    },
    {
      key: "student_dob",
      label: "Student DOB",
    },
    {
      key: "student_allergies",
      label: "Student Allergies",
    },
    {
      key: "enrollment_status",
      label: "Enrollment Status",
    },
    {
      key: "course_name",
      label: "Course Name",
    },
    {
      key: "camp_start_date",
      label: "Camp Start Date",
    },
    {
      key: "camp_time",
      label: "Camp Start Time",
    },
    {
      key: "batch_time",
      label: "Camp Start Time",
    },
    {
      key: "regular_price",
      label: "Regular Price",
    },
    {
      key: "discount_code",
      label: "Discount Code",
    },
    {
      key: "total",
      label: "Total",
    },
    {
      key: "tax",
      label: "Tax",
    }
  ];