export const oooHeaders = [
  {
    key: "user_id",
    label: "ID",
  },
  {
    key: "hours",
    label: "Hours",
  },
  {
    key: "user_name",
    label: "Name",
  },
  {
    key: "name",
    label: "Task",
  },
  {
    key: "date",
    label: "Date",
  },
  ];