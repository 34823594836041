/* eslint eqeqeq: "off" */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PanToolIcon from "@material-ui/icons/PanTool";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import axios from "axios";
import { FieldArray, FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button as BootstrapButton,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import { checkAndReplace, trimOffTheTop } from "../../../../helpers/Formatter";

const useStyles = makeStyles((theme) => ({
  defaultColumn: {
    width: "25%",
    textAlign: "left",
  },
  statusColumn: {
    width: "2.5%",
    textAlign: "center",
  },
  iconColumn: {
    width: "25%",
    textAlign: "center",
  },
  attendanceStatus: {
    width: "17.5%",
    textAlign: "center",
  },
  identifierColumn: {
    width: "5%",
    textAlign: "center",
  },
  attendanceStatusBadgeSuccess: {
    backgroundColor: "#54afe2",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    fontSize: "12px",
    marginRight: "5px",
  },
  attendanceStatusBadgeDanger: {
    backgroundColor: "#dc3545",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    fontSize: "12px",
    marginRight: "5px",
  },
  attendanceStatusBadgeWarning: {
    backgroundColor: "#ffc107",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    fontSize: "12px",
    marginRight: "5px",
  },
}));

/**
 * Return date of the week based on the day index
 * @date 2022-08-09
 * @param {date} date
 * @param {dayIndex} dayIndex
 */
const getWeekDay = (date, dayIndex) => {
  console.log(
    dayIndex,
    "(",
    typeof parseInt(dayIndex),
    ")",
    moment(date).day(),
    "(",
    typeof moment(date).day(),
    ")\n",
    moment(date).format("yyyy-MM-DD"),
    parseInt(dayIndex) === moment(date).day() ||
      (moment(date).day() === 0 && parseInt(dayIndex) === 7)
  );
  if (
    parseInt(dayIndex) === moment(date).day() ||
    (moment(date).day() === 0 && parseInt(dayIndex) === 7)
  ) {
    return moment(date).format("yyyy-MM-DD");
  }

  const new_date = moment(date)
    .day(parseInt(dayIndex))
    .format("yyyy-MM-DD");
  return new_date;
};

export function AttendanceDialog({
  history,
  batchId,
  dayIndex,
  userId,
  show,
  onHide,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [attendanceOptions, setAttendanceOptions] = useState([]);
  const initAttendanceValues = {
    attendance_week_id: null,
    comment: "",
    attendance_date: "",
    batch_id: parseInt(batchId),
    modified_by: userId,
    student_list: [],
  };

  const classes = useStyles();

  const yupSchema = Yup.object().shape({
    student_list: Yup.array().of(
      Yup.object().shape({
        attendance_id: Yup.number().nullable(),
        modified_on: Yup.string().nullable(),
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        role_id: Yup.string().required(),
        user_id: Yup.string().required(),
      })
    ),
    attendance_date: Yup.string().required("Attendance date is required"),
    batch_id: Yup.number().required("Batch id is required"),
    comment: Yup.string(),
  });

  const setUnmarkedAttendance = async (formikValues) => {
    const setFieldsPromise = new Promise((resolve, reject) => {
      setIsLoading(true);
      const studentList = formikValues.student_list;
      const studentListLength = studentList.length;
      let counter = 0;
      studentList.forEach((student) => {
        if (formikValues.attendance_week_id && !student.modified_on) {
          student.lateEntry = 1;
        }
        if (!student.attendance_id) {
          student.attendance_id = attendanceOptions.find(
            (option) => option.label === "Unmarked"
          ).value;
          student.modified_on = moment().format();
        }
        counter++;
      });
      if (counter === studentListLength) {
        setIsLoading(false);
        resolve(true);
      }
    });
    return setFieldsPromise;
  };

  const saveAttendance = async (formikValues) => {
    setIsLoading(true);
    formikValues.comment = checkAndReplace(trimOffTheTop(formikValues.comment));
    await setUnmarkedAttendance(formikValues);
    if (formikValues.attendance_week_id) {
      await axios.put(`/api/batches/attendance`, formikValues);
    } else {
      await axios.post("/api/batches/attendance", formikValues);
    }
    setIsLoading(false);
    onHide();
  };

  const formik = useFormik({
    initialValues: initAttendanceValues,
    validationSchema: yupSchema,
    onSubmit: (values) => {
      // console.log("values", values);
      saveAttendance(values);
    },
  });

  const getAttendanceStudents = async (date) => {
    setIsLoading(true);
    const response = await axios.get(
      `api/batches/${batchId}/attendance/${date}`
    );
    // console.log("response", response);

    formik.setFieldValue("student_list", response.data.results.student_list);
    formik.setFieldValue(
      "attendance_week_id",
      response.data.results.attendance_week_id
    );
    formik.setFieldValue("comment", response.data.results.comment);
    formik.setFieldValue("attendance_date", date);
    formik.setFieldValue("modified_by", userId);
    formik.setFieldValue("batch_id", parseInt(batchId));

    setIsLoading(false);
  };

  const getAttendanceOptions = async () => {
    const response = await axios.get(`api/batches/attendance/options`);
    setAttendanceOptions(response.data.results);
  };

  useEffect(() => {
    if (batchId) {
      formik.resetForm();
      getAttendanceStudents(getWeekDay(new Date(), dayIndex));
      getAttendanceOptions();
    }
    // eslint-disable-next-line
  }, [batchId]);

  // useEffect(() => {
  //   console.log("formik", formik);
  //   console.log("formik.values", formik.values);
  // }, [formik.values]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Class Attendance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <FieldArray
            name="student_list"
            render={(arrayHelpers) => (
              <fieldset disabled={isLoading}>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <input
                      type="date"
                      id="date"
                      name="attendance_date"
                      className={`form-control ${isLoading ? "disabled" : ""}`}
                      placeholder="Date"
                      value={formik.values.attendance_date}
                      onChange={(e) => {
                        const new_date = getWeekDay(e.target.value, dayIndex);
                        formik.resetForm();
                        getAttendanceStudents(new_date);
                        getAttendanceOptions();
                        formik.setFieldValue("attendance_date", new_date);

                        formik.setFieldValue("student_list", []);
                        getAttendanceStudents(new_date);
                      }}
                    />
                    <span className="form-text text-muted ml-1">
                      Attendance Date
                    </span>
                  </div>
                </div>
                <Table striped bordered hover>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.statusColumn}></TableCell>
                      <TableCell className={classes.identifierColumn}>
                        ID
                      </TableCell>
                      <TableCell className={classes.defaultHeader}>
                        First Name
                      </TableCell>
                      <TableCell className={classes.defaultHeader}>
                        Last Name
                      </TableCell>
                      <TableCell className={classes.attendanceStatus}>
                        Attendance
                      </TableCell>
                      <TableCell className={classes.iconColumn}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(formik.values.student_list) &&
                    Array.isArray(attendanceOptions) &&
                    attendanceOptions.length > 0 &&
                    formik.values.student_list.length > 0 ? (
                      formik.values.student_list.map((student, index) => {
                        return (
                          <TableRow
                            key={index}
                            className={"p-1"}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#fafafa",
                            }}
                          >
                            <TableCell className={classes.statusColumn}>
                              <div className="d-flex flex-row align-items-center">
                                {!formik.values.student_list[index]
                                  .attendance_id ||
                                formik.values.student_list[index]
                                  .attendance_id ===
                                  attendanceOptions.find(
                                    (option) => option.label === "Unmarked"
                                  ).value ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="products-edit-tooltip">
                                        Unmarked Attendance
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      className="fa fa-info-circle mr-2"
                                      aria-hidden="true"
                                      style={{ color: "#54afe2" }}
                                    ></i>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="products-edit-tooltip">
                                        Marked Attendance
                                      </Tooltip>
                                    }
                                  >
                                    <i className="fa fa-check-circle text-success fa-lg mr-2" />
                                  </OverlayTrigger>
                                )}
                                {formik.values.student_list[index].makeup ===
                                true ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="products-edit-tooltip">
                                        Makeup Student
                                      </Tooltip>
                                    }
                                  >
                                    <TransferWithinAStationIcon
                                      style={{ color: "#54afe2" }}
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </TableCell>
                            <TableCell className={classes.identifierColumn}>
                              {student.user_id}
                            </TableCell>
                            <TableCell className={classes.defaultColumn}>
                              {student.first_name}
                            </TableCell>
                            <TableCell className={classes.defaultColumn}>
                              {student.last_name}
                            </TableCell>
                            <TableCell className={classes.attendanceStatus}>
                              {student.attendance_id != null ? (
                                <Badge
                                  pill
                                  className={
                                    student.attendance_id ===
                                    attendanceOptions.find(
                                      (option) => option.label === "Present"
                                    ).value
                                      ? classes.attendanceStatusBadgeSuccess
                                      : student.attendance_id ===
                                        attendanceOptions.find(
                                          (option) => option.label === "Absent"
                                        ).value
                                      ? classes.attendanceStatusBadgeDanger
                                      : classes.attendanceStatusBadgeWarning
                                  }
                                >
                                  {
                                    attendanceOptions.find(
                                      (option) =>
                                        option.value === student.attendance_id
                                    ).label
                                  }
                                </Badge>
                              ) : (
                                <span>-</span>
                              )}
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="products-edit-tooltip">
                                    Student Present
                                  </Tooltip>
                                }
                              >
                                <BootstrapButton
                                  variant={`${
                                    student.attendance_id ==
                                    attendanceOptions.find(
                                      (option) => option.label === "Present"
                                    ).value
                                      ? "primary"
                                      : "secondary"
                                  }`}
                                  className="mr-2 btn-sm align-text"
                                  onClick={() => {
                                    if (
                                      student.attendance_id ===
                                      attendanceOptions.find(
                                        (option) => option.label === "Present"
                                      ).value
                                    ) {
                                      formik.setFieldValue(
                                        `student_list[${index}].attendance_id`,
                                        attendanceOptions.find(
                                          (option) =>
                                            option.label === "Unmarked"
                                        ).value
                                      );
                                    } else {
                                      formik.setFieldValue(
                                        `student_list[${index}].attendance_id`,
                                        attendanceOptions.find(
                                          (option) => option.label === "Present"
                                        ).value
                                      );
                                    }
                                    if (
                                      formik.values.attendance_week_id !==
                                        null &&
                                      student.modified_on === null
                                    ) {
                                      formik.setFieldValue(
                                        `student_list[${index}].lateEntry`,
                                        1
                                      );
                                    }
                                    formik.setFieldValue(
                                      `student_list[${index}].modified_on`,
                                      moment().format()
                                    );
                                  }}
                                >
                                  <PanToolIcon fontSize="small" />
                                </BootstrapButton>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="products-edit-tooltip">
                                    Student Absent
                                  </Tooltip>
                                }
                              >
                                <BootstrapButton
                                  variant={`${
                                    student.attendance_id ===
                                    attendanceOptions.find(
                                      (option) => option.label === "Absent"
                                    ).value
                                      ? "danger"
                                      : "secondary"
                                  }`}
                                  className="mr-2 btn-sm align-text"
                                  onClick={() => {
                                    if (
                                      student.attendance_id ===
                                      attendanceOptions.find(
                                        (option) => option.label === "Absent"
                                      ).value
                                    ) {
                                      formik.setFieldValue(
                                        `student_list[${index}].attendance_id`,
                                        attendanceOptions.find(
                                          (option) =>
                                            option.label === "Unmarked"
                                        ).value
                                      );
                                    } else {
                                      formik.setFieldValue(
                                        `student_list[${index}].attendance_id`,
                                        attendanceOptions.find(
                                          (option) => option.label === "Absent"
                                        ).value
                                      );
                                    }
                                    if (
                                      formik.values.attendance_week_id !==
                                        null &&
                                      student.modified_on === null
                                    ) {
                                      formik.setFieldValue(
                                        `student_list[${index}].lateEntry`,
                                        1
                                      );
                                    }
                                    formik.setFieldValue(
                                      `student_list[${index}].modified_on`,
                                      moment().format()
                                    );
                                  }}
                                >
                                  <DirectionsRunIcon fontSize="small" />
                                </BootstrapButton>
                              </OverlayTrigger>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="center"
                          style={{ fontSize: "1.25rem" }}
                        >
                          {isLoading ? (
                            <LinearProgress
                              color="secondary"
                              styles="width:100%"
                            />
                          ) : (
                            "No Students"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {/* Comment Section */}
                <div className="form-group row" style={{ marginTop: "3rem" }}>
                  <div className="col-lg-12">
                    <label htmlFor="comment">Attendance Comment</label>
                    <textarea
                      // id="comment"
                      name="comment"
                      className={`form-control ${isLoading ? "disabled" : ""}`}
                      placeholder="Comment"
                      rows={3}
                      value={formik.values.comment}
                      onChange={(e) => {
                        formik.setFieldValue("comment", e.target.value);
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-danger form-text m-2">
                        {formik.errors.comment ? formik.errors.comment : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
          />
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer>
        <BootstrapButton
          variant="secondary"
          onClick={() => {
            onHide();
          }}
        >
          Close
        </BootstrapButton>
        <BootstrapButton
          variant="primary"
          onClick={() => {
            if (formik.isValid) {
              formik.submitForm();
            }
          }}
          disabled={
            (Array.isArray(formik.values.student_list) &&
              formik.values.student_list.length === 0) ||
            Object.values(formik.errors).length > 0
          }
        >
          Save
        </BootstrapButton>
      </Modal.Footer>
    </Modal>
  );
}
