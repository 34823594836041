import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../../_metronic/layout";
import { InstructorProfileEdit } from "./InstructorProfileEdit";
import { InstructorProfileList } from "./InstructorProfileList";
import { InstructorProfileContextProvider } from "./Components/InstructorProfileDataContext";

export default function InstructorProfilePage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const instructorProfileUIEvents = {
    newInstructorProfileButtonClick: () => {
      history.push("/branch-maintenance/instructor-profiles/add-instructor-profiles");
    },
    openEditInstructorProfilePage: (id) => {
      history.push(`/branch-maintenance/instructor-profiles/${id}/edit-instructor-profiles`);
    },
    openActivateInstructorProfileDialog: (id) => {
      history.push(`/branch-maintenance/instructor-profiles/instructor-profiles-list/${id}/activate-instructor-profiles`);
    },
    openDeactivateInstructorProfileDialog: (id) => {
      history.push(`/branch-maintenance/instructor-profiles/instructor-profiles-list/${id}/de-activate-instructor-profiles`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <InstructorProfileContextProvider instructorProfileUIEvents={instructorProfileUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/branch-maintenance/instructor-profiles" to="/branch-maintenance/instructor-profiles/instructor-profiles-list" />}
          <ContentRoute path="/branch-maintenance/instructor-profiles/add-instructor-profiles" component={InstructorProfileEdit} />

          <ContentRoute
            path="/branch-maintenance/instructor-profiles/:id/edit-instructor-profiles"
            component={InstructorProfileEdit}
          />

          <ContentRoute path="/branch-maintenance/instructor-profiles/instructor-profiles-list" component={InstructorProfileList} />

        </Switch>
      </Suspense>
    </InstructorProfileContextProvider>
  );
}
