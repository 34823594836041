import React, { useMemo, useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useBranchCourseContext } from "./BranchCourseDataContext";

const prepareFilter = (values) => {
  const {
    idFilter,
    programTypeFilter,
    courseTypeFilter,
    activeFilter,
  } = values;
  const filter = {};
  // Filter by program
  filter.id = idFilter !== "" ? idFilter : "";
  filter.program_name = programTypeFilter !== "" ? programTypeFilter : "";
  filter.batch_type = courseTypeFilter !== "" ? courseTypeFilter : "";
  filter.active_value = activeFilter !== "" ? activeFilter : "";
  // Filter by all fields
  //   filter.days = searchText;
  //   if (searchText) {
  //     filter.start_time = searchText;
  //     filter.end_time = searchText;
  //     filter.instructor = searchText;
  //   }
  return filter;
};
export function BranchCourseFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  const [dataLoaded, setDataLoaded] = useState(false);
  const context = useBranchCourseContext();

  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  }

  useEffect(() => {
    const initialValues = {
      idFilter: "",
      programTypeFilter: "",
      courseTypeFilter: "",
      activeFilter: "Active",
    };
    applyFilter(initialValues);
  }, [origData]);
  //fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={{
          idFilter: "",
          programTypeFilter: "",
          courseTypeFilter: "",
          activeFilter: "Active",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-1">
                <input
                  className="form-control"
                  placeholder="ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Active"
                  name="activeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("activeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.activeFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.ACTIVE &&
                    DROPDOWN_TYPE.ACTIVE.map((a) => (
                      <option key={a.id} value={a.value}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Active
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Schedule Type"
                  name="courseTypeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("courseTypeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.courseTypeFilter}
                >
                  <option value="">All</option>
                  <option value="RC">Regular Class</option>
                  <option value="Camp">Camp</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Schedule Type
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Program Type"
                  name="programTypeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("programTypeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.programTypeFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.PROGRAM_TYPE &&
                    DROPDOWN_TYPE.PROGRAM_TYPE.map((a) => (
                      <option key={a.id} value={a.value}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Program Type
                </small>
              </div>
              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />
              {/* <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div> */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
