import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  user: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      console.log(action.payload);
    },
  },
});
