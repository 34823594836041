import { Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, useSubheader } from "../../../../_metronic/layout";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { BranchCourseActivateDialog } from "./Components/BranchCourseActivateDialog";
import { BranchCourseCallEnrollOffDialog } from "./Components/BranchCourseCallEnrollOffDialog";
import { BranchCourseCallEnrollOnDialog } from "./Components/BranchCourseCallEnrollOnDialog";
import { useBranchCourseContext } from "./Components/BranchCourseDataContext";
import { BranchCourseDeleteDialog } from "./Components/BranchCourseDeleteDialog";
import { BranchCourseFeeEdit } from "./Components/BranchCourseFees/BranchCourseFeeEdit";
import { BranchCourseFilter } from "./Components/BranchCourseFilter";
import CourseTable from "./Components/CourseTable";

let headers = [
  { key: "branch_course_id", label: "Branch Stream Id" },
  { key: "branch_course_fee_id", label: "Branch Stream Fee Id" },
  { key: "course_code", label: "Stream Code" },
  { key: "course_name", label: "Stream Name" },
  { key: "active_value", label: "Fee Status" },
  { key: "course_active", label: "Stream Status" },
  { key: "batch_type_value", label: "Batch Type" },
  { key: "session", label: "Session" },
  { key: "frequency_unit", label: "Frequency Unit" },
  { key: "duration", label: "Duration" },
  { key: "delivery_type_value", label: "Delivery Type Value" },
  { key: "half_day_ind", label: "Half Day Indicator" },
  { key: "course_fee", label: "Stream Fee" },
  { key: "fee_tax_percentage", label: "Fee Tax Percentage" },
  { key: "extended_care_fee", label: "Extended Care Fee" },
  { key: "call_to_enroll_ind", label: "Call to Enroll Indicator" },
];

const CSVDownload = (props) => {
  const btnRef = useRef(null);
  useEffect(() => btnRef.current?.click(), [btnRef]);
  return (
    <CSVLink {...props}>
      <span ref={btnRef} />
    </CSVLink>
  );
};

export const BranchCourseList = () => {
  const [data, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formattedData, setformattedData] = useState();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useBranchCourseContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Branch Stream List");
  const coursesUrl = "/api/branch-course/";

  async function fetchCoursesForBranch() {
    setLoading(true);
    const result = await axios(coursesUrl + branch.branch_id);
    setData(result.data);
    setOrigData(result.data);
    setLoading(false);
  }

  const getFees = async () => {
    const results = await axios(`api/branch-course/getCSV/${branch.branch_id}`);
    return results.data.data;
  };
  const generateReport = () => {
    getFees().then((data) => {
      setformattedData(data);
    });
  };

  //fetching classes for a branch
  useEffect(() => {
    branch && fetchCoursesForBranch();
  }, [branch]);

  //fetching classes for a branch
  useEffect(() => {
    context.refetch && fetchCoursesForBranch();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;
      for (let key in filters) {
        if (filters[key] !== "") {
          if (key === "id") {
            if (!(item["branch_course_id"] + "").includes(filters[key])) {
              add = false;
            }
          }
          if (key === "program_name") {
            // console.log(item, filters, item["program_name"].includes(filters[key]))
            if (!item["program_name"].includes(filters[key])) {
              add = false;
            }
          }
          if (key === "batch_type") {
            if (
              (filters[key] === "RC" && item.frequency_unit !== "monthly") ||
              (filters[key] !== "RC" && item.frequency_unit === "monthly")
            ) {
              add = false;
            }

            // When the filter used to be types of batches not just RC vs Camp
            // Asad is leaving this here because he bets Jai will want it in the future
            // if (
            //   item.fees.filter((fee) => {
            //     return fee.batch_type != filters[key];
            //   }).length == item.fees.length
            // ) {
            //   add = false;
            // }
          }
          if (
            key !== "id" &&
            key !== "batch_type" &&
            key !== "program_name" &&
            (item[key] === undefined || item[key] !== filters[key])
          )
            return false;
        }
      }
      return add && true;
    });
    setData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={context.successOpen}
        autoHideDuration={3000}
        onClose={() => context.setSuccessOpen(false)}
      >
        <SnackbarWrapper
          onClose={() => context.setSuccessOpen(false)}
          variant={
            context.successOpen
              ? "success"
              : context.errorOpen
              ? "error"
              : "success"
          }
          message={
            context.successOpen
              ? "Edited the streams successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Stream Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              className="btn btn-icon btn-success btn-hover-primary btn-lg"
              onClick={generateReport}
            >
              <i className="fa fa-solid fa-download"></i>
              {formattedData && (
                <CSVDownload
                  filename={`${branch.branch_name}-fees-summary.csv`}
                  data={formattedData}
                  headers={headers}
                ></CSVDownload>
              )}
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <BranchCourseFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      {data && (
        <span>
          <CourseTable courses={data} showActions={true} loading={loading} />

          <ContentRoute path="/branch-course/branch-course-list/:id/edit">
            {({ history, match }) => (
              <BranchCourseFeeEdit
                history={history}
                show={match != null}
                course_info={match && data[match.params.id]}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/branch-course/branch-course-list");
                }}
              />
            )}
          </ContentRoute>

          <ContentRoute path="/branch-course/branch-course-list/:id/activate-branch">
            {({ history, match }) => (
              <BranchCourseActivateDialog
                history={history}
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/branch-course/branch-course-list");
                }}
              />
            )}
          </ContentRoute>
          <ContentRoute path="/branch-course/branch-course-list/:id/delete-branch">
            {({ history, match }) => (
              <BranchCourseDeleteDialog
                history={history}
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/branch-course/branch-course-list");
                }}
              />
            )}
          </ContentRoute>
          <ContentRoute path="/branch-course/branch-course-list/:id/enroll-on">
            {({ history, match }) => (
              <BranchCourseCallEnrollOnDialog
                history={history}
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/branch-course/branch-course-list");
                }}
              />
            )}
          </ContentRoute>
          <ContentRoute path="/branch-course/branch-course-list/:id/enroll-off">
            {({ history, match }) => (
              <BranchCourseCallEnrollOffDialog
                history={history}
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/branch-course/branch-course-list");
                }}
              />
            )}
          </ContentRoute>
        </span>
      )}
    </div>
  );
};
