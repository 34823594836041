export const campHeaders = [
  {
    key: "delivery_type",
    label: "Delivery Type",
  },
  {
    key: "program_type",
    label: "Program Type",
  },
  {
    key: "camp_week",
    label: "Camp Week",
  },
  {
    key: "camp_type",
    label: "Camp Type",
  },
  {
    key: "camp_times",
    label: "Camp Times",
  },
  {
    key: "course_abbr",
    label: "Stream (Abbr)",
  },
  {
    key: "delivery_type",
    label: "Delivery Type",
  },
  {
    key: "student_id",
    label: "Student ID",
  },
  {
    key: "student_name",
    label: "Student Name",
  },
  {
    key: "parent_name",
    label: "Parent Name",
  },
  {
    key: "phone",
    label: "Phone Number",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "alternate_emails",
    label: "Alternate Emails",
  },
  {
    key: "dob",
    label: "DOB",
  },
  {
    key: "allergies",
    label: "Allergies",
  },
  {
    key: "special_need",
    label: "Special Needs",
  },
];
