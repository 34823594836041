/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export const WebsiteAnnouncementsTableActions = ({
  row,
  openEditWebsiteAnnouncementsPage,
  openActivateWebsiteAnnouncementsDialog,
  openDeactivateWebsiteAnnouncementsDialog,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit Website Announcements</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            openEditWebsiteAnnouncementsPage(row.announcements_id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

   
    </>
  );
};
