import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";

const ACTIVE_URL = "api/workshops/active";


const EventContext = createContext();

export function useEventContext() {
  return useContext(EventContext);
}

export const EventConsumer = EventContext.Consumer;

export function EventContextProvider({ eventUIEvents, children }) {
  const [ACTIVE, SETACTIVE] = useState([]);

  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const eventContext = {
    newEventButtonClick: eventUIEvents.newEventButtonClick,
    openEditEventPage: eventUIEvents.openEditEventPage,
    openDeleteEventDialog: eventUIEvents.openDeleteEventDialog,
    openActivateEventDialog: eventUIEvents.openActivateEventDialog,
    openEventFullDialog: eventUIEvents.openEventFullDialog,
    openEventNotFullDialog: eventUIEvents.openEventNotFullDialog,
    openDuplicateEventPage: eventUIEvents.openDuplicateEventPage,
    refetch: eventUIEvents.refetch,
    setRefetch: eventUIEvents.setRefetch,
    TYPES: {
      ACTIVE,
      SETACTIVE,
    },
  };

  return (
    <EventContext.Provider value={eventContext}>
      {children}
    </EventContext.Provider>
  );
}
