import ApexCharts from "apexcharts";
import axios from "axios";
import React, { useEffect } from "react";

export function BranchBarGraph(props) {
  let getOptions;
  let querystr;

  const setGraph = (data) => {
    const element = document.getElementById(props.type);
    if (!element) {
      return;
    }
    const options = getOptions(data);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanup() {
      chart.destroy();
    };
  };
  const fetchData = async () => {
    // console.log(querystr);
    return await axios(querystr);
  };

  const configureGraphType = (type) => {
    switch (type) {
      case "Course":
        querystr =
          "api/reports/class/" + props.bid + "/default/default/1/graph/course";

        getOptions = (data) => {
          let optionsCourse = {
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            title: {
              text: "Enrollment By Stream",
              align: "center",
              margin: 0,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
            theme: {
              palette: "palette1",
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            series: [
              {
                name: "In-Person",
                data: Object.values(data.data["In-person"]),
              },
              {
                name: "Virtual",
                data: Object.values(data.data["Virtual"]),
              },
            ],
            xaxis: {
              categories: Object.keys(data.data.Virtual),
            },
            yaxis: {
              categories: ["students"],
            },
          };
          return optionsCourse;
        };
        break;
      case "Day":
        querystr =
          "api/reports/class/" + props.bid + "/default/default/1/graph/day";

        getOptions = (data) => {
          let week = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];

          let seriesObj = {};
          for (const day of week) {
            Object.keys(data.data).map((s) => {
              if (Object.keys(seriesObj).includes(s)) {
                seriesObj[s] = [...seriesObj[s], data.data[s][day]];
              } else {
                seriesObj[s] = [data.data[s][day]];
              }
            });
          }
          let seriesArr = Object.keys(seriesObj).map((e) => {
            return { name: e, data: seriesObj[e] };
          });
          let optionsCourse = {
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            title: {
              text: "Enrollment By Day",
              align: "center",
              margin: 0,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
            theme: {
              palette: "palette1",
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            series: [...seriesArr],
            xaxis: {
              categories: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
            },
          };
          return optionsCourse;
        };

        break;
      case "Count":
        querystr =
          "api/reports/class/" +
          props.bid +
          "/default/default/1/graph/deliverycount";

        getOptions = (data) => {
          let values = Object.values(data.data);
          // console.log(data)
          // console.log(values)

          let options = {
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            title: {
              text: "Enrollment By Program",
              align: "center",
              margin: 0,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
            theme: {
              palette: "palette1",
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            series: [
              {
                name: "In-Person",
                data: [
                  values[0]["In-person"],
                  values[1]["In-person"],
                  values[2]["In-person"],
                  values[3]["In-person"],
                  values[4]["In-person"],
                ],
              },
              {
                name: "Virtuals",
                data: [
                  values[0]["Virtual"],
                  values[1]["Virtual"],
                  values[2]["Virtual"],
                  values[3]["Virtual"],
                  values[4]["Virtual"],
                ],
              },
            ],
            xaxis: {
              categories: Object.keys(data.data),
            },
            yaxis: {
              categories: ["students"],
            },
          };
          return options;
        };
        break;
      case "Alt":
        querystr =
          "api/reports/class/" +
          props.bid +
          "/default/default/1/graph/deliverycount";

        getOptions = (data) => {
          let seriesArr = [];
          Object.keys(data.data).map((s) => {
            seriesArr.push({
              name: s,
              data: [data.data[s]["In-person"], data.data[s]["Virtual"]],
            });
          });

          let options = {
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            title: {
              text: "Enrollment By Delivery Type",
              align: "center",
              margin: 0,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#263238",
              },
            },
            theme: {
              palette: "palette1",
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            series: [...seriesArr],
            xaxis: {
              categories: ["In-person", "Virtual"],
            },
            yaxis: {
              categories: ["students"],
            },
          };
          return options;
        };
        break;
    }
  };
  useEffect(() => {
    configureGraphType(props.type);
    fetchData().then((result) => {
      if (!Object.keys(result.data).length == 0) {
        setGraph(result);
        // console.log("Debugging Purposes Ignore : " + "Type : " + props.type);
        // console.log(result.data);
      }
      // else {
      //   console.log("Center returned no data");
      // }
    });
  }, []);
  return (
    <div className="card card-custom bg-gray-100 p-10">
      <div id={props.type}></div>
    </div>
  );
}
