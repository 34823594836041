/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useBatchContext } from "./BatchDataContext";

export function BatchNotFullDialog({
  history,
  id,
  full_value,
  show,
  onHide,
  setNotFullSuccessOpen,
  setNotFullErrorOpen,
}) {
  const context = useBatchContext();
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const markBatchNotFull = async () => {
    const batchNotFullUrl = "api/batches/notfull/";
    const result = await axios.patch(batchNotFullUrl + id);
    if (result.data === "success") {
      console.log("marked batch not full");
      setNotFullSuccessOpen(true);
      context.setRefetch(true);

      setTimeout(() => {
        history.push("/batches/batch-list");
      }, 1000);
    } else {
      console.log(result.data);
      setNotFullErrorOpen(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Batch Not Full
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to mark this batch as not full?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={markBatchNotFull}
            className="btn btn-delete btn-elevate"
          >
            Mark Not Full
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
