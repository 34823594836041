export const LMSvsPortalActivityHeaders = [
    {
      key: "student_id",
      label: "Student ID",
    },
    {
      key: "student_name",
      label: "Student Name",
    },
    {
      key: "startDate",
      label: "Batch Start Date",
    },
    {
      key: "end_date",
      label: "Batch End Date",
    },
    {
      key: "course_name",
      label: "Stream Name",
    },
    {
      key: "course_code",
      label: "Stream Code",
    },
    {
      key: "sub_course_code",
      label: "Sub Stream Code",
    },
  ];