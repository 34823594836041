// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  TextArea,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { InputWithLimit } from "../../../../../../_metronic/_partials/controls/forms/InputWithLimit";
import moment from "moment";
import { useInstructorProfileContext } from "./InstructorProfileDataContext";
import { PersistFormikValues } from "formik-persist-values";

// Validation schema
const InstructorProfileEditSchema = Yup.object().shape({
  name: Yup.string().required("A name is required"),
  image_link: Yup.string().required("An image URL is required"),
  position: Yup.string().required("An Job Title is required"),
  bio: Yup.string().required("A bio is required"),
  active_id: Yup.number().required("An Active Status is required"),
});

export function InstructorProfileEditForm({
  profile,
  btnRef,
  saveInstructorProfile,
  defaultImages,
}) {
  const DROPDOWN_TYPE = useInstructorProfileContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={profile}
        validationSchema={InstructorProfileEditSchema}
        onSubmit={(values) => {
          saveInstructorProfile(values);
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
          getFieldProps,
        }) => (
          console.log(values, touched, errors),
          (
            <>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-md-4">
                    <Select name={`active_id`} label="Profile Active">
                      {DROPDOWN_TYPE.ACTIVE &&
                        DROPDOWN_TYPE.ACTIVE.map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.value}
                          </option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-lg-4">
                    <label className={"required-field"}>
                      Enter Instructor Name
                    </label>
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Instrutor Name"
                      type="text"
                    />
                  </div>

                  <div className="col-lg-4">
                    <label className={"required-field"}>Enter Title</label>
                    <Field
                      name="position"
                      component={Input}
                      placeholder="Enter Staff Title"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label className={"required-field"}>Enter Image Link</label>
                    <Field
                      name="image_link"
                      component={Input}
                      placeholder="Image link"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label className={"required-field"}>
                      Enter Instructor Bio
                    </label>
                    <InputWithLimit
                      name="bio"
                      type="textarea"
                      rows="6"
                      value={values.bio}
                      touched={touched.bio}
                      errors={errors.bio}
                      onChange={(e) => {
                        setFieldValue("bio", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            </>
          )
        )}
      </Formik>
    </>
  );
}
