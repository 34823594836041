// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  TextArea,
  Select,
  SelectCreatable,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import moment from "moment";
import { useFeatureContext } from "./FeatureDataContext";
import { PersistFormikValues } from "formik-persist-values";

// Validation schema
const FeatureEditSchema = Yup.object().shape({
  active_id: Yup.string(),
  image_link: Yup.string().required("An image URL is required"),
  clickable_link: Yup.string()
    .required("Link is required")
    .required("An feature link is required"),

  feature_name: Yup.string().required("Feature Name is required"),
});

export function FeatureEditForm({
  feature,
  btnRef,
  saveFeature,
  defaultImages,
}) {
  const DROPDOWN_TYPE = useFeatureContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={feature}
        validationSchema={FeatureEditSchema}
        onSubmit={(values) => {
          saveFeature(values);
        }}
      >
        {({ handleSubmit, values, errors, touched }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-md-3">
                  <Select name={`active_id`} label="Feature Active">
                    {DROPDOWN_TYPE.ACTIVE &&
                      DROPDOWN_TYPE.ACTIVE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Feature Name</label>
                  <Field
                    name="feature_name"
                    component={Input}
                    placeholder="Feature Name"
                    type="text"
                  />
                  {touched.feature_name && errors.feature_name && (
                    <ErrorMessage
                      name={"feature_name"}
                      component="div"
                      className="invalid-feedback"
                    />
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Image Link</label>
                  <Field
                    name="image_link"
                    component={Input}
                    placeholder="Image link"
                    type="text"
                  />
                  {touched.image_link && errors.image_link && (
                    <ErrorMessage
                      name={"image_link"}
                      component="div"
                      className="invalid-feedback"
                    />
                  )}
                </div>
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Feature Link</label>
                  <Field
                    name="clickable_link"
                    component={Input}
                    placeholder="Feature link"
                    type="text"
                  />
                  {touched.clickable_link && errors.clickable_link && (
                    <ErrorMessage
                      name={"clickable_link"}
                      component="div"
                      className="invalid-feedback"
                    />
                  )}
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
              {/* {feature.featured_in_id && (
                <PersistFormikValues name="feature-form" />
              )} */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
