import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    minWidth: 750,
    marginBottom: theme.spacing(2),
  },
  regularClassIcon: {
    color: "#9f7431",
  },
}));

export default function RequestForm({}) {
  // Family Redux state
  const suhbeader = useSubheader();
  suhbeader.setTitle("Request Form Page");
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className="col-lg-12">
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Request Form
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0 pb-10">
          <div className={classes.root}>
            <div className="rounded border border-primary">
              <div className="bg-primary p-5 text-light-warning">
                <h2>Request Form</h2>
              </div>
              <div className="p-5 ">
                <p className="">
                  Use this form to report Portal/Website bugs, Portal/Website
                  enhancements, Price Change Requests, Purchase Requests etc.
                </p>
                <button className="btn btn-warning">
                  <a
                    className="text-light"
                    target={"_blank"}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfu5KYNM053PyZOMlh-BCteEL7PH0OR3_tW3QLWlQG5zdjIvA/viewform?usp=sf_link"
                  >
                    Fill out Form
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
