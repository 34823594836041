import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "react-bootstrap";
import HelpPageRow from "./HelpPageRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    minWidth: 750,
    marginBottom: theme.spacing(2),
  },
  regularClassIcon: {
    color: "#9f7431",
  },
}));

export default function HelpPage({}) {
  // Family Redux state
  const suhbeader = useSubheader();
  suhbeader.setTitle("Help Page");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState("");
  const [helpData, setHelpData] = useState([]);

  const fetchHelpData = async () => {
    const result = await axios("/api/help");
    console.log(result)
    setHelpData(result.data.results)
  }

useEffect(() => {
    fetchHelpData();
}, [])

  const handleRowClick = (help_id) => {
    setOpen(!open);
    setHelpOpen(help_id);
  };

  return (
    <div className="col-lg-12">
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Help Page
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  {helpData && helpData.map((hD) => {
                    return <HelpPageRow hD={hD} />;
                  })}
                </Table>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
