// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import axios from "axios";
import { ErrorMessage } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function AttendanceReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;
  const BATCHES_URL = useReportContext().URLS.BATCHES_URL;
  const branch = useReportContext().AUTH.branch;
  const userAuth = useReportContext().AUTH.userAuth;

  const updateStudentNames = async (startDate, endDate) => {
    const response = await axios(
      `${BATCHES_URL}/${userAuth[0].user_id}/attendance/options/students/name?startDate=${startDate}&endDate=${endDate}&branch_id=${branch.branch_id}`
    );
    DROPDOWN_TYPE.SETSTUDENT_NAME(response.data.results);
  };

  return (
    <>
      <div className="form-group row">
        <div className={"col-lg-3"}>
          <label className={"required-field pr-2"}>Start Date</label>
          <OverlayTrigger overlay={<Tooltip>The day of the class</Tooltip>}>
            <i className="fa fa-question-circle" aria-hidden="true"></i>
          </OverlayTrigger>
          <input
            type="date"
            className={
              "form-control" +
              (errors.startdate && touched.startdate ? " is-invalid" : "")
            }
            value={values.startdate ? values.startdate.split("T")[0] : ""}
            onChange={(e) => {
              setFieldValue("startdate", e.target.value);
              updateStudentNames(e.target.value, values.enddate);
              setFieldValue("student_id", "");
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          ></input>
          <ErrorMessage
            name={`startdate`}
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className={"col-lg-3"}>
          <label className={"required-field pr-2"}>End Date</label>
          <OverlayTrigger overlay={<Tooltip>The day of the class</Tooltip>}>
            <i className="fa fa-question-circle" aria-hidden="true"></i>
          </OverlayTrigger>
          <input
            type="date"
            className={
              "form-control" +
              (errors.enddate && touched.enddate ? " is-invalid" : "")
            }
            value={values.enddate ? values.enddate.split("T")[0] : ""}
            onChange={(e) => {
              setFieldValue("enddate", e.target.value);
              updateStudentNames(values.startdate, e.target.value);
              setFieldValue("student_id", "");
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          ></input>
          <ErrorMessage
            name={`enddate`}
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className={"col-lg-3"}>
          <Select
            name={`student_id`}
            label="Student Name"
            isLabelListCount={true}
            validationColorScheme={false}
            isClearable={true}
            onChange={(e) => {
              if (e) {
                setFieldValue("student_id", e.value);
              } else {
                setFieldValue("student_id", "");
              }
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          >
            {DROPDOWN_TYPE.STUDENT_NAME &&
              DROPDOWN_TYPE.STUDENT_NAME.map((a) => (
                <option key={a.value} value={a.value}>
                  {a.label}
                </option>
              ))}
          </Select>
          <ErrorMessage
            name={`student_id`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </>
  );
}
