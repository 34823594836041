// get users based on name
export const getSearchedUsers = (users, keyword) => {
  const searchedUsers =
    keyword.length > 2
      ? users.filter((user) =>
          (`${user.firstname} ${user.lastname} ${user.user_id} ${user.mobile} ${user.email}`)
            .toString()
            .toLowerCase()
            .includes(keyword.toString().toLowerCase())
        )
      : [];

  return searchedUsers;
};

// //get users based on category
// export const getSortedUsers = (users, sortType, sortValue) => {
//   if (users && sortType && sortValue) {
//     if (sortType === "type") {
//       return users.filter(
//         (product) => product.type.filter((single) => single === sortValue)[0]
//       );
//     }

//     if (sortType === "search") {
//       const includeColumns = ["name", "type", "id"];

//       const searchedData = users.filter((product) => {
//         return Object.keys(product).some((key) => {
//           return includeColumns.includes(key)
//             ? product[key] &&
//                 product[key]
//                   .toString()
//                   .toLowerCase()
//                   .includes(sortValue)
//             : false;
//         });
//       });
//       return searchedData;
//     }
//     // if (sortType === "filterSort") {
//     //   let sortUsers = [...users];
//     //   if (sortValue === "default") {
//     //     return sortUsers;
//     //   }
//     //   if (sortValue === "priceHighToLow") {
//     //     return sortUsers.sort((a, b) => {
//     //       return b.price[0] - a.price[0];
//     //     });
//     //   }
//     //   if (sortValue === "priceLowToHigh") {
//     //     return sortUsers.sort((a, b) => {
//     //       return a.price[0] - b.price[0];
//     //     });
//     //   }
//     // }
//   }
//   return users;
// };
