import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { CouponEdit } from "./CouponEdit";
import { CouponList } from "./CouponList";
import { CouponContextProvider } from "./Components/CouponDataContext";

export default function CouponPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const couponUIEvents = {
    newCouponButtonClick: () => {
      history.push("/coupons/add-page");
    },
    openEditCouponPage: (id) => {
      history.push(`/coupons/${id}/edit-coupon`);
    },
    openDeleteCouponDialog: (id) => {
      history.push(`/coupons/coupon-list/${id}/delete-coupon`);
    },
    openActivateCouponDialog: (id) => {
      history.push(`/coupons/coupon-list/${id}/activate-coupon`);
    },
    openCouponShowDialog: (id) => {
      history.push(`/coupons/coupon-list/${id}/coupon-show`);
    },
    openCouponHideDialog: (id) => {
      history.push(`/coupons/coupon-list/${id}/coupon-hide`);
    },
    openDuplicateCouponPage: (id) => {
      history.push(`/coupons/${id}/duplicate-coupon/`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <CouponContextProvider couponUIEvents={couponUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/coupons" to="/coupons/coupon-list" />}
          <ContentRoute path="/coupons/add-coupon" component={CouponEdit} />

          <ContentRoute
            path="/coupons/:id/edit-coupon"
            component={CouponEdit}
          />

          <ContentRoute path="/coupons/coupon-list" component={CouponList} />

          <ContentRoute
            exact
            path="/coupons/:id/:duplicate/:dest_id"
            component={CouponEdit}
          />

          <ContentRoute
            exact
            path="/coupons/:id/:duplicate"
            component={CouponEdit}
          />
        </Switch>
      </Suspense>
    </CouponContextProvider>
  );
}
