import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../../../helpers/TimeConvert";
import { useFeatureContext } from "../Components/FeatureDataContext";
import { FeatureTableActions } from "./ActionFormatter/FeatureTableActions";
import moment from "moment";
import { Badge } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function FeatureRow(props) {
  const classes = useStyles();
  const { row, showActions } = props;
  const featureContext = useFeatureContext();


  return (
    <Fragment>
      <TableRow key={row.featured_in_id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.featured_in_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.feature_name}
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <FeatureTableActions
              row={row}
              openEditFeaturePage={featureContext.openEditFeaturePage}
              openActivateFeatureDialog={featureContext.openActivateFeatureDialog}
              openDeactivateFeatureDialog={featureContext.openDeactivateFeatureDialog}
            />
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
}
