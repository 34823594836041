// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  TextArea,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { InputWithLimit } from "../../../../../../_metronic/_partials/controls/forms/InputWithLimit";
import moment from "moment";
import { useWebsiteAnnouncementsContext } from "./WebsiteAnnouncementsDataContext";
import { PersistFormikValues } from "formik-persist-values";

// Validation schema
const WebsiteAnnouncementsEditSchema = Yup.object().shape({
  active_id: Yup.number().required("An active value is required"),
  show_ind: Yup.number().required("A show value is required"),
  title: Yup.string().required("A title is required"),
  button_text: Yup.string(),
  description: Yup.string().required("A description is required"),
  img: Yup.string()
    .required("An image URL is required")
    .nullable()
    .test("valid-image", "Image URL is not valid", (image) => {
      if (image !== null) {
        return image.match(/\.(jpeg|jpg|png|webp)$/) !== null;
      } else {
        return true;
      }
    }),
  link: Yup.string().required("A link is required"),
  startdate: Yup.date().required("Start Date is required"),
  enddate: Yup.date().when("startdate", (startdate, schema) => {
    return startdate
      ? schema.min(startdate, "Start Date must be before End Date")
      : schema;
  }),
  start_time: Yup.string(),
  end_time: Yup.string().test(
    "is-greater",
    "end time should be greater",
    function(value) {
      const { start_time } = this.parent;
      return start_time ? moment(value, "hh:mm").isAfter(moment(start_time, "hh:mm")) : true;
    }
  ),
});

export function WebsiteAnnouncementsEditForm({
  announcements,
  btnRef,
  saveWebsiteAnnouncements,
  defaultImages,
}) {
  const DROPDOWN_TYPE = useWebsiteAnnouncementsContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={announcements}
        validationSchema={WebsiteAnnouncementsEditSchema}
        onSubmit={(values) => {
          saveWebsiteAnnouncements(values);
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
          getFieldProps,
        }) => (
          // console.log(values, touched, errors),
          (
            <>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-md-4">
                    <Select name={`active_id`} label="Active">
                      {DROPDOWN_TYPE.ACTIVE &&
                        DROPDOWN_TYPE.ACTIVE.map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.value}
                          </option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-4">
                    <Select name={`show_ind`} label="Show">
                      {[
                        { id: 0, value: "Hide" },
                        { id: 1, value: "Show" },
                      ].map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className={"required-field"}>Enter Title</label>
                    <InputWithLimit
                      name="title"
                      type="text"
                      value={values.title}
                      touched={touched.title}
                      errors={errors.title}
                      maxLength={50}
                      onChange={(e) => {
                        setFieldValue("title", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className={"required-field"}>Enter Button Text</label>
                    <InputWithLimit
                      name="button_text"
                      type="text"
                      value={values.button_text}
                      touched={touched.button_text}
                      errors={errors.button_text}
                      maxLength={50}
                      onChange={(e) => {
                        setFieldValue("button_text", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className={"required-field"}>Enter Image Link</label>
                    <InputWithLimit
                      name="img"
                      type="text"
                      value={values.img}
                      touched={touched.img}
                      errors={errors.img}
                      maxLength={250}
                      onChange={(e) => {
                        setFieldValue("img", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className={"required-field"}>
                      Enter Clickable Link
                    </label>
                    <InputWithLimit
                      name="link"
                      type="text"
                      value={values.link}
                      touched={touched.link}
                      errors={errors.link}
                      maxLength={250}
                      onChange={(e) => {
                        setFieldValue("link", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className={"required-field"}>Enter Start Date</label>
                    <Field
                    name="startdate"
                    component={Input}
                    type="date"
                    value={values.startdate}
                  />
                  </div>
                  <div className="col-lg-6">
                    <label>
                      Enter End Date
                    </label>
                    <Field
                    name="enddate"
                    component={Input}
                    type="date"
                    value={values.enddate}
                  />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Enter Start Time</label>
                    <Field
                    name="start_time"
                    component={Input}
                    type="time"
                    placeholder="Start Time"
                    value={values.start_time}
                  />
                  </div>
                  <div className="col-lg-6">
                    <label>
                      Enter End Time
                    </label>
                    <Field
                    name="end_time"
                    component={Input}
                    type="time"
                    placeholder="End Time"
                    value={values.end_time}
                  />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className={"required-field"}>
                      Enter Description
                    </label>
                    <InputWithLimit
                      name="description"
                      type="textarea"
                      value={values.description}
                      touched={touched.description}
                      errors={errors.description}
                      displaylimit={"true"}
                      rows={3}
                      maxLength={50}
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            </>
          )
        )}
      </Formik>
    </>
  );
}
