import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmationModal({
  show,
  setShow,
  id,
  setSuccessMessage,
  setErrorMessage,
  onHide,
  title,
  message,
  hint,
  handleAction,
}) {

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        {hint && <p className="text-danger">{hint}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button
          variant="primary"
          onClick={handleAction}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}