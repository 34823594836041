/* eslint eqeqeq: "off" */
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../../_metronic/layout";
import { EmailDialog } from "../../../../_zebra/_components/EmailDialog";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { CampActivateDialog } from "./CampComponents/CampActivateDialog";
import { useCampContext } from "./CampComponents/CampDataContext";
import { CampDeleteDialog } from "./CampComponents/CampDeleteDialog";
import { CampFilter } from "./CampComponents/CampFilter";
import { CampPointsDialog } from "./CampComponents/CampPointsDialog";
import ClassTable from "./CampComponents/CampTable";

export const CampList = () => {
  const [data, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [emailSuccessOpen, setEmailSuccessOpen] = useState(false);
  const [emailErrorOpen, setEmailErrorOpen] = useState(false);
  const { branch, authUser } = useSelector(
    (state) => ({
      branch: state.auth.branches.currentBranch,
      authUser: state.auth.user,
    }),
    shallowEqual
  );
  const context = useCampContext();
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Camp List");
  const campsUrl = "api/camps/branch/";

  async function fetchCampsForBranch() {
    let activeValues = ["Active", "In-Active"];
    setLoading(true);
    const result = await axios(campsUrl + branch.branch_id);
    result.data.result.sort((a, b) => {
      return (
        activeValues.indexOf(a.active_value) -
        activeValues.indexOf(b.active_value)
      );
    });
    setData(result.data.result);
    setOrigData(result.data.result);
    setLoading(false);
  }

  const handleClose = () => {
    setEmailSuccessOpen(false);
    setEmailErrorOpen(false);
  };

  //fetching classes for a branch
  useEffect(() => {
    branch && fetchCampsForBranch();
    // eslint-disable-next-line
  }, [branch]);

  //fetching classes for a branch
  useEffect(() => {
    context.refetch && fetchCampsForBranch();
    context.setRefetch(false);
    // eslint-disable-next-line
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;
      for (let key in filters) {
        if (filters[key] !== "") {
          if (key === "courses") {
            let common = item[key].filter((b) => {
              return filters[key].includes(b.course_code);
            });
            if (common.length == 0) {
              add = false;
            }
          }
          if (key === "date") {
            if (
              !moment(filters[key]).isBefore(
                moment(item["enddate"], "yyyy-MM-DD").add(1, "days")
              )
            ) {
              add = false;
            }
          }
          if (key === "id") {
            if (!(item["camp_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (key === "camp_type_id") {
            if (!(item["camp_type_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (
            key !== "courses" &&
            key !== "date" &&
            key !== "id" &&
            key !== "camp_type_id" &&
            (item[key] === undefined || item[key] !== filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });
    setData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={emailSuccessOpen ? emailErrorOpen : false}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={emailSuccessOpen ? "success" : "error"}
          message={
            emailSuccessOpen
              ? "Email Successfully sent"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Camp Filters
            </span>
          </h3>
          {access["batches"] == 0 && (
            <div className="card-toolbar">
              <Link
                to="/camps/add-camp"
                className="btn btn-primary font-weight-bolder font-size-sm"
              >
                Add New Camp
              </Link>
            </div>
          )}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <CampFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <ClassTable camps={data} showActions={true} loading={loading} />

      <ContentRoute path="/camps/camp-list/:id/points">
        {({ history, match }) => (
          <CampPointsDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/camps/camp-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/camps/camp-list/:id/delete-camp">
        {({ history, match }) => (
          <CampDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/camps/camp-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/camps/camp-list/:id/activate-camp">
        {({ history, match }) => (
          <CampActivateDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/camps/camp-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/camps/camp-list/:id/email">
        {({ history, match }) => (
          <EmailDialog
            history={history}
            routePath={"/camps/camp-list"}
            camp_ind={true}
            id={match && match.params.id}
            branch_id={branch && branch.branch_id}
            userId={authUser && authUser[0].user_id}
            show={match != null}
            onHide={() => {
              history.push("/camps/camp-list");
            }}
            setEmailSuccessOpen={setEmailSuccessOpen}
            setEmailErrorOpen={setEmailErrorOpen}
          />
        )}
      </ContentRoute>
    </div>
  );
};
