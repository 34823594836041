import { Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import { useWebTransactionsContext } from "./Components/WebTransactionsDataContext";
import { WebTransactionsFilter } from "./Components/WebTransactionsFilter";
import WebTransactionsTable from "./Components/WebTransactionsTable";
import { WebTransactionManuallyEntered } from "./Modals/WebTransactionManuallyEntered";
import { WebTransactionsTriggerEmail } from "./Modals/WebTransactionTriggerEmail";
import { CSVDownloadDialog } from "./Components/CSVDownloadDialog";

export const WebTransactionsList = () => {
  const [webData, setWebData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [CSVData, setCSVData] = useState([]);

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useWebTransactionsContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Web Transactions List");

  async function fetchWebTransactionsForBranch() {
    setLoading(true);
    const result = await axios(context.URL.transactionURL + branch.branch_id);

    setWebData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
  }

  // CSV data object
  async function fetchCSVData() {
    setLoading(true);
    const result = await axios(`${context.URL.transactionURL}csv-data/${branch.branch_id}`);
    setCSVData(result.data.results);
    setLoading(false);
  }

  //fetching leads for a branch
  useEffect(() => {
    branch && fetchWebTransactionsForBranch();
  }, [branch]);

  //fetching leads for a branch
  useEffect(() => {
    context.refetch && fetchWebTransactionsForBranch();
    // context.setRefetch(false);
  }, [context.refetch]);

   //fetching CSV data
   useEffect(() => {
    fetchCSVData();
  }, []);

  const handleClose = () => {
    setEmailFailed(false);
    setEmailSent(false);
  };

   //success error messages
   const [downloadSuccessOpen, setDownloadSuccessOpen] = useState(false);
   const [downloadErrorOpen, setDownloadErrorOpen] = useState(false);

   const handleCloseDownload = () => {
    setDownloadSuccessOpen(false);
    setDownloadErrorOpen(false);
  };

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "web_transaction_parent_id") {
            if (
              !(item["web_transaction_parent_id"] + "").includes(
                filters[key] + ""
              )
            ) {
              add = false;
            }
          }

          if (key === "parent_name") {
            if (
              !(item["parent_name"] + "")
                .toLowerCase()
                .includes(filters[key].toLowerCase())
            ) {
              add = false;
            }
          }

          if (key === "status") {
            if (filters[key] === "Default") {
              if (
                item["status"] === "Abandoned" ||
                item["status"] === "Enrolled"
              ) {
                add = false;
              }
            } else {
              if (item["status"] != filters[key]) {
                add = false;
              }
            }
          }
        }
      }
      return add;
    });

    setWebData(newData);
  };
 
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={emailSent || emailFailed}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={emailSent ? "success" : "error"}
          message={emailSent ? "Email Sent" : "Something went wrong"}
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Web Transactions Filters
            </span>
          </h3>
            {/* <Link
              to="/web-transactions/web-transactions-list/download"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Download
            </Link> */}
            </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <WebTransactionsFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>
      <WebTransactionsTable
        webData={webData}
        showActions={true}
        loading={loading}
      />

      <ContentRoute path="/web-transactions/web-transactions-list/trigger-email/:id">
        {({ history, match }) => (
          <WebTransactionsTriggerEmail
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/web-transactions/web-transactions-list");
            }}
            setEmailFailed={setEmailFailed}
            setEmailSent={setEmailSent}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/web-transactions/web-transactions-list/manually-entered/:id">
        {({ history, match }) => (
          <WebTransactionManuallyEntered
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/web-transactions/web-transactions-list");
            }}
          />
        )}
      </ContentRoute>
      <ContentRoute path="/web-transactions/web-transactions-list/download">
              {({history,match}) => (
                <CSVDownloadDialog
                  show={match != null}
                  setDownloadSuccessOpen={setDownloadSuccessOpen}
                  setDownloadErrorOpen={setDownloadErrorOpen}
                  onHide={() => {
                    history.push("/web-transactions/web-transactions-list");
                  }}
                />
              )}
           </ContentRoute>
    </div>
  );
};
