/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Snackbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { UserMapping } from "./WebTransactionEditForm";
import { useWebTransactionsContext } from "./WebTransactionsDataContext";

export function WebTransactionEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const context = useWebTransactionsContext();
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const btnRef = useRef();

  const user_id = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id,
    shallowEqual
  );

  useEffect(() => {
    let _subTitle = "Enroll Registration";

    suhbeader.setTitle(_subTitle);
  }, [id]);

  useEffect(() => {
    const getDetails = async () => {
      console.log("Try get details");
      try {
        const details_url = context.URL.transactionURL + `edit-details/${id}`;
        const res = await axios(details_url);
        let users = { ...res.data.results };
        let values = {
          transaction_data: { ...users.transaction_data },
          parents: [...Array(users.parents.length)].map((x, i) => {
            return {
              ...users.parents[i],
              status: context.defaultSelectValue,
              isNew: false,
            };
          }),
          students: [...Array(users.students.length)].map((x, i) => {
            return {
              ...users.students[i],
              status: context.defaultSelectValue,
              isNew: false,
              primaryParent: context.defaultSelectValue,
            };
          }),
        };
        setData(users);
        setInitialValues(values);
        context.setWarnings(context.defaultWarnings);
      } catch (e) {
        console.log(e);
      }
    };
    typeof id !== "undefined" && getDetails();
  }, [id, user_id]);

  const saveEnrollment = async (values) => {
    const result = await axios.post(
      context.URL.transactionURL + `/${user_id}`,
      values
    );

    if (result.data !== "failed") {
      setSuccessOpen(true);
      setTimeout(() => {
        history.push("/web-transactions/web-transactions-list");
      }, 1000);
    } else {
      setErrorOpen(true);
    }
  };

  const handleClose = () => {
    setSuccessOpen(true);
    setErrorOpen(false);
  };

  const saveButton = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveEnrollment(null);
    }
  };

  const backButton = () => {
    history.push(`/web-transactions`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the customer successfully"
              : editSuccessOpen
              ? "Edited the customer successfully"
              : "FAILED: Users Partially Created"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <Typography
              variant="body2"
              component="p"
              className="mr-10 text-danger"
            >
              Once the transaction is complete{" "}
              <b>PLEASE MAKE SURE TO VALIDATE THE INFORMATION</b>. It is{" "}
              <b>YOUR RESPONSIBILITY</b> to ensure clean data
            </Typography>
            <button
              type="button"
              onClick={backButton}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              disabled={!buttonEnable}
              onClick={saveButton}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <UserMapping
            data={data}
            btnRef={btnRef}
            saveEnrollment={saveEnrollment}
            initialValues={initialValues}
            buttonEnable={buttonEnable}
            setButtonEnable={setButtonEnable}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}
