import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const BatchTypeTable = ({ row, branches }) => {
  return Object.keys(row).map((c, i) => {
    return (
      <TableRow key={i}>
        <TableCell>
          <p
            onClick={() => {
              console.log(row[c]);
              console.log(
                row[c].course_name,
                "is missing",
                branches
                  .filter((b, k) => {
                    return (
                      row[c].branch.filter((c, q) => {
                        return c.branch_name == b.branch_name;
                      }).length == 0
                    );
                  })
                  .map((b, q) => {
                    return b.branch_name;
                  })
                  .join(", ")
              );
            }}
          >
            {row[c].course_name}
          </p>
        </TableCell>
        {branches.map((b, i) => {
          return row[c].branch.filter((l) => {
            return l.branch_name == b.branch_name;
          }).length > 0 ? (
            <TableCell key={i}>
              <p
                onClick={() => {
                  console.log(
                    row[c].branch.filter((l) => {
                      return l.branch_name == b.branch_name;
                    })
                  );
                }}
              >
                x
              </p>
            </TableCell>
          ) : (
            <TableCell key={i}>
              <p className="bg-danger">o</p>
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
};

const StreamsTabs = ({ rows, branches }) => {
  return (
    <Tabs>
      {Object.keys(rows).map((bt, i) => {
        return (
          <Tab key={i} eventKey={bt} title={bt}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component={"th"} scope="row">
                    Course
                  </TableCell>
                  {branches &&
                    branches.length > 0 &&
                    branches.map((b, u) => {
                      return (
                        <TableCell key={u} component={"th"} scope="row">
                          {b.branch_name}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                <BatchTypeTable row={rows[bt]} branches={branches} />
              </TableBody>
            </Table>
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default function EnhancedReportTable({ reports, showActions }) {
  const [branches, setBranches] = useState([]);
  const classes = useStyles();
  const rows = reports;

  async function fetchBranches() {
    const branch_url = `api/branch-course`;
    const res = await axios(branch_url);
    setBranches(res.data.results);
  }
  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {rows && branches && branches.length > 0 && (
            <StreamsTabs rows={rows} branches={branches}></StreamsTabs>
          )}
        </div>
      </Paper>
    </div>
  );
}
