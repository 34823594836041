// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import React from "react";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function UnpaidReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;
  const getMonths = useReportContext().getMonths;

  return (
    <>
      <div className="form-group row">
        <div className={"col-md-3"}>
          <Select
            name={`batch_type`}
            label="Type"
            onChange={(e) => {
              setFieldValue("batch_type", e.value);
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
            validationColorScheme={false}
          >
            <option key={"1"} value={"1"}>
              {"Regular Class"}
            </option>
            <option key={"2"} value={"2"}>
              {"Camp"}
            </option>
          </Select>
        </div>

        {values && values.batch_type && values.batch_type == "1" && (
          <div className={"col-lg-3"}>
            <Select
              name={`selected_month`}
              label="Month"
              onChange={(e) => {
                setFieldValue("selected_month", e.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
              validationColorScheme={false}
            >
              {getMonths(6).map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
            <ErrorMessage
              name={`selected_month`}
              component="div"
              className="invalid-feedback"
            />
          </div>
        )}
      </div>
      {/* errors)} */}
    </>
  );
}
