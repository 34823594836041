/* eslint-disable no-restricted-imports */
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import TextEditor from "../../../../_zebra/_partials/TextEditor";

export function ReportsEmailDialog({
  reportEmails,
  branch_id,
  showEmail,
  onHideEmail,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [branchEmail, setBranchEmail] = useState("");
  const [plainTextBody, setPlainTextBody] = useState("");

  const getLocationEmail = async () => {
    const emailLink = `api/email-service/get-email?branchId=${branch_id}`;
    const result = await axios.get(emailLink);
    setBranchEmail(result.data.results[0].email);
  };

  const yupSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    emailBody: Yup.string().required("Email body is required"),
  });

  const sendEmail = async (values) => {
    //send email
    const sendUrl = "api/email-service/send";

    values.fromEmail = branchEmail;
    values.branchId = branch_id;
    values.to = [];
    values.bcc = [...reportEmails];

    const result = await axios.post(sendUrl, values);
    if (result.data !== "failed") {
      onHideEmail();
    } else {
      console.log("Email sending error");
    }
  };

  useEffect(() => {
    branch_id && getLocationEmail();
  }, [branch_id]);

  return (
    <Modal
      show={showEmail}
      onHide={onHideEmail}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LinearProgress color="secondary" styles="width:100%" />
        ) : (
          <Formik
            initialValues={{ emailBody: "", subject: "" }}
            validationSchema={yupSchema}
            onSubmit={(values, { resetForm }) => {
              sendEmail(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
            }) => (
              <form>
                <div className="form-group row">
                  {" "}
                  <div className="col-lg-12">
                    <label htmlFor="to">Emails</label>
                    <textarea
                      // id="comment"
                      name="to"
                      className={`form-control`}
                      value={reportEmails.join(", ")}
                      rows={Math.min(reportEmails.length / 2, 15)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {" "}
                  <div className="col-lg-12">
                    <label htmlFor="subject">Subject</label>
                    <input
                      // id="comment"
                      name="subject"
                      className={`form-control ${isLoading ? "disabled" : ""}`}
                      placeholder="subject"
                      value={values.subject}
                      onChange={(e) => {
                        setFieldValue("subject", e.target.value);
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-danger form-text m-2">
                        {errors.subject ? errors.subject : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label htmlFor="emailBody">Email Body</label>

                    <TextEditor
                      name="emailBody"
                      value={values["emailBody"]}
                      setValue={setFieldValue}
                      setPlainTextBody={setPlainTextBody}
                      onBlur={(e) => {
                        setFieldTouched("emailBody", true);
                        setFieldTouched("bodyLength", true);
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-danger form-text m-2">
                        {errors.emailBody ? errors.emailBody : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="form-group row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    {" "}
                    <button
                      className="btn btn-muted"
                      type="button"
                      onClick={() => {
                        onHideEmail();
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={!branchEmail || !reportEmails.length > 0}
                      onClick={() => handleSubmit()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
