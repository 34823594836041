/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { WebsiteAnnouncementsEditForm } from "./Components/WebsiteAnnouncementsEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { SnackbarWrapper } from "../../../Snackbar/SnackbarWrapper";
import { checkAndReplace } from "../../../../helpers/Formatter";
import moment from "moment";

import { shallowEqual, useSelector } from "react-redux";

const initAnnouncements = {
  title: "",
  active_id: 1,
  img: "",
  description: "",
  link: "",
  startdate: "",
  enddate: "",
  start_time: "",
  end_time: "",
  show_ind: 1,
  button_text: "",
};

export function WebsiteAnnouncementsEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);

  const [values, setValues] = useState();
  const btnRef = useRef();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  initAnnouncements.branch_id = branch.branch_id;

  useEffect(() => {
    const getWebsiteAnnouncementsInfo = async () => {
      const profiles_url = `api/branches/website-announcements/edit/${id}`;
      const res = await axios(profiles_url);
      setData(res.data.results[0]);
    };
    id && getWebsiteAnnouncementsInfo();
  }, [id]);

  useEffect(() => {
    const newValues = data && id ? data : initAnnouncements;

    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Website Announcements";
    if (id && duplicate) {
      _title = `Duplicate Website Announcements`;
    } else if (id) {
      _title = `Edit Website Announcements - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [id]);

  const saveWebsiteAnnouncements = async (values) => {
    values.title = checkAndReplace(values.title.trim());
    values.description = checkAndReplace(values.description.trim());
    values.button_text = checkAndReplace(values.button_text.trim());
    
    if (!id) {
      //save new profile post
      const announcement = "api/branches/website-announcements/";

      const result = await axios.post(announcement, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(
            `/branch-maintenance/website-announcements/website-announcements-list`
          );
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else {
      //edit profile patch
      const editProfile = `api/branches/website-announcements/`;
      const result = await axios.patch(editProfile + id, values);
      // console.log("got", result);
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push(
            "/branch-maintenance/website-announcements/website-announcements-list"
          );
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveWebsiteAnnouncementsClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveWebsiteAnnouncements(null);
    }
  };

  const backToWebsiteAnnouncementsList = () => {
    history.push(`/branch-maintenance/website-announcements`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the Website Announcements successfully"
              : editSuccessOpen
              ? "Edited the Website Announcements successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToWebsiteAnnouncementsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveWebsiteAnnouncementsClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item">
              <a className={`nav-link active`} data-toggle="tab" role="tab">
                Website Announcements info
              </a>
            </li>
          </ul>
          <div className="mt-5">
            {values && (
              <WebsiteAnnouncementsEditForm
                announcements={values}
                btnRef={btnRef}
                saveWebsiteAnnouncements={saveWebsiteAnnouncements}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
