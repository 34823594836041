import { ErrorMessage, Field } from "formik";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { useBranchCourseContext } from "../BranchCourseDataContext";
export function BranchCourseFeeEditRow({
  fee,
  index,
  type,
  errors,
  touched,
  remove,
  removeRow,
}) {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  return (
    <div
      className={`row pt-2${index % 2 == 0 ? " bg-secondary" : ""}${
        type != 1 ? " pb-2" : ""
      }`}
      key={index}
    >
      <div className="col-lg-2">
        <Select name={`fees.${index}.delivery_type_id`}>
          <option key={1} value={1}>
            In-Person
          </option>
          <option key={2} value={2}>
            Virtual
          </option>
        </Select>
      </div>
      <div className={type == 1 ? "col-lg-2" : "col-lg-3"}>
        <Select name={`fees.${index}.batch_type`}>
          {DROPDOWN_TYPE.BATCH_TYPE.filter((bT) => {
            return type == 1 ? bT.camp_ind == 0 : bT.camp_ind == 1;
          }).map((bT) => {
            return (
              <option key={bT.id} value={bT.id}>
                {bT.value}
              </option>
            );
          })}
        </Select>
      </div>
      <div className="col-lg-2">
        {type == 1 && (
          <Select name={`fees.${index}.frequency_nbr`}>
            <option key={4} value={4}>
              4
            </option>
            <option key={8} value={8}>
              8
            </option>
          </Select>
        )}
        {type != 1 && (
          <Select name={`fees.${index}.frequency_nbr`}>
            <option key={1} value={1}>
              1
            </option>
            <option key={2} value={2}>
              2
            </option>
            <option key={3} value={3}>
              3
            </option>
            <option key={4} value={4}>
              4
            </option>
            <option key={5} value={5}>
              5
            </option>
          </Select>
        )}
      </div>
      {type == 1 && (
        <div className="col-lg-2">
          <Field
            name={`fees.${index}.duration`}
            customFeedbackLabel=" "
            component={Input}
            value={fee.duration}
            type={"number"}
            step={"any"}
            className={
              "form-control" +
              (errors.duration && touched.duration ? " is-invalid" : "")
            }
          />
          <ErrorMessage
            name={`fees.${index}.duration`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
      <div className={type == 1 ? "col-lg-2" : "col-lg-1"}>
        <Field
          name={`fees.${index}.course_fee`}
          customFeedbackLabel=" "
          component={Input}
          value={fee.course_fee}
          type="text"
          className={
            "form-control" +
            (errors.course_fee && touched.course_fee ? " is-invalid" : "")
          }
        />

        <ErrorMessage
          name={`fees.${index}.course_fee`}
          component="div"
          className="invalid-feedback"
        />
      </div>

      {type != 1 && (
        <div className="col-lg-1">
          <Field
            name={`fees.${index}.added_fee`}
            customFeedbackLabel=" "
            component={Input}
            value={fee.added_fee}
            type={"text"}
            className={
              "form-control" +
              (errors.added_fee && touched.added_fee ? " is-invalid" : "")
            }
          />
          <ErrorMessage
            name={`fees.${index}.added_fee`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}

      {type != 1 && (
        <div className="col-lg-1">
          <Field
            type="checkbox"
            name={`fees.${index}.half_day_ind`}
            checked={fee.half_day_ind}
            value={fee.half_day_ind}
            className={
              "form-control" +
              (errors.half_day_ind && touched.half_day_ind ? " is-invalid" : "")
            }
          />
          <ErrorMessage
            name={`fees.${index}.half_day_ind`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
      <div className={type == 1 ? "col-lg-1" : "col-lg-1"}>
        <Field
          type="checkbox"
          name={`fees.${index}.price_range_ind`}
          checked={fee.price_range_ind == 0 ? false : true}
          value={fee.price_range_ind == 0 ? false : true}
          className={
            "form-control" +
            (errors.price_range_ind && touched.price_range_ind
              ? " is-invalid"
              : "")
          }
          //disabled={access["branch_course"] == undefined}
          //hi asad, it's jai 4/12/2022
        />
        <ErrorMessage
          name={`fees.${index}.price_range_ind`}
          component="div"
          className="invalid-feedback"
        />
      </div>

      <div className="col-lg-1 text-center">
        <button
          type="button"
          className="btn btn-icon btn-danger btn-hover-primary btn-sm"
          onClick={() => {
            removeRow(fee, remove, index);
          }} // insert an empty string at a position
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
}
