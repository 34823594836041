import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../helpers/TimeConvert";
import { useUserContext } from "../Components/UserDataContext";
import { UserTableActions } from "./ActionFormatter/UserTableActions";
import moment from "moment";
import { Badge } from "react-bootstrap";
import {getFormattedNumber} from "../../../helpers/Formatter"
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  roleWrapper: {
    color: "white",
    background: "#4fa4d5",
  },
}));

export default function EventRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const userContext = useUserContext();

  return (
    <Fragment>
      <TableRow key={row.id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.first_last}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.roles.map((role, index) => (
            <Badge pill key={index} className={classes.roleWrapper}>
              {role.role_name}
            </Badge>
          ))}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.branches.map((branch, index) => (
            <Badge pill key={index} className={classes.greyRatio}>
              {branch.branch_name}
            </Badge>
          ))}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {
            // format string to international phone number format using regex
            getFormattedNumber(row.mobile_contact)
          }
        </TableCell>
        {/* <TableCell align="center">
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.startdate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>{" "}
          -{" "}
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.enddate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>
        </TableCell> */}
        {showActions && (
          <TableCell align="center">
            <UserTableActions
              row={row}
              openEditUserPage={userContext.openEditUserPage}
              openDeleteUserDialog={userContext.openDeleteUserDialog}
              openActivateUserDialog={userContext.openActivateUserDialog}
              // openEventFullDialog={userContext.openEventFullDialog}
              // openDuplicateEventPage={userContext.openDuplicateEventPage}
              // openEventNotFullDialog={userContext.openEventNotFullDialog}
            />
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
}
