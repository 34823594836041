/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { FeatureEditForm } from "./Components/FeatureEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { SnackbarWrapper } from "../../../Snackbar/SnackbarWrapper";
import moment from "moment";


const initFeature = {
  active_id: "1",
  clickable_link: "",
  image_link: "",
  feature_name: "",
};

export function FeatureEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [featuresToAdd, setFeaturesToAdd] = useState([]);
  const [values, setValues] = useState();
  const btnRef = useRef();


  useEffect(() => {
    const getFeatureInfo = async () => {
      const feature_info_url = `api/featured_in/${id}`;
      const res = await axios(feature_info_url);
      setData(res.data.results[0]);

    };
    id && getFeatureInfo();
  }, [id]);

  useEffect(() => {
    const newValues = data && id ? data : initFeature;
    
    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Feature";
    if (id && duplicate) {
      _title = `Duplicate Feature`;
    } else if (id) {
      _title = `Edit Feature - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [id]);

  const saveFeature = async (values) => {
    alert("Saving")
    if (!id) {
      //save new feature post
      const featuresUrl = "api/featured_in";
      
      const result = await axios.post(featuresUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/branch-maintenance/features/feature-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else {
      
      //edit feature patch
      const editFeatureUrl = `api/featured_in/`;
      const result = await axios.patch(editFeatureUrl + id, values);
      
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/branch-maintenance/features/feature-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveFeatureClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveFeature(null);
    }
  };

  const backToFeatureList = () => {
    history.push(`/branch-maintenance/features`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the feature successfully"
              : editSuccessOpen
              ? "Edited the feature successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToFeatureList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveFeatureClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Feature info
              </a>
            </li>
            
          </ul>
          <div className="mt-5">
            {tab === "basic" && values && (
              <FeatureEditForm
                feature={values}
                btnRef={btnRef}
                saveFeature={saveFeature}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
