import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Container from "react-bootstrap/Container";

export default function HelpPageRow({ hD }) {
  const [open, setOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState("");

  const handleRowClick = (help_id) => {
    setOpen(!open);
    setHelpOpen(help_id);
  };

  return (
    <>
      <TableRow key={hD.id} hover tabIndex={-1}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(hD.id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <b>{hD.title}</b>
        </TableCell>
      </TableRow>
      <TableRow key={"details" + hD.id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Container>
                <Row>{hD.description}</Row>
                <Row align="center">
                  <iframe
                    src={`${hD.video}`}
                    width="640"
                    height="360"
                    frameborder="0"
                    allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen" allow="autoplay; fullscreen"
                  ></iframe>
                </Row>
                <Row>{hD.notes}</Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
