import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "axios";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useWebTransactionsContext } from "../Components/WebTransactionsDataContext";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function WebTransactionStudentMatch({ user, setUser }) {
  const [page, setPage] = useState(0);
  const [matches, setMatches] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    URL,
    defaultSelectValue,
    newSelectValue,
    warnings,
    setWarnings,
  } = useWebTransactionsContext();
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const { values, setFieldValue, setFieldError } = useFormikContext();

  useEffect(() => {
    const fetchMatches = async () => {
      let res = await axios.get(
        `${URL.transactionURL}student-matches?firstName=${user.firstName}&lastName=${user.lastName}`
      );
      setMatches(res.data.results);
    };
    Object.keys(user).length > 0 && fetchMatches();
    setPage(0);
    setRowsPerPage(10);
  }, [user]);

  const onHandleClose = () => {
    setPage(0);
    setMatches([]);
    setRowsPerPage(5);
    setUser({});
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
    const classes = useStyles1();
    const theme = useTheme();

    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const onHandleSelectedUser = (status, isNew) => {
    setFieldValue(`students.${user.index}.status`, status, false);
    setFieldValue(`students.${user.index}.isNew`, isNew, false);

    console.log(user, status);
    let newWarnings = { ...warnings };
    newWarnings.parents[user.index] = [];

    if (status === newSelectValue) {
      console.log(values.parents[0].status);
      if (
        values.parents[0].status === newSelectValue ||
        values.parents[0].status === defaultSelectValue
      ) {
        setFieldValue(
          `students.${user.index}.primaryParent`,
          { ...user.transactionPrimaryParent, primary_ind: 1 },
          false
        );
      } else {
        setFieldValue(
          `students.${user.index}.primaryParent`,
          { ...values.parents[0].status, primary_ind: 1 },
          false
        );
      }
    } else if (status === defaultSelectValue) {
      setFieldValue(
        `students.${user.index}.primaryParent`,
        defaultSelectValue,
        false
      );
    } else {
      /*******
      Check the primary parents
      *******/
      let primaryParents = status.parents.filter((p) => {
        return p.primary_ind === 1;
      });
      if (primaryParents.length === 1) {
        setFieldValue(
          `students.${user.index}.primaryParent`,
          primaryParents[0],
          false
        );
      } else {
        setFieldError(
          `students.${user.index}`,
          `This user has ${primaryParents.length} primary parents. Please take out a ticket to address this issue. For the time being please handle this transaction manually`
        );
      }

      /*******
      Check the user's branch access
      *******/

      if (
        status.branches.filter((r) => {
          return r.branchId === branch.branch_id;
        }).length <= 0
      ) {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index].push("This user isn't in your location");
        setWarnings(newWarnings);

      } else {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index] = [];
        setWarnings(newWarnings);
      }



            /*******
      Check the user's information
      *******/
      
      if (
        status.email != user.email || status.phone != user.phone || status.address != user.address || status.zip != user.zip 
      ) {
        let newWarnings = { ...warnings };
        newWarnings.students[user.index].push("User information doesn't match between the Portal and the Transaction.");
        setWarnings(newWarnings);

      } else {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index] = [];
        setWarnings(newWarnings);
      }


      



  
    }

    onHandleClose();
  };

  return (
    <Modal
      show={Object.keys(user).length > 0 && !user.isParent}
      onHide={() => {
        setUser({});
      }}
      aria-labelledby="match-modal-title"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="match-modal-title">
          Potential Matches for {user.firstName + " " + user.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Parents</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page === 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={5}>{defaultSelectValue}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        onHandleSelectedUser(defaultSelectValue, false);
                      }}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>{newSelectValue}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        onHandleSelectedUser(newSelectValue, true);
                      }}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}
            {matches &&
              matches
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((m, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{m.userId}</TableCell>
                      <TableCell>{m.firstName + " " + m.lastName}</TableCell>
                      <TableCell>{m.dob}</TableCell>
                      <TableCell>
                        {m.parents
                          .map((p) => {
                            return p.firstName + " " + p.lastName;
                          })
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {m.branches
                          .map((b) => {
                            return b.branchName;
                          })
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            onHandleSelectedUser(m, false);
                          }}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={6}
                count={matches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setUser({});
          }}
        >
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
