import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { getFormattedNumber } from "../../../../helpers/Formatter";
import { useBatchContext } from "../Components/BatchDataContext";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
}));

export function BatchCoachTable({ instructors }) {
  return instructors ? (
    <Fragment>
      <h4> Coaches</h4>
      {instructors?.length > 0 ? (
        <p>
          {instructors.map((instructor, index) => {
            return (
              instructor.instructor +
              (instructor.student_count
                ? " (" + instructor.student_count + ")"
                : "") +
              (index < instructors.length - 1 ? ", " : "")
            );
          })}
        </p>
      ) : (
        <p>No Coaches Added</p>
      )}
    </Fragment>
  ) : null;
}

export function BatchStudentTable({
  students,
  tableLabel = "Students",
  firstAndLastName = false,
}) {
  const batchContext = useBatchContext();
  return students ? (
    <Fragment>
      <h4>{tableLabel}</h4>
      {students?.length > 0 ? (
        students
          .sort((a, b) =>
            a.course_code.toString().localeCompare(b.course_code.toString())
          )
          .map((student) => {
            return (
              <div className="form-group row mb-0 mt-0">
                {tableLabel !== "Trial Students" ? null : (
                  <div className="col-lg-1">
                    <p
                      onClick={() =>
                        batchContext.openLeadAttendanceDialog(student.user_id)
                      }
                    >
                      <i className="fa fa-user-check"></i>
                    </p>
                  </div>
                )}
                <div className="col-lg-11">
                  <p key={student.user_id}>
                    {student.date &&
                    (tableLabel === "Batch Students"
                      ? moment(student.date.split("T")[0], [
                          "yyyy-MM-DD",
                        ]).isSameOrAfter(moment(new Date()), "day")
                      : true) ? (
                      <b>
                        [
                        {moment(student.date.split("T")[0], [
                          "yyyy-MM-DD",
                        ]).format("MMM Do")}
                        ]
                      </b>
                    ) : (
                      ""
                    )}{" "}
                    {tableLabel !== "Trial Students"
                      ? student.user_id + " - "
                      : ""}
                    {firstAndLastName
                      ? student.firstname + " " + student.lastname
                      : student.student_name}{" "}
                    <b>
                      {student.course_code}
                      {student.sub_course_code
                        ? "-" + student.sub_course_code
                        : ""}
                    </b>{" "}
                    {student.instructor ? " - " + student.instructor : ""}{" "}
                    {student.allergies &&
                    student.allergies.trim().length > 0 ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            {student.allergies}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Food/peanut.svg"
                            )}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                    {student.special_need &&
                    student.special_need.trim().length > 0 ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            {student.special_need}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Clipboard.svg"
                            )}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                    {student.parent_name &&
                    student.parent_name.trim().length > 0 ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            {student.parent_name}
                            <br></br>
                            Mobile:{" "}
                            {student.parent_mobile
                              ? getFormattedNumber(student.parent_mobile)
                              : "Not on File"}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Active-call.svg"
                            )}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                    {student.dob &&
                    student.dob.length > 0 &&
                    Math.abs(
                      moment(student.dob.split("T")[0], "2024-MM-DD").diff(
                        moment(new Date()),
                        "days"
                      )
                    ) <= 14 ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            {moment(
                              student.dob.split("T")[0],
                              "yyyy-MM-DD"
                            ).format("MMM DD")}
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-birthday-cake text-info icon-md"></i>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          })
      ) : (
        <p> No Students In The class</p>
      )}
    </Fragment>
  ) : null;
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
