/* eslint-disable jsx-a11y/anchor-is-valid */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { InputWithLimit } from "../../../_metronic/_partials/controls/forms/InputWithLimit";

//The scheme used for new students to make sure entered information is correct
const Schema = Yup.object().shape({
  studentType: Yup.string()
    .oneOf(["existing", "new"], "Please select an option")
    .required("Student Type is required"),
  firstname: Yup.string().when("studentType", {
    is: "existing",
    then: (schema) => schema,
    otherwise: Yup.string().required("First name is required"),
  }),
  lastname: Yup.string().when("studentType", {
    is: "existing",
    then: (schema) => schema,
    otherwise: Yup.string().required("Last name is required"),
  }),
  dob: Yup.date().when("studentType", {
    is: "existing",
    then: (schema) => schema,
    otherwise: Yup.date().required("Date Of Birth is required"),
  }),
  school: Yup.string().when("studentType", {
    is: "existing",
    then: (schema) => schema,
    otherwise: Yup.string(),
  }),
  allergies: Yup.string(),
  special_needs: Yup.string(),
});

export const AddStudentForm = ({
  id,
  initialValues,
  parents,
  student,
  setStudent,
  duplicateCheck,
  saveStudent,
  students,
  existingParents,
  studentActivity,
}) => {
  /*
    id - the id of the student being editted (if present, we are in edit mode)
    initialValues - either values for an existing student or will be empty strings for student info
    parents - the list of possible parents to associate with student
    student - the student we are editing/when adding an existing student
    setStudent - when adding an exsiting student, we will use this to set the state
    saveStudent - the submit function for the form
    students - list of possible students to add
  */
  const [parentChoices, setParentChoises] = useState([]); //the list of choices for parents to add to a student (excluding primary parent)
  //const [primaryChosen, setPrimaryChosen] = useState();//the primary parents chosen to be added to the student
  const [parentChosen, setParentsChosen] = useState([]); //the list of parents chosen to be added to a student in the select (multi-select)
  const [loading, setloading] = useState(false);
  const history = useHistory(); //used to navigate on cancel, or success
  const searchId = useSelector((state) => state.family.searchid); //the id of the person in the url
  //const primary_parent = useSelector((state) => state.family.primaryParent)

  //Formik classes based on if there are errors or not
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  /*
    When are primary parent is chosen, we need to reset the list of parents
    for the multi-select to stop duplicate parents from being added.
  */
  // useEffect(() => {
  //   if (parents && primaryChosen) {
  //     let choices = parents.filter((p) => {
  //       return p.user_id != primaryChosen.value;
  //     });

  //     setParentChoises(choices);
  //     parentChosen.filter((p) => {
  //       return p.value == primaryChosen.value;
  //     }).length > 0
  //       ? setParentsChosen([])
  //       : (choices = choices);
  //   }
  // }, [parents, primaryChosen]);

  //When student is set/changed reset the parentsChosen select
  // useEffect(()=>{
  //     if(!id && student){
  //       setParentsChosen([])
  //     }
  // }, [student])

  useEffect(() => {
    if (existingParents) {
      let parentsChoicesLocal = parents
        .filter((p) => {
          return p.primary_ind != 1;
        })
        .map((p) => {
          return { value: p.user_id, label: p.name };
        });
      let parentsChosenLocal = existingParents
        .filter((p) => {
          return p.primary_ind != 1;
        })
        .map((p) => {
          return { value: p.user_id, label: p.name };
        });
      setParentsChosen(parentsChosenLocal);
      setParentChoises(parentsChoicesLocal);
    } else {
      let parentsChoicesLocal = parents
        .filter((p) => {
          return p.primary_ind != 1;
        })
        .map((p) => {
          return { value: p.user_id, label: p.name };
        });
      setParentsChosen(parentsChoicesLocal);
      setParentChoises(parentsChoicesLocal);
    }
  }, [parents, existingParents]);

  //This is the select for choosing a student when you choose to add a child that exists
  const existingStudentAutocomplete = () => {
    return (
      <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Select a student
        </label>
        <div className="col-lg-9 col-xl-6">
          <Autocomplete
            options={students}
            getOptionLabel={(s) => s.studentName}
            id="students"
            //value={student}
            onChange={(event, newStudent) => {
              if (newStudent) {
                setStudent(newStudent);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </div>
      </div>
    );
  };

  //This is the multi-select for selecting parents to add to a student
  const parentMultiSelect = () => {
    return (
      <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Select a parent
        </label>
        <div className="col-lg-9 col-xl-6">
          <Select
            key={""}
            name="parents"
            className="react-select"
            options={parentChoices}
            onChange={(val) => {
              if (val) {
                setParentsChosen(val);
              }
            }}
            value={parentChosen}
            isMulti
          />
        </div>
        {parentChosen.length < 1 ? (
          <div className="text-default">Please Select At Least One Parent</div>
        ) : null}
      </div>
    );
  };

  //This is the form used to add a new student to a family OR editting a student's info
  const mainForm = () => {
    return (
      <div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Status</label>
          <div className="col-lg-9 col-xl-6">
            <select
              className="form-control form-control-lg form-control-solid"
              name="active_id"
              {...formik.getFieldProps("active_id")}
            >
              <option>Select Status...</option>
              <option value="1">Active</option>

              {(studentActivity &&
                studentActivity.class_activity &&
                studentActivity.class_activity.length > 0) ||
              (studentActivity &&
                studentActivity.camp_activity &&
                studentActivity.camp_activity.legth > 0) ? (
                <option value="0" disabled>In-Active</option>
              ) : (
                <option value="0">
                  In-Active
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label required-field">
            First Name
          </label>
          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="text"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "firstname"
                )}`}
                name="firstname"
                {...formik.getFieldProps("firstname")}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="invalid-feedback">
                  {formik.errors.firstname}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label required-field">
            Last Name
          </label>

          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="text"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "lastname"
                )}`}
                name="lastname"
                {...formik.getFieldProps("lastname")}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="invalid-feedback">{formik.errors.lastname}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label required-field">
            Date of Birth
          </label>

          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="date"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "dob"
                )}`}
                name="dob"
                {...formik.getFieldProps("dob")}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div className="invalid-feedback">{formik.errors.dob}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Gender</label>
          <div className="col-lg-9 col-xl-6">
            <select
              className="form-control form-control-lg form-control-solid"
              name="gender"
              {...formik.getFieldProps("gender")}
            >
              <option>Select Gender...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">School</label>
          <div className="col-lg-9 col-xl-6">
            <div className="input-group input-group-lg input-group-solid">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-school"></i>
                </span>
              </div>
              <input
                type="text"
                placeholder="School"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "school"
                )}`}
                name="school"
                {...formik.getFieldProps("school")}
              />
              {formik.touched.school && formik.errors.school ? (
                <div className="invalid-feedback">{formik.errors.school}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
          <label className="col-xl-3"></label>
          <div className="col-lg-9 col-xl-6">
            <h5 className="font-weight-bold mb-6">Student Needs:</h5>
          </div>
        </div>
        {/* begin::Form Group */}
        {/* begin::Form Group */}
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Allergies</label>
          <div className="col-lg-9 col-xl-6">
            <InputWithLimit
              className={`form-control form-control-lg form-control-solid ${getInputClasses(
                "allergies"
              )}`}
              name="allergies"
              id="allergies"
              type="textarea"
              maxLength={100}
              rows="2"
              {...formik.getFieldProps("allergies")}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            Special Needs
          </label>
          <div className="col-lg-9 col-xl-6">
            <InputWithLimit
              className={`form-control form-control-lg form-control-solid ${getInputClasses(
                "special_needs"
              )}`}
              name="special_needs"
              id="special_needs"
              type="textarea"
              maxLength={250}
              rows="2"
              {...formik.getFieldProps("special_needs")}
            />
          </div>
        </div>{" "}
      </div>
    );
  };

  //setting Formik for validating a NEW student, and tracking the values selected
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  //render the main screen. Will also decide which forms to display, read more below
  return (
    <form className="card card-custom">
      {loading && <ModalProgressBar />}
      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Student Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            {id ? "Edit" : "Add"} Student Information
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
            onClick={() => {
              duplicateCheck(
                formik.values,
                formik.setStatus,
                formik.setSubmitting,
                parentChosen
              );
            }}
          >
            {id ? "Save" : "Add"}
            {formik.isSubmitting}
          </button>
          <Link
            to={searchId ? "/family-profile/" + searchId + "/" : "/dashboard"}
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          {id ? null : (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Add an...
              </label>
              <div className="col-lg-9 col-xl-6">
                <select
                  name="studentType"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "studentType"
                  )}`}
                  {...formik.getFieldProps("studentType")}
                >
                  <option value="">Choose...</option>
                  <option value="existing">Existing Student</option>
                  <option value="new">New Student</option>
                </select>
                {formik.touched.studentType && formik.errors.studentType ? (
                  <div className="invalid-feedback">
                    {formik.errors.studentType}
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {/* Show the entire form if editing a student or adding a new student, but not if adding an existing student */}
          {(!id && formik.values.studentType == "new") || id
            ? mainForm()
            : null}
          <>
            {/* Show an autocomplete if adding an existing student */}
            {formik.values.studentType == "existing" && !id
              ? existingStudentAutocomplete()
              : null}
          </>

          {/* Show the parent multi-select if parents exist and if adding a child, then the type must be selected*/}
          {parents &&
          (formik.values.studentType == "new" ||
            formik.values.studentType == "existing")
            ? parentMultiSelect()
            : null}
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
};
export default AddStudentForm;
