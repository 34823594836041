export const courseEnrollmentVsDobHeaders = [
  {
    key: "student_id",
    label: "Student ID",
  },
  {
    key: "firstname",
    label: "First Name",
  },
  {
    key: "lastname",
    label: "Last Name",
  },
  {
    key: "dob",
    label: "DOB",
  },
  {
    key: "age",
    label: "Age",
  },
  {
    key: "calculated_grade",
    label: "Grade",
  },
  {
    key: "registration_date",
    label: "Registration date",
  },
  {
    key: "stream_code",
    label: "Registered Stream Code",
  },
  {
    key: "stream_name",
    label: "Registered Stream Name",
  },
  {
    key: "stream_grade_min",
    label: "Stream Grade Min",
  },
  {
    key: "stream_grade_max",
    label: "Stream Grade Max",
  },
  {
    key: "batch_id",
    label: "Batch ID",
  },
  {
    key: "day",
    label: "Batch Day",
  },
  {
    key: "start_time",
    label: "Batch Time",
  },
];
