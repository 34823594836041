export const CourseChallengeHeaders = [
  {
    key: "Student_Id",
    label: "Student Id",
  },
  {
    key: "Student_Name",
    label: "Student Name",
  },
  {
    key: "Course_Name",
    label: "Course Name",
  },
  {
    key: "Batch_Day",
    label: "Batch Day",
  },
  {
    key: "Batch_Start_Time",
    label: "Batch Start Time",
  },
  {
    key: "Batch_End_Time",
    label: "Batch End Time",
  },
  {
    key: "Challenge_Name",
    label: "Latest Challenge",
  },
  {
    key: "Submission_Date",
    label: "Submission Date",
  },
];
