import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useReportContext } from "../ReportDataContext";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));
export default function CourseChallengeReportRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState("");
  const reportContext = useReportContext();
  return (
    <Fragment>
      <TableRow hover tabIndex={-1}>
      <TableCell align="center" component="th" scope="row">
          {row.Student_Id}
      </TableCell>
      <TableCell align="center" component="th" scope="row">
          {row.Student_Name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.Course_Name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.Batch_Day}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.Batch_Start_Time}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.Batch_End_Time}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.Challenge_Name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
        {row.Submission_Date}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}