/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";

export function StudentDuplicateDialog({
  students,
  duplicateKnown,
  setDuplicateKnown,
  onHide,
  show
}) {
  // if !id we should close modal
  useEffect(() => {
    if (duplicateKnown) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateKnown]);

  const createDuplicate = async () => {
      setDuplicateKnown(true)
      setTimeout(() => {
        onHide()
      }, 1000);
    
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Duplicate Found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <span>{(students && students.length > 1) ? "Duplicates of this student have been found." : "A duplicate of this student has been found." }
        {
          students.map((student) => (
            <div key={student.user_id}>
            <br/>
            <br/>
            <b>Student ID:</b> {student.user_id}
            <br/>
            <b>First Name:</b> {student.firstname}
            <br/> 
            <b>Last Name:</b> {student.lastname}
            <br/>
            <br/>
            </div>
          ))
        }
        
            
            Are you sure you want to continue?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={createDuplicate}
            className="btn btn-delete btn-elevate"
          >
            I'm Sure
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
