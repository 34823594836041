import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, { useState } from "react";
import { useBranchCourseContext } from "../BranchCourseDataContext";
import { BranchClassFeeEditTableRow } from "./BranchClassFeeEditTableRow";

export const BranchClassFeeEditTable = ({
  values,
  errors,
  touched,
  deleted,
  setDeleted,
}) => {
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  const [open, setOpen] = useState(false);
  const [batchTypeOpen, setBatchTypeOpen] = useState("");
  const TYPE = values.fees[0].batch_type;
  const empty_row = {
    frequency_unit: values.fees[0].frequency_unit,
    virtual_course_fee: values.fees[0].virtual_course_fee,
    price_range_ind: 0,
    branch_course_fee_id: null,
  };
  const removeRow = (branch_course, remove, index) => {
    if (values.fees.length > 1) {
      remove(index);
      if (branch_course.branch_course_fee_id != null) {
        deleted
          ? setDeleted([...deleted, branch_course.branch_course_fee_id])
          : setDeleted([branch_course.branch_course_fee_id]);
      }
    }
  };

  const handleRowClick = (id) => {
    setOpen(!open);
    setBatchTypeOpen(id);
  };
  return (
    <span>
      <Table>
        <TableBody>
          {[
            { id: 4, value: "4 times a week" },
            { id: 8, value: "8 times a week" },
          ].map((bt) => {
            return (
              <BranchClassFeeEditTableRow
                values={values}
                errors={errors}
                touched={touched}
                deleted={deleted}
                setDeleted={setDeleted}
                bt={bt}
                TYPE={TYPE}
              />
            );
          })}
        </TableBody>
      </Table>
    </span>
  );
};
