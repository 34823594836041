/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CouponEditForm } from "./Components/CouponEditForm";
import { useSubheader } from "../../../_metronic/layout";
import { CouponListSelection } from "../SelectionWidgets/CouponListSelection";
import { InstructorListSelection } from "../SelectionWidgets/InstructorListSelection";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import moment from "moment";

const initCoupon = {
  coupon_name: "",
  active_id: "1",
  showing: "1",
  coupon_value: "",
  discount_type_id: "1",
  branch_id: 0,
  batch_type_id: "1",
  combined: "0",
  startdate: "",
  enddate: "",
  applicable_start_date: "",
  applicable_end_date: "",
  program_type_id: "",
};

export function CouponEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [branchCoupons, setBranchCoupons] = useState([]);
  const [branchMissingCoupons, setBranchMissingCoupons] = useState([]);
  const [couponsToRemove, setCouponsToRemove] = useState([]);
  const [couponsToAdd, setCouponsToAdd] = useState([]);
  const [values, setValues] = useState();
  const btnRef = useRef();
  ////console.log(id);

  initCoupon.branch_id = branch.branch_id;

  useEffect(() => {
    //TODO: fetch details for editing batch here
    const getCouponInfo = async () => {
      const coupon_info_url = `api/discounts/edit/${id}`;
      const res = await axios(coupon_info_url);
      setData(res.data.results[0]);

      // //console.log(res);
    };
    id && getCouponInfo();
  }, [id]);

  useEffect(() => {
    const newValues = data && id ? data : initCoupon;
    //console.log("newvalues:", newValues);
    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Coupon";
    if (id && duplicate) {
      _title = `Duplicate Coupon`;
    } else if (id) {
      _title = `Edit Coupon - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //Get list of coupons in branch
  useEffect(() => {
    const getCoupons = async () => {
      try {
        const coupons_url = `api/discounts/coupons/${branch.branch_id}`;
        const res = await axios(coupons_url);
        //console.log(coupons_url, res);

        setBranchCoupons(res.data.results);
        dest_id
          ? setCouponsToAdd(
              res.data.results.map((c) => {
                return c.coupon_id;
              })
            )
          : setCouponsToAdd(couponsToAdd);
        // //console.log(res.data.results);
      } catch (e) {
        //console.log("coupons in branch: ", e);
      }
    };
    id && getCoupons();
  }, [id, dest_id]);

  //Get List of coupons not in branch
  useEffect(() => {
    const getMissingCoupons = async () => {
      try {
        const coupons_url = `api/discounts/coupons/missing/${branch.branch_id}`;
        const res = await axios(coupons_url);
        setBranchMissingCoupons(res.data.results);
      } catch (e) {
        //console.log("missing coupons: ", e);
      }
    };
    id && getMissingCoupons();
  }, [id]);

  const saveCoupon = async (values) => {
    if (!id) {
      //save new coupon post
      const couponsUrl = "api/discounts";
      //console.log("post new coupon data", values);
      const result = await axios.post(couponsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/coupons/coupon-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && !dest_id) {
      //save new duplicated coupon post
      const couponsUrl = "api/discounts";
      //console.log("post duplicate coupon data", values);
      const result = await axios.post(couponsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/coupons/coupon-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && dest_id) {
      values.branch_id = dest_id;
      //console.log("edit duplicate coupon data", values);
      //edit duplicate coupon patch
      const editCouponUrl = `api/discounts/edit/`;
      const result = await axios.patch(editCouponUrl + id, values);
      // //console.log(result)
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/coupons/coupon-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    } else {
      //console.log("edit coupon data", values);
      //edit coupon patch
      const editCouponUrl = `api/discounts/edit/`;
      const result = await axios.patch(editCouponUrl + id, values);
      // //console.log(result)
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/coupons/coupon-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveCouponClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveCoupon(null);
    }
  };

  const backToCouponList = () => {
    history.push(`/coupons`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the coupon successfully"
              : editSuccessOpen
              ? "Edited the coupon successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToCouponList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveCouponClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Coupon info
              </a>
            </li>
            {/* {((id && !duplicate) || (id && duplicate && dest_id)) && (
              <>
                {" "}
                <li className="nav-item" onClick={() => setTab("coupons")}>
                  <a
                    className={`nav-link ${tab === "coupons" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "coupons").toString()}
                  >
                    Unadded Coupons
                  </a>
                </li>
              </>
            )} */}
          </ul>
          <div className="mt-5">
            {tab === "basic" && values && (
              <CouponEditForm
                //actionsLoading={actionsLoading}
                //Pass in the edit coupon OR new coupon
                coupon={values}
                btnRef={btnRef}
                saveCoupon={saveCoupon}
              />
            )}
            {/* {tab === "coupons" && id && (
              <div>
                {" "}
                <CouponListSelection
                  id={id}
                  setCouponsToAdd={setCouponsToAdd}
                  couponsToAdd={couponsToAdd}
                  setCouponsToRemove={setCouponsToRemove}
                  couponsToRemove={couponsToRemove}
                  branchCoupons={branchCoupons}
                  setBranchCoupons={setBranchCoupons}
                  branchMissingCoupons={branchMissingCoupons}
                  setBranchMissingCoupons={setBranchMissingCoupons}
                />{" "}
              </div>
            )} */}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
