import React, { useMemo, useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useUserContext } from "./UserDataContext";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import {
  Input,
  TextArea,
  Select,
  SelectCreatable,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";

const initialFilterValues = {
  idFilter: "",
  dateFilter: "",
  activeFilter: "1",
  branchFilter: "",
  roleFilter: "",
  nameFilter: "",
};

const prepareFilter = (values) => {
  const {
    idFilter,
    dateFilter,
    activeFilter,
    branchFilter,
    roleFilter,
    nameFilter,
  } = values;
  const filter = {};
  
  filter.id = idFilter !== "" ? idFilter : "";
  filter.date = dateFilter !== "" ? dateFilter : "";
  filter.active_id = activeFilter !== "" ? activeFilter : "";
  filter.branch_id = branchFilter !== "" ? branchFilter : "";
  filter.role_id = roleFilter !== "" ? roleFilter : "";
  filter.first_last = nameFilter !== "" ? nameFilter : "";

  return filter;
};

export function UserFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const DROPDOWN_ACTIVE = useUserContext().ACTIVE;
  const DROPDOWN_ROLE = useUserContext().ROLE;
  const DROPDOWN_BRANCH = useUserContext().BRANCH;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const context = useUserContext();
  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };
  const useStyles = makeStyles({
    btnCircleSm: {
      width: "30px",
            height: "30px",
            padding: "0px 7px",
            margin: "2px 1px 1px 1px",
            borderRadius: "15px",
            fontSize: "4px",
            textAlign: "center",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    applyFilter(initialFilterValues);
  }, [origData]);

  // fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          // handleChange,
          setFieldValue,
        }) => (
          <fieldset className="mb-3" disabled={context.isLoading}>
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-1">
                <input
                  className="form-control"
                  placeholder="ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>

              <div className="col-lg-2">
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Full Name"
                  name="nameFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("nameFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.nameFilter}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Full Name
                </small>
              </div>
              
              <div className="col-lg-2">
                <Select
                  placeholder="Filter by Role"
                  name="roleFilter"
                  validationColorScheme={false}
                  isClearable={true}
                  onChange={(e) => {
                    setFieldValue("roleFilter", e ? e.value : "");
                    handleSubmit();
                  }}
                >
                  {DROPDOWN_ROLE.ROLES && Array.isArray(DROPDOWN_ROLE.ROLES) &&
                    DROPDOWN_ROLE.ROLES.map((a) => {
                      return (
                        <option key={a.value} value={a.value}>
                          {a.label}
                        </option>
                      );
                    }
                  )}

                </Select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Role
                </small>
              </div>

              <div className="col-lg-3">
                <Select
                  placeholder="Filter by Location"
                  name="branchFilter"
                  validationColorScheme={false}
                  isClearable={true}
                  onChange={(e) => {
                    setFieldValue("branchFilter", e ? e.value : "");
                    handleSubmit();
                  }}
                >
                  {DROPDOWN_BRANCH.BRANCHES && Array.isArray(DROPDOWN_BRANCH.BRANCHES) &&
                    DROPDOWN_BRANCH.BRANCHES.filter(b => !b.disabled).map((a) => (
                      <option key={a.value} value={a.value}>
                        {a.label}
                      </option>
                    ))}

                </Select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Location
                </small>
              </div>

              <div className="col-lg-2">
                <Select
                  placeholder="Filter by Status"
                  name="activeFilter"
                  validationColorScheme={false}
                  isClearable={true}
                  onChange={(e) => {
                    setFieldValue("activeFilter", e ? e.value : "");
                    handleSubmit();
                  }}
                >
                  {DROPDOWN_ACTIVE.ACTIVES && Array.isArray(DROPDOWN_ACTIVE.ACTIVES) &&
                    DROPDOWN_ACTIVE.ACTIVES.map((a) => (
                      <option key={a.value} value={a.value}>
                        {a.label}
                      </option>
                    ))}
                </Select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>

              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />

              {/* <button
                className={`btn btn-sm btn-circle btn-light ${classes.btnCircleSm}`}
                onClick={(e) => setShowMoreFilters(!showMoreFilters)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i
                    className={`fa text-${
                      showMoreFilters ? `secondary fa-minus` : `primary fa-plus`
                    }`}
                  ></i>
                </span>
              </button> */}
            </div>
            {/* {showMoreFilters && (
              <div className="form-group row">
              
              </div>
            )} */}
          </form>
        </fieldset>
        )}
      </Formik>
    </>
  );
}
