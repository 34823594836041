import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const ACTIVE_URL = "api/workshops/active";


const WebsiteAnnouncementsContext = createContext();

export function useWebsiteAnnouncementsContext() {
  return useContext(WebsiteAnnouncementsContext);
}

export const WebsiteAnnouncementsConsumer = WebsiteAnnouncementsContext.Consumer;

export function WebsiteAnnouncementsContextProvider({ websiteAnnouncementsUIEvents, children }) {
  const [ACTIVE, SETACTIVE] = useState([]);

  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const websiteAnnouncementsContext = {
    newWebsiteAnnouncementsButtonClick: websiteAnnouncementsUIEvents.newWebsiteAnnouncementsButtonClick,
    openEditWebsiteAnnouncementsPage: websiteAnnouncementsUIEvents.openEditWebsiteAnnouncementsPage,
    refetch: websiteAnnouncementsUIEvents.refetch,
    setRefetch: websiteAnnouncementsUIEvents.setRefetch,
    TYPES: {
      ACTIVE,
      SETACTIVE,
    },
  };

  return (
    <WebsiteAnnouncementsContext.Provider value={websiteAnnouncementsContext}>
      {children}
    </WebsiteAnnouncementsContext.Provider>
  );
}
