// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage, Field } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function CouponReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return (
    <>
      <div className="form-group row">
        <div className={"col-md-3"}>
          <Select
            name={`batch_type`}
            label="Type"
            onChange={(e) => {
              setFieldValue("batch_type", e.value);
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
            validationColorScheme={false}
          >
            <option key={"1"} value={"1"}>
              {"Regular Class"}
            </option>
            <option key={"2"} value={"2"}>
              {"Camp"}
            </option>
          </Select>
        </div>

        {
          <div className="col-md-3">
            <label className={"required-field"}>Coupon Name</label>
            <Field
              as="select"
              name={`coupon_name`}
              className={
                "form-control" +
                (errors.coupon_name && touched.coupon_name ? " is-invalid" : "")
              }
              onChange={(e) => {
                setFieldValue("coupon_name", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            >
              <option value={"default"}>Select a coupon...</option>
              {values.batch_type == "1" &&
                DROPDOWN_TYPE.CLASSCOUPON_NAME &&
                DROPDOWN_TYPE.CLASSCOUPON_NAME.sort((a, b) =>
                  a.value.toString().localeCompare(b.value.toString())
                ).map((a) => (
                  <option key={a.id} value={a.value}>
                    {a.value}
                  </option>
                ))}
              {values.batch_type == "2" &&
                DROPDOWN_TYPE.CAMPCOUPON_NAME &&
                DROPDOWN_TYPE.CAMPCOUPON_NAME.sort((a, b) =>
                  a.value.toString().localeCompare(b.value.toString())
                ).map((a) => (
                  <option key={a.id} value={a.value}>
                    {a.value}
                  </option>
                ))}
            </Field>
            <ErrorMessage
              name={`coupon_name`}
              component="div"
              className="invalid-feedback"
            />
          </div>
        }
        {
          <div className={"col-lg-3"}>
            <label className={"required-field pr-2"}>Date</label>
            <OverlayTrigger
              overlay={
                <Tooltip>The date of the registration using the coupon</Tooltip>
              }
            >
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </OverlayTrigger>

            <input
              type="date"
              className={
                "form-control" +
                (errors.selected_date && touched.selected_date
                  ? " is-invalid"
                  : "")
              }
              value={
                values.selected_date ? values.selected_date.split("T")[0] : ""
              }
              onChange={(e) => {
                setFieldValue("selected_date", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            ></input>
            <ErrorMessage
              name={`selected_date`}
              component="div"
              className="invalid-feedback"
            />
          </div>
        }
      </div>
    </>
  );
}
