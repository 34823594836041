/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { Input, Select } from "../../../../_metronic/_partials/controls";

import { PersistFormikValues } from "formik-persist-values";

const PriceFooterForm = ({ messages, saveMessages, btnRef }) => {
  const getIntialValues = (messages) => {
    let styles = ["text-muted", "text-primary", "text-danger"]
    let initialValues = {};
    Object.keys(messages).map((type) => {
      initialValues[
        type
          .toLowerCase()
          .split(" ")
          .join("_") + "_message"
      ] = messages[type].message;
      initialValues[
        type
          .toLowerCase()
          .split(" ")
          .join("_") + "_style"
      ] = styles.indexOf(messages[type].style);
    });
    // console.log("init",initialValues)
    return initialValues;
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={getIntialValues(messages)}
      // validationSchema={messagesSchema}
      onSubmit={(values) => {
        saveMessages({
          ...values,
        });
      }}
    >
      {({ handleSubmit, values, errors, setFieldValue, getFieldProps }) => (
        <Form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-2 font-weight-bolder">
              Class Type
            </div>
            <div className="col-lg-6 text-center font-weight-bolder">
              Message
            </div>
            <div className="col-lg-3 text-center font-weight-bolder">Style</div>
          </div>

          {messages &&
            Object.keys(messages).map((type, type_index_key) => {
              return (
                <div className="form-group row" key={type_index_key}>
                  <div className="col-lg-2 font-weight-bold">
                    <label>{type}</label>
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name={`${type
                        .toLowerCase()
                        .split(" ")
                        .join("_")}_message`}
                      component={Input}
                      type="text"
                    />
                  </div>
                  <div className="col-lg-3">
                    <Select
                      name={`${type
                        .toLowerCase()
                        .split(" ")
                        .join("_")}_style`}
                    >
                      <option key={0} value={0}>
                        Greyed Out
                      </option>
                      <option key={1} value={1}>
                        Blue
                      </option>
                      <option key={2} value={2}>
                        Red
                      </option>
                    </Select>
                  </div>
                </div>
              );
            })}
          <button
            type="submit"
            style={{ display: "none" }}
            ref={btnRef}
            onSubmit={() => handleSubmit()}
          ></button>
          {/* {<PersistFormikValues name="messages-op-form" />} */}
        </Form>
      )}
    </Formik>
  );
};
export function PriceFooter({ history }) {
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [messages, setMessages] = useState([]);
  const [messagesSuccessOpen, setMessagesSuccessOpen] = useState(false);
  const [messagesErrorOpen, setMessagesErrorOpen] = useState(false);
  const btnRef = useRef();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  useEffect(() => {
    const getMessages = async () => {
      const result = await axios(
        "api/branches/price/footer/view/" + branch.branch_id
      );
      console.log("HERE",result.data.results);
      setMessages(result.data.results);
    };

    branch && getMessages();
  }, [branch]);

  const handleClose = () => {
    setMessagesSuccessOpen(false);
    setMessagesErrorOpen(false);
  };

  const saveMessages = async (values) => {
    let styles = ["text-muted", "text-primary", "text-danger"]
    let body = {}
    Object.keys(messages).map((type) => {
      body[type] = {}
      body[type].message = values[type.toLowerCase().split(" ").join("_") + "_message"]
      body[type].style = styles[values[type.toLowerCase().split(" ").join("_") + "_style"]]
      body[type].id = messages[type].branch_price_footer_id
    });

    console.log(body)

    const patchUrl = "api/branches/price/footer/view/";
    const result = await axios.patch(patchUrl, body);

    if (result.data != "failed") {
      setMessagesSuccessOpen(true);
      setTimeout(() => {
        history.push(`/branch-maintenance`);
      }, 1000);
    } else {
      setMessagesErrorOpen(true);
    }
  };

  const saveMessagesClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveMessages(null);
    }
  };
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={messagesSuccessOpen ? messagesSuccessOpen : messagesErrorOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={messagesSuccessOpen ? "success" : "error"}
          message={
            messagesSuccessOpen
              ? "Added payment successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={"Edit Hours Of Operation"}>
          <CardHeaderToolbar>
            {/* <Link
            to={id ? `/` : "/"}
            className="btn btn-primary"
          >
            {" "}
            Back{" "}
          </Link> */}
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveMessagesClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link active`}
                data-toggle="tab"
                role="tab"
                aria-selected="true"
              >
                Messages
              </a>
            </li>
          </ul>

          <PriceFooterForm
            messages={messages}
            saveMessages={saveMessages}
            btnRef={btnRef}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}
