import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";

export function SearchDropdown() {
  const [loading, setLoading] = useState(false);
  const [doSearch, setDoSearch] = useState(true);
  //const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const token = useSelector((state) => state.auth.authToken, shallowEqual);
  const userListUrl = "api/user/searchable/";
  const [users, setUsers] = useState(null);
  let timeoutId;

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const useStyles = makeStyles({
    formControl: {
      backgroundColor: "transparent",
      paddingRight: 0,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      padding: "30px",
      borderRadius: 0,
    },
    groupAppend: {
      padding: 0,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },
  });
  const classes = useStyles();

  async function fetchUsers() {
    // const headers = {
    //   headers: {
    //     "x-auth-token": token,
    //     "Content-Type": "application/json",
    //   },
    // };
    // console.log("loading users... from dropdown");
    const result = await axios(
      userListUrl + branch.branch_id + "/" + searchValue
    );
    //const result = await axios(userListUrl+branch.branch_id+"/"+searchValue, headers);

    // console.log(result)
    setUsers(result.data);
    //console.log(result.data);
    setDoSearch(false);
  }

  // useEffect(() => {
  //   doSearch && fetchUsers();
  // }, [doSearch]);

  useEffect(() => {
    if (searchValue != "" && searchValue.length > 2) {
      setDoSearch(true);
      fetchUsers();
    }
  }, [branch, searchValue]);

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const handleSearchChange = (event) => {
    //setData(null);
    setSearchValue(event.target.value);

    if (event.target.value.length > 2) {
      clearTimeout();

      setLoading(true);

      // simulate getting search result
      timeoutId = setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const clear = () => {
    setSearchValue("");
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {/* {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )} */}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={() => {
            //setData(null);
            setLoading(false);
            setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            
            <div>
              <form className="quick-search-form">
                <div className="input-group">
                 
                  <div className="searchBar">
                    <input
                      id="searchQueryInput"
                      className="formControl"
                      //autoFocus={true}
                      autoComplete="off"
                      type="text"
                      name="searchQueryInput"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={handleSearchChange}
                      
                    />
                    <button
                      id="searchQuerySubmit"
                      type="submit"
                      name="searchQuerySubmit"
                    >
                      <span className="svg-icon svg-icon-sm">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                      </span>
                      
                    </button>
                  </div>

                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
                    <span>
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        // onClick={clear}
                        // className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result":
                  users &&
                  (users.students.length ||
                    users.parents.length ||
                    users.instructors.length),
              })}
            >
              <SearchResult users={users} keyword={searchValue} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
