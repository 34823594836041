import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";

const DELIVERY_URL = "api/batches/delivery-type";
const COURSE_URL = "api/customer-leads/course/";
const getCoursesUrl = `api/portal-programs/`;
const getBatchesUrl = `api/programs/`;

const CustomerLeadsContext = createContext();

export function useCustomerLeadsContext() {
  return useContext(CustomerLeadsContext);
}

export const CustomerLeadsConsumer = CustomerLeadsContext.Consumer;

export function CustomerLeadsContextProvider({
  leadUICustomerLeads,
  children,
}) {
  const [DELIVERYTYPE, SETDELIVERYTYPE] = useState([]);
  const [COURSE, SETCOURSE] = useState([]);
  const [AUTOSELECTCOURSE, SETAUTOSELECTCOURSE] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseData, setSelectedCourseData] = useState();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_delivery_type = await axios(DELIVERY_URL);
        // console.log("Got dt ", res_delivery_type.data.result)
        SETDELIVERYTYPE(res_delivery_type.data.results);
        let res_course = await axios(COURSE_URL + branch.branch_id);
        // console.log("context 1 programs: ", res_course.data.results);
        let sortedData = res_course.data.results.map((c) => {return {label: c.abbr, value: c.id, category: c.category, name: c.name, code: c.code}})
        SETCOURSE(sortedData);

        const result_course_data = await axios(getCoursesUrl, {
          params: { branch_id: branch.branch_id, type: "programs" },
        });
        SETAUTOSELECTCOURSE(result_course_data.data.results)
      } catch (e) {
        console.error(e);
      }
    };

    INIT_HELPERS();
  }, [branch]);

  const getCourseBatches = async () => {
    if(selectedCourse){
    //{label: 'Rob. Design', value: 20, category: 'Robotics', name: 'Robot Design & Navigation ', code: 'R400'}
    const result = await axios(
      getCoursesUrl + selectedCourse.id + `?branchId=${branch.branch_id}`
    );

    setSelectedCourseData(result.data.results);
    }
  };

  useEffect(() => {
    selectedCourse && getCourseBatches();
  }, [selectedCourse]);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const LeadsContext = {
    newCustomerLeadsButtonClick:
      leadUICustomerLeads.newCustomerLeadsButtonClick,
    openEditCustomerLeadsPage: leadUICustomerLeads.openEditCustomerLeadsPage,
    openDeleteCustomerLeadsDialog:
      leadUICustomerLeads.openDeleteCustomerLeadsDialog,
    openDuplicateCustomerLeadsPage:
      leadUICustomerLeads.openDuplicateCustomerLeadsPage,
    refetch: leadUICustomerLeads.refetch,
    setRefetch: leadUICustomerLeads.setRefetch,
    TYPES: {
      DELIVERYTYPE,
      SETDELIVERYTYPE,
      COURSE,
      SETCOURSE,
      AUTOSELECTCOURSE,
      SETAUTOSELECTCOURSE,
      selectedCourse: selectedCourse,
      setSelectedCourse: setSelectedCourse,
      selectedCourseData: selectedCourseData,
      setSelectedCourseData: setSelectedCourseData,
    },
  };

  return (
    <CustomerLeadsContext.Provider value={LeadsContext}>
      {children}
    </CustomerLeadsContext.Provider>
  );
}
