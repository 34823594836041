import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { WebTransactionEdit } from "./Components/WebTransactionEdit";
import { WebTransactionsContextProvider } from "./Components/WebTransactionsDataContext";
import { WebTransactionsList } from "./WebTransactionsList";

export default function WebTransactionsPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const trasnactionUIWebTransactions = {
    openEnrollPage: (id) => {
      history.push(`/web-transactions/enroll/${id}`);
    },
    triggerEmail: (id) => {
      history.push(
        `/web-transactions/web-transactions-list/trigger-email/${id}`
      );
    },
    manuallyEntered: (id) => {
      history.push(
        `/web-transactions/web-transactions-list/manually-entered/${id}`
      );
    },

    refetch: refetch,
    setRefetch: setRefetch,
  };

  return (
    <WebTransactionsContextProvider
      trasnactionUIWebTransactions={trasnactionUIWebTransactions}
    >
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            <Redirect
              exact={true}
              from="/web-transactions"
              to="/web-transactions/web-transactions-list"
            />
          }

          <ContentRoute
            path="/web-transactions/web-transactions-list"
            component={WebTransactionsList}
          />
          <ContentRoute
            path="/web-transactions/enroll/:id"
            component={WebTransactionEdit}
          />
        </Switch>
      </Suspense>
    </WebTransactionsContextProvider>
  );
}
