import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";

const TASKS_URL = "api/task";
const USER_URL = "api/user";
const USERS_LIST_OPTIONS_URL = "api/user/filter";

const TimesheetContext = createContext();

export function useTimesheetContext() {
  return useContext(TimesheetContext);
}

export const TimesheetConsumer = TimesheetContext.Consumer;

export function TimesheetContextProvider({ timesheetUIEvents, children }) {
  const [date, setDate] = useState(
    moment()
      .startOf("week")
      .day(1)
      .format("yyyy-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment()
      .startOf("week")
      .day(1)
      .subtract(4, "weeks")
      .format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment()
      .startOf("week")
      .day(7)
      .format("yyyy-MM-DD")
  );
  const [TASKS, SET_TASKS] = useState([]);
  const [BRANCHES, SET_BRANCHES] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userApprover, setUserApprover] = useState(false);

  const { userId, userBranches, currentBranch } = useSelector(
    ({ auth }) => ({
      userId: auth.user && auth.user[0].user_id,
      userBranches: auth.branches.branchList,
      currentBranch: auth.branches.currentBranch,
    }),
    shallowEqual
  );

  const isUserApprover = async () => {
    try {
      const res_roles = await axios.get(USER_URL + "/role/" + userId);
      return (
        res_roles.data.find(
          (role) =>
            role.role_name === "Branch Head" || role.role_name === "Super Admin"
        ) !== undefined
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUserTasks = async (userId) => {
    const { data } = await axios.get(USER_URL + "/" + userId + "/tasks");
    SET_TASKS(data);
  };

  const getTaskNameById = (taskId) => {
    const task = TASKS.find((task) => task.value == taskId);
    return task ? task.label : "";
  };

  const getBranchNameById = (branchId) => {
    const branch = branchId == null ? {value: 0, label: "Corporate"} : BRANCHES.find((branch) => branch.value == branchId);
    return branch ? branch.label : "";
  };

  useEffect(() => {
    const initData = async () => {
      const res_user_tasks = await axios(
        USER_URL + "/" + userId + "/tasks/options"
      );
      const res_all_tasks = await axios(`${TASKS_URL}/tasks/options/${userId}`);
      const task_options = res_all_tasks.data.map((task) => {
        return {
          value: task.value,
          label: task.label,
          corporate: task.corporate,
          disabled:
            res_user_tasks.data.find(
              (user_task) => user_task.value === task.value
            ) === undefined
              ? true
              : false,
        };
      });
      SET_TASKS(task_options);

      const res_all_branches = await axios.get(
        USERS_LIST_OPTIONS_URL + "?branches=true"
      );
      const branch_options = res_all_branches.data.branches.map((branch) => {
        return {
          value: branch.value,
          label: branch.label,
          disabled:
            userBranches.find(
              (user_branch) => user_branch.branch_id === branch.value
            ) === undefined
              ? true
              : false,
        };
      });
  
      SET_BRANCHES(branch_options);

      setRefetch(false);
    };

    userId && userBranches && initData();
  }, [userId, refetch]);

  useEffect(() => {
    const initUserApprover = async () => {
      const res_userAuth = await isUserApprover();
      setUserApprover(res_userAuth);
    };

    userId && initUserApprover();
  }, [userId]);

  useEffect(() => {
    setRefetch(true);
  }, [startDate, endDate]);

  // useEffect(() => {
  //   console.log("Auth: ", auth);
  // }, [auth]);

  const timesheetContext = {
    // newTimesheetButtonClick: timesheetUIEvents.newTimesheetButtonClick,
    userId: userId,
    TASK: {
      TASKS,
      SET_TASKS,
    },
    BRANCH: {
      BRANCHES,
      SET_BRANCHES,
    },
    states: {
      refetch,
      setRefetch,
      date,
      setDate,
      isLoading,
      setIsLoading,
      userApprover,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    },
    functions: {
      getTaskNameById,
      getBranchNameById,
    },
  };

  return (
    <TimesheetContext.Provider value={timesheetContext}>
      {children}
    </TimesheetContext.Provider>
  );
}
