import React from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useUserContext } from "./UserDataContext";

export default function DeletePaymentDialog({
  show,
  setShow,
  onHide,
  id,
  setSuccessMessage,
  setErrorMessage,
}) {
  const context = useUserContext();

  const onDelete = async () => {
    const deletePaymentURL = "api/user/expire/";

    const result = await axios(deletePaymentURL + id);
    // console.log(result);

    if (result.data == "success") {
      setSuccessMessage("User Removing Successfully");
      onHide();
      setTimeout(() => {
        context.setRefetch({...context.refetch, users: true});
      }, 1000);
    } else {
      setErrorMessage("Error Removing User");
    }
    
  };
  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Remove User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to remove this User?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onHide}
        >
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onDelete();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
