import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../../../helpers/TimeConvert";
import { useInstructorProfileContext } from "../Components/InstructorProfileDataContext";
import { InstructorProfileTableActions } from "./ActionFormatter/InstructorProfileTableActions";
import moment from "moment";
import { Badge } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function InstructorProfileRow(props) {
  const classes = useStyles();
  const { row, showActions } = props;
  const instructorProfileContext = useInstructorProfileContext();


  return (
    <Fragment>
      <TableRow key={row.instructor_profile_id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.instructor_profile_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.position}
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <InstructorProfileTableActions
              row={row}
              openEditInstructorProfilePage={instructorProfileContext.openEditInstructorProfilePage}
              openActivateInstructorProfileDialog={instructorProfileContext.openActivateInstructorProfileDialog}
              openDeactivateInstructorProfileDialog={instructorProfileContext.openDeactivateInstructorProfileDialog}
            />
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
}
