// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { useReportContext } from "../ReportDataContext";

export function MaintenanceReportForm({
  reportResults,
  setReportResults,
  values,
  errors,
  touched,
  setReportTypeId,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return <></>;
}
