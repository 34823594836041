import axios from "axios";

export const FAMILY_URL = "api/family-view/family/";
export const STUDENT_COURSES_URL = "api/family-view/student-courses/";

// READ
export function findFamily(id) {
  const res = axios.get(FAMILY_URL + id);
  //console.log(res);
  return res;
}

export function getStudentCourses(id) {
  const res = axios.get(STUDENT_COURSES_URL + id);
  //console.log(res);
  return res;
}
