export const campAttendanceHeaders = [
  {
    id: "camp_type",
    numeric: false,
    disablePadding: true,
    label: "Camp Type"
  },
  {
    id: "course_abbr",
    numeric: false,
    disablePadding: true,
    label: "Stream (Abbr)"
  },
  {
    id: "student_id",
    numeric: false,
    disablePadding: true,
    label: "Student ID"
  },
  {
    id: "student_name",
    numeric: false,
    disablePadding: true,
    label: "Student Name"
  },
  {
    id: "parent_name",
    numeric: false,
    disablePadding: true,
    label: "Parent Name"
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Phone Number"
  },
];