import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const BATCH_TYPE_URL = "api/batches/batch-type";
const DELIVERY_TYPE_URL = "api/batches/delivery-type";
const PROGRAM_TYPE_URL = "api/batches/program-type";
const ACTIVE_URL = "api/batches/active";
const COURSE_URL = "api/batches/course/";
const COACH_URL = "api/batches/coach/";

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const BatchContext = createContext();

export function useBatchContext() {
  return useContext(BatchContext);
}

export const BatchConsumer = BatchContext.Consumer;

export function BatchContextProvider({ batchUIEvents, children }) {
  const [BATCH_TYPE, SETBATCH_TYPE] = useState([]);
  const [DELIVERY_TYPE, SETDELIVERY_TYPE] = useState([]);
  const [PROGRAM_TYPE, SETPROGRAM_TYPE] = useState([]);
  const [ACTIVE, SETACTIVE] = useState([]);
  const [COURSE, SETCOURSE] = useState([]);
  const [COACH, SETCOACH] = useState([]);
  const [SELECT_COURSE_OPTIONS, SETSELECT_COURSE_OPTIONS] = useState([]);
  // const [refetch, setRefetch] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_batch = await axios(BATCH_TYPE_URL);
        SETBATCH_TYPE(res_batch.data.results);
        let res_delivery = await axios(DELIVERY_TYPE_URL);
        SETDELIVERY_TYPE(res_delivery.data.results);
        let res_program = await axios(PROGRAM_TYPE_URL);
        SETPROGRAM_TYPE(res_program.data.results);
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
        let res_course = await axios(COURSE_URL + branch.branch_id);
        SETCOURSE(res_course.data.results);
        let res_coach = await axios(COACH_URL + branch.branch_id);
        SETCOACH(res_coach.data.results);
        let selectOptions = [];
        res_program.data.results.forEach((pt) => {
          let option = {
            label: pt.value,
            options: res_course.data.results
              .filter((c) => {
                return c.program_type === pt.id;
              })
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map((a) => {
                return { value: a.value, label: `${a.value} - ${a.abbr}` };
              }),
          };
          selectOptions.push(option);
        });
        SETSELECT_COURSE_OPTIONS(selectOptions);
        //console.log("results", res_course.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, [branch]);

  const batchContext = {
    newBatchButtonClick: batchUIEvents.newBatchButtonClick,
    openEditBatchPage: batchUIEvents.openEditBatchPage,
    openDeleteBatchDialog: batchUIEvents.openDeleteBatchDialog,
    openActivateBatchDialog: batchUIEvents.openActivateBatchDialog,
    openBatchFullDialog: batchUIEvents.openBatchFullDialog,
    openDuplicateBatchPage: batchUIEvents.openDuplicateBatchPage,
    openLeadAttendanceDialog: batchUIEvents.openLeadAttendanceDialog,
    openPointsDialog: batchUIEvents.openPointsDialog,
    refetch: batchUIEvents.refetch,
    setRefetch: batchUIEvents.setRefetch,
    openBatchNotFullDialog: batchUIEvents.openBatchNotFullDialog,
    openAttendanceDialog: batchUIEvents.openAttendanceDialog,
    openEmailDialog: batchUIEvents.openEmailDialog,
    TYPES: {
      BATCH_TYPE,
      SETBATCH_TYPE,
      DELIVERY_TYPE,
      SETDELIVERY_TYPE,
      PROGRAM_TYPE,
      SETPROGRAM_TYPE,
      ACTIVE,
      SETACTIVE,
      COURSE,
      SETCOURSE,
      DAYS,
      COACH,
      SETCOACH,
      SELECT_COURSE_OPTIONS,
    },
    DAYS,
  };

  return (
    <BatchContext.Provider value={batchContext}>
      {children}
    </BatchContext.Provider>
  );
}
