import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TimesheetRow from "./TimesheetRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "react-bootstrap";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import moment from "moment";

let headRows = [
  {
    id: "icon_name",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Icon",
    width: "1%",
    colSpan: 1,
  },
  {
    id: "task_branch_name",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Task",
    label2: "Branch",
    width: "20%",
    colSpan: 1,
  },
  {
    id: "monday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Monday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "tuesday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Tuesday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "wednesday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Wednesday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "thursday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Thursday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "friday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Friday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "saturday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Saturday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "sunday_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Sunday",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "total_hours",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Total",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "action_actions",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    width: "5%",
    colSpan: 2,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    overflowX: "auto",
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  table: {
    minWidth: 750,
  },
}));

export default function EnhancedTimesheetTable({
  rows,
  addRow,
  removeRow,
  save,
  loading,
  setFieldValue,
  values,
  errors,
}) {
  const timesheetStates = useTimesheetContext().states;
  const classes = useStyles();

  /**
   * Returns the total hours for a given day
   * @date 2022-07-12
   * @param {day_index} day_index
   */
  const getDayTotal = (day_index) => {
    // console.log("day_index", day_index);
    let total = 0;
    for (const task of values.timesheet_tasks) {
      total += parseFloat(task.timesheet_entries[day_index].hours);
    }
    return total;
  };

  /**
   * Returns the total hours for a given task
   * @date 2022-07-12
   * @param {task_index} task_index
   */
  const getTaskTotal = (task_index) => {
    let total = 0;
    for (const day in values.timesheet_tasks[task_index].timesheet_entries) {
      total += parseFloat(
        values.timesheet_tasks[task_index].timesheet_entries[day].hours
      );
    }
    return total;
  };

  /**
   * Returns the total hours for a given task/day
   * @date 2022-07-12
   */
  const getDayTaskTotal = () => {
    let total = 0;
    if (values.timesheet_tasks.length > 0) {
      for (const task of values.timesheet_tasks) {
        for (const day in task.timesheet_entries) {
          total += parseFloat(task.timesheet_entries[day].hours);
        }
      }
    }
    return total;
  };

  useEffect(() => {
    const total = getDayTaskTotal();
    const setDayTaskTool = () => {
      setFieldValue("total_hours", total);
    };
    total !== values.total_hours && setDayTaskTool();
  }, [values.timesheet_tasks]);

  // useEffect(() => {
  //   console.log("values", values);
  //   console.log("timesheetStates.date", timesheetStates.date);
  // }, [values]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {/* BEGIN TABLE HEAD */}
            {Array.isArray(rows) && rows.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    {headRows.map((row, index) => (
                      <TableCell
                        key={index}
                        align={row.align}
                        colSpan={row.colSpan}
                        style={{
                          width: row.width,
                          paddingLeft: index === 1 ? "0px" : "auto",
                        }}
                      >
                        {index > 1 && index < headRows.length - 2 ? (
                          <>
                            <span>{row.label}</span>
                            <br />
                            <span style={{ fontSize: "0.8rem" }}>
                              {moment(timesheetStates.date)
                                .startOf("week")
                                .day(index - 1)
                                .format("MMM/DD")}
                            </span>
                          </>
                        ) : row.label2 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <span className="w-50 pr-5">{row.label}</span>
                            <span className="w-50 pl-5">{row.label2}</span>
                          </div>
                        ) : index === 0 ? (
                          ""
                        ) : (
                          <span>{row.label}</span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* END TABLE HEAD */}
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <LinearProgress color="secondary" styles="width:100%" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody style={{ zIndex: "100" }}>
                    {Array.isArray(rows) &&
                      rows.map((i, index) => {
                        return (
                          <TimesheetRow
                            key={index}
                            row={i}
                            rowIndex={index}
                            editMode={
                              values.request_approve_on === null ? false : true
                            }
                            removeRow={removeRow}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            loading={loading}
                          />
                        );
                      })}
                    <TableRow>
                      {Array.isArray(headRows) &&
                        headRows.map((row, index) => {
                          if (index === 0) {
                            return (
                              <TableCell
                                key={index}
                                colSpan={2}
                                align={row.align}
                              >
                                <span
                                  style={{
                                    fontSize: "0.9rem",
                                    color: "#181c32",
                                    fontWeight: "500",
                                  }}
                                >
                                  Totals
                                </span>
                              </TableCell>
                            );
                          } else if (index > headRows.length - 3) {
                            return (
                              <TableCell key={index} colSpan={1}></TableCell>
                            );
                          } else if (index < headRows.length - 3) {
                            return (
                              <TableCell
                                key={index}
                                colSpan={1}
                                align={row.align}
                                style={row.style}
                              >
                                <span
                                  className="pr-4"
                                  style={{
                                    fontSize: "0.9rem",
                                    color: "#181c32",
                                    fontWeight: "500",
                                  }}
                                >
                                  {Array.isArray(values.timesheet_tasks) &&
                                    getDayTotal(index - 1)}
                                </span>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={index}
                                colSpan={1}
                                align={row.align}
                                style={row.style}
                              >
                                <span
                                  style={{
                                    fontSize: "0.9rem",
                                    color: "#181c32",
                                    fontWeight: "500",
                                  }}
                                >
                                  {Array.isArray(values.timesheet_tasks) &&
                                    values.timesheet_tasks.length > 0 &&
                                    getDayTaskTotal()}
                                </span>
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                  </TableBody>
                )}
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <span
                      style={{
                        fontSize: "1.1rem",
                        color: "#181c32",
                        fontWeight: "500",
                      }}
                    >
                      Please add a task to your timesheet.
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Button
                    className="btn btn-secondary font-weight-bolder font-size-sm"
                    onClick={() => {
                      if (values.request_approve_on === null) {
                        addRow();
                      }
                    }}
                    style={{
                      cursor:
                        values.request_approve_on === null
                          ? "pointer"
                          : "not-allowed",
                    }}
                    disabled={loading || values.request_approve_on !== null}
                  >
                    <i className="ki ki-plus icon-sm"></i>
                    <span>Add New Task</span>
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </div>
  );
}
