import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { Fragment, useState } from "react";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function LmsRowReport(props) {
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={`${row.id}`} hover tabIndex={-1}>
        <TableCell align="left" component="th" scope="row">
          {row.user_name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.unique_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.added_to_course_at}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {`${moment(row.last_login_at).format("MMM D, YYYY")}`}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {`${moment(row.last_submission).format("MMM D, YYYY")}`}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.course_name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.title}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
