// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function LMSAccountsReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
  Accounts,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;
  //const BATCHES_URL = useReportContext().URLS.BATCHES_URL;
  const branch = useReportContext().branch;
  //const userAuth = useReportContext().AUTH.userAuth;

  return (
    <>
      <div className="form-group row">
        <div className={"col-lg-3"}>
          <label className={"required-field"}>Start Date</label>
          <OverlayTrigger
            overlay={
              <Tooltip>The first day of class or camp for the student</Tooltip>
            }
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
          </OverlayTrigger>
          <input
            type="date"
            className={
              "form-control" +
              (errors.startdate && touched.startdate ? " is-invalid" : "")
            }
            value={values.startdate ? values.startdate.split("T")[0] : ""}
            onChange={(e) => {
              setFieldValue("startdate", e.target.value);
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          ></input>
          <ErrorMessage
            name={`startdate`}
            component="div"
            className="invalid-feedback"
          />
        </div>

        <div className={"col-md-3"}>
          <label className={"required-field"}>Type</label>
          <Select
            name={`batch_type`}
            onChange={(e) => {
              setFieldValue("batch_type", e.value);
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
            validationColorScheme={false}
          >
            <option key={"1"} value={"1"}>
              {"Regular Class"}
            </option>
            <option key={"2"} value={"2"}>
              {"Camp"}
            </option>
          </Select>
        </div>
      </div>
    </>
  );
}
