/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useCampContext } from "./CampDataContext";
import { shallowEqual, useSelector } from "react-redux";

export function CampDeleteDialog({ history, id, active_value, show, onHide }) {
  const [activity, setActivity] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useCampContext();
  // Camp Context

  async function fetchStudentsInCamp() {
    const response = await axios.get("/api/classes-report/camp-student-list/"+ id + "/" + branch.branch_id);
    console.log("response", response.data.results);
    let activityVal = response.data.results.length > 0;
    setActivity(activityVal);
  }

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    else{
      fetchStudentsInCamp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deactivateCamp = async () => {
    const deactivateUrl = "api/camps/deactivate/";

    //edit camp post
    const result = await axios.patch(deactivateUrl + id);
    if (result.data === "success") {
      //setEditSuccessOpen(true);
      // console.log("inactivated");
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/camps/camp-list");
      }, 1000);
    } else {
      //setEditErrorOpen(true);
    }
    // server request for deleting product by id
    // refresh list after deletion

    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Camp De-Activate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {activity ? (
          <>
            <p className="text-danger"> Camp can't be de-activated.</p>{" "}
            <p>Check to see if:</p>
            <ul>
              <li> students enrolled in this camp</li>
            </ul>
          </>
        ) : (
          <p>Are you sure to de-activate this camp?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {activity ? (
            <button
              type="button"
              className="btn btn-danger btn-elevate"
              disabled
            >
              Deactivate
            </button>
          ) : (
            <button
              type="button"
              onClick={deactivateCamp}
              className="btn btn-danger btn-elevate"
            >
              Deactivate
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
