import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";

const USER_URL = "api/user";
const USER_LIST_URL = "api/user/list";
const USERS_LIST_OPTIONS_URL = "api/user/filter";
const TASKS_URL = "api/task";
const CERTIFICATES_URL = "api/certification";
const SECRET = {
  value: "zebra123",
  hash: "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea",
};

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export const UserConsumer = UserContext.Consumer;

export function UserContextProvider({ userUIUsers, children }) {
  const [ACTIVES, SETACTIVES] = useState([]);
  const [ROLES, SETROLES] = useState([]);
  const [BRANCHES, SETBRANCHES] = useState([]);
  const [TASKS, SETTASKS] = useState([]);
  const [CERTIFICATES, SETCERTIFICATES] = useState([]);
  const [userData, setUserData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [secret, setSecret] = useState(SECRET);
  const [refetch, setRefetch] = useState({
    users: true,
    actives: true,
    roles: true,
    branches: true,
    tasks: true,
    certificates: true,
  });

  const state_branches = useSelector(
    (state) => state.auth.branches.branchList,
    shallowEqual
  );

  const state_user = useSelector((state) => state.auth.user, shallowEqual);
  const access = useSelector((state) => state.auth.access, shallowEqual);

  useEffect(() => {
    const getUserRoleId = async (userId) => {
      let res_user_role = { data: { role_id: 0 } };
      try {
        res_user_role = await axios(
          USER_URL + "/" + userId + "/values?role=true"
        );
      } catch (e) {
        console.error(e);
      }
      return res_user_role.data[0].role_id;
    };

    const getUserListData = async () => {
      setLoading(true);
      const res_user_list = await axios(
        USER_LIST_URL + "/" + state_user[0].user_id
      );
      setUserData(res_user_list.data.results);
    };

    const getSortedAuthorizedRoleOptionsList = async (
      currentRoleId,
      roleList
    ) => {
      if (currentRoleId == 1) {
        return roleList;
      } else if (currentRoleId == 2) {
        return roleList.filter((role) => role.value >= 2 && role.value != 4);
      } else if (currentRoleId == 4) {
        return roleList.filter((role) => role.value >= 4 || role.value == 2);
      }
    };

    const getCertificates = async () => {
      await axios(`${CERTIFICATES_URL}/courses`)
        .then((res) => {
          SETCERTIFICATES(res.data.results);
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const refetchRequest = async () => {
      setLoading(true);

      let build_url = USERS_LIST_OPTIONS_URL + "?";

      if (refetch.actives) {
        build_url += "actives=true&";
      }
      if (refetch.roles) {
        build_url += "roles=true&";
      }
      if (refetch.branches) {
        build_url += "branches=true";
      }

      await axios(build_url)
        .then((res) => {
          // adjust role objects to match user role
          const adjusted_roles = getUserRoleId(state_user[0].user_id)
            .then((role_id) => {
              return getSortedAuthorizedRoleOptionsList(
                role_id,
                res.data.roles
              );
            })
            .catch((e) => {
              console.error(e);
            });

          // adjust branches objects to match user branch
          const adjusted_branches = res.data.branches.map((branch) => {
            if (
              state_branches
                .map((branch) => branch.branch_id)
                .includes(branch.value)
            )
              return {
                value: branch.value,
                label: branch.label,
                disabled: false,
              };
            else
              return {
                value: branch.value,
                label: branch.label,
                disabled: true,
              };
          });
          const adjusted_all_locations = () => {
            if (
              res.data.branches.length ===
              adjusted_branches.filter((branch) => !branch.disabled).length
            ) {
              return { value: 0, label: "All Locations" };
            } else {
              return { value: 0, label: "All Locations", disabled: true };
            }
          };

          if (refetch.actives) {
            SETACTIVES(res.data.actives);
          }
          if (refetch.roles) {
            adjusted_roles.then((roles) => {
              SETROLES(roles);
            });
          }
          if (refetch.branches) {
            SETBRANCHES([adjusted_all_locations(), ...adjusted_branches]);
          }
        })
        .catch((e) => {
          console.error(e);
        });

      if (refetch.tasks) {
        await axios(`${TASKS_URL}/tasks/options/${state_user[0].user_id}`)
          .then((res) => {
            SETTASKS(res.data);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    };

    state_user &&
      refetch &&
      refetchRequest()
        .then(() => {
          refetch.actives = false;
          refetch.roles = false;
          refetch.branches = false;
          getUserListData().then(() => {
            refetch.users = false;
            setLoading(false);
          });
          refetch.tasks = false;
        })
        .catch((e) => {
          console.error(e);
        });

    getCertificates();
  }, [refetch, state_user]);

  const userContext = {
    newUserButtonClick: userUIUsers.newUserButtonClick,
    openEditUserPage: userUIUsers.openEditUserPage,
    openDeleteUserDialog: userUIUsers.openDeleteUserDialog,
    openActivateUserDialog: userUIUsers.openActivateUserDialog,
    openUserFullDialog: userUIUsers.openUserFullDialog,
    openUserNotFullDialog: userUIUsers.openUserNotFullDialog,
    openDuplicateUserPage: userUIUsers.openDuplicateUserPage,
    userData: userData,
    isLoading: isLoading,
    setLoading: setLoading,
    refetch: refetch,
    setRefetch: setRefetch,
    secret: secret,
    ACTIVE: {
      ACTIVES,
      SETACTIVES,
    },
    ROLE: {
      ROLES,
      SETROLES,
    },
    BRANCH: {
      BRANCHES,
      SETBRANCHES,
    },
    TASK: {
      TASKS,
      SETTASKS,
    },
    STATUS:
      access["certification"] == 0
        ? [
            { value: "Complete", label: "Complete", title: "Complete" },
            { value: "Certified", title: "Certified", label: "Certified" },
            { value: "Active", title: "Active", label: "Active" },
            { value: "On-Hold", title: "On-Hold", label: "On-Hold" },
            { value: "Cancelled", title: "Cancelled", label: "Cancelled" },
          ]
        : [
            { value: "Complete", label: "Complete", title: "Complete" },
            { value: "Active", title: "Active", label: "Active" },
            { value: "On-Hold", title: "On-Hold", label: "On-Hold" },
            { value: "Cancelled", title: "Cancelled", label: "Cancelled" },
          ],
    CERTIFICATE: {
      CERTIFICATES,
      SETCERTIFICATES,
    },
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
}
