import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../../../_metronic/layout";
import { InstructorProfileToggleDialog } from "./Components/InstructorProfileToggleDialog";
import { useInstructorProfileContext } from "./Components/InstructorProfileDataContext";
import { InstructorProfileFilter } from "./Components/InstructorProfileFilter";
import InstructorProfileTable from "./Components/InstructorProfileTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../../../Snackbar/SnackbarWrapper";
import { shallowEqual, useSelector } from "react-redux";

export const InstructorProfileList = () => {
  const [instructorProfileData, setInstructorProfileData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);

  const context = useInstructorProfileContext();
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const suhbeader = useSubheader();
  suhbeader.setTitle("Instructor Profile List");
  const instructorProfileUrl = "api/coaches-profile/";

  async function fetchInstructorProfiles() {
    setLoading(true);
    
    const result = await axios(instructorProfileUrl + branch.branch_id);


    setInstructorProfileData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
  }

  //success error messages
  const [toggleSuccessOpen, setToggleSuccessOpen] = useState(false);
  const [toggleErrorOpen, setToggleErrorOpen] = useState(false);

  const handleClose = () => {
    setToggleSuccessOpen(false);
    setToggleErrorOpen(false);
  };

  //fetching Instructor Profiles
  useEffect(() => {
    fetchInstructorProfiles();
  }, []);

  //fetching Instructor Profiles 
  useEffect(() => {
    context.refetch && fetchInstructorProfiles();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (!(item["instructor_profile_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (
            key !== "id" &&
            (item[key] === undefined || item[key] != filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });

    setInstructorProfileData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          toggleSuccessOpen ?
            toggleSuccessOpen
            : toggleErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={toggleSuccessOpen ? "success" : "error"}
          message={
            toggleSuccessOpen
              ? "Status updated successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
            Instructor Profile Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <Link
              to="/branch-maintenance/instructor-profiles/add-instructor-profiles"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Add New Instructor Profile
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <InstructorProfileFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <InstructorProfileTable profiles={instructorProfileData} showActions={true} loading={loading} />

      <ContentRoute path="/branch-maintenance/instructor-profiles/instructor-profiles-list/:id/activate-instructor-profiles">
        {({ history, match }) => (
          <InstructorProfileToggleDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            active_value={1}
            onHide={() => {
              history.push("/branch-maintenance/instructor-profiles/instructor-profiles-list");
            }}
          />
        )}
      </ContentRoute>
      <ContentRoute path="/branch-maintenance/instructor-profiles/instructor-profiles-list/:id/de-activate-instructor-profiles">
        {({ history, match }) => (
          <InstructorProfileToggleDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            active_value={0}
            onHide={() => {
              history.push("/branch-maintenance/instructor-profiles/instructor-profiles-list");
            }}
          />
        )}
      </ContentRoute>
    </div>
  );
};
