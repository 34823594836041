import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const PROGRAM_TYPE_URL = "api/batches/program-type";
const ACTIVE_URL = "api/batches/active";
const BATCH_TYPE_URL = "api/batches/batch-type";

const BranchCourseContext = createContext();

export function useBranchCourseContext() {
  return useContext(BranchCourseContext);
}

export const BranchCourseConsumer = BranchCourseContext.Consumer;

export function BranchCourseContextProvider({
  branchCourseUIEvents,
  children,
}) {
  const [PROGRAM_TYPE, SETPROGRAM_TYPE] = useState([]);
  const [ACTIVE, SETACTIVE] = useState([]);
  const [BATCH_TYPE, SETBATCH_TYPE] = useState([]);

  const [refetch, setRefetch] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_program = await axios(PROGRAM_TYPE_URL);
        SETPROGRAM_TYPE(res_program.data.results);
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
        let res_batch_type = await axios(BATCH_TYPE_URL);
        SETBATCH_TYPE(res_batch_type.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  const branchCourseContext = {
    openDeleteBranchCourseDialog:
      branchCourseUIEvents.openDeleteBranchCourseDialog,
    openActivateBranchCourseDialog:
      branchCourseUIEvents.openActivateBranchCourseDialog,
    openCallOnBranchCourseDialog:
      branchCourseUIEvents.openCallOnBranchCourseDialog,
    openCallOffBranchCourseDialog:
      branchCourseUIEvents.openCallOffBranchCourseDialog,
    openEditBranchCoursePage: branchCourseUIEvents.openEditBranchCoursePage,
    refetch: refetch,
    setRefetch: setRefetch,
    successOpen: successOpen,
    setSuccessOpen: setSuccessOpen,
    errorOpen: errorOpen,
    setErrorOpen: setErrorOpen,
    TYPES: {
      PROGRAM_TYPE,
      SETPROGRAM_TYPE,
      ACTIVE,
      SETACTIVE,
      BATCH_TYPE,
    },
  };

  return (
    <BranchCourseContext.Provider value={branchCourseContext}>
      {children}
    </BranchCourseContext.Provider>
  );
}
