import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Badge } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  attendanceStatusBadgeSuccess: {
    backgroundColor: "#54afe2",
    color: "white",
  },
  attendanceStatusBadgeDanger: {
    backgroundColor: "#dc3545",
    color: "white",
  },
}));

export default function AttendanceRowReport(props) {
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={`${row.student_batch_attendance_id}`} hover tabIndex={-1}>
        <TableCell align="left" component="th" scope="row">
          {row.student.firstname}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.student.lastname}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <div className="row d-flex justify-content-center">
            {`${row.batch.day} ${moment(row.batch.start_time, [
              "hh:mm:ss",
            ]).format("h:mm a")} - ${moment(row.batch.end_time, [
              "hh:mm:ss",
            ]).format("h:mm a")}`}
            {row.batch.delivery_type === "Virtual" ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">Virtual</Tooltip>}
              >
                <div className="svg-icon svg-icon-md svg-icon-danger ml-2">
                  <i className="fas fa-laptop"></i>
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">In-person</Tooltip>}
              >
                  <div className="svg-icon svg-icon-md svg-icon-danger ml-2">
                    <i className="fas fa-user-friends"></i>
                  </div>
              </OverlayTrigger>
            )}
          </div>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Badge pill className={classes.greyRatio}>
            {row.course.course_abbr}
          </Badge>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {`${row.date}`}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.attendance_value && (
            <Badge
              pill
              className={
                row.attendance_value == "Present"
                  ? classes.attendanceStatusBadgeSuccess
                  : classes.attendanceStatusBadgeDanger
              }
            >
              {row.attendance_value}
            </Badge>
          )}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {`${row.make_up}`}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
