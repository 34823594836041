import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import AddStudent from "./AddStudent";
import AddParent from "./AddParent";
import { AddStudentNavigation } from "./AddStudentNavigation";
import * as actions from "../FamilyProfile/_redux/FamilyActions";

export default function AddStudentPage() {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle("New Registration");
  dispatch(actions.dropFamily());

  return (
    <div className="d-flex flex-row">
      <AddStudentNavigation></AddStudentNavigation>
      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Redirect
            from="/new-registration/:isNew"
            exact={true}
            to="/new-registration/parent-info/:isNew"
          />
          <Route
            path="/new-registration/parent-info/:isNew"
            component={AddParent}
          />
          <Route
            exact
            path="/new-registration/student-info"
            component={AddStudent}
          />
        </Switch>
      </div>
    </div>
  );
}
