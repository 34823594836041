import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { EventEdit } from "./EventEdit";
import { EventList } from "./EventList";
import { EventContextProvider } from "./Components/EventDataContext";

export default function EventPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const eventUIEvents = {
    newEventButtonClick: () => {
      history.push("/events/add-event");
    },
    openEditEventPage: (id) => {
      history.push(`/events/${id}/edit-event`);
    },
    openDeleteEventDialog: (id) => {
      history.push(`/events/event-list/${id}/delete-event`);
    },
    openActivateEventDialog: (id) => {
      history.push(`/events/event-list/${id}/activate-event`);
    },
    openEventFullDialog: (id) => {
      history.push(`/events/event-list/${id}/event-full`);
    },
    openEventNotFullDialog: (id) => {
      history.push(`/events/event-list/${id}/event-not-full`);
    },
    openDuplicateEventPage: (id) => {
      history.push(`/events/${id}/duplicate-event/`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <EventContextProvider eventUIEvents={eventUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/events" to="/events/event-list" />}
          <ContentRoute path="/events/add-event" component={EventEdit} />

          <ContentRoute
            path="/events/:id/edit-event"
            component={EventEdit}
          />

          <ContentRoute path="/events/event-list" component={EventList} />

          <ContentRoute
            exact
            path="/events/:id/:duplicate/:dest_id"
            component={EventEdit}
          />

          <ContentRoute
            exact
            path="/events/:id/:duplicate"
            component={EventEdit}
          />
        </Switch>
      </Suspense>
    </EventContextProvider>
  );
}
