import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";

const ACTIVE_URL = "api/discounts/active";
const DISCOUNT_TYPE_URL = "api/discounts/discount-type";
const BATCH_TYPE_URL = "api/discounts/batch-type";

export const SHOWING = [
  { id: 0, value: "Unavailable" },
  { id: 1, value: "Available" },
];

export const DISCOUNT_TYPE = [
  { id: 1, value: "Dollar" },
  { id: 2, value: "Percentage" },
];

export const COMBINE = ["0", "1"];
const CouponContext = createContext();

export function useCouponContext() {
  return useContext(CouponContext);
}

export const CouponConsumer = CouponContext.Consumer;

export function CouponContextProvider({ couponUIEvents, children }) {
  const [ACTIVE, SETACTIVE] = useState([]);
  const [BATCH_TYPE, SETBATCH_TYPE] = useState([]);
  //const [DISCOUNT_TYPE, SETDISCOUNT_TYPE] = useState([]);

  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
        let res_batch = await axios(BATCH_TYPE_URL);
        SETBATCH_TYPE(res_batch.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const couponContext = {
    newCouponButtonClick: couponUIEvents.newCouponButtonClick,
    openEditCouponPage: couponUIEvents.openEditCouponPage,
    openDeleteCouponDialog: couponUIEvents.openDeleteCouponDialog,
    openActivateCouponDialog: couponUIEvents.openActivateCouponDialog,
    openCouponShowDialog: couponUIEvents.openCouponShowDialog,
    openCouponHideDialog: couponUIEvents.openCouponHideDialog,
    openDuplicateCouponPage: couponUIEvents.openDuplicateCouponPage,
    refetch: couponUIEvents.refetch,
    setRefetch: couponUIEvents.setRefetch,
    TYPES: {
      ACTIVE,
      SETACTIVE,
      SHOWING,
      DISCOUNT_TYPE,
      COMBINE,
      BATCH_TYPE,
      SETBATCH_TYPE,
    },
  };

  return (
    <CouponContext.Provider value={couponContext}>
      {children}
    </CouponContext.Provider>
  );
}
