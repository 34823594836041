/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useUserContext } from "./UserDataContext";

export function UserActivateDialog({
  id,
  isActive,
  show,
  onHide,
  setSuccessMessage,
  setErrorMessage,
}) {
  const context = useUserContext();

  // event Context

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  const activateUser = async () => {
    const activateUrl = "api/user/activate/";

    //edit event post
    const result = await axios(activateUrl + id + "?active=" + isActive);
    if (result.data == "success") {
      setSuccessMessage(`User ${isActive == "true" ? "Activated" : "Deactivated"} Successfully`);
      onHide();
      setTimeout(() => {
        context.setRefetch({...context.refetch, users: true});
      }, 1000);
    } else {
      setErrorMessage(`Error ${isActive == "true" ? "Activating" : "Deactivating"} User`);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"

    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          User {isActive == "true" ? "Activate" : "Deactivate"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to {isActive == "true" ? "activate" : "deactivate"} this user?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={activateUser}
            className="btn btn-delete btn-elevate"
          >
            {isActive == "true" ? "Activate" : "Deactivate"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
