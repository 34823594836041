import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { UserActivateDialog } from "./Components/UserActivateDialog";
import UserDeleteDialog from "./Components/UserDeleteDialog";
import { useUserContext } from "./Components/UserDataContext";
import { UserFilter } from "./Components/UserFilter";
import UserTable from "./Components/UserTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";

export const UserList = () => {
  const [userData, setUserData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useUserContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Staff List");

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);


  const handleClose = () => {
    setSuccessSnackbarMessage("");
    setErrorSnackbarMessage("");
    setShowSnackbar(false);
  };

  useEffect(() => {
    const loadSnackbarSuccess = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    successSnackbarMessage && successSnackbarMessage != "" && loadSnackbarSuccess();
  }, [successSnackbarMessage]);

  useEffect(() => {
    const loadSnackbarError = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    errorSnackbarMessage && errorSnackbarMessage != "" && loadSnackbarError();
  }, [errorSnackbarMessage]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setOrigData(context.userData);
      setLoading(false);
    }
    branch && fetchUsers();
  }, [context.userData]);

  useEffect(() => {
    setLoading(true);
    context.setRefetch(
      {
        users: true,
        actives: true,
        roles: true,
        branches: true,
        tasks: true,
      }
    );
    setLoading(false);
  }, [branch]);

  //Filter Data based on search
  const filterData = (filters) => {
    if (Array.isArray(origData)) {
      const newData = origData.filter(function(user) {
        var add = true;

        for (let key in filters) {
          if (filters[key] != "") {

            if (key !== "branch_id" && key !== "role_id" && key !== "first_last" && user[key] != filters[key]) {
              add = false;
            }

            // branches Filter
            if (key === "branch_id") {
              if (user.branches.length > 0) {
                if (!user.branches.some((branch) => branch.value == filters[key])) {
                  add = false;
                }
              } else if (filters[key] == 0 && user.branches.length != 0) {
                add = false;
              }
            }

            // roles Filter
            if (key === "role_id") {
              if (user.roles.length > 0) {
                if (!user.roles.find((role) => role.value == filters[key])) {
                  add = false;
                } 
              }
            }

            // first_last Filter regex match
            if (key === "first_last") {
              if (user.first_last.toLowerCase().search(filters[key].toLowerCase()) == -1) {
                add = false;
              }
            }

          }
        }
        return add ? true : false;
      });
      setUserData(newData);
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successSnackbarMessage != "" ? "success" : "error"}
          message={successSnackbarMessage != "" ? successSnackbarMessage : errorSnackbarMessage}
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Staff Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <Link
              to="/users/add-user"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Add Staff User
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <UserFilter
            filterData={filterData}
            origData={origData}
            loading={context.isLoading}
          />
        </div>
      </div>

      <UserTable users={userData} showActions={true} loading={context.isLoading} />

      <ContentRoute path="/users/user-list/:id/delete-user">
        {({ history, match }) => (
          <UserDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            setSuccessMessage={setSuccessSnackbarMessage}
            setErrorMessage={setErrorSnackbarMessage}
            onHide={() => {
              history.push("/users/user-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/users/user-list/:id/activate-user/:isActive">
        {({ history, match }) => (
          <UserActivateDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            isActive={match && match.params.isActive}
            setSuccessMessage={setSuccessSnackbarMessage}
            setErrorMessage={setErrorSnackbarMessage}
            onHide={() => {
              history.push("/users/user-list");
            }}
          />
        )}
      </ContentRoute>
    </div>
  );
};
