import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import { BatchEdit } from "./BatchEdit";
import { BatchList } from "./BatchList";
import { BatchContextProvider } from "./Components/BatchDataContext";

export default function BatchPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const batchUIEvents = {
    newBatchButtonClick: () => {
      history.push("/batches/add-page");
    },
    openEditBatchPage: (id) => {
      history.push(`/batches/${id}/edit-batch`);
    },
    openDeleteBatchDialog: (id) => {
      history.push(`/batches/batch-list/${id}/delete-batch`);
    },
    openActivateBatchDialog: (id) => {
      history.push(`/batches/batch-list/${id}/activate-batch`);
    },
    openBatchFullDialog: (id) => {
      history.push(`/batches/batch-list/${id}/batch-full`);
    },
    openLeadAttendanceDialog: (id) => {
      history.push(`/batches/batch-list/${id}/lead-attendance`);
    },
    openDuplicateBatchPage: (id) => {
      history.push(`/batches/${id}/duplicate-batch/`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
    openBatchNotFullDialog: (id) => {
      history.push(`/batches/batch-list/${id}/batch-not-full`);
    },
    openAttendanceDialog: (id, dayIndex) => {
      history.push(`/batches/batch-list/${id}/attendance/${dayIndex}`);
    },
    openPointsDialog: (id) =>{
      history.push(`/batches/batch-list/${id}/points`);

    },
    openEmailDialog: (id) =>{
      history.push(`/batches/batch-list/${id}/email`);

    }
  };
  return (
    <BatchContextProvider batchUIEvents={batchUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/batches" to="/batches/batch-list" />}
          <ContentRoute path="/batches/add-batch" component={BatchEdit} />

          <ContentRoute path="/batches/:id/edit-batch" component={BatchEdit} />

          <ContentRoute path="/batches/batch-list" component={BatchList} />

          <ContentRoute
            exact
            path="/batches/:id/:duplicate/:dest_id"
            component={BatchEdit}
          />

          <ContentRoute
            exact
            path="/batches/:id/:duplicate"
            component={BatchEdit}
          />
        </Switch>
      </Suspense>
    </BatchContextProvider>
  );
}
