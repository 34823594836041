import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BranchActions } from "../../Branch/BranchActions";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function LoginBranchModal(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.BRANCH.MODAL.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Select A Default Branch</p>
        <BranchActions />
      </div>
      {/* end::Head */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(LoginBranchModal));
