export const classHeaders = [
  {
    id: "day",
    numeric: false,
    disablePadding: true,
    label: "Day",
  },
  {
    id: "times",
    numeric: false,
    disablePadding: true,
    label: "Times",
  },
  {
    id: "course_abbr",
    numeric: false,
    disablePadding: true,
    label: "Stream (Abbr)",
  },
  {
    id: "sub_course_code",
    numeric: false,
    disablePadding: true,
    label: "Course (Code)",
  },
  {
    id: "student_id",
    numeric: false,
    disablePadding: true,
    label: "Student ID",
  },
  {
    id: "student_name",
    numeric: false,
    disablePadding: true,
    label: "Student Name",
  },
  {
    id: "instructor_name",
    numeric: false,
    disablePadding: true,
    label: "Instructor Name",
  },
  {
    id: "make_up_date",
    numeric: false,
    disablePadding: true,
    label: "Make Up Date",
  },
  {
    id: "trial_date",
    numeric: false,
    disablePadding: true,
    label: "Trial Date",
  },
];
