import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import UserRow from "./UserRow";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

let headRows = [
  {
    id: "user_id",
    numeric: true,
    disablePadding: true,
    label: "User ID",
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "user_role",
    numeric: false,
    disablePadding: false,
    label: "Role(s)",
  },
  {
    id: "user_locations",
    numeric: false,
    disablePadding: false,
    label: "Location(s)",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Primary Phone Number",
  },
];

function EnhancedUserTableHead(props) {
  const { order, orderBy, onRequestSort, showActions } = props;
  const createSortHandler = (property) => (e_user) => {
    onRequestSort(e_user, property);
  };

  const addAccessCols = () => {
    if (headRows[headRows.length - 1].id != "actions") {
      headRows = [
        ...headRows,
        {
          id: "actions",
          numeric: false,
          disablePadding: false,
          label: "Actions",
        },
      ];
    }
  };
  if (showActions) {
    addAccessCols();
  }

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align="center"
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedUserTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function EnhancedUserTable({ users, showActions, loading }) {
  const classes = useStyles();
  const rows = users;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accordianReset, setAccordianReset] = useState(false);

  function handleRequestSort(e_user, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(e_user, newPage) {
    setPage(newPage);
    setAccordianReset(true);
  }

  function handleChangeRowsPerPage(e_user) {
    setRowsPerPage(+e_user.target.value);
  }

  function refreshTable() {
    setAccordianReset(false);
  }

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedUserTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows ? rows.length : 0}
              showActions={showActions}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <LinearProgress color="secondary" styles="width:100%" />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {rows &&
                  stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // console.log(rows);
                      return (
                        <UserRow
                          key={index}
                          row={row}
                          reset={accordianReset}
                          setReset={setAccordianReset}
                          showActions={showActions}
                        />
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
