/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function BranchMaintenanceNav({}) {
  const access = useSelector((state) => state.auth.access, shallowEqual);

  const user_id = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id,
    shallowEqual
  );
  return (
    <>
      {
        <div
          className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
          id="kt_branch_maintenance_aside"
        >
          <div className="card card-custom card-stretch">
            {/* begin::Body */}
            <div className="card-body pt-4">
              {/* begin::Nav */}
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/branch-maintenance`}
                    exact
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Tools/Tools.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Branch Maintenance Dashboard
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/branch-maintenance/branch-hours`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Hours of Operation
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/branch-maintenance/price-footer`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Sale2.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Coupon/Tax Message
                    </span>
                  </NavLink>
                </div>
                {(access["feeatued_in"] != undefined || user_id == 12632) && (
                  <div className="navi-item mb-2">
                    <NavLink
                      to={`/branch-maintenance/instructor-profiles`}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <i className="fas fa-users"></i>
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Instructor Profiles
                      </span>
                    </NavLink>
                  </div>
                )}
                {access["feeatued_in"] != undefined && (
                  <div className="navi-item mb-2">
                    <NavLink
                      to={`/branch-maintenance/features`}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/misc/004-retweet.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Featured In
                      </span>
                    </NavLink>
                  </div>
                )}
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/branch-maintenance/website-announcements`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Sale2.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Website Announcements
                    </span>
                  </NavLink>
                </div>
              </div>
              {/* end::Nav */}
            </div>
            {/* end::Body */}
          </div>
        </div>
      }
    </>
  );
}
