export const attendanceHeaders = [
    {
      key: "student.user_id",
      label: "Student ID",
    },
    {
      key: "student.firstname",
      label: "First Name",
    },
    {
      key: "student.lastname",
      label: "Last Name",
    },
    {
      key: "batch.batch_id",
      label: "Batch ID",
    },
    {
      key: "batch.day",
      label: "Batch Day",
    },
    {
      key: "batch.start_time",
      label: "Batch Start Time",
    },
    {
      key: "batch.end_time",
      label: "Batch End Time",
    },
    {
      key: "course.course_name",
      label: "Stream Name",
    },
    {
      key: "course.course_abbr",
      label: "Stream (Abbr)",
    },
    {
      key: "course.course_code",
      label: "Stream Code",
    },
    {
      key: "date",
      label: "Attendance Date",
    },
    {
      key: "attendance_value",
      label: "Attendance",
    },
    {
      key: "make_up",
      label: "Make Up",
    },
    {
      key: "modified_on",
      label: "Attendance Entry Date",
    },
    {
      key: "modified_by",
      label: "Attendance Entry By",
    },
    {
      key: "comment",
      label: "Attendance Week Comment",
    },
  ];