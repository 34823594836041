import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { useCustomerLeadsContext } from "./Components/CustomerLeadsDataContext";
import { CustomerLeadsDeleteDialog } from "./Components/CustomerLeadsDeleteDialog";
import { CustomerLeadsDownloadDialog } from "./Components/CustomerLeadsDownloadDialog";
import { CustomerLeadsFilter } from "./Components/CustomerLeadsFilter";
import CustomerLeadsTable from "./Components/CustomerLeadsTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import { getLeadsFormattedNumber } from "./../../helpers/Formatter";


export const CustomerLeadsList = () => {
  const [CustomerLeadsData, setCustomerLeadsData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useCustomerLeadsContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Customer Leads List");
  const leadsUrl = "api/customer-leads/customer-leads/";

  async function fetchCustomerLeadsForBranch() {
    setLoading(true);
    const result = await axios(leadsUrl + branch.branch_id);

    setCustomerLeadsData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
    // console.log(result.data.results)
  }

  //success error messages
  const [downloadSuccessOpen, setDownloadSuccessOpen] = useState(false);
  const [notdownloadSuccessOpen, setNotdownloadSuccessOpen] = useState(false);
  const [downloadErrorOpen, setDownloadErrorOpen] = useState(false);
  const [notdownloadErrorOpen, setNotdownloadErrorOpen] = useState(false);

  const handleClose = () => {
    setDownloadSuccessOpen(false);
    setNotdownloadSuccessOpen(false);
    setDownloadErrorOpen(false);
    setNotdownloadErrorOpen(false);
  };

  //fetching leads for a branch
  useEffect(() => {
    branch && fetchCustomerLeadsForBranch();
  }, [branch]);

  //fetching leads for a branch
  useEffect(() => {
    context.refetch && fetchCustomerLeadsForBranch();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (!(item["lead_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (key === "lead_info") {
            if (
                !item["lead_name"]
                    .toLowerCase()
                    .includes(filters["lead_info"].toLowerCase()) && 
                !item["email"]
                    .toLowerCase()
                    .includes(filters["lead_info"].toLowerCase()) && 
                !(  /* if only numbers apply phone filter */
                    /^\d+$/.test(filters["lead_info"]) && 
                    getLeadsFormattedNumber(item["lead_phone"]) 
                        .includes(getLeadsFormattedNumber(filters["lead_info"]))
                )
            ) {
                add = false;
            }
          } 
           
          if (key === "student_name") {
            if (
              !(item["student_name"] + "")
                .toLowerCase()
                .includes(filters[key].toLowerCase())
            ) {
              add = false;
            }
          }

          if (key === "course") {
            if (
              !item["course_of_interest"]
                .map((coi) => {
                  return coi.value;
                })
                .includes(parseInt(filters[key]))
            ) {
              add = false;
            }
          }

          if (key === "trial_date") {
            if (
              !moment(filters[key]).isSameOrBefore(
                moment(item["trial_date"], "yyyy-MM-DD")
              )
            ) {
              add = false;
            }
          }

          if (key === "lead_date") {
            if (
              !moment(filters[key]).isSameOrBefore(
                moment(item["lead_date"], "yyyy-MM-DD")
              )
            ) {
              add = false;
            }
          }

          if (key === "reg_date") {
            if (
              !moment(filters[key], "yyyy-MM-DD").isSameOrBefore(
                moment(item["registration_date"], "yyyy-MM-DD")
              )
            ) {
              add = false;
            }
          }

          if (
            key !== "id" &&
            key !== "lead_info" &&
            key !== "course" &&
            key !== "student_name" &&
            !(key.indexOf("date") > 0) &&
            (key !== "status" ||
              (key === "status" && filters[key] != "Default")) &&
            (item[key] === undefined || item[key] != filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });
    console.log(newData)
    setCustomerLeadsData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          downloadSuccessOpen
            ? downloadSuccessOpen
            : notdownloadSuccessOpen
            ? notdownloadSuccessOpen
            : downloadErrorOpen
            ? downloadErrorOpen
            : notdownloadErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={
            downloadSuccessOpen || notdownloadSuccessOpen ? "success" : "error"
          }
          message={
            downloadSuccessOpen
              ? "Download Successfull"
              : downloadSuccessOpen
              ? "Marked lead not full successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Customer Leads Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <div className="form-group row">
              <div className={"col-lg-4"}>
                <Link
                  to="/customer-leads/customer-leads-list/download"
                  className="btn btn-secondary font-weight-bolder font-size-sm"
                >
                  Download
                </Link>
              </div>
              <div className={"col-lg-8"}>
                <Link
                  to="/customer-leads/add-customer-leads"
                  className="btn btn-primary font-weight-bolder font-size-sm"
                >
                  Add New Customer Lead
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <CustomerLeadsFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>
      <CustomerLeadsTable
        leads={CustomerLeadsData}
        showActions={true}
        loading={loading}
      />
      <ContentRoute path="/customer-leads/customer-leads-list/download">
        {({ history, match }) => (
          <CustomerLeadsDownloadDialog
            history={history}
            show={match != null}
            setDownloadSuccessOpen={setDownloadSuccessOpen}
            setDownloadErrorOpen={setDownloadErrorOpen}
            onHide={() => {
              history.push("/customer-leads/customer-leads-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/customer-leads/customer-leads-list/:id/delete-customer-leads">
        {({ history, match }) => (
          <CustomerLeadsDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/customer-leads/customer-leads-list");
            }}
          />
        )}
      </ContentRoute>
    </div>
  );
};
