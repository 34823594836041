export const unpaidHeaders = [
  {
    key: "month",
    label: "Month",
  },
  {
      key: "student_user_id",
      label: "Student ID",
    },
    {
      key: "student_name",
      label: "Student Name",
    },
    {
      key: "parent_name",
      label: "Parent Name",
    },
    {
      key:"recurring_payment_type",
      label: "Recurring Payment Type",
    },
    {
      key: "quantity",
      label: "Times a week",
    },
    {
      key: "course_abbr",
      label: "Stream Abbrv",
    },
    {
      key: "startdate",
      label: "Start Date",
    },
    {
      key: "final_total",
      label: "Final Total",
    },
    {
      key: "notes",
      label: "Notes",
    }
  ];

  export const campUnpaidHeaders = [
    {
        key: "student_user_id",
        label: "Student ID",
      },
      {
        key: "student_name",
        label: "Student Name",
      },
      {
        key: "parent_name",
        label: "Parent Name",
      },
      {
        key: "course_abbr",
        label: "Stream Abbrv",
      },
      {
        key: "camp_start_date",
        label: "Camp Start Date",
      },
      {
        key: "camp_end_date",
        label: "Camp End Date",
      },
      {
        key: "camp_start_time",
        label: "Camp Start Time",
      },
      {
        key: "camp_end_time",
        label: "Camp End Time",
      },
      {
        key: "final_total",
        label: "Final Total",
      },
      {
        key: "notes",
        label: "Notes",
      }
    ];