import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FieldArray } from "formik";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useBranchCourseContext } from "../BranchCourseDataContext";
import { BranchCourseFeeEditRow } from "./BranchCourseFeeEditRow";

export const BranchCampFeeEditTableRow = ({
  values,
  errors,
  touched,
  deleted,
  setDeleted,
  bt,
  TYPE,
}) => {
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  const [open, setOpen] = useState(false);
  const [batchTypeOpen, setBatchTypeOpen] = useState("");

  const handleRowClick = (id) => {
    setOpen(!open);
    setBatchTypeOpen(id);
  };

  const empty_row = {
    frequency_unit: values.fees[0].frequency_unit,
    virtual_course_fee: values.fees[0].virtual_course_fee,
    price_range_ind: 0,
    branch_course_fee_id: null,
    batch_type: bt.id,
  };
  const removeRow = (branch_course, remove, index) => {
    if (values.fees.length > 1) {
      remove(index);
      if (branch_course.branch_course_fee_id != null) {
        deleted
          ? setDeleted([...deleted, branch_course.branch_course_fee_id])
          : setDeleted([branch_course.branch_course_fee_id]);
      }
    }
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(bt.id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {bt.value}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Container>
            <FieldArray name="fees">
              {({ push, remove }) => {
                return (
                  <span>
                    <div className="table table-striped">
                      <div className="thead-light">
                        <div className="row">
                          <div className="col-lg-2">Delivery Type</div>
                          <div className={TYPE == 1 ? "col-lg-2" : "col-lg-3"}>
                            Batch Type
                          </div>
                          <div className="col-lg-2">
                            Sessions/{TYPE != 1 ? "week" : "month"}
                          </div>
                          {TYPE == 1 && (
                            <div className="col-lg-2">Duration</div>
                          )}
                          <div className={TYPE == 1 ? "col-lg-2" : "col-lg-1"}>
                            Stream Fee
                          </div>
                          {TYPE != 1 && (
                            <div className="col-lg-1">Extended Care Fee</div>
                          )}
                          {TYPE != 1 && <div className="col-lg-1">HD Ind</div>}
                          <div className={TYPE == 1 ? "col-lg-1" : "col-lg-1"}>
                            Display in Range
                          </div>
                          <div className="col-lg-1">Actions</div>
                        </div>
                      </div>
                      <div className="tbody">
                        {values &&
                          values.fees.length > 0 &&
                          values.fees.map((fee, index) => {
                            const feesErrors =
                              (errors.fees?.length && errors.fees[index]) || {};
                            const feesTouched =
                              (touched.fees?.length && touched.fees[index]) ||
                              {};
                            return fee.batch_type == bt.id ? (
                              <BranchCourseFeeEditRow
                                type={TYPE}
                                key={index}
                                fee={fee}
                                errors={feesErrors}
                                touched={feesTouched}
                                index={index}
                                removeRow={removeRow}
                                remove={remove}
                              ></BranchCourseFeeEditRow>
                            ) : null;
                          })}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        {
                          TYPE == 1 && push(empty_row);
                        }
                        {
                          TYPE != 1 &&
                            values.fees.length <
                              8 * (DROPDOWN_TYPE.BATCH_TYPE.length - 1) &&
                            push(empty_row);
                        }
                      }} // insert an empty string at a position
                    >
                      {TYPE == 1 && "Add New Fee"}
                      {TYPE != 1 &&
                        (values.fees.length <
                        1000 * (DROPDOWN_TYPE.BATCH_TYPE.length - 1)
                          ? "Add New Fee"
                          : "Max # Rows Reached")}
                    </button>
                  </span>
                );
              }}
            </FieldArray>
          </Container>
        </Collapse>
      </TableRow>
    </>
  );
};
