import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { actions } from "../app/modules/Auth/_redux/authRedux";
import { BuilderPage } from "./pages/BuilderPage";
import DashboardPage from "./pages/DashboardPage";
import RegisterFamily from "./modules/StudentRegisteration/RegisterFamily";
import BatchPage from "./modules/Batches/Classes/BatchPage";
import CouponPage from "./modules/Coupons/CouponPage";
import TimesheetPage from "./modules/Timesheet/TimesheetPage";
import EventPage from "./modules/Events/EventPage";
import CustomerLeadsPage from "./modules/CustomerLeads/CustomerLeadsPage";
import WebTransactionsPage from "./modules/WebTransactions/WebTransactionsPage";
import ReportingPage from "./modules/Reporting/ReportingPage";
import BranchCoursePage from "./modules/Branch/BranchCourse/BranchCoursePage";
import { NoAccessError } from "./pages/Error/NoAccessError";
import CampPage from "./modules/Batches/Camps/CampPage";
import BranchMaintenancePage from "./modules/BranchMaintenance/BranchMaintenancePage";
import UserPage from "./modules/Users/UserPage";
import HelpPage from "./modules/HelpPage/HelpPage";
import RequestForm from "./modules/RequestForm/RequestForm";
import EmailPage from "./modules/EmailService/EmailPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const FamilyPage = lazy(() => import("./modules/FamilyProfile/FamilyPage"));

const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
export default function BasePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.requestUser());
  }, [dispatch]);

  const user_id = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id,
    shallowEqual
  );
  const access = useSelector((state) => state.auth.access, shallowEqual);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/e-commerce" component={ECommercePage} />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute
          path="/new-registration/:isNew"
          component={RegisterFamily}
        />
        {access["family_profile"] != undefined ? (
          <ContentRoute path="/family-profile/:id" component={FamilyPage} />
        ) : (
          <ContentRoute path="/family-profile/:id" component={NoAccessError} />
        )}
        <ContentRoute path="/batches" component={BatchPage} />
        <ContentRoute path="/classes" component={BatchPage} />
        <ContentRoute path="/camps" component={CampPage} />
        <ContentRoute path="/branch-course" component={BranchCoursePage} />
        <ContentRoute path="/coupons" component={CouponPage} />
        <ContentRoute path="/events" component={EventPage} />
        <ContentRoute path="/timesheet" component={TimesheetPage} />
        {access["reporting"] != undefined ||
        [8219, 7661, 6724].includes(user_id) ? (
          <ContentRoute path="/reporting" component={ReportingPage} />
        ) : (
          <ContentRoute path="/reporting" component={NoAccessError} />
        )}
        <ContentRoute path="/customer-leads" component={CustomerLeadsPage} />
        <ContentRoute
          path="/branch-maintenance"
          component={BranchMaintenancePage}
        />
        <ContentRoute path="/users" component={UserPage} />
        <ContentRoute path="/help" component={HelpPage} />
        <ContentRoute path="/request-form" component={RequestForm} />
        <ContentRoute
          path="/web-transactions"
          component={WebTransactionsPage}
        />
        <Route path="/user-profile" component={UserProfilepage} />
        <ContentRoute path="/no-access" component={NoAccessError} />
        <ContentRoute path="/emails" component={EmailPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
