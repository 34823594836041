import { ErrorMessage, Field } from "formik";
import React from "react";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function InputWithLimit({ ...props }) {
  return (
    <>
      {props.value && props.displaylimit ? (
        <div className="row">
          {props.label && props.label.length > 0 && (
            <div className="col-6">
              <label className="font-weight-bold mr-2" htmlFor={props.name}>
                {props.label}
              </label>
            </div>
          )}
          <div className="col-6 text-right">
            <small className="text-muted">
              ({props.maxLength - props.value.length}/{props.maxLength})
            </small>
          </div>
        </div>
      ) : (
        props.label &&
        props.label.length > 0 && (
          <label htmlFor={props.name}>{props.label}</label>
        )
      )}

      {props.rows ? (
        <textarea
          name={props.name}
          type={props.type}
          className={getFieldCSSClasses(props.touched, props.errors)}
          maxLength={props.maxLength}
          {...props}
          touched={props.touched?.toString()}
        />
      ) : (
        <Field
          name={props.name}
          type={props.type}
          className={getFieldCSSClasses(props.touched, props.errors)}
          maxLength={props.maxLength}
          {...props}
        />
      )}

      {props.touched && props.errors && (
        <ErrorMessage
          name={props.name}
          component="div"
          className="invalid-feedback"
        />
      )}
    </>
  );
}
