import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "axios";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { getFormattedNumber } from "../../../helpers/Formatter";
import { useWebTransactionsContext } from "../Components/WebTransactionsDataContext";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function WebTransactionParentMatch({ user, setUser }) {
  const [page, setPage] = useState(0);
  const [matches, setMatches] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {
    URL,
    defaultSelectValue,
    newSelectValue,
    warnings,
    setWarnings,
  } = useWebTransactionsContext();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const { setFieldValue, setFieldError } = useFormikContext();

  useEffect(() => {
    const fetchMatches = async () => {
      let res = await axios.get(
        `${URL.transactionURL}parent-matches?firstName=${user.firstName}&lastName=${user.lastName}&email=${user.email}&phone=${user.phone}`
      );
      setMatches(res.data.results);
    };
    Object.keys(user).length > 0 && fetchMatches();
  }, [user]);



  const onHandleClose = () => {
    setPage(0);
    setMatches([]);
    setRowsPerPage(5);
    setUser({});
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
    const classes = useStyles1();
    const theme = useTheme();

    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const onHandleSelectedUser = (status, isNew) => {
    setFieldValue(`parents.${user.index}.status`, status, false);
    setFieldValue(`parents.${user.index}.isNew`, isNew, false);
    console.log(user, status);

    

 
    let newWarnings = { ...warnings };
    newWarnings.parents[user.index] = [];
    
    if (status === newSelectValue || status === defaultSelectValue) {
      setFieldError(`parents.${user.index}`, undefined);
      let newWarnings = { ...warnings };
      newWarnings.parents[user.index] = [];
      setWarnings(newWarnings);
    } else {
      
      if (
        status.branches.filter((r) => {
          return r.branchId === branch.branch_id;
        }).length <= 0
      ) {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index].push("This user isn't in your location");
        setWarnings(newWarnings);

      } else {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index] = [];
        setWarnings(newWarnings);
      }


      /*******
      Check the user's information
      *******/
      
      if (
        status.dob != user.dob
      ) {
        let newWarnings = { ...warnings };
        console.log("new warnings", newWarnings.parents[user.index]);
        newWarnings.parents[user.index].push("User information doesn't match between the Portal and the Transaction.");
        setWarnings(newWarnings);

      } else {
        let newWarnings = { ...warnings };
        newWarnings.parents[user.index] = [];
        setWarnings(newWarnings);
      }



    }
    onHandleClose();
  };

  return (
    <Modal
      show={Object.keys(user).length > 0 && user.isParent}
      onHide={onHandleClose}
      aria-labelledby="match-modal-title"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="match-modal-title">
          Potential Matches for {user.firstName + " " + user.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page === 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={5}>{defaultSelectValue}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        onHandleSelectedUser(defaultSelectValue, false);
                      }}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>{newSelectValue}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        onHandleSelectedUser(newSelectValue, true);
                      }}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}

            {matches &&
              matches
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((m, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{m.userId}</TableCell>
                      <TableCell>
                        <p onClick={() => {}}>
                          {m.firstName + " " + m.lastName}
                        </p>
                      </TableCell>
                      <TableCell>{m.email}</TableCell>
                      <TableCell>{getFormattedNumber(m.phone)}</TableCell>
                      <TableCell>
                        {m.branches
                          .map((b) => {
                            return b.branchName;
                          })
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            onHandleSelectedUser(m, false);

                          }}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={6}
                count={matches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onHandleClose();
          }}
        >
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
