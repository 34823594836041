import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../../../_metronic/layout";
import { useWebsiteAnnouncementsContext } from "./Components/WebsiteAnnouncementsDataContext";
import { WebsiteAnnouncementsFilter } from "./Components/WebsiteAnnouncementsFilter";
import WebsiteAnnouncementsTable from "./Components/WebsiteAnnouncementsTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../../../Snackbar/SnackbarWrapper";
import { shallowEqual, useSelector } from "react-redux";

export const WebsiteAnnouncementsList = () => {
  const [websiteAnnouncementsData, setWebsiteAnnouncementsData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);

  const context = useWebsiteAnnouncementsContext();
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const suhbeader = useSubheader();
  suhbeader.setTitle("Website Announcement List");
  const websiteAnnouncementsUrl = "api/branches/website-announcements/";

  async function fetchWebsiteAnnouncements() {
    setLoading(true);
    
    const result = await axios(websiteAnnouncementsUrl + branch.branch_id);

    // console.log("Got data", result.data.results)
    setWebsiteAnnouncementsData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
  }

  //success error messages
  const [toggleSuccessOpen, setToggleSuccessOpen] = useState(false);
  const [toggleErrorOpen, setToggleErrorOpen] = useState(false);

  const handleClose = () => {
    setToggleSuccessOpen(false);
    setToggleErrorOpen(false);
  };

  //fetching Instructor Profiles
  useEffect(() => {
    fetchWebsiteAnnouncements();
  }, []);

  //fetching Instructor Profiles 
  useEffect(() => {
    context.refetch && fetchWebsiteAnnouncements();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (!(item["announcements_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (
            key !== "id" &&
            (item[key] === undefined || item[key] != filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });

    setWebsiteAnnouncementsData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          toggleSuccessOpen ?
            toggleSuccessOpen
            : toggleErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={toggleSuccessOpen ? "success" : "error"}
          message={
            toggleSuccessOpen
              ? "Status updated successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
            Website Announcements Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <Link
              to="/branch-maintenance/website-announcements/add-website-announcements"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Add New Website Announcements
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <WebsiteAnnouncementsFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <WebsiteAnnouncementsTable announcements={websiteAnnouncementsData} showActions={true} loading={loading} />

    </div>
  );
};
