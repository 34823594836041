import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import Badge from "react-bootstrap/Badge";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row } from "react-bootstrap";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Container from "react-bootstrap/Container";
import { IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { useSelector, shallowEqual } from "react-redux";

const TIMESHEET_URL = "/api/timesheet";

const useStyles = makeStyles((theme) => ({
  dropdownColumn: {
    minWidth: 400,
    paddingLeft: "0px",
  },
  defaultColumn: {
    textAlign: "center",
    // minWidth: 50,
    // maxWidth: 50,
    // padding: 5,
  },
  totalColumn: {
    fontSize: "0.875rem",
    color: "#181c32",
    fontWeight: "500",
    textAlign: "center",
  },
  nameColumn: {
    textAlign: "left",
  },
  dateColumn: {
    // textAlign: "center",
  },
  commentLabelColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "0.5rem",
    // backgroundColor: "#c2c2c2",
    // border: "1px solid #757575",
  },
  commentColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "center",
    fontSize: "0.85rem",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "0.25rem",
    margin: "0",
  },
}));

const errorMessages = {
  duplicates: "Duplicate - Please select a different task and branch",
  incomplete: "Incomplete - Please select a task and branch",
};

export default function TimesheetRow({
  history,
  row,
  rowIndex,
  editMode,
  removeRow,
  setFieldValue,
  values,
  loading,
  setLoading,
  reset,
  setReset,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const timesheetFunctions = useTimesheetContext().functions;
  const timesheetStates = useTimesheetContext().states;

  const { userId } = useSelector(
    ({ auth }) => ({
      userId: auth.user[0].user_id,
    }),
    shallowEqual
  );

  /**
   * Return total hours of a task / row
   * @date 2022-07-13
   */
  const getTaskTotal = (taskIndex) => {
    let total = 0;
    for (const entry of Object.values(
      row.timesheet_tasks[taskIndex].timesheet_entries
    )) {
      total += parseFloat(entry.hours);
    }
    return total;
  };

  const getDayTotal = (day_index) => {
    let total = 0;
    for (const task of row.timesheet_tasks) {
      total += parseFloat(task.timesheet_entries[day_index].hours);
    }
    return total;
  };

  const isRowIncomplete = () => {
    return (
      (values.timesheet_tasks[rowIndex].task_id > 0 &&
        values.timesheet_tasks[rowIndex].branch_id == 0) ||
      (values.timesheet_tasks[rowIndex].task_id == 0 &&
        values.timesheet_tasks[rowIndex].branch_id > 0)
    );
  };

  /**
   * Return boolean if task is a duplicate (same task and branch in another row)
   * @date 2022-07-13
   */
  const isRowDuplicate = () => {
    let duplicates = false;
    let task_branch_ids = [];
    for (const task of values.timesheet_tasks) {
      if (
        task_branch_ids.find(
          (logged_task) =>
            logged_task.task_id == task.task_id &&
            logged_task.branch_id == task.branch_id
        )
      ) {
        if (
          task.branch_id == values.timesheet_tasks[rowIndex].branch_id &&
          task.task_id == values.timesheet_tasks[rowIndex].task_id &&
          task.task_id != 0 && task.branch_id != 0
        ) {
          duplicates = true;
        }
      } else {
        task_branch_ids.push({
          task_id: task.task_id,
          branch_id: task.branch_id,
        });
      }
    }
    return duplicates;
  };

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={rowIndex} hover tabIndex={-1}>
        <TableCell align="right" component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick()}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.portal_transaction_parent_id}
        </TableCell>

        {/* Date Column with items in row*/}
        <TableCell className={classes.defaultColumn}>
          <div>
            <span className={classes.dateColumn}>
              {moment(row.startDate).format("MMM DD")}
            </span>
            <span
              style={{ width: "100%", textAlign: "center" }}
            >{`\t-\t`}</span>
            <span className={classes.dateColumn}>
              {moment(row.endDate).format("MMM DD")}
            </span>
          </div>
        </TableCell>

        {/* Name Column */}
        <TableCell className={classes.nameColumn}>{row.user_name}</TableCell>

        {/* Total Hours Column */}
        <TableCell className={classes.defaultColumn}>
          {row.total_hours}
        </TableCell>

        {/* Status Hours Column */}
        <TableCell className={classes.defaultColumn}>
          <Badge
            pill
            style={{
              backgroundColor: row.approval_status ? "#54afe2" : "#e2b252",
              color: "white",
            }}
          >
            {row.approval_status ? "Approved" : "Pending"}
          </Badge>
        </TableCell>

        {/* Actions */}
        <TableCell className={classes.defaultColumn}>
          {row.approval_status ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Revoke Approval</Tooltip>
              }
              disabled={loading}
            >
              <a
                onClick={() => {
                  const setApprove = async () => {
                    if (userId) {
                      return await axios.post(
                        `${TIMESHEET_URL}/${row.timesheet_week_id}/approve?status=false&user_id=${userId}`
                      );
                    }
                  };
                  setLoading(true);
                  setApprove().then((res) => {
                    if (res.status === 200) {
                      timesheetStates.setRefetch(true);
                    }
                  });
                  setLoading(false);
                }}
                className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
              >
                {/* ThumbUp */}
                <ThumbUpIcon style={{ fontSize: "1.5rem", color: "#54afe2" }} />
              </a>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Approve Timesheet</Tooltip>
              }
            >
              <a
                onClick={() => {
                  const setApprove = async () => {
                    if (userId) {
                      return await axios.post(
                        `${TIMESHEET_URL}/${row.timesheet_week_id}/approve?status=true&user_id=${userId}`
                      );
                    }
                  };
                  setLoading(true);
                  setApprove().then((res) => {
                    if (res.status === 200) {
                      timesheetStates.setRefetch(true);
                    }
                  });
                  setLoading(false);
                }}
                className="btn btn-icon btn-light btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
              >
                <ThumbUpAltOutlinedIcon style={{ color: "#54afe2" }} />
              </a>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-edit-tooltip">
                {row.approval_status
                  ? "Cannot Decline Approved Timesheet"
                  : "Decline Timesheet"}
              </Tooltip>
            }
            disabled={loading}
          >
            <a
              onClick={() => {
                if (!row.approval_status) {
                  history.push(
                    `/timesheet/submissions/${row.timesheet_week_id}/decline`
                  );
                }
              }}
              className="btn btn-icon btn-light btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
              disabled={loading}
            >
              <ThumbDownIcon
                style={{
                  color: row.approval_status ? "#a8aaac" : "#f64e60",
                  cursor: row.approval_status ? "not-allowed" : "pointer",
                }}
              />
            </a>
          </OverlayTrigger>
        </TableCell>
      </TableRow>
      <TableRow key={"details" + row.id}>
        <TableCell colSpan={6} className="pb-0 pt-0">
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={2}
              margin={3}
              style={{ backgroundColor: "#f5f5f5", borderRadius: "0.5rem" }}
            >
              <Fragment>
                <Container></Container>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Task</TableCell>
                      <TableCell>Branch</TableCell>
                      {Object.values(
                        row.timesheet_tasks[0].timesheet_entries
                      ).map((entry, index) => (
                        <TableCell
                          key={index}
                          className={classes.defaultColumn}
                        >
                          {moment(entry.date.split("T")[0]).format("ddd, MMM DD")}
                        </TableCell>
                      ))}
                      <TableCell className={classes.defaultColumn}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.timesheet_tasks.map((task, index) => (
                      <TableRow key={task.timesheet_week_id}>
                        <TableCell>
                          {timesheetFunctions.getTaskNameById(task.task_id)}
                        </TableCell>
                        <TableCell>
                          {timesheetFunctions.getBranchNameById(task.branch_id)}
                        </TableCell>
                        {Object.values(task.timesheet_entries).map(
                          (entry, index) => (
                            <TableCell
                              key={index}
                              className={classes.defaultColumn}
                            >
                              {entry.hours}
                            </TableCell>
                          )
                        )}
                        <TableCell className={classes.defaultColumn}>
                          <span className={classes.totalColumn}>
                            {getTaskTotal(index)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} className={classes.totalColumn}>
                        Totals
                      </TableCell>
                      {Object.values(
                        row.timesheet_tasks[0].timesheet_entries
                      ).map((entry, index) => (
                        <TableCell
                          key={index}
                          className={classes.defaultColumn}
                        >
                          <span className={classes.totalColumn}>
                            {getDayTotal(index)}
                          </span>
                        </TableCell>
                      ))}
                      <TableCell className={classes.defaultColumn}>
                        <span className={classes.totalColumn}>
                          {row.total_hours}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Fragment>
            </Box>
            {/* Comment */}
            <Box
              padding={2}
              margin={3}
              style={{ backgroundColor: "#f5f5f5", borderRadius: "0.5rem" }}
            >
              <Container>
                <Row>
                  <div className={classes.commentLabelColumn}>
                    Weekly Comment:
                  </div>
                  <p className={classes.commentColumn}>
                    {row.comment ? row.comment : "No comment"}
                  </p>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
