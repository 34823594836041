/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { UserEditForm } from "./Components/UserEditForm";
import { useSubheader } from "../../../_metronic/layout";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { useUserContext } from "./Components/UserDataContext";
import {checkAndReplace} from "../../helpers/Formatter";

const USER_URL = "api/user";

const initUser = {
  user_id: 0,
  firstname: "",
  lastname: "",
  gender: "",
  email: "",
  alternate_email: "",
  mobile: "",
  homephone: "",
  other_phone: "",
  address: "",
  street: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  dob: "",
  username: "",
  password: "",
  registered_on: "",
  refferal: "",
  modify_on: "",
  resetPasswordToken: "",
  resetPasswordExpires: "",
  profile_pic: "",
  active_id: "1",
  role_id: "",
  branches: [],
  tasks: [],
  comment: "",
  certificate: [],
};

export function UserEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  // Subheader
  const suhbeader = useSubheader();

  //user data
  const [userData, setUserData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userValues, setUserValues] = useState();
  const btnRef = useRef();

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  initUser.branch_id = branch.branch_id;

  const context = useUserContext();
  const userId = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id
  );

  const handleClose = () => {
    setSuccessSnackbarMessage("");
    setErrorSnackbarMessage("");
    setShowSnackbar(false);
  };

  useEffect(() => {
    const loadSnackbarSuccess = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    successSnackbarMessage &&
      successSnackbarMessage != "" &&
      loadSnackbarSuccess();
  }, [successSnackbarMessage]);

  useEffect(() => {
    const loadSnackbarError = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    errorSnackbarMessage && errorSnackbarMessage != "" && loadSnackbarError();
  }, [errorSnackbarMessage]);

  useEffect(() => {
    let _title = id ? "Edit Staff User" : "New Staff User";

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [id]);

  useEffect(() => {
    const getUserFields = async () => {
      setLoading(true);
      const user_info_url = `${USER_URL}/edit?userId=${id}`;
      const res_user = await axios(user_info_url);

      if (res_user.data[0].dob === null) {
        res_user.data[0].dob = "";
      } else {
        res_user.data[0].dob = moment(
          res_user.data[0].dob.split("T")[0],
          "yyyy-MM-DD"
        ).format("yyyy-MM-DD");
      }

      setUserData(res_user.data[0]);
      setLoading(false);
      return res_user;
    };

    id && getUserFields();
  }, [id]);

  useEffect(() => {
    const newUserData = id ? userData : initUser;

    setUserValues(newUserData);
  }, [userData]);

  const saveUser = async (userValues) => {
    const USER_POST_URL = `${USER_URL}/user`;

    setLoading(true);

    userValues.modified_by = userId;

    userValues.firstname = checkAndReplace(userValues.firstname);
    userValues.lastname = checkAndReplace(userValues.lastname);
    userValues.address = checkAndReplace(userValues.address);
    userValues.city = checkAndReplace(userValues.city);
    userValues.zip = checkAndReplace(userValues.zip);
    userValues.comment = checkAndReplace(userValues.comment);

    if (!id) {
      // set default values
      userValues.registeredOn = moment().format("yyyy-MM-DD");
      userValues.modifyOn = moment().format("yyyy-MM-DD");
      userValues.alternateEmail = userValues.alternateEmail || "";
      userValues.homephone = userValues.homephone || "";
      userValues.otherPhone = userValues.otherPhone || "";
      userValues.address = userValues.address || "";
      userValues.street = userValues.street || "";
      userValues.username = userValues.username || userValues.email;
      userValues.password =
        userValues.password ||
        "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea";
      userValues.profilePic = userValues.profilePic || "";
      userValues.branches = Array.isArray(userValues.branches)
        ? userValues.branches
        : [];

      const result = await axios.post(USER_POST_URL, userValues);
      if (result.data === "failed") {
        setErrorSnackbarMessage("Failed to create user");
      } else if (result.data === "exists") {
        setErrorSnackbarMessage("User email is taken - please try another");
        // console.log("user exists");
      } else if (result.data === "success") {
        setSuccessSnackbarMessage("User created successfully");
        setTimeout(() => {
          history.push(`/users/user-list`);
        }, 1000);
      }
    } else {
      userValues.branches = Array.isArray(userValues.branches)
        ? userValues.branches
        : [];

      // console.log("userValues", userValues);

      //edit event patch
      const editUserUrl = `${USER_URL}/user/`;
      const result = await axios.patch(editUserUrl + id, userValues);

      if (result.data === "failed") {
        setErrorSnackbarMessage("Failed to create user");
      } else if (result.data === "exists") {
        setErrorSnackbarMessage("User email is taken - please try another");
        // console.log("user exists");
      } else if (result.data === "success") {
        //if user editted successfully. Also edit certificates
        let certValues = {
          newCert: userValues.certificate.filter((cert) => {
            return (
              userData.certificate.filter((uDCert) => {
                return uDCert.course == cert.course;
              }).length == 0
            );
          }),
          updatedCert: userValues.certificate.filter((cert) => {
            return (
              userData.certificate.filter((uDCert) => {
                return uDCert.course == cert.course;
              }).length != 0
            );
          }),
        };

        const editCertUrl = `api/certification/courses/`;
        const certResult = await axios.patch(editCertUrl + id, certValues);
        if (certResult.data == "success") {
          setSuccessSnackbarMessage("User saved successfully");
          setTimeout(() => {
            history.push(`/users/user-list`);
          }, 1000);
        } else {
          setErrorSnackbarMessage("Failed to save user");
        }
      }
    }

    setLoading(false);
  };

  const saveUserClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveUser(null);
    }
  };

  const backToUserList = () => {
    history.push(`/users`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successSnackbarMessage != "" ? "success" : "error"}
          message={
            successSnackbarMessage != ""
              ? successSnackbarMessage
              : errorSnackbarMessage
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader
          title={
            id
              ? userValues
                ? `Staff User: ${userValues.firstname} ${userValues.lastname}`
                : "Loading User Data"
              : "New Staff User"
          }
        >
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToUserList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveUserClick}
            >
              {id ? "Save" : "Add"}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        {isLoading && <LinearProgress color="secondary" styles="width:100%" />}
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Basic Info
              </a>
            </li>
            <li className="nav-item" onClick={() => setTab("address")}>
              <a
                className={`nav-link ${tab === "address" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "address").toString()}
              >
                Address Info
              </a>
            </li>
            <li className="nav-item" onClick={() => setTab("permissions")}>
              <a
                className={`nav-link ${tab === "permissions" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "permissions").toString()}
              >
                Permissions
              </a>
            </li>
            {userValues && userValues.user_id != 0 && (
              <li className="nav-item" onClick={() => setTab("certification")}>
                <a
                  className={`nav-link ${tab === "certification" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "certification").toString()}
                >
                  Certification
                </a>
              </li>
            )}
          </ul>
          <div className="mt-5">
            <UserEditForm
              userValues={userValues}
              btnRef={btnRef}
              saveUser={saveUser}
              tab={tab}
              setTab={setTab}
              isLoading={isLoading}
              setLoading={setLoading}
              setSuccessSnackbarMessage={setSuccessSnackbarMessage}
              setErrorSnackbarMessage={setErrorSnackbarMessage}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
