import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { CouponActivateDialog } from "./Components/CouponActivateDialog";
import { useCouponContext } from "./Components/CouponDataContext";
import { CouponDeleteDialog } from "./Components/CouponDeleteDialog";
import { CouponFilter } from "./Components/CouponFilter";
import { CouponShowDialog } from "./Components/CouponShowDialog";
import { CouponHideDialog } from "./Components/CouponHideDialog";
import CouponTable from "./Components/CouponTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";

export const CouponList = () => {
  const [couponData, setCouponData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useCouponContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Coupon List");
  const couponsUrl = "api/discounts/coupons/";

  async function fetchCouponsForBranch() {
    //let activeValues = ["Active", "In-Active"];
    setLoading(true);
    const result = await axios(couponsUrl + branch.branch_id);
    // result.data.results.sort((a, b) => {
    //   return (
    //     activeValues.indexOf(a.active_value) -
    //     activeValues.indexOf(b.active_value)
    //   );
    // });

    setCouponData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
  }

  //success error messages
  const [showSuccessOpen, setShowSuccessOpen] = useState(false);
  const [hideSuccessOpen, setHideSuccessOpen] = useState(false);
  const [showErrorOpen, setShowErrorOpen] = useState(false);
  const [hideErrorOpen, setHideErrorOpen] = useState(false);

  const handleClose = () => {
    setShowSuccessOpen(false);
    setHideSuccessOpen(false);
    setShowErrorOpen(false);
    setHideErrorOpen(false);
  };

  //fetching coupons for a branch
  useEffect(() => {
    branch && fetchCouponsForBranch();
  }, [branch]);

  //fetching coupons for a branch
  useEffect(() => {
    context.refetch && fetchCouponsForBranch();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (!(item["coupon_id"] + "").includes(filters[key])) {
              add = false;
            }
          }
          if (key === "date") {
            if (
              !moment(filters[key]).isBefore(
                moment(item["enddate"], "yyyy-MM-DD").add(1, "days")
              )
            ) {
              add = false;
            }
          }
          if (key === "batch_type") {
            if (!(item["batch_type"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (
            key !== "id" && key !== "batch_type" && key !== "date" &&
            (item[key] === undefined || item[key] != filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });

    setCouponData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          showSuccessOpen
            ? showSuccessOpen
            : hideSuccessOpen
            ? hideSuccessOpen
            : showErrorOpen
            ? showErrorOpen
            : hideErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={showSuccessOpen || hideSuccessOpen ? "success" : "error"}
          message={
            showSuccessOpen
              ? "Marked coupon to show successfully"
              : hideSuccessOpen
              ? "Marked coupon to hide successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Coupon Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <Link
              to="/coupons/add-coupon"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Add New Coupon
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <CouponFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <CouponTable coupons={couponData} showActions={true} loading={loading} />

      <ContentRoute path="/coupons/coupon-list/:id/delete-coupon">
        {({ history, match }) => (
          <CouponDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/coupons/coupon-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/coupons/coupon-list/:id/activate-coupon">
        {({ history, match }) => (
          <CouponActivateDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/coupons/coupon-list");
            }}
          />
        )}
      </ContentRoute>
      <ContentRoute path="/coupons/coupon-list/:id/coupon-show">
        {({ history, match }) => (
          <CouponShowDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/coupons/coupon-list");
            }}
            setShowSuccessOpen={setShowSuccessOpen}
            setShowErrorOpen={setShowErrorOpen}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/coupons/coupon-list/:id/coupon-hide">
        {({ history, match }) => (
          <CouponHideDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/coupons/coupon-list");
            }}
            setHideSuccessOpen={setHideSuccessOpen}
            setHideErrorOpen={setHideErrorOpen}
          />
        )}
      </ContentRoute>
    </div>
  );
};
