export const lmsHeaders = [
  // pending student name -TIMP
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    label: "Student Name",
  },
  {
    id: "zebra_username",
    numeric: false,
    disablePadding: false,
    label: "Student ID",
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "last_login",
    numeric: false,
    disablePadding: false,
    label: "Last Login",
  },
  {
    id: "last_submission",
    numeric: false,
    disablePadding: false,
    label: "Last Submission Date",
  },
  {
    id: "course_name",
    numeric: false,
    disablePadding: false,
    label: "Course Name",
  },
  {
    id: "challenge_title",
    numeric: false,
    disablePadding: false,
    label: "Challenge Title",
  },
];
