/* eslint eqeqeq: "off" */
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import { useCampContext } from "./CampDataContext";
// Validation schema
const CampEditSchema = Yup.object().shape({
  startdate: Yup.date().required("Start Date is required"),
  enddate: Yup.date()
    .required("End Date is required")
    .test("is-greater", "end date should be greater", function(value) {
      const { startdate } = this.parent;
      return moment(value, "yyyy-mm-dd").isAfter(
        moment(startdate, "yyyy-mm-dd").subtract(1, "days")
      );
    }),
  start_time: Yup.string().required("Start time is required"),
  end_time: Yup.string()
    .when(`times.${0}.offered`, {
      is: 0 || false,
      then: (schema) => schema,
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test("is-greater", "end time should be greater", function(value) {
          const { start_time } = this.parent;
          return moment(value, "hh:mm").isAfter(moment(start_time, "hh:mm"));
        })
        .nullable(),
    })
    .nullable(),

  half_day_am_end_time: Yup.string()
    .when(`times.${1}.offered`, {
      is: 0 || false,
      then: (schema) => schema,
      otherwise: Yup.string()
        .required("Morning end time is required")
        .test("is-greater", "end time should be greater", function(value) {
          const { start_time } = this.parent;
          return moment(value, "hh:mm").isAfter(moment(start_time, "hh:mm"));
        })
        .nullable(),
    })
    .nullable(),
  half_day_pm_start_time: Yup.string()
    .when(`times.${2}.offered`, {
      is: 0 || false,
      then: (schema) => schema,
      otherwise: Yup.string()
        .required("Afternoon start time is required")
        .nullable(),
    })
    .nullable(),
  half_day_pm_end_time: Yup.string()
    .when("end_time", {
      is: (end_time) => end_time && end_time.length > 0,
      then: (schema) => schema,
      otherwise: Yup.string()
        .when(`times.${2}.offered`, {
          is: 0 || false,
          then: (schema) => schema,
          otherwise: Yup.string()
            .required("Afternoon end time is required")
            .test("is-greater", "end time should be greater", function(value) {
              const { half_day_pm_start_time } = this.parent;
              return moment(value, "hh:mm").isAfter(
                moment(half_day_pm_start_time, "hh:mm")
              );
            })
            .nullable(),
        })
        .nullable(),
    })
    .nullable(),
  extended_care_end_time: Yup.string()
    .when(`times.${3}.offered`, {
      is: 0 || false,
      then: (schema) => schema,
      otherwise: Yup.string()
        .required("Extended care end time is required")
        .test("is-greater", "end time should be greater", function(value) {
          const { end_time } = this.parent;
          const { half_day_pm_end_time } = this.parent;
          const { half_day_am_end_time } = this.parent;
          if (end_time) {
            return moment(value, "hh:mm").isAfter(moment(end_time, "hh:mm"));
          } else if (half_day_pm_end_time) {
            return moment(value, "hh:mm").isAfter(
              moment(half_day_pm_end_time, "hh:mm")
            );
          } else if (half_day_am_end_time) {
            return moment(value, "hh:mm").isAfter(
              moment(half_day_am_end_time, "hh:mm")
            );
          }
        })
        .nullable(),
    })
    .nullable(),
  maximum_student: Yup.number()
    .min(1, "1 is minimum")
    .max(100, "100 is maximum")
    .required("Max Students is required"),
  program_type_id: Yup.string(),
  active_id: Yup.string(),
  camp_type_id: Yup.string(),
  delivery_type_id: Yup.string(),
  am_full_ind: Yup.boolean(),
  pm_full_ind: Yup.boolean(),
  full_day_full_ind: Yup.boolean(),
  am_show_ind: Yup.boolean(),
  pm_show_ind: Yup.boolean(),
  full_day_show_ind: Yup.boolean(),
  times: Yup.array().of(
    Yup.object().shape({
      offered: Yup.boolean(),
      show: Yup.boolean(),
      start_time: Yup.string(),
      end_time: Yup.string(),
    })
  ),
});

export function CampEditForm({ camp, btnRef, saveCamp }) {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const DROPDOWN_TYPE = useCampContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={camp}
        validationSchema={CampEditSchema}
        onSubmit={(values) => {
          saveCamp(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, getFieldMeta }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <Select name={`program_type_id`} label="Program Type">
                    {DROPDOWN_TYPE.PROGRAM_TYPE &&
                      DROPDOWN_TYPE.PROGRAM_TYPE.map((a) => (
                        <option
                          key={a.id}
                          disabled={values.camp_id ? true : false}
                          value={a.id}
                        >
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-lg-3">
                  <Select name={`camp_type_id`} label="Camp Type">
                    {DROPDOWN_TYPE.BATCH_TYPE &&
                      DROPDOWN_TYPE.BATCH_TYPE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>

                <div className="col-lg-3">
                  <Select name={`active_id`} label="Camp Status">
                    {DROPDOWN_TYPE.ACTIVE &&
                      DROPDOWN_TYPE.ACTIVE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-lg-3">
                  <Select name={`delivery_type_id`} label="Delivery Type">
                    {DROPDOWN_TYPE.DELIVERY_TYPE &&
                      DROPDOWN_TYPE.DELIVERY_TYPE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label className={"required-field"}>Enter Start Date</label>
                  <Field
                    name={`startdate`}
                    component={Input}
                    type="date"
                    value={values.startdate.split("T")[0]}
                  />
                </div>
                <div className="col-lg-4">
                  <label className={"required-field"}>Enter End Date</label>
                  <Field
                    name={`enddate`}
                    component={Input}
                    type="date"
                    value={values.enddate.split("T")[0]}
                  />
                </div>
                <div className="col-lg-4">
                  <label className={"required-field"}>
                    Enter Maximum Student Count
                  </label>
                  <Field
                    name={`maximum_student`}
                    component={Input}
                    placeholder="Maximum Student Count"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  Mark Specific Camps as Full:
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-1 col-sm-3 col-md-3 col-lg-3"></div>
                <div
                  className="col-xs-1 col-sm-2 col-md-2 col-lg-2"
                  style={{ textAlign: "center" }}
                >
                  <label>
                    AM:
                    <Field
                      name={`am_full_ind`}
                      type="checkbox"
                      label="AM:"
                      value={values.am_full_ind == 0 ? false : true}
                      checked={values.am_full_ind == 0 ? false : true}
                      className={"form-control"}
                    />
                  </label>
                </div>
                <div
                  className="col-xs-1 col-sm-2 col-md-2 col-lg-2"
                  style={{ textAlign: "center" }}
                >
                  <label>
                    PM:
                    <Field
                      name={`pm_full_ind`}
                      type="checkbox"
                      label="PM:"
                      value={values.pm_full_ind == 0 ? false : true}
                      checked={values.pm_full_ind == 0 ? false : true}
                      className={"form-control"}
                    />
                  </label>
                </div>
                <div
                  className="col-xs-1 col-sm-2 col-md-2 col-lg-2"
                  style={{ textAlign: "center" }}
                >
                  <label>
                    FD:
                    <Field
                      name={`full_day_full_ind`}
                      type="checkbox"
                      value={values.full_day_full_ind == 0 ? false : true}
                      checked={values.full_day_full_ind == 0 ? false : true}
                      className={"form-control"}
                    />
                  </label>
                </div>
                <div className="col-xs-1 col-sm-3 col-md-3 col-lg-3"></div>
              </div>
              <table
                style={{ textAlign: "center" }}
                className="table table-striped"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Offered</th>
                    <th scope="col">Show</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={0}>
                    <td>{values.times[0].type}</td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`times.${0}.offered`}
                          onChange={(e) => {
                            if (e.value == 0) {
                              setFieldValue(`times.${0}.offered`, e.value);
                              setFieldValue("full_day_show_ind", e.value);
                              setFieldValue("start_time", "");
                              setFieldValue("end_time", "");

                              const half_day_am_end_time = getFieldMeta(
                                "half_day_am_end_time"
                              );
                              const half_day_pm_end_time = getFieldMeta(
                                "half_day_pm_end_time"
                              );

                              if (!half_day_am_end_time.touched) {
                                setFieldValue("half_day_am_end_time", "");
                              }
                              if (!half_day_pm_end_time.touched) {
                                setFieldValue("half_day_pm_end_time", "");
                              }
                            } else {
                              setFieldValue(`times.${0}.offered`, e.value);
                              setFieldValue("full_day_show_ind", e.value);
                            }
                          }}
                        >
                          {DROPDOWN_TYPE.OFFERED &&
                            DROPDOWN_TYPE.OFFERED.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`full_day_show_ind`}
                          disabled={
                            parseInt(values.times[0].offered) != 1
                              ? true
                              : false
                          }
                        >
                          {DROPDOWN_TYPE.SHOW &&
                            DROPDOWN_TYPE.SHOW.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <Field
                        name={`start_time`}
                        component={Input}
                        type="time"
                        value={values.start_time}
                        disabled={
                          parseInt(values.times[0].offered) != 1 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setFieldValue("start_time", e.target.value);
                            setFieldValue(
                              `times.${1}.start_time`,
                              e.target.value
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Field
                        name={`end_time`}
                        component={Input}
                        type="time"
                        value={values.end_time}
                        disabled={
                          parseInt(values.times[0].offered) != 1 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setFieldValue(`end_time`, e.target.value);
                            setFieldValue(
                              "half_day_pm_end_time",
                              e.target.value
                            );
                            setFieldValue(
                              `times.${3}.start_time`,
                              e.target.value
                            );
                          }
                          // else if (e.target.value) {
                          //   console.log("here");
                          //   setFieldValue(`end_time`, "");
                          //   // if (
                          //   //   values.half_day_pm_end_time &&
                          //   //   values.half_day_pm_end_time.length > 0
                          //   // ) {
                          //   //   setFieldValue(
                          //   //     "half_day_pm_end_time",
                          //   //     values.half_day_pm_end_time
                          //   //   );
                          //   // }
                          // }
                        }}
                      />
                    </td>
                  </tr>

                  <tr key={1}>
                    <td>{values.times[1].type}</td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`times.${1}.offered`}
                          onChange={(e) => {
                            if (e.value == 0) {
                              setFieldValue(`times.${1}.offered`, e.value);
                              setFieldValue("am_show_ind", e.value);
                              setFieldValue("half_day_am_end_time", "");
                            } else {
                              setFieldValue(`times.${1}.offered`, e.value);
                              setFieldValue("am_show_ind", e.value);
                            }
                          }}
                        >
                          {DROPDOWN_TYPE.OFFERED &&
                            DROPDOWN_TYPE.OFFERED.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`am_show_ind`}
                          disabled={
                            parseInt(values.times[1].offered) != 1
                              ? true
                              : false
                          }
                        >
                          {DROPDOWN_TYPE.SHOW &&
                            DROPDOWN_TYPE.SHOW.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <Field
                        name={`start_time`}
                        component={Input}
                        type="time"
                        value={values.start_time}
                        disabled={
                          values.times[1].offered != 1
                            ? true
                            : values.start_time != "" &&
                              parseInt(values.times[0].offered) != 0
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <Field
                        name={`half_day_am_end_time`}
                        component={Input}
                        type="time"
                        value={values.half_day_am_end_time}
                        disabled={
                          parseInt(values.times[1].offered) != 1 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setFieldValue(
                              `half_day_am_end_time`,
                              e.target.value
                            );

                            const fdOffered = getFieldMeta(
                              `times.${0}.offered`
                            );
                            const pmOffered = getFieldMeta(
                              `times.${2}.offered`
                            );
                            if (fdOffered.value == 0 && pmOffered.value == 0) {
                              // setFieldValue("end_time", e.target.value);
                              setFieldValue(
                                `times.${3}.start_time`,
                                e.target.value
                              );
                            }
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr key={2}>
                    <td>{values.times[2].type}</td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`times.${2}.offered`}
                          onChange={(e) => {
                            if (e.value == 0) {
                              setFieldValue(`times.${2}.offered`, e.value);
                              setFieldValue("pm_show_ind", e.value);
                              setFieldValue("half_day_pm_start_time", "");
                              setFieldValue("half_day_pm_end_time", "");
                            } else {
                              setFieldValue(`times.${2}.offered`, e.value);
                              setFieldValue(`pm_show_ind`, e.value);
                            }
                          }}
                        >
                          {DROPDOWN_TYPE.OFFERED &&
                            DROPDOWN_TYPE.OFFERED.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`pm_show_ind`}
                          disabled={
                            parseInt(values.times[2].offered) != 1
                              ? true
                              : false
                          }
                        >
                          {DROPDOWN_TYPE.SHOW &&
                            DROPDOWN_TYPE.SHOW.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <Field
                        name={`half_day_pm_start_time`}
                        component={Input}
                        type="time"
                        value={values.half_day_pm_start_time}
                        disabled={
                          parseInt(values.times[2].offered) != 1 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setFieldValue(
                              `half_day_pm_start_time`,
                              e.target.value
                            );
                            const fdOffered = getFieldMeta(
                              `times.${0}.offered`
                            );
                            const amOffered = getFieldMeta(
                              `times.${1}.offered`
                            );
                            if (fdOffered.value == 0 && amOffered.value == 0) {
                              setFieldValue("start_time", e.target.value);
                            }
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Field
                        name={`half_day_pm_end_time`}
                        component={Input}
                        type="time"
                        value={values.half_day_pm_end_time}
                        disabled={
                          parseInt(values.times[2].offered) != 1
                            ? true
                            : values.end_time != "" &&
                              parseInt(values.times[0].offered) != 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setFieldValue(
                              `half_day_pm_end_time`,
                              e.target.value
                            );
                            setFieldValue(
                              `times.${3}.start_time`,
                              e.target.value
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr key={3}>
                    <td>{values.times[3].type}</td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`times.${3}.offered`}
                          onChange={(e) => {
                            if (e.value == 0) {
                              setFieldValue(`times.${3}.offered`, e.value);
                              setFieldValue(`times.${3}.start_time`, "");
                              setFieldValue("extended_care_end_time", "");
                            } else {
                              setFieldValue(`times.${3}.offered`, e.value);
                            }
                          }}
                        >
                          {DROPDOWN_TYPE.OFFERED &&
                            DROPDOWN_TYPE.OFFERED.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.value}
                              </option>
                            ))}
                        </Select>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Select
                          name={`times.${3}.show`}
                          disabled={
                            parseInt(values.times[3].offered) != 1
                              ? true
                              : false
                          }
                        ></Select>
                      </div>
                    </td>
                    <td>
                      <Field
                        name={`times.${3}.start_time`}
                        component={Input}
                        type="time"
                        value={values.times[3].start_time}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <Field
                        name={`extended_care_end_time`}
                        component={Input}
                        type="time"
                        value={values.extended_care_end_time}
                        disabled={
                          parseInt(values.times[3].offered) != 1 ? true : false
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
              {/* {camp.camp_id && <PersistFormikValues name="camp-form" />} */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
