export const unpaidHeaders = [
  {
    id: "student_course_fee_id",
    numeric: true,
    disablePadding: true,
    label: "Fee ID",
  },
  {
    id: "student_user_id",
    numeric: false,
    disablePadding: true,
    label: "Student ID",
  },
    {
      id: "student_name",
      numeric: false,
      disablePadding: true,
      label: "Student Name",
    },
    {
      id: "course_abbr",
      numeric: false,
      disablePadding: true,
      label: "Stream Abbrv",
    },
    {
      id: "final_total",
      numeric: false,
      disablePadding: true,
      label: "Total",
    }
]