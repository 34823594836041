/* eslint eqeqeq: "off" */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { checkAndReplace } from "../../../../helpers/Formatter";

export function CampPointsDialog({ id, show, onHide }) {
  const [isLoading, setIsLoading] = useState(false);
  const [studentPoints, setStudentPoints] = useState([]);

  const handlePointsChange = (index, newValue) => {
    // console.log("change time");
    if (!studentPoints) {
      return;
    }

    let studentsCopy = [...studentPoints];
    studentsCopy[index].adding = newValue == "adding" ? true : false;
    setStudentPoints(studentsCopy);
  };

  const savePoints = async () => {
    setIsLoading(true);
    let transactionDate = moment(new Date()).format("yyyy-MM-DD");
    // console.log(studentPoints);

    let finalPoints = studentPoints.map((sp, i) => {
      return { ...sp, comment: checkAndReplace(sp.comment) };
    });

    // eslint-disable-next-line
    let res = await axios.post("/api/camps/points/", {
      camp_id: id,
      date: transactionDate,
      points: finalPoints,
    });
    setIsLoading(false);
    onHide();
  };

  const getPoints = async () => {
    // console.log("Getting points");
    setIsLoading(true);
    const response = await axios.get(`api/camps/points/${id}`);
    // console.log(response.data.results);
    setStudentPoints(response.data.results);

    setIsLoading(false);
  };

  useEffect(() => {
    id && getPoints();
    // eslint-disable-next-line
  }, [id]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Class Points
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LinearProgress color="secondary" styles="width:100%" />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Current Points</TableCell>
                <TableCell colSpan={3}>Add/Remove Points</TableCell>
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentPoints && studentPoints.length > 0 ? (
                studentPoints.map((sp, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{sp.user_id}</TableCell>
                      <TableCell>{sp.firstname + " " + sp.lastname}</TableCell>
                      <TableCell>{sp.changed_grade}</TableCell>
                      <TableCell className="m-0 p-0">
                        <button
                          className={
                            "btn btn-elevate " +
                            (sp.adding ? "btn-success" : "btn-light")
                          }
                          onClick={() => {
                            handlePointsChange(index, "adding");
                          }}
                        >
                          +
                        </button>
                      </TableCell>
                      <TableCell className="m-0 p-0">
                        <button
                          className={
                            "btn btn-elevate " +
                            (sp.adding ? "btn-light" : "btn-danger")
                          }
                          onClick={() => {
                            handlePointsChange(index, "remove");
                          }}
                        >
                          -
                        </button>
                      </TableCell>
                      <TableCell className="m-0 p-0">
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            let newPoints = [...studentPoints];
                            newPoints[index].amount = isNaN(
                              parseInt(e.target.value)
                            )
                              ? 0
                              : parseInt(e.target.value);
                            setStudentPoints(newPoints);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            let newPoints = [...studentPoints];
                            newPoints[index].comment = e.target.value;
                            setStudentPoints(newPoints);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : studentPoints && studentPoints.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    No Students? Please say there's no students and this isn't a
                    bug...
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-muted"
          onClick={() => {
            onHide();
          }}
        >
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            savePoints();
          }}
          disabled={false}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
