import { select } from "@redux-saga/core/effects";
import * as requestFromServer from "./FamilyCrud";
import { familySlice, callTypes } from "./FamilySlice";

const { actions } = familySlice;

export const fetchFamily = (userid) => (dispatch) => {
  // console.log("[ACTION] Fetching Family");
  dispatch(actions.startCall({ callType: callTypes.fetchFamily }));
  return requestFromServer
    .findFamily(userid)
    .then((response) => {
      const { students, parents, user } = response.data.results;
      dispatch(actions.familyFetched({ students, parents, userid, user }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find family";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const dropFamily = () => (dispatch) => {
  // console.log("[ACTION] Droping Family");
  dispatch(actions.startCall({ callType: callTypes.familyDropped }));
  return dispatch(actions.familyDropped());
};

export const fetchStudentCourses = (students) => (dispatch) => {
  // console.log("[ACTION] Fetching Student Streams");
  dispatch(actions.startCall({ callType: callTypes.list }));
  students.map((student, index) => {
    requestFromServer
      .getStudentCourses(student.user_id)
      .then((response) => {
        //console.log(response.data);
        const courses = { courses: response.data };
        dispatch(
          actions.studentCoursesFetched({ courses, id: student.user_id })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
