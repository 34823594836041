// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import moment from "moment";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function ClassReportForm({
  reportResults,
  setReportResults,
  values,
  errors,
  touched,
  setReportTypeId,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return (
    <>
      <div className="form-group row">
        {
          <>
            <div className={"col-md-3"}>
              <Select
                name={`program_type_id`}
                label="Program Type"
                onChange={(e) => {
                  setFieldValue("program_type_id", e.value);
                  if (reportResults && reportResults.length > 0) {
                    setReportResults();
                  }
                }}
                validationColorScheme={false}
              >
                <option value={"default"}>All</option>
                {DROPDOWN_TYPE.PROGRAM_TYPE &&
                  DROPDOWN_TYPE.PROGRAM_TYPE.map((a, index) => (
                    <option key={index} value={a.id}>
                      {a.value}
                    </option>
                  ))}
              </Select>
            </div>
            <div className={"col-md-3"}>
              <Select
                name={`delivery_type_id`}
                label="Delivery Type"
                onChange={(e) => {
                  setFieldValue("delivery_type_id", e.value);
                  if (reportResults && reportResults.length > 0) {
                    setReportResults();
                  }
                }}
                validationColorScheme={false}
              >
                <option value={"default"}>All</option>
                {DROPDOWN_TYPE.DELIVERY_TYPE &&
                  DROPDOWN_TYPE.DELIVERY_TYPE.map((a, index) => (
                    <option key={index} value={a.id}>
                      {a.value}
                    </option>
                  ))}
              </Select>
            </div>
          </>
        }
        {
          <div className="col-md-3">
            <Select
              name={`active_id`}
              label="Stream Status"
              onChange={(e) => {
                setFieldValue("active_id", e.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
              validationColorScheme={false}
            >
              {DROPDOWN_TYPE.ACTIVE &&
                DROPDOWN_TYPE.ACTIVE.map((a, index) => (
                  <option key={index} value={a.id}>
                    {a.value}
                  </option>
                ))}
            </Select>
          </div>
        }
      </div>

      <div className="form-group row">
        <div className={"col-md-3"}>
          <Select
            name={`day`}
            label="Day"
            onChange={(e) => {
              setFieldValue("day", e.value);
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
            validationColorScheme={false}
          >
            {[
              "All",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((m, index) => {
              return (
                <option key={index} value={m}>
                  {m}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={"col-md-3"}>
          <label>Include Make Up and Trial</label>
          <input
            type="checkbox"
            className={`form-control`}
            name="include_extra"
            onChange={(e) => {
              setFieldValue("include_extra", e.target.value);
            }}
            value={values.include_extra == false ? false : true}
          />
        </div>
      </div>
      <div className="form-group row">
        {!["1"].includes(values.active_id) && (
          <>
            <div className={"col-md-3"}>
              <label className={"required-field pr-2"}>From Date</label>
              <OverlayTrigger
                overlay={<Tooltip>The date of the class</Tooltip>}
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
              </OverlayTrigger>
              <input
                type="date"
                className={
                  "form-control" +
                  (errors.regular_from_date && touched.regular_from_date
                    ? " is-invalid"
                    : "")
                }
                value={
                  values.regular_from_date
                    ? values.regular_from_date.split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  setFieldValue("regular_from_date", e.target.value);
                  setFieldValue(
                    "regular_to_date",
                    moment(e.target.value, "yyyy-mm-dd")
                      .add(6, "months")
                      .add(1, "days")
                      .format("YYYY-MM-DD")
                  );

                  if (reportResults && reportResults.length > 0) {
                    setReportResults();
                  }
                }}
              ></input>
              <label className={"text-muted"}>
                Results retrieved will be from date selected to 6 months after
              </label>
              <ErrorMessage
                name={`regular_from_date`}
                component="div"
                className="invalid-feedback"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
