/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useCouponContext } from "./CouponDataContext";

export function CouponHideDialog({
  history,
  id,
  full_value,
  show,
  onHide,
  setHideSuccessOpen,
  setHideErrorOpen,
}) {
  const context = useCouponContext();
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const hideCoupon = async () => {
    const couponHideUrl = "api/discounts/hide/";
    const result = await axios.patch(couponHideUrl + id);
    if (result.data === "success") {
      //console.log("marked to hide coupon");
      setHideSuccessOpen(true);
      context.setRefetch(true);

      setTimeout(() => {
        history.push("/coupons/coupon-list");
      }, 1000);
    } else {
      //console.log(result.data);
      setHideErrorOpen(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Hide Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to hide this coupon?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={hideCoupon}
            className="btn btn-delete btn-elevate"
          >
            Hide Coupon
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
