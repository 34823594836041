/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
/* eslint eqeqeq: "off" */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useBatchContext } from "../BatchDataContext";

export const ClassTableActions = ({
  row,
  openEditBatchPage,
  openDeleteBatchDialog,
  openBatchFullDialog,
  openDuplicateBatchPage,
  openBatchNotFullDialog,
  openActivateBatchDialog,
  openAttendanceDialog,
  openPointsDialog,
  openEmailDialog,
}) => {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const batchContext = useBatchContext();
  const dayIndex = batchContext.DAYS.findIndex((day) => day === row.day) + 1;

  return (
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <Row className="justify-content-center">
        {access["batches"] == 0 ? (
          <div>
            {" "}
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Edit batch</Tooltip>}
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
                onClick={() => {
                  // console.log(row);
                  openEditBatchPage(row.batch_id);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <> </>
            {row.active_value === "Active" ? (
              <span>
                {/* Asad remember to add stuff to reactivate */}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-delete-tooltip">
                      Deactivate Batch
                    </Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
                    onClick={() => openDeleteBatchDialog(row.batch_id)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <i className="fa fa-power-off text-success"></i>
                    </span>
                  </a>
                </OverlayTrigger>
                {row.batch_full_ind !== 0 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-delete-tooltip">
                        Mark as Not Full
                      </Tooltip>
                    }
                  >
                    <a
                      className="btn btn-icon btn-danger btn-hover-success btn-sm mr-1"
                      onClick={() => openBatchNotFullDialog(row.batch_id)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <i className="fa fa-box text-default"></i>
                      </span>
                    </a>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-delete-tooltip">
                        Mark as Full
                      </Tooltip>
                    }
                  >
                    <a
                      className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
                      onClick={() => openBatchFullDialog(row.batch_id)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <i className="fa fa-box-open text-primary"></i>
                      </span>
                    </a>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-duplicate-tooltip">
                      Duplicate Batch
                    </Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
                    onClick={() => openDuplicateBatchPage(row.batch_id)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <i className="far fa-copy text-success"></i>
                    </span>
                  </a>
                </OverlayTrigger>
              </span>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-delete-tooltip">Activate Batch</Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
                  onClick={() => openActivateBatchDialog(row.batch_id)}
                >
                  <i className="fa fa-power-off"></i>
                </a>
              </OverlayTrigger>
            )}{" "}
          </div>
        ) : (
          // if user does not have access to edit batch
          <></>
        )}
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Open Attendance</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
            onClick={() => openAttendanceDialog(row.batch_id, dayIndex)}
          >
            <i className="fa fa-user-check"></i>
          </a>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Add/Remove Points</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
            onClick={() => openPointsDialog(row.batch_id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-success">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}
              ></SVG>
            </span>
          </a>
        </OverlayTrigger>
        {access["batches"] == 0 && (
          <OverlayTrigger
            overlay={<Tooltip id="products-delete-tooltip">Email</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
              onClick={() => openEmailDialog(row.batch_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                ></SVG>
              </span>
            </a>
          </OverlayTrigger>
        )}
      </Row>
    </Container>
  );
};
