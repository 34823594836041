/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Snackbar } from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import TextEditor from "../../../_zebra/_partials/TextEditor";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";

const initEmail = {
  fromEmail: "",
  toEmail: [],
  bccEmail: [],
  subject: "",
  emailBody: "",
  bodyLength: 0,
};

const EmailSchema = Yup.object().shape({
  toEmail: Yup.array()
    .of(
      Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("At least one email is required")
    )
    .min(1, "Need at least one email"),
  subject: Yup.string()
    .min(5, "subject must be at least 5 character")
    .required("Subject is required"),
  bodyLength: Yup.number()
    .min(5, "Body is required, minimum of 5 characters")
    .required("Email body is required"),
});

export function EmailEdit({ history }) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const user = useSelector((state) => state.auth.user, shallowEqual);
  // Subheader
  const suhbeader = useSubheader();

  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [values, setValues] = useState(initEmail);
  const [branchEmail, setBranchEmail] = useState("");
  const [contacts, setContacts] = useState("");
  const [plainTextBody, setPlainTextBody] = useState("");

  const btnRef = useRef();
  ////console.log(id);

  const getLocationEmail = async (values) => {
    const emailLink = `api/email-service/get-email?branchId=${branch.branch_id}`;
    const result = await axios(emailLink);
    setBranchEmail(result.data.results[0].email);
  };

  const getContacts = async (values) => {
    const emailLink = `api/email-service/branch-contacts?branchId=${branch.branch_id}`;
    const result = await axios(emailLink);
    setContacts(result.data.results);
  };

  useEffect(() => {
    setTitle("New Email");
    suhbeader.setTitle("New Email");
    branch && getLocationEmail();
    branch && getContacts();
  }, [user]);

  const sendEmail = async (values) => {
    //send email
    const sendUrl = "api/email-service/send";
    // console.log("post new email data", values);
    values.fromEmail = branchEmail;
    values.branchId = branch.branch_id;
    values.to = [
      ...values.toEmail.map((e) => {
        return e.value;
      }),
    ];
    values.bcc = [
      ...values.bccEmail.map((e) => {
        return e.value;
      }),
    ];

    const result = await axios.post(sendUrl, values);
    if (result.data !== "failed") {
      setSuccessOpen(true);
      setTimeout(() => {
        history.push(`/dashboard`);
      }, 1000);
    } else {
      setErrorOpen(true);
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const sendEmailClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      sendEmail(null);
    }
  };

  const backToEmailList = () => {
    history.push(`/dashboard`);
  };

  const createableOnChange = (setFieldValue, currentValues, name) => {
    let splitableOptions = currentValues
      ? currentValues.filter((v) => {
          return v.value.indexOf(",") >= 0;
        })
      : [];
    let normalOptions = currentValues
      ? currentValues.filter((v) => {
          return v.value.indexOf(",") < 0;
        })
      : [];
    let finalOptions = [...normalOptions];
    splitableOptions.map((v) => {
      v.value.split(",").map((val) => {
        finalOptions.push({ value: val, label: val });
      });
    });
    // console.log("split", splitableOptions, "normal", normalOptions,"correct" ,finalOptions)
    setFieldValue(name, finalOptions);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={successOpen ? successOpen : errorOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen ? "success" : "error"}
          message={
            successOpen ? "Sent the email successfully" : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToEmailList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Cancel
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={sendEmailClick}
            >
              Send
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={values}
            validationSchema={EmailSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              sendEmail(values);
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              setFieldError,
            }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label className={"required-field"}>From</label>
                      <Field
                        name="fromEmail"
                        component={Input}
                        placeholder="Your email"
                        type="text"
                        value={branchEmail}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label className={"required-field"}>To</label>
                      <Field
                        component={CreatableSelect}
                        isMulti
                        name="toEmail"
                        options={contacts}
                        value={values.toEmail}
                        className={`${errors.toEmail ? "is-invalid" : ""}`}
                        onChange={(selectedOptions) => {
                          createableOnChange(
                            setFieldValue,
                            selectedOptions,
                            "toEmail"
                          );
                        }}
                        onBlur={() => {
                          createableOnChange(
                            setFieldValue,
                            values.toEmail,
                            "toEmail"
                          );
                        }}
                        placeholder="Select or Create email recipients"
                      />
                      {errors.toEmail && (
                        <div className="invalid-feedback">{errors.toEmail}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label>BCC</label>
                      <Field
                        component={CreatableSelect}
                        isMulti
                        name="bccEmail"
                        options={contacts}
                        value={values.bccEmail}
                        className={`${errors.bccEmail ? "is-invalid" : ""}`}
                        onChange={(selectedOptions) => {
                          createableOnChange(
                            setFieldValue,
                            selectedOptions,
                            "bccEmail"
                          );
                        }}
                        onBlur={() => {
                          createableOnChange(
                            setFieldValue,
                            values.bccEmail,
                            "bccEmail"
                          );
                        }}
                        placeholder="Select or Create email recipients"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label className={"required-field"}>Subject</label>
                      <Field
                        name="subject"
                        component={Input}
                        placeholder="Subject"
                        type="text"
                        onChange={(e) => {
                          // console.log("changed", e.value, e.target.value);
                          setFieldValue("subject", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label className={"required-field"}>Body</label>

                      <TextEditor
                        name="emailBody"
                        value={values["emailBody"]}
                        setValue={setFieldValue}
                        setPlainTextBody={setPlainTextBody}
                        onBlur={(e) => {
                          setFieldTouched("emailBody", true);
                          setFieldTouched("bodyLength", true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {errors.bodyLength ? (
                        <p className="mt-10">
                          <small className="text-danger">
                            {errors.bodyLength}
                          </small>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <button
                      type="submit"
                      style={{ display: "none" }}
                      ref={btnRef}
                      onSubmit={() => handleSubmit()}
                    ></button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Fragment>
  );
}
