import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import { Select } from "../../../../../../_metronic/_partials/controls";
import { useBranchCourseContext } from "../BranchCourseDataContext";
import { BranchCampFeeEditTable } from "./BranchCampFeeEditTable";
import { BranchClassFeeEditTable } from "./BranchClassFeeEditTable";

const getEditSchema = (type) => {
  return type == 1
    ? Yup.object().shape({
        virtual_course_fee: Yup.number()
          .typeError("Specify a number")
          .required("Required"),
        fees: Yup.array().of(
          Yup.object().shape({
            delivery_type_id: Yup.number(),
            frequency_nbr: Yup.number().required("Required"),
            course_fee: Yup.number()
              .typeError("Specify a number")
              .required("Required"),
            fee_tax_percentage: Yup.boolean(),
            half_day_ind: Yup.boolean(), //checkbox
            price_range_ind: Yup.boolean(), //checkbox
            added_fee: Yup.number().typeError("Specify a number"),
            duration: Yup.number()
              .typeError("Specify a number")
              .required("Required"),
          })
        ),
      })
    : Yup.object().shape({
        virtual_course_fee: Yup.number()
          .typeError("Specify a number")
          .required("Required"),
        fees: Yup.array().of(
          Yup.object().shape({
            delivery_type_id: Yup.number(),
            frequency_nbr: Yup.number().required("Required"),
            course_fee: Yup.number()
              .typeError("Specify a number")
              .required("Required"),
            fee_tax_percentage: Yup.boolean(),
            half_day_ind: Yup.boolean(), //checkbox
            price_range_ind: Yup.boolean(), //checkbox
            added_fee: Yup.number().typeError("Specify a number"),
            duration: Yup.number().typeError("Specify a number"),
          })
        ),
      });
};

export function BranchCourseFeeEditForm({
  id,
  values,
  btnRef,
  saveFees,
  deleted,
  setDeleted,
}) {
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const type = values.fees[0].batch_type;
  const [error, setError] = useState();

  const validateFees = (values) => {
    let uniqueValues = [];
    if (type == 1) {
      uniqueValues = new Set(
        values.fees.map(
          (fee) =>
            `${fee.frequency_nbr}_${fee.delivery_type_id}_${fee.duration}`
        )
      );
      // console.log(uniqueValues);
    } else {
      uniqueValues = new Set(
        values.fees.map(
          (fee) =>
            `${fee.frequency_nbr}_${fee.batch_type}_${fee.half_day_ind}_${fee.delivery_type_id}`
        )
      );
      // console.log(uniqueValues);
    }
    const price_range_ind_check = values.fees.filter(
      (fee) => fee.price_range_ind == 1 || fee.price_range_ind == true
    );

    if (uniqueValues.size < values.fees.length) {
      // console.log("Duplicate fees found. Please ensure the fees are unique");
      setError("Duplicate fees found. Please ensure the fees are unique");
      return {
        error: "Duplicate fees found. Please ensure the fees are unique",
      };
    }
    if (price_range_ind_check.length == 0) {
      setError("Please select a price to be included in the range display.");
      return {
        error: "Please select a price to be included in the range display.",
      };
    } else {
      setError(null);
      return {};
    }
  };

  const getFeesTable = (fees) => {
    return (
      <table className="table table-striped">
        <thead className="thead-light">
          <tr className="text-center">
            <th scope="col">Delivery Type</th>
            <th scope="col">Batch Type</th>
            <th scope="col">Sessions/{type != 1 ? "week" : "month"}</th>
            {type == 1 && <th scope="col">Duration</th>}
            <th scope="col">Stream Fee</th>
            {type != 1 && <th scope="col">Extended Care Fee</th>}
            <th scope="col">Display in Range</th>
            {type != 1 && <th scope="col">HD Ind</th>}
          </tr>
        </thead>
        <tbody>
          {fees.map((fee, index) => (
            <tr key={index} className="text-center">
              <td>
                {fee.delivery_type_id === "2" || fee.delivery_type_id === 2
                  ? "Virtual"
                  : "In-Person"}
              </td>
              <td>
                {
                  DROPDOWN_TYPE.BATCH_TYPE.filter((bT) => {
                    return fee.batch_type == bT.id;
                  })[0].value
                }
              </td>
              <td>{fee.frequency_nbr}</td>
              {type == 1 && <td>{fee.duration}</td>}
              <td>{fee.course_fee}</td>
              {type != 1 && <td>{fee.added_fee}</td>}
              <td className="text-center">
                <Field
                  type="checkbox"
                  name={`fees.${index}.price_range_ind`}
                  checked={fee.price_range_ind == 0 ? false : true}
                  value={fee.price_range_ind == 0 ? false : true}
                  className={"form-check-input"}
                  //disabled={access["branch_course"] == undefined}
                  //hi asad, it's jai 4/12/2022
                />
              </td>
              {type != 1 && <td> {fee.half_day_ind ? "On" : "Off"}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={values}
      validate={validateFees}
      validationSchema={getEditSchema(type)}
      validator={() => ({})}
      onSubmit={(values) => {
        saveFees(values);
      }}
    >
      {({ handleSubmit, values, errors, touched }) => (
        <Form className="form form-label-right">
          <div className="form-body">
            <div className="form-group row">
              <div className="col-md-6">
                <Select name={`active_id`} label="Stream Status">
                  <option key={1} value={1}>
                    Active
                  </option>
                  <option key={0} value={0}>
                    In-Active
                  </option>
                  <option key={2} value={2}>
                    Completed
                  </option>
                </Select>
              </div>
              <div className="col-md-6">
                <Select name={`call_to_enroll_ind`} label="Call To Enroll">
                  <option key={1} value={1}>
                    Yes
                  </option>
                  <option key={0} value={0}>
                    No
                  </option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-6">
                <Select name={`fee_tax_ind`} label="Is Course Taxable?">
                  <option key={1} value={1}>
                    Yes
                  </option>
                  <option key={0} value={0}>
                    No
                  </option>
                </Select>
              </div>
            </div>

            <br />
            {values && access["branch_course"] == 0 ? (
              type !== 1 ? (
                <BranchCampFeeEditTable
                  values={values}
                  errors={errors}
                  touched={touched}
                  deleted={deleted}
                  setDeleted={setDeleted}
                />
              ) : (
                <BranchClassFeeEditTable
                  values={values}
                  errors={errors}
                  touched={touched}
                  deleted={deleted}
                  setDeleted={setDeleted}
                />
              )
            ) : (
              getFeesTable(values.fees)
            )}
            {error && <div className="text-danger"> {error}</div>}
            <button
              type="submit"
              style={{ display: "none" }}
              ref={btnRef}
              onSubmit={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
