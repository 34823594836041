import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Badge } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  attendanceStatusBadgeSuccess: {
    backgroundColor: "#54afe2",
    color: "white",
  },
  attendanceStatusBadgeDanger: {
    backgroundColor: "#dc3545",
    color: "white",
  },
}));

export default function LMSAccountsRowReport(props) {
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={`${row.student_batch_attendance_id}`} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.user_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.firstname + " " + row.lastname}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.course_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.course_code}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.sub_course_code}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.startDate}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
