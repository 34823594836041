/* eslint eqeqeq: "off" */
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useBatchContext } from "./BatchDataContext";

const prepareFilter = (values) => {
  const {
    idFilter,
    programTypeFilter,
    daysFilter,
    deliveryTypeFilter,
    activeFilter,
    courseFilter,
    startTimeFilter,
    endTimeFilter,
    coachFilter,
  } = values;
  const filter = {};
  // Filter by program
  filter.id = idFilter !== "" ? idFilter : "";
  filter.program_name = programTypeFilter !== "" ? programTypeFilter : "";
  filter.day = daysFilter !== "" ? daysFilter : "";
  filter.delivery_type_value =
    deliveryTypeFilter !== "" ? deliveryTypeFilter : "";
  filter.active_value = activeFilter !== "" ? activeFilter : "";
  filter.courses = courseFilter !== "" ? courseFilter : "";
  filter.start_time = startTimeFilter != "" ? startTimeFilter : "";
  filter.end_time = endTimeFilter != "" ? endTimeFilter : "";
  filter.coaches = coachFilter != "" ? coachFilter : "";

  // Filter by all fields
  //   filter.days = searchText;
  //   if (searchText) {
  //     filter.start_time = searchText;
  //     filter.end_time = searchText;
  //     filter.instructor = searchText;
  //   }

  return filter;
};

export function BatchFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const DROPDOWN_TYPE = useBatchContext().TYPES;
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const context = useBatchContext();
  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };

  const useStyles = makeStyles({
    btnCircleSm: {
      width: "30px",
      height: "30px",
      padding: "0px 7px",
      margin: "2px 1px 1px 1px",
      borderRadius: "15px",
      fontSize: "4px",
      textAlign: "center",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const initialValues = {
      idFilter: "",
      programTypeFilter: "",
      daysFilter: "",
      deliveryTypeFilter: "",
      activeFilter: "Active",
      courseFilter: "",
      startTimeFilter: "",
      endTimeFilter: "",
      coachFilter: "",
    };
    applyFilter(initialValues);
    // eslint-disable-next-line
  }, [origData]);

  //fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={{
          idFilter: "",
          programTypeFilter: "",
          daysFilter: "",
          deliveryTypeFilter: "",
          activeFilter: "Active",
          courseFilter: "",
          startTimeFilter: "",
          endTimeFilter: "",
          coachFilter: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Active"
                  name="activeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("activeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.activeFilter}
                >
                  <option value="">All</option>

                  {DROPDOWN_TYPE.ACTIVE &&
                    DROPDOWN_TYPE.ACTIVE.map((a) => (
                      <option key={a.id} value={a.value}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Active
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Day"
                  name="daysFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("daysFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.daysFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.DAYS &&
                    DROPDOWN_TYPE.DAYS.map((a) => (
                      <option key={a} value={a}>
                        {a}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Day
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Delivery Type"
                  name="deliveryTypeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("deliveryTypeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.deliveryTypeFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.DELIVERY_TYPE &&
                    DROPDOWN_TYPE.DELIVERY_TYPE.map((a) => (
                      <option key={a.id} value={a.value}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Delivery Type
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Program Type"
                  name="programTypeFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("programTypeFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.programTypeFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.PROGRAM_TYPE &&
                    DROPDOWN_TYPE.PROGRAM_TYPE.map((a) => (
                      <option key={a.id} value={a.value}>
                        {a.value}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Program Type
                </small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <Select
                  isMulti
                  name="courseFilter"
                  options={DROPDOWN_TYPE.SELECT_COURSE_OPTIONS}
                  onChange={(e) => {
                    let streams = e.map((c) => {
                      return c.value;
                    });
                    if (streams.length == 0) {
                      streams = "";
                    }
                    setFieldValue("courseFilter", streams);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Stream Name
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Coach"
                  name="coachFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("coachFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.coachFilter}
                >
                  <option value="">All</option>
                  {DROPDOWN_TYPE.COACH &&
                    DROPDOWN_TYPE.COACH.sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    ).map((a) => (
                      <option key={a.id} value={a.id}>
                        {`${a.name}`}
                      </option>
                    ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Coach
                </small>
              </div>
              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />
              {/* <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div> */}

              <button
                className={`btn btn-sm btn-circle btn-light ${classes.btnCircleSm}`}
                onClick={(e) => setShowMoreFilters(!showMoreFilters)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i
                    className={`fa text-${
                      showMoreFilters ? `secondary fa-minus` : `primary fa-plus`
                    }`}
                  ></i>
                </span>
              </button>
            </div>
            {showMoreFilters && (
              <div className="form-group row">
                <div className="col-lg-2">
                  <input
                    className="form-control"
                    placeholder="ID"
                    name="idFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("idFilter", e.target.value);
                      handleSubmit();
                    }}
                    value={values.idFilter}
                  ></input>
                  <small className="form-text text-muted">
                    <b>Filter</b> by ID
                  </small>
                </div>
                <div className="col-lg-2">
                  <input
                    className="form-control"
                    placeholder="Start Time"
                    type="time"
                    name="startTimeFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("startTimeFilter", e.target.value);
                      handleSubmit();
                    }}
                    value={values.startTimeFilter}
                  ></input>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Start Time
                  </small>
                </div>
                <div className="col-lg-2">
                  <input
                    className="form-control"
                    placeholder="Start Time"
                    type="time"
                    name="endTimeFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("endTimeFilter", e.target.value);
                      handleSubmit();
                    }}
                    value={values.endTimeFilterf}
                  ></input>
                  <small className="form-text text-muted">
                    <b>Filter</b> by End Time
                  </small>
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}
