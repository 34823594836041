import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../helpers/TimeConvert";
import { useCustomerLeadsContext } from "./CustomerLeadsDataContext";
import { CustomerLeadsTableActions } from "./ActionFormatter/CustomerLeadsTableActions";
import { getFormattedNumber } from "../../../helpers/Formatter";
import moment from "moment";
import { Badge } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const TrialTable = ({ row, COURSE, selectedCourseData }) => {
  let setCourse =
    row.course_id &&
    COURSE &&
    COURSE.filter((c) => {
      return c.value == row.course_id;
    })[0].label;
  return (
    <Fragment>
      <h4>Trial classes</h4>
      {row.course_id && row.trial_date && row.batch_id != null &&
          typeof row.batch_id != undefined ? (
        <p key={`${row.batch_id}-${row.lead_id}`}>
          {" "}
          {setCourse}
          {row.batch_id == 0
            ? "@ Other"
            : `@ ${moment(row.trial_date, "YYYY-MM-DD").format(
                "ddd DD MMM YYYY"
              )}: ${moment(row.start_time, "HH:mm:ss").format(
                "hh:mm a"
              )} - ${moment(row.end_time, "HH:mm:ss").format("hh:mm a")}`}
        </p>
      ) : (
        <p>No Trial Classes Added</p>
      )}
    </Fragment>
  );
};
const CourseOfInterestTable = ({ row, COURSE }) => {
  return (
    <>
      <h4>Course Of Interest</h4>
      {row.course_of_interest.map((c) => {
        let myCourse =
          COURSE &&
          COURSE.filter((c2) => {
            return c2.value == c.value;
          })[0];
        return (
          <p key={`${myCourse.code}-${row.lead_id}`}>
            {myCourse ? `${myCourse.code} - ${myCourse.label}` : "ERROR"}
          </p>
        );
      })}
    </>
  );
};

const CommentTable = ({ row }) => {
  return (
    <>
      <h4>Notes</h4>
      <p>{row.notes ? row.notes : "No Comment Added"}</p>
    </>
  );
};

export default function CustomerLeadsRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [leadOpen, setLeadOpen] = useState("");
  const CustomerLeadsContext = useCustomerLeadsContext();
  const { COURSE, selectedCourseData } = CustomerLeadsContext.TYPES;

  const handleRowClick = (batch_id) => {
    setOpen(!open);
    setLeadOpen(batch_id);
    //console.log("here" + batch_id);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={row.lead_id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(row.batch_id)}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.lead_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.lead_date && row.lead_date != ""
            ? moment(row.lead_date, "YYYY-MM-DD").format("ddd MMM DD, YYYY")
            : ""}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.lead_name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.student_name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell align="left" component="th" scope="row">

          {getFormattedNumber(row.lead_phone)}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.status}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Badge pill className={classes.greyRatio}>
            {row.trial_date && row.trial_date != ""
              ? moment(
                  convertFromTimeStampTToDate(row.trial_date),
                  "YYYY-MM-DD"
                ).format("ddd MMM DD, YYYY")
              : ""}
          </Badge>
        </TableCell>

        {showActions && (
          <TableCell align="center">
            <CustomerLeadsTableActions
              row={row}
              openEditCustomerLeadsPage={
                CustomerLeadsContext.openEditCustomerLeadsPage
              }
              openDeleteCustomerLeadsDialog={
                CustomerLeadsContext.openDeleteCustomerLeadsDialog
              }
              openDuplicateCustomerLeadsPage={
                CustomerLeadsContext.openDuplicateCustomerLeadsPage
              }
            />
          </TableCell>
        )}
      </TableRow>

      <TableRow key={"details" + row.lead_id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Container>
                <Row>
                  <Col>
                    <TrialTable
                      row={row}
                      COURSE={COURSE}
                      selectedCourseData={selectedCourseData}
                    />
                  </Col>
                  <Col>
                    <CourseOfInterestTable row={row} COURSE={COURSE} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CommentTable row={row} />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
