/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";
import { Button } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function InstructorListSelection({
  className,
  id,
  type,
  setCoachesToAdd,
  coachesToAdd,
  coachesToRemove,
  setCoachesToRemove,
  batchInstructors,
  setBatchInstructors,
  batchMissingInstructors,
  setBatchMissingInstructors,
  campInstructors,
  setCampInstructors,
  campMissingInstructors,
  setCampMissingInstructors,
}) {
  //getbranch
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const removeInstructor = async (coach) => {
    // console.log("remove instructor", instructor_batch_id);
    // const values = { active_id: 0 };
    // //save new batch post
    // const removeInstructorsUrl = `api/batches/instructors/${instructor_batch_id}`;
    // const result = await axios.patch(removeInstructorsUrl, values);
    // if (result.data === "success") {
    //   console.log("success");
    //   setRefetch(true);
    // } else {
    //   console.log("error");
    //}

    //check if it is in added
    const isItInAdded = coachesToAdd.filter((c) => c === coach.instructor_id);

    if (isItInAdded.length > 0) {
      //remove the coach from removed array
      const r = coachesToAdd.filter((c) => c != coach.instructor_id);
      setCoachesToAdd(r);

      if (type == "batch") {
        const removeFromC = batchInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setBatchInstructors(removeFromC);
        setBatchMissingInstructors([coach, ...batchMissingInstructors]);
      } else if (type == "camp") {
        const removeFromC = campInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setCampInstructors(removeFromC);
        setCampMissingInstructors([coach, ...campMissingInstructors]);
      }
    } else {
      setCoachesToRemove([coach.instructor_id, ...coachesToRemove]);

      if (type == "batch") {
        const removeFromCourses = batchInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setBatchInstructors(removeFromCourses);
        setBatchMissingInstructors([coach, ...batchMissingInstructors]);
      } else if (type == "camp") {
        const removeFromCourses = campInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setCampInstructors(removeFromCourses);
        setCampMissingInstructors([coach, ...campMissingInstructors]);
      }
    }
  };
  const addInstructor = async (coach) => {
    // console.log("Add instructor", instructor_id);
    // const values = { batch_id: batchid, instructor_id: instructor_id };
    // //save new batch post
    // const addInstructorsUrl = `api/batches/instructors`;
    // const result = await axios.post(addInstructorsUrl, values);
    // if (result.data === "success") {
    //   console.log("success");
    //   setRefetch(true);
    // } else {
    //   console.log("error");
    // }
    //check if instructor was originally removed
    const isItInRemove = coachesToRemove.filter(
      (c) => c === coach.instructor_id
    );

    //if it is in remove it means it doesnt need to be added again
    if (isItInRemove.length > 0) {
      //remove the instructor from removed array
      const r = coachesToRemove.filter((c) => c != coach.instructor_id);
      setCoachesToRemove(r);
      //updated batch list views
      if (type == "batch") {
        setBatchInstructors([coach, ...batchInstructors]);
        const removeFromCoaches = batchMissingInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setBatchMissingInstructors(removeFromCoaches);
      } else if (type == "camp") {
        setCampInstructors([coach, ...campInstructors]);
        const removeFromCoaches = campMissingInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setCampMissingInstructors(removeFromCoaches);
      }
    } else {
      if (type == "batch") {
        batchInstructors.filter((c) => c.instructor_id === coach.instructor_id)
          .length < 1 &&
          setCoachesToAdd([coach.instructor_id, ...coachesToAdd]);
        setBatchInstructors([coach, ...batchInstructors]);
        const removeFromC = batchMissingInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setBatchMissingInstructors(removeFromC);
      } else if (type == "camp") {
        campInstructors.filter((c) => c.instructor_id === coach.instructor_id)
          .length < 1 &&
          setCoachesToAdd([coach.instructor_id, ...coachesToAdd]);
        setCampInstructors([coach, ...campInstructors]);
        const removeFromC = campMissingInstructors.filter(
          (c) => c.instructor_id != coach.instructor_id
        );
        setCampMissingInstructors(removeFromC);
      }
    }
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0"></div>

      {/* Body */}
      <div className="card-body pt-0 row">
        <div className="col-md-6 p-20  rounded bg-light-primary">
          <div className="row">
            {" "}
            <h5> Instructors</h5>{" "}
          </div>
          {type == "batch" &&
            batchInstructors &&
            batchInstructors.map((instructor) => {
              /* begin::Item */
              return (
                <div className="mb-6" key={instructor.instructor_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {instructor.instructor_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {instructor.instructor_id}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}

                      {/* begin::Button */}
                      <OverlayTrigger overlay={<Tooltip>Remove Coach</Tooltip>}>
                        <a
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => removeInstructor(instructor)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i className="fa fa-minus text-danger"></i>
                          </span>
                        </a>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              );
            })}
          {type == "camp" &&
            campInstructors &&
            campInstructors.map((instructor) => {
              /* begin::Item */
              return (
                <div className="mb-6" key={instructor.instructor_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {instructor.instructor_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {instructor.instructor_id}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}

                      {/* begin::Button */}
                      <OverlayTrigger overlay={<Tooltip>Remove Coach</Tooltip>}>
                        <a
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => removeInstructor(instructor)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i className="fa fa-minus text-danger"></i>
                          </span>
                        </a>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              );
            })}
        </div>
        <div className="col-md-6 p-20 ">
          <div className="row">
            {" "}
            <h5> Instructors To Be Added</h5>{" "}
          </div>

          {/* listing all instructors not in camp */}
          {
            (type ==
              "batch" &&
              batchMissingInstructors &&
              batchMissingInstructors.map((instructor) => {
                /* begin::Item */
                return (
                  <div className="mb-6" key={instructor.instructor_id}>
                    {/* begin::Content */}
                    <div className="d-flex align-items-center flex-grow-1">
                      {/* begin::Section */}
                      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                        {/* begin::Info */}
                        <div className="d-flex flex-column align-items-cente py-2 w-75">
                          {/* begin::Title */}
                          <a
                            href="#"
                            className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                          >
                            {instructor.instructor_name}
                          </a>
                          {/* end::Title */}

                          {/* begin::Data */}
                          <span className="text-muted font-weight-bold">
                            {instructor.instructor_id}
                          </span>
                          {/* end::Data */}
                        </div>
                        {/* end::Info */}

                        {/* begin::Button */}
                        <OverlayTrigger overlay={<Tooltip>Add Coach</Tooltip>}>
                          <a
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => addInstructor(instructor)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <i className="fa fa-plus text-success"></i>
                            </span>
                          </a>
                        </OverlayTrigger>

                        {/* end:Button */}
                      </div>
                      {/* end::Section */}
                    </div>
                    {/* end::Content */}
                  </div>

                  /* end::Item */
                );
              }))
          }
          {
            (type ==
              "camp" &&
              campMissingInstructors &&
              campMissingInstructors.map((instructor) => {
                /* begin::Item */
                return (
                  <div className="mb-6" key={instructor.instructor_id}>
                    {/* begin::Content */}
                    <div className="d-flex align-items-center flex-grow-1">
                      {/* begin::Section */}
                      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                        {/* begin::Info */}
                        <div className="d-flex flex-column align-items-cente py-2 w-75">
                          {/* begin::Title */}
                          <a
                            href="#"
                            className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                          >
                            {instructor.instructor_name}
                          </a>
                          {/* end::Title */}

                          {/* begin::Data */}
                          <span className="text-muted font-weight-bold">
                            {instructor.instructor_id}
                          </span>
                          {/* end::Data */}
                        </div>
                        {/* end::Info */}

                        {/* begin::Button */}
                        <OverlayTrigger overlay={<Tooltip>Add Coach</Tooltip>}>
                          <a
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => addInstructor(instructor)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <i className="fa fa-plus text-success"></i>
                            </span>
                          </a>
                        </OverlayTrigger>

                        {/* end:Button */}
                      </div>
                      {/* end::Section */}
                    </div>
                    {/* end::Content */}
                  </div>

                  /* end::Item */
                );
              }))
          }
        </div>
      </div>
    </div>
  );
}
