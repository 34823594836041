/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useBranchCourseContext } from "../Components/BranchCourseDataContext";

export function BranchCourseDeleteDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const context = useBranchCourseContext();
  // Branch Context

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deactivateBranch = async () => {
    const deactivateUrl = "api/branch-course/deactivate/";

    //deactivate branch course post
    const result = await axios.patch(deactivateUrl + id);
    if (result.data === "success") {
      // console.log("inactivated");
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/branch-course/branch-course-list");
      }, 1000);
    } else {
    }
    // server request for deleting product by id
    // refresh list after deletion

    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          De-Activate Branch Stream
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to de-activate this branch stream?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deactivateBranch}
            className="btn btn-delete btn-elevate"
          >
            Deactivate Stream
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
