/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { actions } from "./modules/Auth/_redux/authRedux";
import { useDispatch } from "react-redux";

export function Routes() {
  const { isAuthorized, isBranchSelected, branchList } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null,
      isBranchSelected: auth.branches.currentBranch.branch_id != -1,
      branchList: auth.branches.branchList,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setBranch = (branch) => {
    dispatch(actions.setBranch(branch));
  };

  const setDefaultBranch = () => {
    const defaultBranch = Array.isArray(branchList) ? branchList[0] : null;
    if (defaultBranch) {
      setBranch(defaultBranch);
      return isBranchSelected;
    } else {
      return false;
    }
  };

  return (
    <Switch>
      
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : !isBranchSelected && isAuthorized ? (
        <Route path="/auth/branch" component={AuthPage} />
      ) : (
        isAuthorized &&
        isBranchSelected && (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : !isBranchSelected && isAuthorized ? (
        branchList.length === 1 ? (
          setDefaultBranch()
        ) : (
          branchList.length > 1 ? (
            <Redirect to="/auth/branch" />
          ) : (
            <LayoutSplashScreen />
          )
        )
      ) : (
        isAuthorized &&
        isBranchSelected && (
          <Layout>
            <BasePage />
          </Layout>
        )
      )}
    </Switch>
  );
}
