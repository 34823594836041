/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSubheader } from "../../../_metronic/layout";
import AttendanceReportTable from "./Components/AttendanceReport/AttendanceReportTable";
import CampAttendanceReportTable from "./Components/CampAttendanceReport/CampAttendanceReportTable";
import CampEnrollmentVsDobReportTable from "./Components/CampEnrollmentVsDobReport/CampEnrollmentVsDobReportTable";
import CampReportTable from "./Components/CampReport/CampReportTable";
import ClassReportTable from "./Components/ClassReport/ClassReportTable";
import CompletedCoursesReportTable from "./Components/CompletedCoursesReport/CompletedCoursesReportTable";
import CouponReportTable from "./Components/CouponReport/CouponReportTable";
import CourseChallengeReportTable from "./Components/CourseChallengeReport/CourseChallengeReportTable";
import CourseEnrollmentVsDobReportTable from "./Components/CourseEnrollmentVsDobReport/CourseEnrollmentVsDobReportTable";
import LMSAccountsReportTable from "./Components/LMSAccountsReport/LMSAccountsReportTable";
import LMSvsPortalActivityReportTable from "./Components/LMSvsPortalActivityReport/LMSvsPortalActivityReportTable";
import LmsReportTable from "./Components/LmsReport/LmsReportTable";
import MaintenanceReportTable from "./Components/MaintenanceReport/MaintenanceReportTable";
import { NewReportForm } from "./Components/NewReportForm";
import OOOReportTable from "./Components/OOOReport/OOOReportTable";
import PaymentReportTable from "./Components/PaymentReport/PaymentReportTable";
import { useReportContext } from "./Components/ReportDataContext";
import { ReportsEmailDialog } from "./Components/ReportsEmailDialog";
import UnpaidReportTable from "./Components/UnpaidReport/UnpaidReportTable";

export function NewReport({ history }) {
  // Subheader
  const suhbeader = useSubheader();

  const [loading, setLoading] = useState(false);
  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [reportResults, setReportResults] = useState();
  const [reportTypeId, setReportTypeId] = useState(0);
  const [selectedReport, setSelectedReport] = useState({
    value: "0",
    label: "Select A Report Type...",
    order: "0",
    desc: "Select A Report",
  });
  const [newReport, setNewReport] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const branch = useReportContext().AUTH.branch;
  const userAuth = useReportContext().AUTH.userAuth;
  const DROPDOWN_TYPE = useReportContext().TYPES;
  const formInitValues = useReportContext().formInitValues;
  const [values, setValues] = useState();
  const btnRef = useRef();

  formInitValues.initReport.branch_id = branch.branch_id;
  formInitValues.initCampReport.branch_id = branch.branch_id;
  formInitValues.initClassReport.branch_id = branch.branch_id;
  formInitValues.initCouponReport.branch_id = branch.branch_id;
  formInitValues.initTrialReport.branch_id = branch.branch_id;
  formInitValues.initPaymentReport.branch_id = branch.branch_id;
  formInitValues.initAttendanceReport.branch_id = branch.branch_id;
  formInitValues.initUnpaidReport.branch_id = branch.branch_id;
  formInitValues.initCompletedCoursesReport.branch_id = branch.branch_id;
  formInitValues.initCourseChallengeReport.branch_id = branch.branch_id;
  formInitValues.initStudentFactReport.branch_id = branch.branch_id;
  formInitValues.initLmsReport.branch_id = branch.branch_id;
  formInitValues.initOOOReport.branch_id = branch.branch_id;
  formInitValues.initCampAttendanceReport.branch_id = branch.branch_id;
  formInitValues.initCourseEnrollmentVsDobReport.branch_id = branch.branch_id;
  formInitValues.initCampEnrollmentVsDobReport.branch_id = branch.branch_id;
  formInitValues.initLMSAccountsReport.branch_id = branch.branch_id;
  formInitValues.initLMSvsPortalActivityReport.branch_id = branch.branch_id;

  useEffect(() => {
    let _title = "New Report";

    setTitle(_title);
    suhbeader.setTitle(_title);
    setNewReport(true);
    setReportTypeId("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reportTypeNumber = parseInt(reportTypeId);
    let reportTypeSelected = {
      value: "0",
      label: "Select A Report Type...",
      order: "0",
      desc: "Select A Report",
    };
    for (let i = 0; i < DROPDOWN_TYPE.REPORT_TYPE.length; i++) {
      for (
        let j = 0;
        DROPDOWN_TYPE.REPORT_TYPE[i] &&
        DROPDOWN_TYPE.REPORT_TYPE[i].options &&
        j < DROPDOWN_TYPE.REPORT_TYPE[i].options.length;
        j++
      ) {
        if (DROPDOWN_TYPE.REPORT_TYPE[i].options[j].value == reportTypeNumber) {
          reportTypeSelected = DROPDOWN_TYPE.REPORT_TYPE[i].options[j];
        }
      }
    }

    setSelectedReport(reportTypeSelected);
    if (reportTypeId == "0") {
      setValues(formInitValues.initReport);
    } else if (reportTypeId == "1") {
      setValues(formInitValues.initClassReport);
    } else if (reportTypeId == "2") {
      setValues(formInitValues.initCampReport);
    } else if (reportTypeId == "3") {
      setValues(formInitValues.initCouponReport);
    } else if (reportTypeId == "4") {
      setValues(formInitValues.initTrialReport);
    } else if (reportTypeId == "5") {
      setValues(formInitValues.initPaymentReport);
    } else if (reportTypeId == "6") {
      setValues(formInitValues.initAttendanceReport);
    } else if (reportTypeId == "7") {
      setValues(formInitValues.initUnpaidReport);
    } else if (reportTypeId == "8") {
      setValues(formInitValues.initCompletedCoursesReport);
    } else if (reportTypeId == "9") {
      setValues(formInitValues.initCourseChallengeReport);
    } else if (reportTypeId == "10") {
      setValues(formInitValues.initOOOReport);
    } else if (reportTypeId == "12") {
      setValues(formInitValues.initMaintenanceReport);
    } else if (reportTypeId == "13") {
      setValues(formInitValues.initLmsReport);
    } else if (reportTypeId == "14") {
      setValues(formInitValues.initCampAttendanceReport);
    } else if (reportTypeId == "15") {
      setValues(formInitValues.initCourseEnrollmentVsDobReport);
    } else if (reportTypeId == "16") {
      setValues(formInitValues.initCampEnrollmentVsDobReport);
    } else if (reportTypeId == "17") {
      setValues(formInitValues.initLMSAccountsReport);
    } else if (reportTypeId == "18") {
      setValues(formInitValues.initLMSvsPortalActivityReport);
    }
  }, [newReport, reportTypeId]);

  const getEmailsByReport = (reportResults) => {
    let emails = [];
    if (reportTypeId == "2" || reportTypeId == "1") {
      reportResults.forEach((x) => {
        if (x.email && x.email.length > 0) {
          emails.push(x.email);
        }
        if (x.alternate_emails && x.alternate_emails.split(",").length > 0) {
          x.alternate_emails.split(",").forEach((y) => {
            if (y.length > 0) {
              emails.push(y);
            }
          });
        }
      });
    }
    return emails;
  };

  const generateReport = (requirements) => {
    if (requirements.report_type_id == "1") {
      generateClassReport(requirements);
    } else if (requirements.report_type_id == "2") {
      generateCampReport(requirements);
    } else if (["3"].includes(requirements.report_type_id)) {
      generateCouponReport(requirements);
    } else if (requirements.report_type_id == "4") {
      generateTrialReport(requirements);
    } else if (requirements.report_type_id == "5") {
      generatePaymentReport(requirements);
    } else if (requirements.report_type_id == "6") {
      generateAttendanceReport(requirements);
    } else if (requirements.report_type_id == "7") {
      generateUnpaidReport(requirements);
    } else if (requirements.report_type_id == "8") {
      generateCompletedCoursesReport(requirements);
    } else if (requirements.report_type_id == "9") {
      generateCourseChallengeReport(requirements);
    } else if (requirements.report_type_id == "10") {
      generateOOOReport(requirements);
    } else if (requirements.report_type_id == "12") {
      generateMaintenanceReport(requirements);
    } else if (requirements.report_type_id == "13") {
      generateLmsReport(requirements);
    } else if (requirements.report_type_id == "14") {
      generateCampAttendanceReport(requirements);
    } else if (requirements.report_type_id == "15") {
      generateCourseEnrollmentVsDobReport(requirements);
    } else if (requirements.report_type_id == "16") {
      generateCampEnrollmentVsDobReport(requirements);
    } else if (requirements.report_type_id == "17") {
      generateLMSAccountsReport(requirements);
    } else if (requirements.report_type_id == "18") {
      generateLMSvsPortalActivityReport(requirements);
    }

    setNewReport(false);
  };
  const generateClassReport = async (requirements) => {
    if (requirements.include_extra) {
      try {
        const reports_url = `api/reports/class-makeup/${
          requirements.branch_id
        }/${requirements.delivery_type_id}/${requirements.program_type_id}/${
          requirements.active_id
        }/${requirements.day}${
          ![1].includes(requirements.active_id) &&
          !["1"].includes(requirements.active_id)
            ? `/${requirements.regular_from_date}/${requirements.regular_to_date}`
            : ``
        }`;
        const res = await axios(reports_url);
        setReportResults(res.data.results);
      } catch (e) {
        console.log("reports in branch: ", e);
      }
    } else {
      try {
        const reports_url = `api/reports/class/${requirements.branch_id}/${
          requirements.delivery_type_id
        }/${requirements.program_type_id}/${requirements.active_id}/${
          requirements.day
        }${
          ![1].includes(requirements.active_id) &&
          !["1"].includes(requirements.active_id)
            ? `/${requirements.regular_from_date}/${requirements.regular_to_date}`
            : ``
        }`;
        const res = await axios(reports_url);
        setReportResults(res.data.results);
      } catch (e) {
        console.log("reports in branch: ", e);
      }
    }
  };

  const generateCampReport = async (requirements) => {
    try {
      const reports_url = `api/reports/camp/${requirements.branch_id}/${requirements.delivery_type_id}/${requirements.program_type_id}/${requirements.startdate}/${requirements.enddate}`;
      const res = await axios(reports_url);

      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateCouponReport = async (requirements) => {
    try {
      const reports_url = `api/reports/coupon/${requirements.batch_type}/${requirements.branch_id}/${requirements.coupon_name}/${requirements.selected_date}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateTrialReport = async (requirements) => {
    try {
      const reports_url = `api/reports/trial/${requirements.branch_id}/${requirements.completed}/${requirements.selected_date}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generatePaymentReport = async (requirements) => {
    try {
      const reports_url = `api/reports/payment?selected_date=${requirements.selected_date}&branch_id=${requirements.branch_id}`;
      const res = await axios(reports_url);
      setReportResults(res.data.result);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateAttendanceReport = async (requirements) => {
    try {
      const reports_url = `api/reports/${userAuth[0].user_id}/batches/attendance?startDate=${requirements.startdate}&endDate=${requirements.enddate}&studentId=${requirements.student_id}&branch_id=${requirements.branch_id}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateUnpaidReport = async (requirements) => {
    try {
      const reports_url = `api/reports/monthly-payment/${
        requirements.batch_type == "1" ? "batch" : "camp"
      }${
        requirements.batch_type == "1" ? "/" + requirements.selected_month : ""
      }/${requirements.branch_id}`;
      const res = await axios(reports_url);
      setReportResults(res.data.result);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateReportClicked = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      generateReport(null);
    }
  };

  const generateCompletedCoursesReport = async (requirements) => {
    setLoading(true);
    try {
      const completed_courses_url = `api/reports/coursecompletion?branchId=${requirements.branch_id}&startDate=${requirements.startdate}&endDate=${requirements.enddate}`;
      const res = await axios(completed_courses_url);
      setReportResults(res.data);
    } catch (e) {
      console.log("Completed Courses Report Exception: ", e);
    }
    setLoading(false);
  };

  const generateCourseChallengeReport = async (requirements) => {
    setLoading(true);
    try {
      const course_challenge_url = `api/reports/course-challenges?branchId=${requirements.branch_id}&studentId=${requirements.studentId}&batchId=${requirements.batchId}`;
      const res = await axios(course_challenge_url);

      setReportResults(res.data);
    } catch (e) {
      console.log("Course Challenge Report Exception: ", e);
    }
    setLoading(false);
  };
  const generateOOOReport = async (requirements) => {
    setLoading(true);
    try {
      const ooo_url = `api/reports/ooo-hours?branch_id=${requirements.branch_id}&start_date=${requirements.startdate}&end_date=${requirements.enddate}`;
      const res = await axios(ooo_url);
      setReportResults(res.data.result);
    } catch (e) {
      console.log("OOO Report Exception: ", e);
    }
    setLoading(false);
  };
  const generateStudentFactReport = async (requirements) => {
    setLoading(true);
    try {
      const student_fact_url = `api/reports/getStudentFact?branchId=${requirements.branch_id}`;
      const res = await axios(student_fact_url);
      setReportResults(res.data);
    } catch (e) {
      console.log("Student Fact Exception: ", e);
    }
    setLoading(false);
  };

  const generateMaintenanceReport = async (requirements) => {
    setLoading(true);
    try {
      const maintenance_url = `api/branch-course/get-full-csv`;
      const res = await axios(maintenance_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("Maintenance Report Exception: ", e);
    }
    setLoading(false);
  };

  const generateLmsReport = async (requirements) => {
    setLoading(true);
    try {
      // remember to update query after table is done
      const lms_report_url = `api/reports/lmsreport/${requirements.startdate}/${requirements.enddate}/${requirements.branch_id}`;
      const res = await axios(lms_report_url);

      setReportResults(res.data);
    } catch (err) {
      console.log("Lms Report Error: ", err);
    }
    setLoading(false);
  };

  const generateCampAttendanceReport = async (requirements) => {
    try {
      const reports_url = `api/reports/${userAuth[0].user_id}/camps/attendance?startDate=${requirements.startdate}&endDate=${requirements.enddate}&studentId=${requirements.student_id}&branch_id=${requirements.branch_id}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateCourseEnrollmentVsDobReport = async (requirements) => {
    setLoading(true);
    try {
      const courseEnrollmentVsDob_url = `api/reports/enrollmentVsDob/${requirements.startdate}/${requirements.branch_id}`;
      const res = await axios(courseEnrollmentVsDob_url);
      setReportResults(res.data);
    } catch (e) {
      console.log("Course Enrollment VS DOB Report Exception: ", e);
    }
    setLoading(false);
  };

  const generateCampEnrollmentVsDobReport = async (requirements) => {
    setLoading(true);
    try {
      const campEnrollmentVsDob_url = `api/reports/campEnrollmentVsDob/${requirements.startdate}/${requirements.branch_id}`;
      const res = await axios(campEnrollmentVsDob_url);
      setReportResults(res.data);
    } catch (e) {
      console.log("Camp Enrollment VS DOB Report Exception: ", e);
    }
    setLoading(false);
  };

  const generateLMSAccountsReport = async (requirements) => {
    try {
      const reports_url = `api/reports/${userAuth[0].user_id}/LMSaccounts?startDate=${requirements.startdate}&endDate=${requirements.enddate}&studentId=${requirements.student_id}&branch_id=${requirements.branch_id}&batch_type=${requirements.batch_type}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const generateLMSvsPortalActivityReport = async (requirements) => {
    try {
      const reports_url = `api/reports/${userAuth[0].user_id}/LMSvsPortalActivity?startDate=${requirements.startdate}&endDate=${requirements.enddate}&studentId=${requirements.student_id}&branch_id=${requirements.branch_id}&batch_type=${requirements.batch_type}`;
      const res = await axios(reports_url);
      setReportResults(res.data.results);
    } catch (e) {
      console.log("reports in branch: ", e);
    }
  };

  const onHideEmail = () => {
    setShowEmail(false);
  };

  return (
    <Fragment>
      {reportResults &&
        reportResults.length > 0 &&
        ["1", "2"].includes(reportTypeId) && (
          <>
            <ReportsEmailDialog
              reportEmails={getEmailsByReport(reportResults)}
              branch_id={branch.branch_id}
              showEmail={showEmail}
              onHideEmail={onHideEmail}
            />
          </>
        )}
      <Card>
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={generateReportClicked}
            >
              Generate Report
            </button>
            {`  `}
            {reportResults && reportResults.length > 0 && reportTypeId !== "0" && (
              <button type="button" className="btn btn-light ml-1">
                <CSVLink
                  filename={`${branch.branch_name} - ${selectedReport.label} - Report.csv`}
                  data={reportResults}
                  headers={
                    reportTypeId == "7"
                      ? reportResults[0].month
                        ? selectedReport.csvHeaders[0]
                        : selectedReport.csvHeaders[1]
                      : selectedReport.csvHeaders
                  }
                >
                  Download Report
                </CSVLink>
              </button>
            )}
            {reportResults &&
              reportResults.length > 0 &&
              ["1", "2"].includes(reportTypeId) && (
                <>
                  <button
                    type="button"
                    className="btn btn-icon btn-light btn-hover-danger mr-1"
                    onClick={() => {
                      setShowEmail(true);
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail.svg"
                        )}
                      ></SVG>
                    </span>
                  </button>
                </>
              )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                New Report
              </a>
            </li>
          </ul>
          <div className="mt-5">
            {values && (
              <NewReportForm
                reportTypeId={reportTypeId}
                reportResults={reportResults}
                setReportResults={setReportResults}
                setReportTypeId={setReportTypeId}
                report={values}
                btnRef={btnRef}
                generateReport={generateReport}
                selectedReport={selectedReport}
              />
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        {loading ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={7}>
                  <LinearProgress color="secondary" styles="width:100%" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <CardBody>
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "1" && (
                <ClassReportTable reports={reportResults} showActions={false} />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "2" && (
                <CampReportTable reports={reportResults} showActions={false} />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "3" && (
                <CouponReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "5" && (
                <PaymentReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "6" && (
                <AttendanceReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "7" && (
                <UnpaidReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "8" && (
                <CompletedCoursesReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "9" && (
                <CourseChallengeReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "10" && (
                <OOOReportTable reports={reportResults} showActions={false} />
              )}

            {reportResults &&
              Object.keys(reportResults).length > 0 &&
              reportTypeId == "12" && (
                <MaintenanceReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "13" && (
                <LmsReportTable reports={reportResults} showActions={false} />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "14" && (
                <CampAttendanceReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "15" && (
                <CourseEnrollmentVsDobReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "16" && (
                <CampEnrollmentVsDobReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "17" && (
                <LMSAccountsReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}

            {reportResults &&
              reportResults.length > 0 &&
              reportTypeId == "18" && (
                <LMSvsPortalActivityReportTable
                  reports={reportResults}
                  showActions={false}
                />
              )}
            {reportResults && reportResults.length == 0
              ? "No Results Found..."
              : ""}
          </CardBody>
        )}
      </Card>
    </Fragment>
  );
}
