/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const CustomerLeadsTableActions = ({
  row,
  openEditCustomerLeadsPage,
  openDeleteCustomerLeadsDialog,
  openActivateCustomerLeadsDialog,
  openCustomerLeadsFullDialog,
  openCustomerLeadsNotFullDialog,
  openDuplicateCustomerLeadsPage,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit Lead</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            //console.log(row);
            openEditCustomerLeadsPage(row.lead_id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
      {/*
      {row.active_id === 1 ? (
        <span>
          Asad remember to add stuff to reactivate
          <OverlayTrigger
            overlay={
              <Tooltip id="products-delete-tooltip">Deactivate Lead</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => openDeleteCustomerLeadsDialog(row.lead_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-power-off text-success"></i>
              </span>
            </a>
          </OverlayTrigger> */}
      {/* {row.full_ind !== 1 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark as Full
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => openCustomerLeadsFullDialog(row.lead_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-box-open text-primary"></i>
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark as Not Full
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                onClick={() => openCustomerLeadsNotFullDialog(row.lead_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-box text-default"></i>
                </span>
              </a>
            </OverlayTrigger>
          )} 
        </span>
      ) : (
        // <OverlayTrigger
        //   overlay={
        //     <Tooltip id="products-delete-tooltip">Activate Lead</Tooltip>
        //   }
        // >
        //   <a
        //     className="btn btn-icon btn-light btn-hover-danger btn-sm"
        //     onClick={() => openActivateCustomerLeadsDialog(row.lead_id)}
        //   >
        //     <i className="fa fa-power-off"></i>
        //   </a>
        // </OverlayTrigger>
      )}*/}

      <OverlayTrigger
        overlay={
          <Tooltip id="products-duplicate-tooltip">Duplicate Lead</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openDuplicateCustomerLeadsPage(row.lead_id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <i className="far fa-copy text-success"></i>
          </span>
        </a>
      </OverlayTrigger>
      
      <OverlayTrigger
        overlay={
          <Tooltip id="products-delete-tooltip">Delete Lead</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteCustomerLeadsDialog(row.lead_id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <i className="fa fa-trash text-danger"></i>
          </span>
        </a>
      </OverlayTrigger>
    </>
  );
};
