// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Form, Formik } from "formik";
import React from "react";
import Select from "react-select";
import { AttendanceReportForm } from "./AttendanceReport/AttendanceReportForm";
import { CampAttendanceReportForm } from "./CampAttendanceReport/CampAttendanceReportForm";
import { CampEnrollmentVsDobReportForm } from "./CampEnrollmentVsDobReport/CampEnrollmentVsDobReportForm";
import { CampReportForm } from "./CampReport/CampReportForm";
import { ClassReportForm } from "./ClassReport/ClassReportForm";
import { CompletedCoursesReportForm } from "./CompletedCoursesReport/CompletedCoursesReportForm";
import { CouponReportForm } from "./CouponReport/CouponReportForm";
import { CourseChallengeReportForm } from "./CourseChallengeReport/CourseChallengeReportForm";
import { CourseEnrollmentVsDobReportForm } from "./CourseEnrollmentVsDobReport/CourseEnrollmentVsDobReportForm";
import { LMSAccountsReportForm } from "./LMSAccountsReport/LMSAccountsReportForm";
import { LmsReportForm } from "./LmsReport/LmsReportForm";
import { LMSvsPortalActivityReportForm } from "./LMSvsPortalActivityReport/LMSvsPortalActivityReportForm";
import { MaintenanceReportForm } from "./MaintenanceReport/MaintenanceReportForm";
import { OOOReportForm } from "./OOOReport/OOOReportForm";
import { PaymentReportForm } from "./PaymentReport/PaymentReportForm";
import { useReportContext } from "./ReportDataContext";
import { TrialReportForm } from "./TrialReport/TrialReportForm";
import { UnpaidReportForm } from "./UnpaidReport/UnpaidReportForm";

export function NewReportForm({
  reportTypeId,
  reportResults,
  setReportResults,
  setReportTypeId,
  report,
  btnRef,
  generateReport,
  selectedReport,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;
  const getReportEditSchema = useReportContext().getReportEditSchema;
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={report}
        validationSchema={getReportEditSchema(reportTypeId)}
        onSubmit={(values) => {
          generateReport(values);
        }}
      >
        {({ handleSubmit, values, errors, touched, setFieldValue }) => (
          <>
            <Form className="form form-label-right">
              {
                <div className="form-group row">
                  <div className={"col-md-3"}>
                    <Select
                      name={`report_type_id`}
                      label="Report Type"
                      onChange={(e) => {
                        setFieldValue("report_type_id", e.value);
                        if (reportResults && reportResults.length > 0) {
                          setReportResults();
                        }
                        setReportTypeId(e.value);
                      }}
                      validationColorScheme={false}
                      options={DROPDOWN_TYPE.REPORT_TYPE}
                    />
                  </div>
                  <div className={"col-md-9"}>
                    <p>{selectedReport.desc}</p>
                  </div>
                </div>
              }
              {["1"].includes(reportTypeId) && (
                <ClassReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["2"].includes(reportTypeId) && (
                <CampReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["3"].includes(reportTypeId) && (
                <CouponReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["4"].includes(reportTypeId) && (
                <TrialReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["5"].includes(reportTypeId) && (
                <PaymentReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["6"].includes(reportTypeId) && (
                <AttendanceReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}

              {["7"].includes(reportTypeId) && (
                <UnpaidReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["8"].includes(reportTypeId) && (
                <CompletedCoursesReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["9"].includes(reportTypeId) && (
                <CourseChallengeReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["10"].includes(reportTypeId) && (
                <OOOReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["12"].includes(reportTypeId) && (
                <MaintenanceReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["13"].includes(reportTypeId) && (
                <LmsReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["14"].includes(reportTypeId) && (
                <CampAttendanceReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["15"].includes(reportTypeId) && (
                <CourseEnrollmentVsDobReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {["16"].includes(reportTypeId) && (
                <CampEnrollmentVsDobReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}

              {["17"].includes(reportTypeId) && (
                <LMSAccountsReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}

              {["18"].includes(reportTypeId) && (
                <LMSvsPortalActivityReportForm
                  reportResults={reportResults}
                  setReportResults={setReportResults}
                  setReportTypeId={setReportTypeId}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
