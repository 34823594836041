/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export const InstructorProfileTableActions = ({
  row,
  openEditInstructorProfilePage,
  openActivateInstructorProfileDialog,
  openDeactivateInstructorProfileDialog,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit Profile</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            openEditInstructorProfilePage(row.instructor_profile_id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>

        <span>
          {/* Asad remember to add stuff to reactivate */}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-delete-tooltip"> {row.active_id === 1 ?"Deactivate" : "Activate"} Profile</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() =>row.active_id === 1 ? openDeactivateInstructorProfileDialog(row.instructor_profile_id) : openActivateInstructorProfileDialog(row.instructor_profile_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className={`fa fa-power-off text-${row.active_id === 1 ?"success" : "danger"}`}></i>
              </span>
            </a>
          </OverlayTrigger>
        </span>
    </>
  );
};
