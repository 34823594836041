import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const RESET_PASSWORD_URL = "api/auth/update-password";
export const USER_BRANCHES_URL = "api/user/branches";
export const USER_ACCESS_URL = "api/user/access";
export const ME_URL = "api/auth/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(data) {
  var email = data.username;
  var password = data.newPassword;
  //console.log(email, password);
  return axios.put(RESET_PASSWORD_URL, { email, password });
}

export function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  axios.defaults.headers.common["x-auth-token"] = token;
  return axios.get(ME_URL);
}

export function getUserBranches(token, userid) {
  // Authorization head should be fulfilled in interceptor.
  // console.log(token);
  const headers = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    params: {
      userid: userid,
    },
  };
  return axios.get(USER_BRANCHES_URL, headers);
}

export function getUserAccess(token, userid) {
  // Authorization head should be fulfilled in interceptor.
  // console.log(token);
  const headers = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    params: {
      userid: userid,
    },
  };
  return axios.get(USER_ACCESS_URL, headers);
}
