/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import moment from "moment";
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSelector, shallowEqual } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { CustomerLeadsEditForm } from "./Components/CustomerLeadsEditForm";
import { checkAndReplace, getFormattedNumber, getVanillaPhoneNumber } from "../../helpers/Formatter";
import { useCustomerLeadsContext } from "./Components/CustomerLeadsDataContext";

export function CustomerLeadsEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const user_id = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id,
    shallowEqual
  );

  // Subheader
  const initCustomerLeads = {
    branch_id: branch.branch_id,
    lead_date: moment().format("YYYY-MM-DD"),
    trial_date: "",
    registration_date: "",
    refferal: "Google Search",
    lead_name: "",
    lead_phone: "",
    email: "",
    student_grade: "",
    student_name: "",
    course_of_interest: [],
    status: "New",
    delivery_type_id: "",
    zip: "",
    notes: "",
    batch_id: -1,
    other: "",
    present_ind: 0,
    coach_comment: "",
  };

  const stats = [
    "New",
    "Open",
    "Trial Scheduled",
    "Trial Taken",
    "Registered",
    "Not Interested",
    "On-Hold",
  ];
  const {
    AUTOSELECTCOURSE,
    setSelectedCourse,
  } = useCustomerLeadsContext().TYPES;

  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [customerLeadsToAdd, setCustomerLeadsToAdd] = useState([]);
  const [values, setValues] = useState();
  const btnRef = useRef();

  // initCustomerLeads.branch_id = branch.branch_id;

  useEffect(() => {
    const getCustomerLeadsInfo = async () => {
      const lead_info_url = `api/customer-leads/edit/${id}`;
      const res = await axios(lead_info_url);
      // console.log("Data", res.data.results);
      res.data.results[0].lead_phone =
        res.data.results[0] && res.data.results[0].lead_phone
          ? getFormattedNumber(res.data.results[0].lead_phone)
          : null;
      setData(res.data.results[0]);
      let selectCourse = AUTOSELECTCOURSE.filter((c) => {
        return c.id == res.data.results[0].course_id;
      })[0];
      setSelectedCourse(selectCourse);
    };
    id && AUTOSELECTCOURSE && getCustomerLeadsInfo();
  }, [id, AUTOSELECTCOURSE]);

  useEffect(() => {
    let newValues = data && id ? data : initCustomerLeads;
    if (id && duplicate) {
      newValues.status = initCustomerLeads.status;
      newValues.registration_date = initCustomerLeads.registration_date;
      newValues.trial_date = initCustomerLeads.trial_date;
      newValues.batch_id = initCustomerLeads.batch_id;
      newValues.course_id = initCustomerLeads.course_id;
      setSelectedCourse(null);
    } else if (!id || (id && data && !data.course_id)) {
      setSelectedCourse(null);
    }
    // console.log("New Values on Edit", newValues)
    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Lead";
    let _subTitle = id ? "" : "New Lead";
    if (id && duplicate) {
      _title = `Duplicate Lead`;
      _subTitle = _title;
    } else if (id) {
      _title = `Edit Lead`;
      _subTitle = `Edit Lead - ${
        data &&
        data.lead_name &&
        data.lead_name.length &&
        data.student_name &&
        data.student_name.length
          ? `${data.lead_name} - (${data.student_name})`
          : id
      }`;
    }

    setTitle(_subTitle);
    suhbeader.setTitle(_title);
  }, [id, data]);

  //Get list of lead in branch
  useEffect(() => {
    const getCustomerLeads = async () => {
      try {
        const customerLeads_url = `api/customer-leads/customer-leads/${id}`;
        const res = await axios(customerLeads_url);

        dest_id
          ? setCustomerLeadsToAdd(
              res.data.results.map((e) => {
                return e.lead_id;
              })
            )
          : setCustomerLeadsToAdd(customerLeadsToAdd);
      } catch (e) {
        console.log("leads in branch: ", e);
      }
    };
    id && getCustomerLeads();
  }, [id, dest_id]);

  /*
    Action: Save Endpoint
  */
  const saveCustomerLeads = async (values) => {
    values.modify_by = user_id;

    console.log("Sending values", values);
    if (values) {
      values.lead_name = values.lead_name
        ? checkAndReplace(values.lead_name.trim())
        : "";
      values.notes = values.notes ? checkAndReplace(values.notes.trim()) : "";
      values.other = values.other ? checkAndReplace(values.other.trim()) : "";
      values.student_name = values.student_name
        ? checkAndReplace(values.student_name.trim())
        : "";
      values.lead_phone = getVanillaPhoneNumber(values.lead_phone);
      values.zip = values.zip.trim();
      if (values.coach_comment) {
        values.coach_comment = checkAndReplace(values.coach_comment.trim());
      }
    }

    if (!id) {
      //save new lead post
      const leadsUrl = "api/customer-leads/";
      // console.log("post")

      const result = await axios.post(leadsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push("/customer-leads/customer-leads-list");
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && !dest_id) {
      // console.log("duplicate post")
      //save new duplicated lead post
      values.branch_id = branch.branch_id;
      const leadsUrl = "api/customer-leads/";

      const result = await axios.post(leadsUrl, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/customer-leads/${result.data}/edit-customer-leads`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && dest_id) {
      values.branch_id = branch.branch_id;
      // console.log("duplicate patch")

      //edit duplicate lead patch
      const editLeadsUrl = `api/customer-leads/edit/`;
      const result = await axios.patch(editLeadsUrl + id, values);

      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/customer-leads/customer-leads-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    } else {
      //edit lead patch
      // console.log("patch")
      values.coi_added = values.course_of_interest.filter((c) => {
        return (
          data.course_of_interest.filter((old_c) => {
            return c.value == old_c.value;
          }).length <= 0
        );
      });
      values.coi_removed = data.course_of_interest.filter((c) => {
        return (
          values.course_of_interest.filter((new_c) => {
            return c.value == new_c.value;
          }).length <= 0
        );
      });
      values.lead_student_id = data.lead_student_id;
      values.lead_student_trial_id = data.lead_student_trial_id;

      const editCustomerLeadsUrl = `api/customer-leads/edit/`;
      const result = await axios.patch(editCustomerLeadsUrl + id, values);
      // console.log("HERE WE GO", result.data)
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/customer-leads/customer-leads-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveCustomerLeadsClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveCustomerLeads(null);
    }
  };

  const backToCustomerLeadsList = () => {
    history.push(`/customer-leads`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the lead successfully"
              : editSuccessOpen
              ? "Edited the lead successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToCustomerLeadsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveCustomerLeadsClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Lead
              </a>
            </li>
          </ul>
          <div className="mt-5">
            {values && (
              <CustomerLeadsEditForm
                id={id}
                stats={stats}
                lead={values}
                setLead={setValues}
                btnRef={btnRef}
                saveCustomerLeads={saveCustomerLeads}
                tab={tab}
                setTab={setTab}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
