import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../../_metronic/layout";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import { TimesheetFilter } from "../Components/TimesheetFilter";
import SubmissionTable from "../Components/SubmissionTable";
import { DownloadTimesheetDialog } from "../Components/DownloadModal";
import moment from "moment";
import ConfirmationModal from "../Components/ConfirmationModal";
import { Snackbar } from "@material-ui/core";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";

const TIMESHEET_URL = "api/timesheet";

export const SubmissionList = ({ history }) => {
  const [submissionData, setSubmissionData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const timesheetStates = useTimesheetContext().states;

  const { currentBranch, branchAccessList, userId } = useSelector(
    ({ auth }) => ({
      currentBranch: auth.branches.currentBranch,
      branchAccessList: auth.branches && auth.branches.branchList,
      userId: auth.user && auth.user[0].user_id,
    }),
    shallowEqual
  );

  const suhbeader = useSubheader();
  suhbeader.setTitle("Timesheet Submissions");

  const fetchTimesheetSubmissions = async (
    startDate,
    endDate,
    branchIds = [currentBranch.branch_id]
  ) => {
    setLoading(true);
    const users = [];
    let response = { status: 0 };

    if (!startDate && !endDate) {
      startDate = timesheetStates.startDate;
      endDate = timesheetStates.endDate;
    }

    try {
      response = await axios.get(
        `${TIMESHEET_URL}/submissions?branchIds=${branchIds.join(",")}&startDate=${startDate}&endDate=${endDate}`);
      // console.log("response", response);

      if (response.status === 200) {
        setOrigData(response.data);
        if (response.data.length === 0) {
          // setErrorSnackbarMessage(`No timesheet submissions found for ${startDate} to ${endDate}`);
        }
      } else {
        setErrorSnackbarMessage("Error downloading timesheet data");
      }
      
    } catch (error) {
      setErrorSnackbarMessage("Error downloading timesheet data");
    }
    timesheetStates.setRefetch(false);
    setLoading(false);

    return response.status && response.status === 200;
  };

  const handleClose = () => {
    setSuccessSnackbarMessage("");
    setErrorSnackbarMessage("");
    setShowSnackbar(false);
  };

  const filterData = async (filters, refetch = false) => {
    if (refetch && userId) {
      await fetchTimesheetSubmissions(filters.startDate, filters.endDate, filters.branch_ids);
    }
    if (Array.isArray(origData)) {
      const newData = origData.filter(function(timesheet) {
        var add = true;

        for (let key in filters) {
          if (filters[key] != "") {
            // approved Filter
            if (key === "approved") {
              if (
                filters[key] != "2" &&
                timesheet.approval_status != filters[key]
              ) {
                add = false;
              }
            }

            // user Filter
            if (key === "user_name") {
              if (timesheet.user_name != filters[key]) {
                add = false;
              }
            }
          }
        }
        return add;
      });
      // console.log("newData", newData);
      setSubmissionData(newData);
    }
    return true;
  };

  useEffect(() => {
    const loadSnackbarSuccess = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    successSnackbarMessage &&
      successSnackbarMessage != "" &&
      loadSnackbarSuccess();
  }, [successSnackbarMessage]);

  useEffect(() => {
    const loadSnackbarError = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    errorSnackbarMessage && errorSnackbarMessage != "" && loadSnackbarError();
  }, [errorSnackbarMessage]);

  useEffect(() => {
    if (userId) {
      timesheetStates.setRefetch(true);
    }
  }, [userId]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successSnackbarMessage != "" ? "success" : "error"}
          message={
            successSnackbarMessage != ""
              ? successSnackbarMessage
              : errorSnackbarMessage
          }
        />
      </Snackbar>
      <fieldset disabled={isLoading}>
        <div className={`card card-custom`}>
          {/* begin::Header */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Submission Filter
              </span>
            </h3>
            <div className="card-toolbar">
              {/* Opem DownloadTimesheetDialog */}
              <Link to="/timesheet/submissions/download">
                <button className="btn btn-sm btn-primary font-weight-bolder">
                  Download Submissions
                </button>
              </Link>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body py-0">
            <TimesheetFilter
              history={history}
              filterData={filterData}
              origData={origData}
              isLoading={isLoading}
              branchAccessList={branchAccessList}
              currentBranch={currentBranch}
            />
          </div>
        </div>

        {/* <UserTable users={userData} showActions={true} loading={context.isLoading} /> */}
        <SubmissionTable
          isLoading={isLoading}
          setLoading={setLoading}
          rows={submissionData}
          history={history}
        ></SubmissionTable>

        <ContentRoute path="/timesheet/submissions/download">
          {({ history, match }) => (
            <DownloadTimesheetDialog
              history={history}
              origData={origData}
              show={match != null}
              id={match && match.params.id}
              setSuccessMessage={setSuccessSnackbarMessage}
              setErrorMessage={setErrorSnackbarMessage}
              branchAccessList={branchAccessList}
              TIMESHEET_URL={TIMESHEET_URL}
              onHide={() => {
                history.push("/timesheet/submissions");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/timesheet/submissions/:timesheet_week_id/decline">
          {({ history, match }) => (
            <ConfirmationModal
              show={match != null}
              id={match && match.params.id}
              setSuccessMessage={setSuccessSnackbarMessage}
              setErrorMessage={setErrorSnackbarMessage}
              onHide={() => {
                history.push("/timesheet/submissions");
              }}
              title="Decline Timesheet"
              message="Are you sure you want to decline this timesheet?"
              hint="This will remove the timesheet from the submissions list."
              handleAction={() => {
                setLoading(true);
                const setApprove = async () => {
                  return await axios.post(
                    `/api/timesheet/${match &&
                      match.params.timesheet_week_id}/request?status=false`
                  );
                };
                setApprove()
                  .then((res) => {
                    if (res.status == 200) {
                      setSuccessSnackbarMessage("Timesheet Declined");
                      history.push("/timesheet/submissions");
                      timesheetStates.setRefetch(true);
                    } else {
                      setErrorSnackbarMessage("Error Declining Timesheet");
                    }
                  })
                  .catch(() => {
                    setLoading(false);
                    setErrorSnackbarMessage("Error declined timesheet");
                  });
              }}
            />
          )}
        </ContentRoute>
      </fieldset>
    </div>
  );
};
