/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function DashboardActionsWidget() {
  
  const user_id = useSelector((state) => state.auth.user && state.auth.user[0].user_id, shallowEqual);
  const access = useSelector((state) => state.auth.access, shallowEqual);
  return (
    <>
      {/* begin::Quick Actions Panel */}
      <div id=" " className=" card card-custom bg-gray-100 p-10">
        {/* begin::Header */}
        <div className=" card-title d-flex align-items-center justify-content-between pb-10">
          <h3 className="font-weight-bold m-0">
            Quick Actions
            <small className="text-muted font-size-sm ml-2"></small>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className=" pr-5 mr-n5">
          <div className="row gutter-b">

            {/* <p>
              Welcome to the Zebra portal. Dashboard is still under development,
              please use the top menu links.
            </p> */}
            {/* begin::Item */}

            {access["registration"] != undefined && (
              <div className="col-6 col-sm-4 col-md-3 col-xl-6">
                <Link
                  to={"/new-registration/new"}
                  className="btn btn-block btn-light-warning btn-hover-warning text-dark-50 text-center py-8 px-5"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-light-warning m-0">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                    />
                  </span>
                  <span className="d-block font-weight-bold font-size-h6 mt-2">
                    New Family
                  </span>
                </Link>
              </div>
            )}

            {/* end::Item */}

            {/* begin::Item */}
            {(access["customer_leads"] != undefined || [8219, 7661, 6724, 4215].includes(user_id)) && (
            <div className="col-6 col-sm-4 col-md-3 col-xl-6">
              <Link
                to="/customer-leads/add-customer-leads"
                className="btn btn-block btn-light-warning btn-hover-warning text-dark-50 text-center py-8 px-5"
              >
                <span className="svg-icon svg-icon-xl svg-icon-light-warning m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Star.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Add Trial Class
                </span>
              </Link>
            </div>
            )}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <div className="col-6">
              <Link
                to="/add-student"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Mail-attachment.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Add Student
                </span>
              </Link>
            </div> */}
            {/* end::Item */}
          </div>
          <div className="row gutter-b">
            {/* begin::Item */}
            {access["timesheet"] != undefined && access["timesheet"] === 0 && (
              <div className="col-6 col-sm-4 col-md-3 col-xl-6">
                <Link
                  to={"/timesheet"}
                  className="btn btn-block btn-light-warning btn-hover-warning text-dark-50 text-center py-8 px-5"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-light-warning m-0">
                    <i className="fa fa-business-time"></i>
                  </span>
                  <span className="d-block font-weight-bold font-size-h6 mt-2">
                    TimeSheet
                  </span>
                </Link>
              </div>
            )}
            {access["timesheet_approver"] != undefined && access["timesheet"] === 0 && (
              <div className="col-6 col-sm-4 col-md-3 col-xl-6">
                <Link
                  to={"/timesheet/submissions"}
                  className="btn btn-block btn-light-warning btn-hover-warning text-dark-50 text-center py-4 px-5"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-light-warning m-0">
                    <i className="fa fa-business-time"></i>
                  </span>
                  <span className="d-block font-weight-bold font-size-h6 mt-2 mb-0">
                    TimeSheet Submissions
                  </span>
                </Link>
              </div>
            )}
            {/* <div className="col-6">
              <a
                href="/batches/add-batch"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Add Class
                </span>
              </a>
            </div> */}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Add Employee
                </span>
              </a>
            </div> */}
            {/* end::Item */}
          </div>
          <div className="row gutter-b">
            {/* begin::Item */}
            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Chart-pie.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  TimeSheet
                </span>
              </a>
            </div> */}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Design/Color-profile.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Settings
                </span>
              </a>
            </div> */}
            {/* end::Item */}
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::Quick Actions Panel */}
    </>
  );
}
