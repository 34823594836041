/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const user_id = useSelector(
    (state) => state.auth.user && state.auth.user[0].user_id,
    shallowEqual
  );
  const access = useSelector((state) => state.auth.access, shallowEqual);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {access["batches"] != undefined && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/google-material"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="menu-text">Batches</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                <li
                  className={`menu-item menu-item-rel ${getMenuItemActive(
                    "/batches"
                  )}`}
                >
                  <NavLink className="menu-link" to="/batches">
                    <span className="menu-text">Regular Classes</span>
                    {layoutProps.rootArrowEnabled && (
                      <i className="menu-arrow" />
                    )}
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-rel ${getMenuItemActive(
                    "/camps"
                  )}`}
                >
                  <NavLink className="menu-link" to="/camps">
                    <span className="menu-text">Camps</span>
                    {layoutProps.rootArrowEnabled && (
                      <i className="menu-arrow" />
                    )}
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {(access["branch_course"] != undefined ||
          access["branch-maintenance"] != undefined) && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/google-material"
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
              <span className="menu-text">Admin Options</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                <li
                  className={`menu-item menu-item-rel ${getMenuItemActive(
                    "/branch-course"
                  )}`}
                >
                  <NavLink className="menu-link" to="/branch-course">
                    <span className="menu-text">Branch Streams</span>
                    {layoutProps.rootArrowEnabled && (
                      <i className="menu-arrow" />
                    )}
                  </NavLink>
                </li>

                <li
                  className={`menu-item menu-item-rel ${getMenuItemActive(
                    "/branch-maintenance"
                  )}`}
                >
                  <NavLink className="menu-link" to="/branch-maintenance">
                    <span className="menu-text">Branch Maintenance</span>
                    {layoutProps.rootArrowEnabled && (
                      <i className="menu-arrow" />
                    )}
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-rel ${getMenuItemActive(
                    "/staff"
                  )}`}
                >
                  <NavLink className="menu-link" to="/users">
                    <span className="menu-text">Staff</span>
                    {layoutProps.rootArrowEnabled && (
                      <i className="menu-arrow" />
                    )}
                  </NavLink>
                </li>
                {(access["reporting"] != undefined ||
                  [8219, 7661, 6724].includes(user_id)) && (
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/reporting"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/reporting">
                      <span className="menu-text">Analytics</span>
                      {layoutProps.rootArrowEnabled && (
                        <i className="menu-arrow" />
                      )}
                    </NavLink>
                  </li>
                )}
                {access["customer_leads"] !== undefined && (
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/customer-leads"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/customer-leads">
                      <span className="menu-text">Customer Leads</span>
                      {layoutProps.rootArrowEnabled && (
                        <i className="menu-arrow" />
                      )}
                    </NavLink>
                  </li>
                )}
                {(access["web_transactions"] !== undefined ||
                  [8219].includes(user_id)) && (
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/web-transactions"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/web-transactions">
                      <span className="menu-text">Web Transactions</span>
                      {layoutProps.rootArrowEnabled && (
                        <i className="menu-arrow" />
                      )}
                    </NavLink>
                  </li>
                )}
                <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/emails"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/emails">
                      <span className="menu-text">Email</span>
                      {layoutProps.rootArrowEnabled && (
                        <i className="menu-arrow" />
                      )}
                    </NavLink>
                  </li>
              </ul>
            </div>
          </li>
        )}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {access["coupons"] != undefined && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/coupons"
            )}`}
          >
            <NavLink className="menu-link" to="/coupons">
              <span className="menu-text">Coupons</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {access["events"] != undefined && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/events"
            )}`}
          >
            <NavLink className="menu-link" to="/events">
              <span className="menu-text">Events</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
