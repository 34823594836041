/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddStudentForm from "./AddStudentForm";
import { StudentDuplicateDialog } from "./StudentDuplicateDialog";
import { checkAndReplace } from "../../helpers/Formatter";

export const AddStudent = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const location = useLocation();
  // Fields

  const [student, setStudent] = useState(); //If it's not a new student, this will get the student info from the db
  const [students, setStudents] = useState([]); //list of possible students you can choose when adding a student
  const [duplicates, setDuplicates] = useState([]); //list of possible duplicate students when adding a student
  const [parents, setParents] = useState([]); //list of possible parents to associate with the student
  const [existingParents, setExistingParents] = useState(); //list of parents that are already add to this student in the db
  const [existingValues, setExistingValues] = useState(); //the info about the student in the db
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [studentActivity, setStudentActivity] = useState([])//the list of active courses for the student (used to see if we can de-activate)
  const [duplicateKnown, setDuplicateKnown] = useState(false);
  const family = useSelector((state) => state.family); //the family redux containing students, parents, and primary parent info
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  ); //get the currently selected branch so we only display students and parents for this branch

  const studentsUrl = "/api/students/"; //end point to get list of all possible students
  const getStudentUrl = "/api/user/"; //end point to get specfic student info
  const duplicateCheckUrl = "/api/user/student-duplicate-check"; //end point to get specfic student info
  const getstudentsParentsUrl = "/api/family-view/get/student/parent/"; //end point to get all the parents for a student in the db

  const initialValues = {
    active_id: 1,
    firstname: "",
    lastname: "",
    dob: "0000-00-00",
    school: "",
    allergies: "",
    special_needs: "",
    studentType: "",
    password: "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea",
  }; //all the values needed for a new student

  //get the students in the db to set students state
  async function fetchStudentsForCurrentBranch() {
    if (branch) {
      const studentResult = await axios(studentsUrl + branch.branch_id);

      setStudents(studentResult.data.results);
    } else {
      setStudents([]);
    }
  }

  //get the duplicate students in the db
  const duplicateCheck = async (
    values,
    setStatus,
    setSubmitting,
    parentChosen
  ) => {
    if (duplicateKnown === false) {
      const studentResult = await axios(
        duplicateCheckUrl + "/ " + values.firstname + "/ " + values.lastname
      );

      if (
        studentResult.data.results &&
        studentResult.data.results.length > 0 &&
        values.studentType != "existing"
      ) {
        setShowDuplicateDialog(true);
        setDuplicates(studentResult.data.results);
      } else {
        saveStudent(values, setStatus, setSubmitting, parentChosen);
      }
    } else {
      saveStudent(values, setStatus, setSubmitting, parentChosen);
    }
  };

  /* get the parents for the selected student in the db to set parents state
    Note: Here we will take the parents in the redux,
          and the parents in the db and make the parents state the things in common
  */
  async function fetchParentsForStudents() {
    if (id) {
      const result = await axios(getstudentsParentsUrl + id);
      let parentsInBoth = [];
      let parentChoices = [...family.parents];

      result.data.results &&
        result.data.results.map((potentialParent) => {
          parentChoices.filter((p) => {
            return p.user_id == potentialParent.user_id;
          }).length > 0
            ? parentsInBoth.push(potentialParent)
            : (potentialParent = potentialParent);
        });

      setParents(family.parents);
      setExistingParents(parentsInBoth);
    } else {
      setExistingParents(family.parents);
      setParents(family.parents);
    }
  }

  //get student data from the db and set the student (state) and the existing values (state)
  async function getStudent(idParam) {
    const res = await axios(getStudentUrl + "student/" + idParam);

    if (id && !student) {
      setStudent(res.data.results[0]);
    }
    let values = res
      ? {
          active_id: res.data.results[0].active_id,
          firstname: res.data.results[0].firstname,
          lastname: res.data.results[0].lastname,
          dob: moment(res.data.results[0].dob, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
          school: res.data.results[0].school ? res.data.results[0].school : "",
          allergies: res.data.results[0].allergies,
          special_needs: res.data.results[0].special_need
            ? res.data.results[0].special_need
            : "",
          gender: res.data.results[0].gender,
          studentType: "existing",
          //zebra123 encrypted
          password:
            "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea",
        }
      : {};
    setExistingValues(values);
  }

  //get whether the student is active or not. So we can allow in-activation
  const getStudentActivity = async (id) => {
    const url = `api/family-view/student-activity/${id}`;
    // console.log(url)
    const res = await axios(url);
    // console.log(res.data);
    setStudentActivity(res.data);
  };

  //when branch global state is changed/set get the list of students
  useEffect(() => {
    fetchStudentsForCurrentBranch();
  }, [branch]);

  //when student state is changed/set get the list of parents for that student, and set the existing values
  useEffect(() => {
    fetchParentsForStudents();
    if (student) {
      getStudent(student.user_id);
      getStudentActivity(student.user_id);
    }
  }, [student]);

  //when the ID exists (we are in edit mode). get the student and set the student variable (this will trigger the fetch for parents and existing values)
  useEffect(() => {
    id && getStudent(id);
    id && getStudentActivity(id);
  }, [id]);

  /*
    When the form is complete save the student to the db (read more in the function)
    values - the values from formik (assuming it's a new student)
    setStatus - formik status state
    setSubmitting - formik submitting state
    parentsChosen - an array of values and labels from the parents select (multi-select)
    primaryParent - a single value and label from the primary parent select
  */
  const saveStudent = async (
    values,
    setStatus,
    setSubmitting,
    parentsChosen
  ) => {
    let primaryParent = family.primaryParent;

    /*
      to figure out which parents are being added
        If you are a new child - all the parents in parentsChosen are new
        Otherwise, compare parentsChosen (param) to existingParents (state)
    */
    let parentsToAdd = !id
      ? parentsChosen.map((p) => {
          return p.value;
        })
      : parentsChosen
          .filter((pc) => {
            return (
              existingParents.filter((ep) => {
                return pc.value == ep.user_id;
              }).length == 0
            );
          })
          .map((pc) => {
            return pc.value;
          });

    /*
      to figure out if primary parents is changed (this is used by the db since primary parents needs to have the ind on)
            if you are a new child then by default the parent is being changed
            see if the primaryParent (param) value exists in the existingParents (state)
    */

    /*
      to figure out which parents are being removed
        If you are a new child - there are no parents to remove
        Otherwise, compare existingParents (state) to parentsChosen (param)
    */
    let parentsToRemove = !id
      ? []
      : existingParents
          .filter((ep) => {
            return ep.user_id == primaryParent.user_id
              ? 0
              : parentsChosen.filter((pc) => {
                  return ep.user_id == pc.value;
                }).length == 0;
          })
          .map((ep) => {
            return ep.user_id;
          });

    //if you are an existing student the final values include the existingValues, otherwise use the values from formik
    let finalValues = id
      ? {
          ...values,
          adding: parentsToAdd,
          removing: parentsToRemove,
          primaryChange: 0,
          primary_parent_id: primaryParent.user_id,
          id: family.searchid,
          //active_id: 1,
          branch: branch.branch_id,
        }
      : values.studentType == "existing"
      ? {
          ...existingValues,
          adding: parentsToAdd,
          removing: parentsToRemove,
          primaryChange: 1,
          primary_parent_id: primaryParent.user_id,
          id: family.searchid,
          //active_id: 1,
          branch: branch.branch_id,
        }
      : {
          adding: parentsToAdd,
          removing: parentsToRemove,
          primaryChange: 1,
          primary_parent_id: primaryParent.user_id,
          id: family.searchid,
          ...values,
          //active_id: 1,
          branch: branch.branch_id,
        };


    
        finalValues.firstname = checkAndReplace(finalValues.firstname);
        finalValues.lastname = checkAndReplace(finalValues.lastname);
        finalValues.school = checkAndReplace(finalValues.school);
        finalValues.allergies = checkAndReplace(finalValues.allergies);
        finalValues.special_needs = checkAndReplace(finalValues.special_needs);
    //if there is no id (not in edit more) and the student type if new, then call post, otherwise patch
    if (!id && values.studentType == "new") {
      //add new student post
      const studentUrl = "api/user/student";
      const result = await axios.post(studentUrl, finalValues);
      if (result.data.res === "success") {
        //setSuccessOpen(true);

        history.push({
          pathname: `/family-profile/${result.data.id}/family-overview`,
        });
      }
    } else {
      let patchStudentUrl = "api/user/student/" + (id ? id : student.user_id);

      const result = await axios.patch(patchStudentUrl, finalValues);
      if (result.data.res === "success") {
        //setSuccessOpen(true);

        history.push({
          pathname: `/family-profile/${result.data.id}/family-overview`,
        });
      }
      //patch request
    }
  };

  /*
    If there is a family, and parents in the family, and an id (in edit more) and there are existingValues, 
      then render edit (using the existingValues)
      otherwise, render the add form, using the initialValues
  */
  return family && family.parents && id && existingValues ? (
    <div>
      {console.log("id: ", id)}
      <AddStudentForm
        id={id}
        initialValues={existingValues}
        parents={parents}
        student={student}
        setStudent={setStudent}
        duplicateCheck={duplicateCheck}
        saveStudent={saveStudent}
        students={students}
        existingParents={existingParents}
        studentActivity={studentActivity}
      />
    </div>
  ) : (
    <div>
      <AddStudentForm
        id={id}
        initialValues={initialValues}
        parents={parents}
        student={student}
        setStudent={setStudent}
        duplicateCheck={duplicateCheck}
        saveStudent={saveStudent}
        students={students}
        existingParents={existingParents}
      />
      <StudentDuplicateDialog
        students={duplicates}
        duplicateKnown={duplicateKnown}
        setDuplicateKnown={setDuplicateKnown}
        onHide={() => {
          setShowDuplicateDialog(!showDuplicateDialog);
        }}
        show={showDuplicateDialog && !duplicateKnown}
      />
    </div>
  );
};
export default AddStudent;
