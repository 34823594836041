import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";

export function NoAccessError() {
  return (
    <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12 card">
      <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
        Oops!
      </h1>

      <p className="font-size-h3">
        You do not have access to this page, contact admin for access.
      </p>
    </div>
  );
}
