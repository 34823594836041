// Form is based on Formik
// Data validation is based on yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field } from "formik";
import csc from "country-state-city";

import * as yup from "yup";

import {
  Input,
  Select,
  SelectCreatable,
} from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { useUserContext } from "./UserDataContext";
import { CertificationTable } from "./CertificationTable";
import { InputWithLimit } from "../../../../_metronic/_partials/controls/forms/InputWithLimit";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// Validation schema
const UserEditSchema = yup.object().shape({
  active_id: yup.string().required("Status is required"),
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required"),
  email: yup
    .string()
    .required("Email Address is required")
    .email("Email Address is not valid"),
  mobile: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  other_phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  dob: yup
    .date()
    .test("valid-birthdate", "Date of Birth is not valid", (birthdate) => {
      if (birthdate) {
        return moment(birthdate).isBefore(moment());
      } else {
        return true;
      }
    }),
  gender: yup.string().required("Gender is required"),
  address: yup.string().required("Street Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip/Postal Code is required"),
  country: yup.string().required("Country is required"),
  role_id: yup.string().required("Role is required"),
  branches: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
      })
    )
    .required("Branch is required")
    .when("role_id", {
      is: (role_id) => role_id == 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.min(1, "Please select at least one branch"),
    }),
  tasks: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
      })
    )
    .required("Task is required")
    .when("role_id", {
      is: (role_id) => role_id == 1 || role_id == 2 || role_id == 4,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.min(1, "Please select at least one task"),
    }),
  comment:  yup.string(),
  refferal:  yup.string(),
  certificate: yup
    .array()
    .of(
      yup.object().shape({
        course: yup.string().required("Please Select A Stream"),
        status: yup.string(),
        trained_date: yup.string(),
        certified_date: yup.string(),
        proctor: yup.string(),
      })
    )
    .notRequired(),
});

const basicTabFields = [
  { value: "other_phone", label: "Emergency Phone Number" },
  { value: "mobile", label: "Primary Phone Number" },
  { value: "gender", label: "Gender" },
  { value: "dob", label: "Date of Birth" },
  { value: "email", label: "Email" },
  { value: "lastname", label: "Last Name" },
  { value: "firstname", label: "First Name" },
  { value: "active_id", label: "Status" },
];

const addressTabFields = [
  { value: "zip", label: "Zip/Postal Code" },
  { value: "state", label: "State or Province" },
  { value: "country", label: "Country" },
  { value: "city", label: "City" },
  { value: "address", label: "Street Address" },
];

const permissionsTabFields = [
  { value: "branches", label: "Branches" },
  { value: "role_id", label: "Role" },
];

export function UserEditForm({
  userValues,
  btnRef,
  saveUser,
  tab,
  setTab,
  isLoading,
  setLoading,
  setSuccessSnackbarMessage,
  setErrorSnackbarMessage,
}) {
  const DROPDOWN_ACTIVE = useUserContext().ACTIVE;
  const DROPDOWN_ROLE = useUserContext().ROLE;
  const DROPDOWN_BRANCH = useUserContext().BRANCH;
  const DROPDOWN_TASK = useUserContext().TASK;
  const TEMP_PASSWORD = useUserContext().secret;
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [tabFlag, setTabFlag] = useState(true);

  // console.log("userValues", userValues);

  const setLocalCountries = async () => {
    const local_countries = csc
      .getAllCountries()
      .filter((country) => country.isoCode == "US" || country.isoCode == "CA");
    setCountries(local_countries);
  };

  const setLocalStates = async () => {
    const local_states = csc.getStatesOfCountry(selectedCountry.isoCode);
    setStates(local_states);
  };

  const Logger = () => {
    const formik = useFormikContext();

    const setTabValidation = () => {
      tabFlag && setTabFlag(false);

      permissionsTabFields.forEach((field) => {
        if (
          formik.errors.hasOwnProperty(field.value) &&
          formik.touched[field.value]
        ) {
          setTab("permissions");
          setErrorSnackbarMessage(`${field.label} is required`);
          return false;
        }
      });

      addressTabFields.forEach((field) => {
        if (
          formik.errors.hasOwnProperty(field.value) &&
          formik.touched[field.value]
        ) {
          setTab("address");
          setErrorSnackbarMessage(`${field.label} is required`);
          return false;
        }
      });

      basicTabFields.forEach((field) => {
        if (
          formik.errors.hasOwnProperty(field.value) &&
          formik.touched[field.value]
        ) {
          setTab("basic");
          setErrorSnackbarMessage(`${field.label} is required`);
          return false;
        }
      });
    };

    useEffect(() => {
      if (tabFlag && Object.entries(formik.errors).length > 0) {
        setTabValidation();
      }
    }, [formik.errors]);

    useEffect(() => {
      formik &&
        formik.values &&
        formik.values.country &&
        setSelectedCountry(
          csc
            .getAllCountries()
            .find(
              (country) =>
                country.name.toLowerCase() ==
                formik.values.country.toLowerCase()
            )
        );
    }, [formik.values]);

    return null;
  };

  useEffect(() => {
    countries.length == 0 && setLocalCountries();
  }, [userValues]);

  useEffect(() => {
    selectedCountry && setLocalStates();
  }, [selectedCountry]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={userValues}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          saveUser(values);
        }}
      >
        {({ getFieldProps, handleSubmit, setFieldValue, values, errors, touched }) => (
          <>
            <fieldset className="form-group" disabled={isLoading}>
              <Form className="form form-label-right">
                <Logger />
                {(() => {
                  if (tab === "basic") {
                    return (
                      <>
                        <div className="form-group row">
                          <div className="col-lg-3 py-1">
                            <Select
                              name="active_id"
                              label="Status"
                              labelClasses="required-field"
                              placeholder="Select Status..."
                              options={DROPDOWN_ACTIVE.ACTIVES}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6 py-1">
                            <Field
                              name="firstname"
                              label="First Name"
                              labelClasses="required-field"
                              placeholder="First Name"
                              type="text"
                              component={Input}
                            />
                          </div>
                          <div className="col-lg-6 py-1">
                            <Field
                              name="lastname"
                              label="Last Name"
                              labelClasses="required-field"
                              placeholder="Last Name"
                              type="text"
                              component={Input}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6 py-1">
                            <Field
                              name="email"
                              label="Email Address"
                              labelClasses="required-field"
                              placeholder="Email Address"
                              type="text"
                              component={Input}
                            />
                            {values && values.email && (
                              <label className="form-text text-secondary">
                                {"Note: This email will be used to login to the system" +
                                  (values.password === TEMP_PASSWORD.hash
                                    ? ` and your default password is: ${TEMP_PASSWORD.value}`
                                    : "")}
                              </label>
                            )}
                          </div>
                          <div className="col-lg-3 py-1">
                            <Field
                              name="dob"
                              label="Date of Birth"
                              // labelClasses="required-field"
                              placeholder="Date of Birth"
                              type="date"
                              component={Input}
                            />
                          </div>
                          <div className="col-lg-3 py-1">
                            <Select
                              name="gender"
                              label="Gender"
                              labelClasses="required-field"
                              placeholder="Select Gender..."
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="nonbinary">Non-binary</option>
                              <option value="notsay">Prefer not to say</option>
                            </Select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6 py-1">
                            <Field
                              name="mobile"
                              label="Primary Phone Number"
                              labelClasses="required-field"
                              placeholder="(123) 456-7890"
                              type="text"
                              component={Input}
                            />
                          </div>
                          </div>
                        <div className="form-group row">
                          <div className="col-lg-6 py-1">
                            <Field
                              name="refferal"
                              label="Emergency Contact Name"
                              labelClasses="required-field"
                              type="text"
                              component={Input}
                            />
                          </div>
                          <div className="col-lg-6 py-1">
                            <Field
                              name="other_phone"
                              label="Emergency Phone Number"
                              // labelClasses="required-field"
                              placeholder="(123) 456-7890"
                              type="text"
                              component={Input}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }
                })()}
                {tab === "address" && (
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12 py-1">
                        <Field
                          name="address"
                          label="Street Address"
                          labelClasses="required-field"
                          placeholder="Street Address"
                          type="text"
                          component={Input}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3 py-1">
                        <Field
                          name="city"
                          label="City"
                          labelClasses="required-field"
                          placeholder="City"
                          type="text"
                          component={Input}
                        />
                      </div>
                      <div className="col-lg-3 py-1">
                        <Select
                          name="country"
                          label="Country"
                          labelClasses="required-field"
                          placeholder="Select Country..."
                          options={countries.map((country) => {
                            return {
                              value: country.name,
                              label: country.name,
                            };
                          })}
                          onChange={(option) => {
                            setFieldValue("country", option.value);
                            setFieldValue("state", "");
                            setSelectedCountry(
                              csc
                                .getAllCountries()
                                .find(
                                  (country) => country.name === option.value
                                )
                            );
                          }}
                        />
                      </div>
                      <div className="col-lg-3 py-1">
                        <Select
                          name="state"
                          label="State/Province"
                          labelClasses="required-field"
                          placeholder="Select State/Province..."
                          isDisabled={!selectedCountry}
                          options={states.map((state) => {
                            return {
                              value: state.isoCode,
                              label: state.name,
                            };
                          })}
                        />
                      </div>
                      <div className="col-lg-3 py-1">
                        <Field
                          name="zip"
                          label="Zip/Postal Code"
                          labelClasses="required-field"
                          placeholder="Zip/Postal Code"
                          type="text"
                          component={Input}
                        />
                      </div>
                    </div>
                  </>
                )}
                {tab === "permissions" && (
                  <>
                    <div className="form-group row">
                      <div className="col-lg-6 py-1">
                        <Select
                          name="role_id"
                          label="User Role"
                          labelClasses="required-field"
                          placeholder="Select Role..."
                          options={DROPDOWN_ROLE.ROLES.filter(
                            (role) => role.disabled !== true
                          )}
                        />
                      </div>
                      <div className="col-lg-6 py-1">
                        <Select
                          name="branches"
                          label="Branch(es)"
                          labelClasses={
                            values.role_id == 1 ? "" : "required-field"
                          }
                          placeholder="Select Branch(es)..."
                          options={DROPDOWN_BRANCH.BRANCHES.filter((branch) => {
                            return (
                              branch.value != "0" && branch.disabled != true
                            );
                          })}
                          isClearable={true}
                          isMulti={true}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 py-1">
                        <Select
                          name="tasks"
                          label="Task(s)"
                          labelClasses={
                            values.role_id == 2 ||
                            values.role_id == 4 ||
                            values.role_id == 1
                              ? ""
                              : "required-field"
                          }
                          placeholder="Select Task(s)..."
                          options={DROPDOWN_TASK.TASKS.filter((task) => {
                            return task.value != "0" && task.disabled != true;
                          })}
                          isClearable={true}
                          isMulti={true}
                        />
                      </div>
                    </div>
                  </>
                )}
                {tab === "certification" && (
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12 py-1">
                      <InputWithLimit
                      className={`form-control form-control-lg form-control-solid`}
                      label="Skills"
                      name="comment"
                      id="comment"
                      type="text"
                      maxLength={250}
                      displaylimit={"true"}
                      touched={touched.comment}
                      errors={errors.comment}
                      {...getFieldProps("comment")}
                      onChange={(e) => {
                        setFieldValue("comment", e.target.value);
                      }}
                    />
                      </div>
                    </div>
                    <div className="form-group row">
                      <CertificationTable
                        values={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  </>
                )}
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onClick={() => {
                    !tabFlag && setTabFlag(true);
                  }}
                  onSubmit={() => {
                    handleSubmit();
                  }}
                ></button>
              </Form>
            </fieldset>
          </>
          // <>
          //   <Form className="form form-label-right">
          //     <h4 className="form-title">
          //       {/* <i className="fa fa-user-circle-o" /> */}
          //       &nbsp;
          //       Basic Information
          //     </h4>
          //     <div className="form-group row">
          //       <div className="col-lg-6 py-1">
          //         <Select
          //           name="activeId"
          //           label="Status"
          //           labelClasses="required-field"
          //           placeholder="Select Status..."
          //           options={DROPDOWN_ACTIVE.ACTIVES}
          //         />
          //       </div>
          //     </div>
          //     <div className="form-group row">
          //       <div className="col-lg-6 py-1">
          //         <Field
          //           name="firstName"
          //           label="First Name"
          //           labelClasses="required-field"
          //           placeholder="First Name"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-6 py-1">
          //         <Field
          //           name="lastName"
          //           label="Last Name"
          //           labelClasses="required-field"
          //           placeholder="Last Name"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>

          //     </div>
          //     <div className="form-group row">
          //     <div className="col-lg-6 py-1">
          //         <Field
          //           name="email"
          //           label="Email Address"
          //           labelClasses="required-field"
          //           placeholder="Email Address"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-3 py-1">
          //         <Field
          //           name="dob"
          //           label="Date of Birth"
          //           labelClasses="required-field"
          //           placeholder="Date of Birth"
          //           type="date"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-3 py-1">
          //         <Select
          //           name="gender"
          //           label="Gender"
          //           labelClasses="required-field"
          //           placeholder="Select Gender..."
          //         >
          //           <option value="male">Male</option>
          //           <option value="female">Female</option>
          //           <option value="nonbinary">Non-binary</option>
          //           <option value="notsay">Prefer not to say</option>
          //         </Select>
          //       </div>
          //     </div>
          //     <div className="form-group row">
          //       <div className="col-lg-6 py-1">
          //         <Field
          //           name="mobile"
          //           label="Primary Phone Number"
          //           labelClasses="required-field"
          //           placeholder="(123) 456-7890"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-6 py-1">
          //         <Field
          //           name="otherPhone"
          //           label="Emergency Phone Number"
          //           placeholder="(123) 456-7890"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //     </div>
          //     <h4 className="form-title">
          //       {/* <i className="fa fa-map-marker" /> */}
          //       &nbsp;
          //       Address
          //     </h4>
          //     <div className="form-group row">
          //       <div className="col-lg-12 py-1">
          //         <Field
          //           name="address"
          //           label="Street Address"
          //           labelClasses="required-field"
          //           placeholder="Street Address"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //     </div>
          //     <div className="form-group row">
          //       <div className="col-lg-3 py-1">
          //         <Field
          //           name="city"
          //           label="City"
          //           labelClasses="required-field"
          //           placeholder="City"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-3 py-1">
          //         <Field
          //           name="country"
          //           label="Country"
          //           labelClasses="required-field"
          //           placeholder="Country"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-3 py-1">
          //         <Field
          //           name="state"
          //           label="State/Province"
          //           labelClasses="required-field"
          //           placeholder="State"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //       <div className="col-lg-3 py-1">
          //         <Field
          //           name="zip"
          //           label="Zip/Postal Code"
          //           labelClasses="required-field"
          //           placeholder="Zip Code"
          //           type="text"
          //           component={Input}
          //         />
          //       </div>
          //     </div>
          //     <h4 className="form-title">
          //       {/* <i class="fa-solid fa-plug"></i> */}
          //       &nbsp;
          //       Permissions
          //     </h4>
          //     <div className="form-group row">
          //       <div className="col-lg-6 py-1">
          //         <Select
          //           name="roleId"
          //           label="User Role"
          //           labelClasses="required-field"
          //           placeholder="Select Role..."
          //           options={DROPDOWN_ROLE.ROLES}
          //         />
          //       </div>
          //       <div className="col-lg-6 py-1">
          //         <SelectCreatable
          //           name="branches"
          //           label="Branch(es)"
          //           labelClasses="required-field"
          //           placeholder="Select Branch(es)..."
          //           options={DROPDOWN_BRANCH.BRANCHES}
          //           isClearable={true}
          //           isMulti={true}
          //         />
          //       </div>
          //     </div>

          // <button
          //   type="submit"
          //   style={{ display: "none" }}
          //   ref={btnRef}
          //   onSubmit={() => handleSubmit()}
          // ></button>
          // </Form>
          // </>
        )}
      </Formik>
    </>
  );
}
