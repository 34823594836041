// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useReportContext } from "../ReportDataContext";

export function PaymentReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return (
    <>
      <div className="form-group row">
        {/* <div className={"col-md-3"}>
                  <Select name={`batch_type`} label="Type" onChange={(e) => {
                    setFieldValue("batch_type", e.target.value)
                    if (reportResults && reportResults.length > 0) {
                        setReportResults()
                    }
                  }}>
                    
                        <option key={"1"} value={"1"}>
                          {"Regular Class"}
                        </option>
                        <option key={"2"} value={"2"}>
                          {"Camp"}
                        </option>
                  </Select>
                </div> */}
        {
          <div className={"col-lg-3"}>
            <label className={"required-field pr-2"}>Date</label>
            <OverlayTrigger
              overlay={
                <Tooltip>The day the payment was entered in the system</Tooltip>
              }
            >
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </OverlayTrigger>
            <input
              type="date"
              className={
                "form-control" +
                (errors.selected_date && touched.selected_date
                  ? " is-invalid"
                  : "")
              }
              value={
                values.selected_date ? values.selected_date.split("T")[0] : ""
              }
              onChange={(e) => {
                setFieldValue("selected_date", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            ></input>
            <ErrorMessage
              name={`selected_date`}
              component="div"
              className="invalid-feedback"
            />
          </div>
        }
      </div>
    </>
  );
}
