import { createSlice } from "@reduxjs/toolkit";

const initialFamilyState = {
  listLoading: false,
  actionsLoading: false,
  students: [],
  parents: [],
  searchid: null,
};
export const callTypes = {
  list: "list",
  action: "action",
  fetchFamily: "fetchFamily",
};

export const familySlice = createSlice({
  name: "family",
  initialState: initialFamilyState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFamilyByUserId
    familyFetched: (state, action) => {
      // console.log(action);
      state.actionsLoading = false;
      state.students = action.payload.students;
      state.parents =
        action.payload.parents.length !== 0
          ? action.payload.parents
          : action.payload.user;
      state.searchid = action.payload.userid;
      state.primaryParent = action.payload.user[0];

      state.error = null;
    },
    familyDropped: (state) => {
      state.actionsLoading = false;
      state.students = [];
      state.parents = [];
      state.searchid = undefined;
      state.primaryParent = undefined;

      state.error = null;
    },
    //get Student Courses
    studentCoursesFetched: (state, action) => {
      // console.log(action.payload);
      const index = state.students.findIndex(
        (student) => student.user_id === action.payload.id
      ); //finding index of the item
      const newArray = [...state.students]; //making a new array
      newArray[index].courses = action.payload.courses; //changing value in the new array
      state.students = newArray;
      state.error = null;
    },
  },
});
