export const campHeaders = [
    {
        id: "camp_week",
        numeric: false,
        disablePadding: true,
        label: "Camp Week"
    },
    {
        id: "camp_type",
        numeric: false,
        disablePadding: true,
        label: "Camp Type"
    },
    {
        id: "camp_times",
        numeric: false,
        disablePadding: true,
        label: "Camp Times"
    },
    {
        id: "course_abbr",
        numeric: false,
        disablePadding: true,
        label: "Stream (Abbr)"
    },
    {
        id: "delivery_type",
        numeric: false,
        disablePadding: true,
        label: "Delivery Type"
    },
    {
        id: "student_id",
        numeric: false,
        disablePadding: true,
        label: "Student ID"
    },
    {
        id: "student_name",
        numeric: false,
        disablePadding: true,
        label: "Student Name"
    },
    // {
    //     id: "parent_name",
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Parent Name"
    // },
    // {
    //     id: "phone",
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Phone Number"
    // },
    // {
    //     id: "email",
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Email Address"
    // }
]