import React, { useMemo, useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useCustomerLeadsContext } from "./CustomerLeadsDataContext";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
const prepareFilter = (values) => {
  const {
    idFilter,
    trialDateFilter,
    leadDateFilter,
    regDateFilter,
    leadInfoFilter,
    courseFilter,
    statusFilter,
    studentNameFilter,
  } = values;
  const filter = {};

  filter.id = idFilter !== "" ? idFilter : "";
  filter.trial_date = trialDateFilter !== "" ? trialDateFilter : "";
  filter.lead_date = leadDateFilter !== "" ? leadDateFilter : "";
  filter.reg_date = regDateFilter !== "" ? regDateFilter : "";
  filter.lead_info = leadInfoFilter !== "" ? leadInfoFilter : "";
  filter.course = courseFilter !== "" ? courseFilter : "";
  filter.status = statusFilter !== "" ? statusFilter : "";
  filter.student_name = studentNameFilter !== "" ? studentNameFilter : "";

  return filter;
};

export function CustomerLeadsFilter({ filterData, origData, loading }) {
  const formRef = useRef();
  const { COURSE } = useCustomerLeadsContext().TYPES;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const context = useCustomerLeadsContext();

  const stats = [
    "Default",
    "New",
    "Open",
    "Trial Scheduled",
    "Trial Taken",
    "Registered",
    "Not Interested",
    "On-Hold",
  ];

  const applyFilter = (values) => {
    //apply filter here
    filterData(prepareFilter(values));
  };
  const useStyles = makeStyles({
    btnCircleSm: {
      width: "30px",
      height: "30px",
      padding: "0px 7px",
      margin: "2px 1px 1px 1px",
      borderRadius: "15px",
      fontSize: "4px",
      textAlign: "center",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const initialValues = {
      leadInfoFilter: "",
      trialDateFilter: "",
      leadDateFilter: "",
      regDateFilter: "",
      statusFilter: "Default",
      idFilter: "",
      courseFilter: "",
      studentNameFilter: "",
    };
    applyFilter(initialValues);
  }, [origData]);

  //fetching classes for a branch
  useEffect(() => {
    formRef.current.click();
  }, [context.refetch, loading]);

  return (
    <>
      <Formik
        initialValues={{
          leadInfoFilter: "",
          trialDateFilter: "",
          leadDateFilter: "",
          regDateFilter: "",
          statusFilter: "Default",
          idFilter: "",
          courseFilter: "",
          studentNameFilter: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          /* fix me */
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Lead ID"
                  name="idFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("idFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.idFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by ID
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Stream"
                  name="courseFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("courseFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.courseFilter}
                >
                  <option key={-1} value={""}>
                    {"All"}
                  </option>
                  {COURSE &&
                    COURSE.sort((a, b) => (a.code > b.code ? 1 : -1)).map(
                      (c, index) => {
                        return (
                          <option key={index} value={c.value}>
                            {c.code}-{c.label}
                          </option>
                        );
                      }
                    )}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Stream
                </small>
              </div>
              <div className="col-lg-3">
                <input
                  className="form-control"
                  placeholder="Parent Information"
                  name="leadInfoFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("leadInfoFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.leadInfoFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by Parent Information
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Status"
                  name="statusFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("statusFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.statusFilter}
                >
                  <option key={-1} value={""}>
                    {"All"}
                  </option>
                  {stats.map((status, index) => {
                    return (
                      <option key={index} value={status}>
                        {status}
                      </option>
                    );
                  })}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>
              
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Student Name"
                  name="studentNameFilter"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("studentNameFilter", e.target.value);
                    handleSubmit();
                  }}
                  value={values.studentNameFilter}
                ></input>
                <small className="form-text text-muted">
                  <b>Filter</b> by Student
                </small>
              </div>

              <button
                type={"submit"}
                ref={formRef}
                style={{ display: "none" }}
                onClick={handleSubmit}
              />

              <button
                className={`btn btn-sm btn-circle btn-light ${classes.btnCircleSm}`}
                onClick={(e) => setShowMoreFilters(!showMoreFilters)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i
                    className={`fa text-${
                      showMoreFilters ? `secondary fa-minus` : `primary fa-plus`
                    }`}
                  ></i>
                </span>
              </button>
            </div>
            {showMoreFilters && (
              <div className="form-group row">
                <div className="col-lg-3">
                  <input
                    type="date"
                    className={`form-control`}
                    placeholder="Filter by Date"
                    name="leadDateFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("leadDateFilter", e.target.value);
                      setFieldValue("trialDateFilter", "");
                      setFieldValue("regDateFilter", "");
                      handleSubmit();
                    }}
                    value={values.leadDateFilter}
                  />
                  <small className="form-text text-muted">
                    <b>Filter</b> by Lead Date
                  </small>
                </div>

                <div className="col-lg-3">
                  <input
                    type="date"
                    className={`form-control`}
                    placeholder="Filter by Date"
                    name="trialDateFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("leadDateFilter", "");
                      setFieldValue("trialDateFilter", e.target.value);
                      setFieldValue("regDateFilter", "");
                      handleSubmit();
                    }}
                    value={values.trialDateFilter}
                  />
                  <small className="form-text text-muted">
                    <b>Filter</b> by Trial Date
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="date"
                    className={`form-control`}
                    placeholder="Filter by Date"
                    name="regDateFilter"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("leadDateFilter", "");
                      setFieldValue("trialDateFilter", "");
                      setFieldValue("regDateFilter", e.target.value);
                      handleSubmit();
                    }}
                    value={values.regDateFilter}
                  />
                  <small className="form-text text-muted">
                    <b>Filter</b> by Registration Date
                  </small>
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}
