export const LMSAccountsHeaders = [
    {
      key: "user_id",
      label: "Student ID",
    },
    {
      key: "firstname",
      label: "First Name",
    },
    {
      key: "lastname",
      label: "Last Name",
    },
    {
      key: "course_name",
      label: "Stream Name",
    },
    {
      key: "course_code",
      label: "Stream Code",
    },
    {
      key: "sub_course_code",
      label: "Sub Stream Code",
    },
    {
      key: "startDate",
      label: "Starting Date",
    },
  ];