/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useBranchCourseContext } from "../Components/BranchCourseDataContext";

const initialFormData = { firstName: "", friends: [] };

export function BranchCourseActivateDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const [formData, setFormData] = useState(initialFormData);
  const context = useBranchCourseContext();
  // Branch Context

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   // this is replacement for a network call that would load the data from a server
  //   setTimeout(() => {
  //     setFormData({
  //       id: 1,
  //       courses: [
  //         { id: 2, firstName: "First Name 2", lastName: "Last Name 2" },
  //         { id: 3, firstName: "First Name 3", lastName: "Last Name 3" }
  //       ]
  //     });
  //   }, 1000);
  // });

  const activateBranch = async () => {
    const activateUrl = "api/branch-course/activate/";

    //activate branch course post
    const result = await axios.patch(activateUrl + id);
    if (result.data === "success") {
      // console.log("activated");
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/branch-course/branch-course-list");
      }, 1000);
    } else {
    }
    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Activate Branch Stream
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to activate this branch Stream?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={activateBranch}
            className="btn btn-delete btn-elevate"
          >
            Activate
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
