import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../../_metronic/layout";
import { FeatureEdit } from "./FeatureEdit";
import { FeatureList } from "./FeatureList";
import { FeatureContextProvider } from "./Components/FeatureDataContext";

export default function FeaturePage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const featureUIEvents = {
    newFeatureButtonClick: () => {
      history.push("/branch-maintenance/features/add-feature");
    },
    openEditFeaturePage: (id) => {
      history.push(`/branch-maintenance/features/${id}/edit-feature`);
    },
    openActivateFeatureDialog: (id) => {
      history.push(`/branch-maintenance/features/feature-list/${id}/activate-feature`);
    },
    openDeactivateFeatureDialog: (id) => {
      history.push(`/branch-maintenance/features/feature-list/${id}/de-activate-feature`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <FeatureContextProvider featureUIEvents={featureUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/branch-maintenance/features" to="/branch-maintenance/features/feature-list" />}
          <ContentRoute path="/branch-maintenance/features/add-feature" component={FeatureEdit} />

          <ContentRoute
            path="/branch-maintenance/features/:id/edit-feature"
            component={FeatureEdit}
          />

          <ContentRoute path="/branch-maintenance/features/feature-list" component={FeatureList} />

        </Switch>
      </Suspense>
    </FeatureContextProvider>
  );
}
