// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import TextField from "@material-ui/core/TextField";
import PanToolIcon from "@material-ui/icons/PanTool";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PersistFormikValues } from "formik-persist-values";
import { getFormattedNumber } from "../../../helpers/Formatter";
import { useCustomerLeadsContext } from "./CustomerLeadsDataContext";
import { Spinner, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import { InputWithLimit } from "../../../../_metronic/_partials/controls/forms/InputWithLimit";

// Validation schema

var phoneRegExp = /^\([0-9]{3}\) - [0-9]{3} - [0-9]{4}/;

export function CustomerLeadsEditForm({
  id,
  stats,
  lead,
  setLead,
  btnRef,
  saveCustomerLeads,
  tab,
  setTab,
}) {
  const CourseSearchAutoComplete = ({
    COURSE,
    selectedCourse,
    setSelectedCourse,
    setFieldValue,
  }) => {
    return AUTOSELECTCOURSE && COURSE ? (
      <Autocomplete
        id="grouped-demo"
        onChange={(event, newValue) => {
          setSelectedCourse(newValue);
          setFieldValue("batch_id", -1);
        }}
        options={AUTOSELECTCOURSE.sort((a, b) =>
          b.primary_category.toString().localeCompare(a.primary_category.toString())
        )}
        groupBy={(AUTOSELECTCOURSE) => AUTOSELECTCOURSE.primary_category}
        getOptionLabel={(AUTOSELECTCOURSE) => AUTOSELECTCOURSE.name}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search For a Stream"
            variant="outlined"
          />
        )}
        value={selectedCourse}
      />
    ) : (
      <div className="text-center">
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </div>
    );
  };

  const TrialTab = ({
    values,
    setFieldValue,
    getFieldProps,
    COURSE,
    selectedCourse,
    setSelectedCourse,
    selectedCourseData,
    touched,
    errors,
  }) => {
    return (
      <>
        <h4 className="mt-10">Trial Info</h4>

        <div className="form-group row">
          <div className="col-lg-12">
            <CourseSearchAutoComplete
              values={values}
              setFieldValue={setFieldValue}
              COURSE={COURSE}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
            />
          </div>
        </div>
        {selectedCourse && (
          <>
            <div className="form-group row">
              <div
                className={`col-lg-4 ${
                  errors && errors.batch_id
                    ? "border border-danger rounded"
                    : ""
                }`}
              >
                <label className="trial_date">Select time(s)</label>
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  className="form-radio"
                >
                  {selectedCourseData &&
                    selectedCourseData.map((batch) => {
                      return (
                        <>
                          <input
                            name="batch_id"
                            value={parseInt(batch.batch_id)}
                            type="radio"
                            onChange={(e) => {
                              setFieldValue(
                                "batch_id",
                                parseInt(e.target.value)
                              );
                            }}
                            checked={values["batch_id"] == batch.batch_id}
                          />
                          <label className="form-radio-label">
                            {" "}
                            {`${batch.day}: ${moment(
                              batch.start_time,
                              "HH:mm:ss"
                            ).format("hh:mm a")} - ${moment(
                              batch.end_time,
                              "HH:mm:ss"
                            ).format("hh:mm a")}`}
                            <small>
                              {batch.delivery_type_value !== "In-person" ? " (" + batch.delivery_type_value + ")" : ""}
                            </small>
                            
                            <small className="text-secondary">
                              <b>{selectedCourse.primary_category !== batch.program_name ? (" " + batch.program_name) : ""}</b>
                            </small>
                            <small className="text-danger">
                              {batch.batch_full_ind ? " (full)" : ""}
                            </small>
                          </label>

                          <br />
                        </>
                      );
                    })}
                  <input
                    name="batch_id"
                    value={0}
                    type="radio"
                    onChange={(e) => {
                      setFieldValue("batch_id", e.target.value);
                    }}
                    checked={values["batch_id"] == 0}
                  />
                  <label className="form-radio-label">Other</label>

                  <br />
                </div>

                {errors && errors.batch_id && (
                  <div className="text-danger">
                    <small>{errors.batch_id}</small>
                  </div>
                )}
              </div>
              <div className={"col-lg-3"}></div>
              <div className={"col-lg-4"}>
                <div className="form-group row">
                  <div className={"col-lg-12"}>
                    <div className="align-items-center ">
                      <label className="trial_date">Trial Date</label>
                      <Field
                        name={"trial_date"}
                        component={Input}
                        type="date"
                        value={moment(
                          values["trial_date"].split("T")[0],
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-1 col-sm-2 col-md-2 col-lg-2">
                    <label>
                      Attendance:
                      {/* <Field
                        name={"present_ind"}
                        type="checkbox"
                        value={values.present_ind == 0 ? false : true}
                        checked={values.present_ind == 0 ? false : true}
                        className={"form-control"}
                      /> */}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            Student Present
                          </Tooltip>
                        }
                      >
                        <Button
                          name={"present_ind"}
                          variant={`${
                            values.present_ind == 1 ? "success" : "secondary"
                          }`}
                          className="mr-2 btn-sm align-text"
                          onClick={() => {
                            let newValue = !values.present_ind;
                            console.log(newValue);
                            setFieldValue("present_ind", newValue);
                          }}
                        >
                          <PanToolIcon fontSize="small" />
                        </Button>
                      </OverlayTrigger>
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className={"col-lg-12"}>
                    <InputWithLimit
                      className={`form-control form-control-lg form-control-solid`}
                      label="Coach's Comment"
                      name="coach_comment"
                      id="coach_comment"
                      type="textarea"
                      maxLength={225}
                      displaylimit={"true"}
                      touched={touched.coach_comment}
                      errors={errors.coach_comment}
                      rows="6"
                      {...getFieldProps("coach_comment")}
                      onChange={(e) => {
                        setFieldValue("coach_comment", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const BasicTab = ({
    values,
    touched,
    errors,
    getFieldProps,
    setFieldValue,
  }) => {
    return (
      <>
        <div className="form-group row">
          <div className=" col-lg-4">
            <div className="align-items-center ">
              <label>Lead Status</label>

              <Select
                name="status"
                onChange={(e) => {
                  setFieldValue("status", e.value);
                  setLead({ ...values, status: e.value });
                }}
              >
                {stats.map((status, index) => {
                  return (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <label className={"lead_date"}>Lead Date</label>
            <Field
              name="lead_date"
              component={Input}
              type="date"
              value={moment(
                values.lead_date.split("T")[0],
                "YYYY-MM-DD"
              ).format("YYYY-MM-DD")}
            />
          </div>

          <div className="col-lg-4">
            <div className="align-items-center ">
              <div>
                <label className="registration_date">Registration Date</label>
                <Field
                  name={"registration_date"}
                  component={Input}
                  type="date"
                  value={moment(
                    values["registration_date"].split("T")[0],
                    "YYYY-MM-DD"
                  ).format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <Select name="refferal" label="How Did You Hear About Us?">
              {[
                "Google Search",
                "Friends and Family",
                "Social Media",
                "Drive Thru",
                "Company Email",
                "Returning Student",
                "Other",
              ].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Select>
            {touched.refferal && errors.refferal ? (
              <div className="invalid-feedback">{errors.refferal}</div>
            ) : null}
          </div>
          {values && values["refferal"] == "Other" && (
            <div className="col-lg-4">
              <div className="align-items-center ">
                <div>
                  <InputWithLimit
                    label="Please Specify"
                    name="other"
                    id="other"
                    type="text"
                    touched={touched.other}
                    errors={errors.other}
                    maxLength={100}
                    displaylimit={"true"}
                    {...getFieldProps("other")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <h4>Primary Contact</h4>

        <div className="form-group row">
          <div className="col-lg-6">
            <InputWithLimit
              label="Parent Name:"
              name={`lead_name`}
              id="lead_name"
              type="text"
              maxLength={225}
              displaylimit={"false"}
              touched={touched.lead_name}
              errors={errors.lead_name}
              value={values.lead_name}
              {...getFieldProps("lead_name")}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <InputWithLimit
              label="Phone:"
              name="lead_phone"
              id="lead_phone"
              type="text"
              touched={touched.lead_phone}
              errors={errors.lead_phone}
              {...getFieldProps("lead_phone")}
              onChange={(e) => {
                let phoneNumber = getFormattedNumber(e.target.value);
                console.log("Returned", phoneNumber);
                setFieldValue("lead_phone", phoneNumber);
              }}
              maxLength={18}
              displaylimit={"false"}
            />
          </div>
          <div className="col-lg-3">
            <InputWithLimit
              label="Email:"
              name="email"
              id="email"
              type="email"
              touched={touched.email}
              errors={errors.email}
              maxLength={100}
              displaylimit={"false"}
              {...getFieldProps("email")}
            />
          </div>

          <div className=" col-lg-3">
            <div className="font-weight-bold align-items-center ">
              <InputWithLimit
                label="Zip:"
                name="zip"
                id="zip"
                type="text"
                maxLength={50}
                displaylimit={"false"}
                touched={touched.zip}
                errors={errors.zip}
                {...getFieldProps("zip")}
              />
            </div>
          </div>
        </div>

        <h4>Student Information</h4>

        <div className="form-group row">
          <div className=" col-lg-6">
            <div className="font-weight-bold align-items-center ">
              <InputWithLimit
                label="Student Name"
                name={`student_name`}
                id="student_name"
                type="text"
                touched={touched.student_name}
                errors={errors.student_name}
                maxLength={225}
                displaylimit={"false"}
                {...getFieldProps("student_name")}
              />
            </div>
          </div>
          <div className=" col-lg-6">
            <div className="font-weight-bold align-items-center ">
              <label className={"student_grade"}>Student Grade</label>
              <Field name="student_grade" component={Input} type="number" />
            </div>
          </div>
        </div>

        <h4>Stream Information</h4>

        <div className="form-group row">
          <div className=" col-lg-6 font-weight-bold mr-2">
            <div className="align-items-center ">
              <label className={"course_of_interest"}>Stream of Interest</label>
              <Select
                name="course_of_interest"
                isMulti={true}
                isClearable={true}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                onChange={(e) => {
                  setFieldValue(
                    "course_of_interest",
                    e
                      ? e.map((ev) => {
                          return { value: ev.value };
                        })
                      : []
                  );
                }}
              >
                {COURSE &&
                  COURSE.sort((a, b) => (a.code > b.code ? 1 : -1)).map(
                    (c, index) => {
                      return (
                        <option key={index} value={c.value}>
                          {`${c.code} - ${c.label}`}
                        </option>
                      );
                    }
                  )}
              </Select>
              {touched.course_id && errors.course_id ? (
                <div className="invalid-feedback">{errors.course_id}</div>
              ) : null}
            </div>
          </div>
          <div className=" col-lg-5 font-weight-bold mr-2">
            <div className="align-items-center ">
              <label className={"delivery_type_id"}>Mode of Delivery</label>
              <Select name="delivery_type_id">
                {DELIVERYTYPE &&
                  DELIVERYTYPE.length > 0 &&
                  DELIVERYTYPE.map((dt) => {
                    return (
                      <option key={dt.id} value={dt.id}>
                        {dt.value}
                      </option>
                    );
                  })}
              </Select>
            </div>
          </div>
        </div>
        {values &&
          (values.status == stats[2] ||
            values.status == stats[3] ||
            values.trial_date != "") && (
            <TrialTab
              values={values}
              setFieldValue={setFieldValue}
              getFieldProps={getFieldProps}
              COURSE={COURSE}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              selectedCourseData={selectedCourseData}
              touched={touched}
              errors={errors}
            />
          )}
        <div className="form-group row">
          <div className="col-lg-12">
            <InputWithLimit
              label="Notes"
              name="notes"
              id="notes"
              type="textarea"
              maxLength={65535}
              displaylimit={"true"}
              touched={touched.notes}
              errors={errors.notes}
              rows="6"
              {...getFieldProps("notes")}
              onChange={(e) => {
                setFieldValue("notes", e.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const CustomerLeadsEditSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    student_name: Yup.string()
      .required("Student Name is required")
      .test("length-test", "Student Name is required", (value, context) => {
        return value && value.length != 1;
      }),
    student_grade: Yup.number()
      .required("Grade is required")
      .min(1, "1 is the minimum")
      .max(12, "12 is the maximum"),
    other: Yup.string(),
    lead_name: Yup.string()
      .required("Parent Name is required")
      .test("length-test", "Parent Name is required", (value, context) => {
        return value && value.length != 1;
      }),
    lead_phone: Yup.string()
    .matches(phoneRegExp, "Please enter a phone number")
    .min(18, "Phone Number must be exactly 10 digits")
    .required("Phone Number is required")
    .test(
      "valid-number",
      "Sorry, only numbers (0-9) are allowed",
      (value, context) => {
        return (
          value &&
          value.split("@").length > 0 &&
          value.split("@")[0].match(/[(0-9)(\-)(\()(\))( ))]*/g)[0] ==
            value.split("@")[0]
        );
      }
    ),
    email: Yup.string()
      .email("Wrong email format")
      .test(
        "valid-email",
        "Sorry, only letters (a-z), numbers (0-9), apostrophe (.), hypen (-), and underscores (_) are allowed",
        (value, context) => {
          return (
            value &&
            value.split("@").length > 0 &&
            value.split("@")[0].match(/[(a-z)(A-Z)(0-9)(.)(_)(\-)]*/g)[0] ==
              value.split("@")[0]
          );
        }
      ),
    zip: Yup.string().test(
      "valid-zip",
      "Sorry, only letters (a-z), numbers (0-9), space ( ), and hypen (-) are allowed",
      (value, context) => {
        return (
          value &&
          value.split("@").length > 0 &&
          value.split("@")[0].match(/[(a-z)(A-Z)(0-9)(\-)( ))]*/g)[0] ==
            value.split("@")[0]
        );
      }
    ),
    notes: Yup.string(),
    lead_date: Yup.date().required("Lead Date is required"),
    trial_date: Yup.date().when("status", {
      is: stats[2] || stats[3],
      then: Yup.date().required("Trial Date is required"),
    }),
    registration_date: Yup.date(),
    course_of_interest: Yup.array().of(
      Yup.object().shape({
        value: Yup.number(),
      })
    ),
    batch_id: Yup.number().when("status", {
      is: stats[2] || stats[3],
      then: Yup.number()
        .notOneOf([-1], "Select A Batch")
        .required("Batches are required"),
    }),
    present_ind: Yup.boolean(),
    coach_comment: Yup.string(),
  });

  const {
    DELIVERYTYPE,
    COURSE,
    AUTOSELECTCOURSE,
    selectedCourse,
    setSelectedCourse,
    selectedCourseData,
  } = useCustomerLeadsContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        validationSchema={CustomerLeadsEditSchema}
        onSubmit={(values) => {
          let course_id = selectedCourse ? selectedCourse.id : null;
          // console.log("On Submit", course_id, selectedCourse)
          saveCustomerLeads({ ...values, course_id: course_id });
        }}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
          getFieldProps,
        }) => (
          <>
            <Form className="form form-label-right">
              {tab === "basic" && (
                <BasicTab
                  values={values}
                  touched={touched}
                  errors={errors}
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                />
              )}

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
