export const CourseChallengeHeaders = [
  {
    id: "Student_Id",
    numeric: false,
    disablePadding: true,
    label: "Student Id",
  },
  {
    id: "Student_Name",
    numeric: false,
    disablePadding: true,
    label: "Student Name",
  },
  {
    id: "Course_Name",
    numeric: false,
    disablePadding: true,
    label: "Course Name",
  },
  {
    id: "Batch_Day",
    numeric: false,
    disablePadding: true,
    label: "Batch Day",
  },
  {
    id: "Batch_Start_Time",
    numeric: false,
    disablePadding: true,
    label: "Batch Start Time",
  },
  {
    id: "Batch_End_Time",
    numeric: false,
    disablePadding: true,
    label: "Batch End Time",
  },
  {
    id: "Challenge_Name",
    numeric: false,
    disablePadding: true,
    label: "Last Challenge",
  },
  {
    id: "Submission_Date",
    numeric: false,
    disablePadding: true,
    label: "Submission Date",
  },
];
