export const oooHeaders = [
  {
    id: "user_id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "hours",
    numeric: false,
    disablePadding: true,
    label: "Hours",
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Task",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
];
