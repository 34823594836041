/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function CourseListSelection({
  className,
  id,
  type,
  setCoursesToAdd,
  coursesToAdd,
  setCoursesToRemove,
  coursesToRemove,
  batchCourses,
  campCourses,
  setBatchCourses,
  setCampCourses,
  batchMissingCourses,
  campMissingCourses,
  setBatchMissingCourses,
  setCampMissingCourses,
}) {
  //getbranch
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleCourse = async (mainCourses, setMainCourses, course) => {
    // console.log("Start toggling", course, "from", coursesToAdd);
    const isItInAdded = coursesToAdd.filter((c) => c.id === course.course_id);
    // console.log("toggle: isItInAdded", isItInAdded);

    if (isItInAdded.length > 0) {
      const r = coursesToAdd.filter((c) => c.id !== course.course_id);
      // console.log("rest", r);
      let newArr = [
        {
          id: course.course_id,
          show_ind: isItInAdded[0].show_ind == 1 ? 0 : 1,
        },
        ...r,
      ];
      // console.log("new", newArr);
      setCoursesToAdd(newArr);
    }
    // console.log("existing", mainCourses);
    let newBC = mainCourses.filter((bc) => {
      return bc.course_id !== course.course_id;
    });
    let editedCourse = mainCourses.filter((bc) => {
      return bc.course_id == course.course_id;
    })[0];
    editedCourse.show_ind = editedCourse.show_ind == 1 ? 0 : 1;
    let newBCArr = [editedCourse, ...newBC];
    // console.log("new bc", newBCArr);
    setMainCourses(newBCArr);
  };
  const removeCourse = async (course) => {
    // setLoading(true);
    // console.log("remove course", batch_course_id);
    // const values = { active_id: 0 };
    // //save new batch post
    // const removeCoursesUrl = `api/batches/courses/${batch_course_id}`;
    // const result = await axios.patch(removeCoursesUrl, values);
    // if (result.data === "success") {
    //   console.log("success");
    //   setRefetch(true);
    //   setLoading(false);
    // } else {
    //   console.log("error");
    //   setLoading(false);
    // }

    //check if it is in added
    const isItInAdded = coursesToAdd.filter((c) => c.id === course.course_id);
    // console.log("isItInAdded", isItInAdded);

    if (isItInAdded.length > 0) {
      //remove the course from removed array
      const r = coursesToAdd.filter((c) => c.id != course.course_id);
      // console.log("removed in added", r);
      setCoursesToAdd(r);
      if (type == "batch") {
        const removeFromCourses = batchCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setBatchCourses(removeFromCourses);
        setBatchMissingCourses([course, ...batchMissingCourses]);
      } else if (type == "camp") {
        const removeFromCourses = campCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setCampCourses(removeFromCourses);
        setCampMissingCourses([course, ...campMissingCourses]);
      }
    } else {
      if (type == "batch") {
        course.course_id &&
          setCoursesToRemove([course.course_id, ...coursesToRemove]);
        const removeFromCourses = batchCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setBatchCourses(removeFromCourses);
        setBatchMissingCourses([course, ...batchMissingCourses]);
      } else if (type == "camp") {
        course.course_id &&
          setCoursesToRemove([course.course_id, ...coursesToRemove]);
        const removeFromCourses = campCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setCampCourses(removeFromCourses);
        setCampMissingCourses([course, ...campMissingCourses]);
      }
    }
  };
  const addCourse = async (course) => {
    // setLoading(true);
    // console.log("Add course", course_id);
    // const values = { batch_id: batchid, course_id: course_id };
    // //save new batch post
    // const addCoursesUrl = `api/batches/courses`;
    // const result = await axios.post(addCoursesUrl, values);
    // if (result.data === "success") {
    //   console.log("success");
    //   setRefetch(true);
    //   setLoading(false);
    // } else {
    //   console.log("error");
    //   setLoading(false);
    // }
    // console.log("course to be added", course);

    //check if course was originally removed
    const isItInRemove = coursesToRemove.filter((c) => c === course.course_id);
    // console.log("isItInRemove", isItInRemove);
    //if it is in remove it means it doesnt need to be added again
    if (isItInRemove.length > 0) {
      //remove the course from removed array
      const r = coursesToRemove.filter((c) => c != course.course_id);
      // console.log("with removed course", r);
      setCoursesToRemove(r);
      //updated batch list views
      if (type == "batch") {
        let newCourse = {...course, show_ind: 1}
        setBatchCourses([newCourse, ...batchCourses]);
        const removeFromCourses = batchMissingCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setBatchMissingCourses(removeFromCourses);
      } else if (type == "camp") {
        let newCourse = {...course, show_ind: 1}
        setCampCourses([newCourse, ...campCourses]);
        const removeFromCourses = campMissingCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setCampMissingCourses(removeFromCourses);
      }
    } else {
      if (type == "batch") {
        batchCourses.filter((c) => c.course_id === course.course_id).length <
          1 &&
          setCoursesToAdd([
            { id: course.course_id, show_ind: 1 },
            ...coursesToAdd,
          ]);
        setBatchCourses([course, ...batchCourses]);
        const removeFromCourses = batchMissingCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setBatchMissingCourses(removeFromCourses);
      } else if (type == "camp") {
        campCourses.filter((c) => c.course_id === course.course_id).length <
          1 &&
          setCoursesToAdd([
            { id: course.course_id, show_ind: 1 },
            ...coursesToAdd,
          ]);
        setCampCourses([course, ...campCourses]);
        const removeFromCourses = campMissingCourses.filter(
          (c) => c.course_id != course.course_id
        );
        setCampMissingCourses(removeFromCourses);
      }
    }
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0"></div>

      {/* Body */}
      <div className="card-body pt-0 row">
        <div className="col-md-6 p-20 rounded bg-light-primary">
          <div className="row">
            {" "}
            <h5> Added Courses</h5>{" "}
          </div>
          {type == "batch" &&
            batchCourses &&
            batchCourses
              .sort((a, b) => {
                const codeA = a.course_code.match(/\d+/g);
                const codeB = b.course_code.match(/\d+/g);
                return codeA - codeB;
              })
              .map((course) => (
                /* begin::Item */
                <div className="mb-6" key={course.course_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {course.course_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {course.course_code}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}
                      {/* begin::Show Toggle */}

                      {course.show_ind == 1 ? (
                        <OverlayTrigger
                          overlay={<Tooltip>Hide Stream</Tooltip>}
                        >
                          <button
                            className="btn btn-icon btn-light btn-hover-muted btn-sm"
                            onClick={() => {
                              toggleCourse(batchCourses, setBatchCourses, course);
                            }}
                            disabled={loading}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <i className="fa fa-eye-slash text-danger"></i>
                            </span>
                          </button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          overlay={<Tooltip>Show Stream</Tooltip>}
                        >
                          <button
                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                            onClick={() => {
                              toggleCourse(batchCourses, setBatchCourses, course);
                            }}
                            disabled={loading}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-muted">
                              <i className="fa fa-eye text-success"></i>
                            </span>
                          </button>
                        </OverlayTrigger>
                      )}
                      {/* end::Show Toggle */}

                      {/* begin::Button */}
                      <OverlayTrigger
                        overlay={<Tooltip>Remove Stream</Tooltip>}
                      >
                        <button
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => removeCourse(course)}
                          disabled={loading}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i className="fa fa-minus text-danger"></i>
                          </span>
                        </button>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              ))}
          {type == "camp" &&
            campCourses &&
            campCourses
              .sort((a, b) => {
                const codeA = a.course_code.match(/\d+/g);
                const codeB = b.course_code.match(/\d+/g);
                return codeA - codeB;
              })
              .map((course) => (
                /* begin::Item */
                <div className="mb-6" key={course.course_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {course.course_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {course.course_code}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}
                                            {/* begin::Show Toggle */}

                                            {course.show_ind == 1 ? (
                        <OverlayTrigger
                          overlay={<Tooltip>Hide Stream</Tooltip>}
                        >
                          <button
                            className="btn btn-icon btn-light btn-hover-muted btn-sm"
                            onClick={() => {
                              toggleCourse(campCourses, setCampCourses, course);
                            }}
                            disabled={loading}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <i className="fa fa-eye-slash text-danger"></i>
                            </span>
                          </button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          overlay={<Tooltip>Show Stream</Tooltip>}
                        >
                          <button
                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                            onClick={() => {
                              toggleCourse(campCourses, setCampCourses, course);
                            }}
                            disabled={loading}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-muted">
                              <i className="fa fa-eye text-success"></i>
                            </span>
                          </button>
                        </OverlayTrigger>
                      )}
                      {/* end::Show Toggle */}

                      {/* begin::Button */}
                      <OverlayTrigger
                        overlay={<Tooltip>Remove Stream</Tooltip>}
                      >
                        <button
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => removeCourse(course)}
                          disabled={loading}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i className="fa fa-minus text-danger"></i>
                          </span>
                        </button>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              ))}
        </div>
        <div className="col-md-6 p-20 ">
          <div className="row">
            {" "}
            <h5> Courses To Be Added</h5>{" "}
          </div>
          {/* listing all courses not in batch */}
          {type == "batch" &&
            batchMissingCourses &&
            batchMissingCourses
              .sort((a, b) => {
                const codeA = a.course_code.match(/\d+/g);
                const codeB = b.course_code.match(/\d+/g);
                return codeA - codeB;
              })
              .map((course) => (
                /* begin::Item */
                <div className="mb-6" key={course.course_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {course.course_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {course.course_code}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}

                      {/* begin::Button */}
                      <OverlayTrigger overlay={<Tooltip>Add Stream</Tooltip>}>
                        <button
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => addCourse(course)}
                          disabled={loading}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-success">
                            <i className="fa fa-plus text-success"></i>
                          </span>
                        </button>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              ))}
          {type == "camp" &&
            campMissingCourses &&
            campMissingCourses
              .sort((a, b) => {
                const codeA = a.course_code.match(/\d+/g);
                const codeB = b.course_code.match(/\d+/g);
                return codeA - codeB;
              })
              .map((course) => (
                /* begin::Item */
                <div className="mb-6" key={course.course_id}>
                  {/* begin::Content */}
                  <div className="d-flex align-items-center flex-grow-1">
                    {/* begin::Section */}
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      {/* begin::Info */}
                      <div className="d-flex flex-column align-items-cente py-2 w-75">
                        {/* begin::Title */}
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                        >
                          {course.course_name}
                        </a>
                        {/* end::Title */}

                        {/* begin::Data */}
                        <span className="text-muted font-weight-bold">
                          {course.course_code}
                        </span>
                        {/* end::Data */}
                      </div>
                      {/* end::Info */}

                      {/* begin::Button */}
                      <OverlayTrigger overlay={<Tooltip>Add Stream</Tooltip>}>
                        <button
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => addCourse(course)}
                          disabled={loading}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-success">
                            <i className="fa fa-plus text-success"></i>
                          </span>
                        </button>
                      </OverlayTrigger>

                      {/* end:Button */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Content */}
                </div>

                /* end::Item */
              ))}
        </div>
      </div>
    </div>
  );
}
