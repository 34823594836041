import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Select } from "../../../../_metronic/_partials/controls";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
const useStyles = makeStyles((theme) => ({
  dropdownColumn: {
    minWidth: 400,
    paddingLeft: "0px",
  },
  iconColumn: {
    textAlign: "center",
    minWidth: 50,
    maxWidth: 50,
    padding: 5,
  },
}));

const errorMessages = {
  duplicates: "Duplicate - Please select a different task and branch",
  task_branch_invalid: "Incomplete - Please select a task and branch",
  hours_set_without_task_branch:
    "Incomplete - Please select a task and branch when hours are set",
  default_set_without_task_branch:
    "Incomplete - Please select a task and branch when favoriting",
};

export default function TimesheetRow({
  row,
  rowIndex,
  editMode,
  removeRow,
  setFieldValue,
  values,
  loading,
}) {
  const classes = useStyles();
  const DROPDOWN_TASK = useTimesheetContext().TASK;
  const DROPDOWN_BRANCH = useTimesheetContext().BRANCH;
  const [star, setStar] = React.useState(
    values.timesheet_tasks[rowIndex].is_default
  );

  /**
   * Return total hours of a task / row
   * @date 2022-07-13
   */
  const getTaskTotal = () => {
    let total = 0;
    for (const day in row.timesheet_entries) {
      total += parseFloat(row.timesheet_entries[day].hours);
    }
    return total;
  };

  const isRowTaskBranchValid = () => {
    let isValid = true;
    if (
      (values.timesheet_tasks[rowIndex].task_id > 0 &&
        values.timesheet_tasks[rowIndex].branch_id == 0) ||
      (values.timesheet_tasks[rowIndex].task_id == 0 &&
        values.timesheet_tasks[rowIndex].branch_id > 0)
    ) {
      isValid = false;
    }
    // console.log("isRowTaskBranchValid", isValid);
    return isValid;
  };

  const isRowHoursSetWithTaskBranch = () => {
    let isValid = true;
    if (getTaskTotal() > 0 && row.branch_id == 0 && row.task_id == 0) {
      isValid = false;
    }
    // console.log("isRowHoursSetWithTaskBranch", isValid);
    return isValid;
  };

  /**
   * Return boolean if task is a duplicate (same task and branch in another row)
   * @date 2022-07-13
   */
  const isRowNotDuplicate = () => {
    let duplicates = false;
    let task_branch_ids = [];
    for (const task of values.timesheet_tasks) {
      if (
        task_branch_ids.find(
          (logged_task) =>
            logged_task.task_id == task.task_id &&
            logged_task.branch_id == task.branch_id
        )
      ) {
        if (
          task.branch_id == values.timesheet_tasks[rowIndex].branch_id &&
          task.task_id == values.timesheet_tasks[rowIndex].task_id &&
          task.task_id != 0 &&
          task.branch_id != 0
        ) {
          duplicates = true;
        }
      } else {
        task_branch_ids.push({
          task_id: task.task_id,
          branch_id: task.branch_id,
        });
      }
    }
    // console.log("isRowNotDuplicate", !duplicates);
    return !duplicates;
  };

  const isRowDefault = () => {
    return getTaskTotal() == 0 && row.task_id == 0 && row.branch_id == 0;
  };

  const isRowDefaultSetWithTaskBranch = () => {
    let isValid = true;
    if (star && isRowDefault()) {
      isValid = false;
    }
    // console.log("isRowDefaultSetWithTaskBranch", isValid);
    return isValid;
  };

  const handleStatusLabel = () => {
    if (
      !isRowTaskBranchValid() ||
      !isRowHoursSetWithTaskBranch() ||
      !isRowNotDuplicate() ||
      !isRowDefaultSetWithTaskBranch()
    ) {
      return (
        <OverlayTrigger
          style={{ position: "relative", margin: "20px" }}
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="tooltip-top">
              {isRowTaskBranchValid()
                ? isRowHoursSetWithTaskBranch()
                  ? isRowDefaultSetWithTaskBranch()
                    ? isRowNotDuplicate()
                      ? ""
                      : errorMessages.duplicates
                    : errorMessages.default_set_without_task_branch
                  : errorMessages.hours_set_without_task_branch
                : errorMessages.task_branch_invalid}
            </Tooltip>
          }
        >
          <div
            className="text-danger text-center"
            style={{
              position: "absolute",
              top: "50",
              right: "50",
              transform: "translate(0, 0)",
            }}
          >
            <span className="text-danger pl-2">
              {isRowTaskBranchValid()
                ? isRowHoursSetWithTaskBranch()
                  ? isRowDefaultSetWithTaskBranch()
                    ? isRowNotDuplicate()
                      ? ""
                      : errorMessages.duplicates
                    : errorMessages.default_set_without_task_branch
                  : errorMessages.hours_set_without_task_branch
                : errorMessages.task_branch_invalid}
            </span>
          </div>
        </OverlayTrigger>
      );
    }
  };

  const handleStatusIcon = () => {
    if (isRowDefault() == true) {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="tooltip-top">
              Incomplete - Please select a task and branch
            </Tooltip>
          }
        >
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            style={{ color: "#54afe2" }}
          ></i>
        </OverlayTrigger>
      );
    } else {
      if (
        !isRowTaskBranchValid() ||
        !isRowHoursSetWithTaskBranch() ||
        !isRowNotDuplicate() ||
        !isRowDefaultSetWithTaskBranch()
      ) {
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip-top">
                {isRowTaskBranchValid()
                  ? isRowHoursSetWithTaskBranch()
                    ? isRowDefaultSetWithTaskBranch()
                      ? isRowNotDuplicate()
                        ? ""
                        : errorMessages.duplicates
                      : errorMessages.default_set_without_task_branch
                    : errorMessages.hours_set_without_task_branch
                  : errorMessages.task_branch_invalid}
              </Tooltip>
            }
          >
            <i
              className="fa fa-exclamation-triangle"
              style={{ color: "red" }}
            ></i>
          </OverlayTrigger>
        );
      } else {
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip-top">{`Task & Branch are valid`}</Tooltip>
            }
          >
            <i className="fa fa-check-circle text-success fa-lg" />
          </OverlayTrigger>
        );
      }
    }
  };

  const handleStar = () => {
    setStar(!star);
    setFieldValue(`timesheet_tasks[${rowIndex}].is_default`, !star);
  };

  const handleTaskChange = (event) => {
    if (
      DROPDOWN_TASK.TASKS.find(
        (task) => task.value == event.value && task.corporate == 1
      )
    ) {
      setFieldValue(`timesheet_tasks[${rowIndex}].branch_id`, -1);
    } else {
      setFieldValue(
        `timesheet_tasks[${rowIndex}].branch_id`,
        DROPDOWN_BRANCH.BRANCHES.filter((b) => !b.disabled)[0].value
      );
    }
    setFieldValue(`timesheet_tasks[${rowIndex}].task_id`, event.value);
  };

  useEffect(() => {
    setStar(values.timesheet_tasks[rowIndex].is_default);
  }, [values.timesheet_tasks]);

  return (
    <Fragment>
      <TableRow key={rowIndex} hover tabIndex={-1}>
        <TableCell className={classes.iconColumn}>
          {handleStatusIcon()}
        </TableCell>

        <TableCell className={classes.dropdownColumn}>
          <Row className="justify-content-center w-100 m-0">
            <Select
              menuPortalTarget={document.body}
              menuPosition="fixed"
              name={`timesheet_tasks.${rowIndex}.task_id`}
              placeholder="Select Task"
              isClearable={false}
              isMulti={false}
              onChange={handleTaskChange}
              validationColorScheme={false}
              isDisabled={editMode}
              className="w-50 pr-5"
            >
              {Array.isArray(DROPDOWN_TASK.TASKS) &&
                DROPDOWN_TASK.TASKS.filter((t) => !t.disabled).map((task) => {
                  return (
                    <option key={task.value} value={task.value}>
                      {task.label}
                    </option>
                  );
                })}
            </Select>

            <Select
              menuPortalTarget={document.body}
              menuPosition="fixed"
              name={`timesheet_tasks.${rowIndex}.branch_id`}
              placeholder="Select Branch"
              isClearable={false}
              isMulti={false}
              onChange={(e) => {
                setFieldValue(`timesheet_tasks.${rowIndex}.branch_id`, e.value);
              }}
              validationColorScheme={false}
              isDisabled={
                editMode ||
                values.timesheet_tasks[rowIndex].branch_id == -1 ||
                  values.timesheet_tasks[rowIndex].branch_id == null
              }
              value={
                values.timesheet_tasks[rowIndex].branch_id == -1 ||
                values.timesheet_tasks[rowIndex].branch_id == null
                  ? { label: "Corporate", value: 0 }
                  : DROPDOWN_BRANCH.BRANCHES.find(
                      (branch) =>
                        branch.value ==
                        values.timesheet_tasks[rowIndex].branch_id
                    )
              }
              className="w-50 pl-5"
            >
              {Array.isArray(DROPDOWN_BRANCH.BRANCHES) &&
                DROPDOWN_BRANCH.BRANCHES.filter((b) => !b.disabled).map(
                  (branch) => {
                    return (
                      <option key={branch.value} value={branch.value}>
                        {branch.label}
                      </option>
                    );
                  }
                )}
            </Select>
          </Row>
          {handleStatusLabel()}
        </TableCell>

        {Array.from(Array(7).keys()).map((i) => {
          return (
            <>
              <TableCell align="right">
                <input
                  className="form-control form-control-sm text-center m-0"
                  type="number"
                  step={"any"}
                  name={`timesheet_tasks[${rowIndex}].timesheet_entries.${i}.hours`}
                  onChange={(e) => {
                    if (e.target.value.toString().match(/^[0][0-9]$/)) {
                      setFieldValue(
                        `timesheet_tasks[${rowIndex}].timesheet_entries.${i}.hours`,
                        e.target.value.substring(1)
                      );
                      setFieldValue(
                        `timesheet_tasks[${rowIndex}].modified_on`,
                        moment.utc().format()
                      );
                    } else if (e.target.value === "") {
                      setFieldValue(
                        `timesheet_tasks[${rowIndex}].timesheet_entries.${i}.hours`,
                        0
                      );
                    } else {
                      setFieldValue(
                        `timesheet_tasks[${rowIndex}].timesheet_entries.${i}.hours`,
                        e.target.value
                      );
                      setFieldValue(
                        `timesheet_tasks[${rowIndex}].modified_on`,
                        moment.utc().format()
                      );
                    }
                  }}
                  value={
                    values.timesheet_tasks[rowIndex].timesheet_entries[i].hours
                  }
                  onBlur={(e) => {
                    // console.log(errors);
                    // console.log(e.target.value);
                  }}
                  min="0"
                  max="24"
                  style={{
                    minWidth: "60px",
                  }}
                ></input>
              </TableCell>
            </>
          );
        })}
        <TableCell align="center">
          <span
            style={{ fontSize: "0.9rem", color: "#181c32", fontWeight: "500" }}
          >
            {getTaskTotal()}
          </span>
        </TableCell>

        <TableCell className="pr-1">
          {!star && (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">
                  Favorite Task - Branch
                </Tooltip>
              }
            >
              <a
                onClick={handleStar}
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
              >
                <i
                  className="fa fa-star"
                  style={{ color: !editMode ? "#98adcd" : "#a8aaac" }}
                ></i>
              </a>
            </OverlayTrigger>
          )}
          {star && (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">
                  Favorite Task - Branch
                </Tooltip>
              }
              disabled={loading}
            >
              <a
                onClick={handleStar}
                className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
              >
                <i
                  className="fa fa-star"
                  style={{ color: !editMode ? "#ffd003" : "#a8aaac" }}
                ></i>
              </a>
            </OverlayTrigger>
          )}
        </TableCell>
        <TableCell className="pl-1">
          <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Remove Task</Tooltip>}
            disabled={loading}
          >
            <a
              onClick={() => {
                if (!editMode) {
                  removeRow(rowIndex);
                }
              }}
              className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
            >
              <i
                className="fa fa-trash"
                style={{ color: !editMode ? "#f64e60" : "#a8aaac" }}
              ></i>
            </a>
          </OverlayTrigger>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
