import { Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../../_metronic/layout";
import { EmailDialog } from "../../../../_zebra/_components/EmailDialog";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { AttendanceDialog } from "./Components/AttendanceDialog";
import { BatchActivateDialog } from "./Components/BatchActivateDialog";
import { useBatchContext } from "./Components/BatchDataContext";
import { BatchDeleteDialog } from "./Components/BatchDeleteDialog";
import { BatchFilter } from "./Components/BatchFilter";
import { BatchFullDialog } from "./Components/BatchFullDialog";
import { BatchNotFullDialog } from "./Components/BatchNotFullDialog";
import ClassTable from "./Components/ClassTable";
import { LeadAttendanceDialog } from "./Components/LeadAttendanceDialog";
import { PointsDialog } from "./Components/PointsDialog";

export const BatchList = () => {
  const [data, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { branch, authUser } = useSelector(
    (state) => ({
      branch: state.auth.branches.currentBranch,
      authUser: state.auth.user,
    }),
    shallowEqual
  );
  const context = useBatchContext();
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Regular Class List");
  const classesUrl = "api/batches/branch/";

  async function fetchBatchesForBranch() {
    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let activeValues = ["Active", "In-Active"];
    setLoading(true);
    const result = await axios(classesUrl + branch.branch_id);
    result.data.result
      .sort((a, b) => {
        return days.indexOf(a.day) > days.indexOf(b.day)
          ? 1
          : days.indexOf(a.day) === days.indexOf(b.day) &&
            a.start_time > b.start_time
          ? 1
          : -1;
      })
      .sort((a, b) => {
        return (
          activeValues.indexOf(a.active_value) -
          activeValues.indexOf(b.active_value)
        );
      });
    setData(result.data.result);
    setOrigData(result.data.result);
    setLoading(false);
  }

  //success error messages
  const [fullSuccessOpen, setFullSuccessOpen] = useState(false);
  const [notFullSuccessOpen, setNotFullSuccessOpen] = useState(false);
  const [fullErrorOpen, setFullErrorOpen] = useState(false);
  const [notFullErrorOpen, setNotFullErrorOpen] = useState(false);
  const [leadAttendaceSuccessOpen, setLeadAttendaceSuccessOpen] = useState(
    false
  );
  const [leadAttendaceErrorOpen, setLeadAttendaceErrorOpen] = useState(false);

  const [emailSuccessOpen, setEmailSuccessOpen] = useState(false);

  const [emailErrorOpen, setEmailErrorOpen] = useState(false);

  const handleClose = () => {
    setFullSuccessOpen(false);
    setNotFullSuccessOpen(false);
    setFullErrorOpen(false);
    setNotFullErrorOpen(false);
    setLeadAttendaceErrorOpen(false);
    setLeadAttendaceSuccessOpen(false);
    setEmailSuccessOpen(false);
    setEmailErrorOpen(false);
  };

  //fetching classes for a branch
  useEffect(() => {
    branch && fetchBatchesForBranch();
    // eslint-disable-next-line
  }, [branch]);

  //fetching classes for a branch
  useEffect(() => {
    context.refetch && fetchBatchesForBranch();
    context.setRefetch(false);
    // eslint-disable-next-line
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;
      for (let key in filters) {
        if (filters[key] !== "" && filters[key] != []) {
          // if (key === "courses") {
          //   for (let object of item[key].values()) {
          //     if (object["course_code"] === filters[key]) {
          //       return true;
          //     }
          //   }
          // }
          if (key === "courses") {
            let common = item[key].filter((b) => {
              return filters[key].includes(b.course_code);
            });
            if (common.length == 0) {
              add = false;
            }
          }
          if (key === "id") {
            if (!(item["batch_id"] + "").includes(filters[key])) {
              add = false;
            }
          }

          if (key === "start_time") {
            // console.log(item, item["start_time"], filters[key])
            if (item["start_time"] !== filters[key] + ":00") {
              add = false;
            }
          }

          if (key === "end_time") {
            // console.log(item, item["end_time"], filters[key])
            if (item["end_time"] !== filters[key] + ":00") {
              add = false;
            }
          }

          if (key === "coaches") {
            if (
              !item.instructor
                .map((i) => {
                  return i.user_id;
                })
                .includes(parseInt(filters[key]))
            ) {
              add = false;
            }
          }

          if (
            key !== "courses" &&
            key !== "coaches" &&
            key !== "id" &&
            key !== "start_time" &&
            key !== "end_time" &&
            (item[key] === undefined || item[key] !== filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });
    setData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          fullSuccessOpen
            ? fullSuccessOpen
            : notFullSuccessOpen
            ? notFullSuccessOpen
            : leadAttendaceSuccessOpen
            ? leadAttendaceSuccessOpen
            : fullErrorOpen
            ? fullErrorOpen
            : notFullErrorOpen
            ? notFullErrorOpen
            : leadAttendaceErrorOpen
            ? emailSuccessOpen
            : emailErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={
            fullSuccessOpen || notFullSuccessOpen || leadAttendaceSuccessOpen
              ? "success"
              : "error"
          }
          message={
            leadAttendaceSuccessOpen
              ? "Attendance Marked"
              : fullSuccessOpen
              ? "Marked batch full successfully"
              : notFullSuccessOpen
              ? "Marked batch not full successfully"
              : emailSuccessOpen
              ? "Email Successfully sent"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Regular Class Filters
            </span>
          </h3>
          {access["batches"] == 0 && (
            <div className="card-toolbar">
              <Link
                to="/batches/add-batch"
                className="btn btn-primary font-weight-bolder font-size-sm"
              >
                Add New Regular Class
              </Link>
            </div>
          )}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <BatchFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <ClassTable batches={data} showActions={true} loading={loading} />

      <ContentRoute path="/batches/batch-list/:id/delete-batch">
        {({ history, match }) => (
          <BatchDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:id/activate-batch">
        {({ history, match }) => (
          <BatchActivateDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
          />
        )}
      </ContentRoute>
      <ContentRoute path="/batches/batch-list/:id/batch-full">
        {({ history, match }) => (
          <BatchFullDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
            setFullSuccessOpen={setFullSuccessOpen}
            setFullErrorOpen={setFullErrorOpen}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:id/batch-not-full">
        {({ history, match }) => (
          <BatchNotFullDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
            setNotFullSuccessOpen={setNotFullSuccessOpen}
            setNotFullErrorOpen={setNotFullErrorOpen}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:batchId/points">
        {({ history, match }) => (
          <PointsDialog
            batchId={match && match.params.batchId}
            show={match != null}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:batchId/attendance/:dayIndex">
        {({ history, match }) => (
          <AttendanceDialog
            history={history}
            batchId={match && match.params.batchId}
            dayIndex={match && match.params.dayIndex}
            userId={authUser && authUser[0].user_id}
            show={match != null}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:trialId/lead-attendance">
        {({ history, match }) => (
          <LeadAttendanceDialog
            history={history}
            trialId={match && match.params.trialId}
            userId={authUser && authUser[0].user_id}
            show={match != null}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
            setLeadAttendaceSuccessOpen={setLeadAttendaceSuccessOpen}
            setLeadAttendaceErrorOpen={setLeadAttendaceErrorOpen}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/batches/batch-list/:batchId/email">
        {({ history, match }) => (
          <EmailDialog
            history={history}
            routePath={"/batches/batch-list"}
            camp_ind={false}
            id={match && match.params.batchId}
            branch_id={branch && branch.branch_id}
            userId={authUser && authUser[0].user_id}
            show={match != null}
            onHide={() => {
              history.push("/batches/batch-list");
            }}
            setEmailSuccessOpen={setEmailSuccessOpen}
            setEmailErrorOpen={setEmailErrorOpen}
          />
        )}
      </ContentRoute>
    </div>
  );
};
