/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { actions } from "../../modules/Auth/_redux/authRedux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const BranchesActionDropdownToggle = forwardRef((props, ref) => {
  const currBranch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  return (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      id="kt_subheader_quick_actions"
      className="btn btn-white font-weight-bold py-3 px-6"
    >
      <LocationOnIcon />{" "}
      {currBranch ? currBranch.branch_name : "No Branch Selected"}
    </button>
  );
});

export function BranchActions() {
  const dispatch = useDispatch();
  const setBranch = (branch) => {
    dispatch(actions.setBranch(branch));
  };
  const branches = useSelector((state) => state.auth.branches, shallowEqual);
  const history = useHistory();
  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="quick-actions-tooltip">Switch Branch</Tooltip>}
      >
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            as={BranchesActionDropdownToggle}
            id="dropdown-toggle-quick-actions-subheader"
          />

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right" style={{height: "250px", overflowY:"scroll"}}>
            <ul className="navi navi-hover py-5">
              {branches &&
                branches.branchList.sort(function(a , b) {
                  return (a.branch_name < b.branch_name) ? -1 : 1
                }).map((branch, index) => (
                  <li className="navi-item" key={index}>
                    <a
                      href="#"
                      className="navi-link"
                      key={branch.branch_id}
                      onClick={() => {
                        setBranch(branch)
                          history.push("/");
                      }}
                    >
                      <span className="navi-icon">
                        <i className="flaticon2-drop"></i>
                      </span>
                      <span className="navi-text">{branch.branch_name}</span>
                    </a>
                  </li>
                ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    </>
  );
}
