/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useInstructorProfileContext } from "./InstructorProfileDataContext";

export function InstructorProfileToggleDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const context = useInstructorProfileContext();
  // event Context

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  const activateInstructorProfile = async () => {
    const activateUrl = "api/coaches-profile/toggle/";

    //edit event post
    const result = await axios.patch(activateUrl + id);
    if (result.data === "success") {
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/branch-maintenance/instructor-profiles/instructor-profiles-list");
      }, 1000);
    } else {
      //setEditErrorOpen(true);
    }
    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        Instructor Profile {active_value == 1 ? "Activate" : "De-activate"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to {active_value == 1 ? "activate" : "de-activate"} this profile?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={activateInstructorProfile}
            className="btn btn-delete btn-elevate"
          >
            {active_value == 1 ? "Activate" : "De-activate"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
