/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const EventTableActions = ({
  row,
  openEditEventPage,
  openDeleteEventDialog,
  openActivateEventDialog,
  openEventFullDialog,
  openEventNotFullDialog,
  openDuplicateEventPage,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit Event</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            //console.log(row);
            openEditEventPage(row.workshop_id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      {row.active_id === 1 ? (
        <span>
          {/* Asad remember to add stuff to reactivate */}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-delete-tooltip">Deactivate Event</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => openDeleteEventDialog(row.workshop_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-power-off text-success"></i>
              </span>
            </a>
          </OverlayTrigger>
          {row.full_ind !== 1 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark as Full
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => openEventFullDialog(row.workshop_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-box-open text-primary"></i>
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Mark as Not Full
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-danger btn-hover-primary btn-sm"
                onClick={() => openEventNotFullDialog(row.workshop_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="fa fa-box text-default"></i>
                </span>
              </a>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-duplicate-tooltip">
                Duplicate Event
              </Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => openDuplicateEventPage(row.workshop_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <i className="far fa-copy text-success"></i>
              </span>
            </a>
          </OverlayTrigger>
        </span>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Activate Event</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openActivateEventDialog(row.workshop_id)}
          >
            <i className="fa fa-power-off"></i>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
};
