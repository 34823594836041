// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import {
  Input,
  TextArea,
  Select,
  SelectCreatable,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { useEventContext } from "./EventDataContext";
import { PersistFormikValues } from "formik-persist-values";

// Validation schema
const EventEditSchema = Yup.object().shape({
  active_id: Yup.string(),
  fee: Yup.number()
    .min(0, "0 is minimum")
    .required("Fee is required"),
  image: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
    .required("An image URL is required")
    .nullable()
    .test("valid-image", "Image URL is not valid", (image) => {
      if (image !== null) {
        return image.value.match(/\.(jpeg|jpg|png|webp)$/) !== null;
      } else {
        return true;
      }
    }),
  description: Yup.string()
    .required("Description is required"),
  max_students: Yup.number()
    .min(1, "1 is minimum")
    .required("A max enrollment is required"),
  grade_min_nbr: Yup.number()
    .min(1, "1 is minimum")
    .max(13, "13 is maximum")
    .required("A min grade is required"),
  grade_max_nbr: Yup.number()
    .min(1, "1 is minimum")
    .max(13, "13 is maximum")
    .when('grade_min_nbr', {
      is: (grade_min_nbr => grade_min_nbr > 1),
      then: Yup.number()
        .min(Yup.ref("grade_min_nbr"), "Max Grade should be greater than or equal Min Grade")
    })
    .required("A max grade is required"),
  startdate: Yup.date()
    .required("Start Date is required"),
  enddate: Yup.date()
    .when('startdate', (startdate, schema) => {
      return startdate ?
      schema.min(startdate, "Start Date must be before End Date")
      : schema
    })
    .required("End Date is required"),
    start_time: Yup.string(),
    end_time: Yup.string()
      .test("is-greater", "end time should be greater", function(value) {
        const { start_time } = this.parent;
        return typeof start_time !== "undefined" ? moment(value, "hh:mm").isAfter(moment(start_time, "hh:mm")) : true;
      }),
  price_subheader: Yup.string(),

  workshop_name: Yup.string().required("Event Name is required"),

  form_link: Yup.string().required("Form link is required"),
  full_ind: Yup.string(),
});

export function EventEditForm({ event, btnRef, saveEvent, defaultImages }) {
  const DROPDOWN_TYPE = useEventContext().TYPES;
  const [images, setImages] = useState();
  const [selectedImage, setSelectedImage] = useState();

  // console.log("event", event);

  const resetImages = async () => {
      event.image = defaultImages[0];
      setSelectedImage(defaultImages[0]);
      setImages(defaultImages)
  };

  useEffect(() => {
    setImages([]);
    setSelectedImage(event.image)
  }, [event.workshop_id]);

  // populate dropdown with images
  useEffect(() => {
    

    images && images.length === 0 && !event.workshop_id && resetImages();
  }, [selectedImage]);


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={event}
        validationSchema={EventEditSchema}
        onSubmit={(values) => {
          saveEvent(values);
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-md-3">
                  <Select name={`active_id`} label="Event Active">
                    {DROPDOWN_TYPE.ACTIVE &&
                      DROPDOWN_TYPE.ACTIVE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-md-3">
                  <Select name="full_ind" label="Event Full?">
                    <option key={0} value={0}>
                      {" "}
                      Not Full
                    </option>
                    <option key={1} value={1}>
                      {" "}
                      Full
                    </option>
                  </Select>
                </div>
              </div>
              {/* {console.log(errors)} */}
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>
                    Enter Event Name
                  </label>
                  <Field
                    name="workshop_name"
                    component={Input}
                    placeholder="Event Name"
                    type="text"
                  />
                </div>
                <div className="col-lg-3">
                  <label className={"required-field"}>Enter Event Fee</label>
                  <Field
                    name="fee"
                    component={Input}
                    placeholder="Fee"
                    type="number"
                  />
                </div>
                <div className="col-lg-3">
                  <label className={"required-field"}>Enter Event Price Sub Header</label>
                  <Field
                    name="price_subheader"
                    component={Input}
                    placeholder="price_subheader"
                    type="text"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className={"col-lg-6"}>
                  <label className={"required-field"}>Event Start Date</label>
                  <Field
                    name="startdate"
                    component={Input}
                    type="date"
                    value={values.startdate.split("T")[0]}
                  />
                </div>
                <div className={"col-lg-6"}>
                  <label className={"required-field"}>Event End Date</label>
                  <Field
                    name="enddate"
                    component={Input}
                    type="date"
                    value={values.enddate.split("T")[0]}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className={"col-lg-6"}>
                  <Field
                    name="start_time"
                    component={Input}
                    type="time"
                    placeholder="Start Time"
                    label="Start Time"
                  />
                </div>
                <div className={"col-lg-6"}>
                  <Field
                    name="end_time"
                    component={Input}
                    type="time"
                    placeholder="End Time"
                    label="End Time"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>
                    Create or Select Event Image Link
                  </label>
                  <SelectCreatable
                    name="image"
                    options={images}
                    isClearable
                    placeholder="Image link"
                    defaultValue={selectedImage}
                    value={selectedImage}
                    onChange={(newSelectedImage) => {
                      setSelectedImage(newSelectedImage);
                      values.image = newSelectedImage;
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className={"required-field"}>
                    Enter Event Form Link
                  </label>
                  <Field
                    name="form_link"
                    component={Input}
                    placeholder="Form link"
                    type="text"
                  />
                </div>
              </div>
              <div className="form-group row">
              <div className="col-lg-6">
                  <label className={"required-field"}>Enter Min Grade</label>
                  <Field
                    name="grade_min_nbr"
                    component={Input}
                    placeholder="1"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Max Grade</label>
                  <Field
                    name="grade_max_nbr"
                    component={Input}
                    placeholder="12"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Max Students</label>
                  <Field
                    name="max_students"
                    component={Input}
                    placeholder="0"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <label className={"required-field"}>
                    Enter Event Description
                  </label>
                  <Field
                    name="description"
                    placeholder="Enter Event Description"
                    component={TextArea}
                    rows="5"
                    cols="50"
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
              {/* {event.workshop_id && <PersistFormikValues name="event-form" />} */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
