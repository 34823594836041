import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { EventActivateDialog } from "./Components/EventActivateDialog";
import { useEventContext } from "./Components/EventDataContext";
import { EventDeleteDialog } from "./Components/EventDeleteDialog";
import { EventFilter } from "./Components/EventFilter";
import { EventFullDialog } from "./Components/EventFullDialog";
import { EventNotFullDialog } from "./Components/EventNotFullDialog";
import EventTable from "./Components/EventTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";

export const EventList = () => {
  const [eventData, setEventData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const context = useEventContext();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Event List");
  const eventsUrl = "api/workshops/workshops/";

  async function fetchEventsForBranch() {
    setLoading(true);
    const result = await axios(eventsUrl + branch.branch_id);


    setEventData(result.data.results);
    setOrigData(result.data.results);
    setLoading(false);
  }

  //success error messages
  const [fullSuccessOpen, setFullSuccessOpen] = useState(false);
  const [notFullSuccessOpen, setNotFullSuccessOpen] = useState(false);
  const [fullErrorOpen, setFullErrorOpen] = useState(false);
  const [notFullErrorOpen, setNotFullErrorOpen] = useState(false);

  const handleClose = () => {
    setFullSuccessOpen(false);
    setNotFullSuccessOpen(false);
    setFullErrorOpen(false);
    setNotFullErrorOpen(false);
  };

  //fetching events for a branch
  useEffect(() => {
    branch && fetchEventsForBranch();
  }, [branch]);

  //fetching events for a branch
  useEffect(() => {
    context.refetch && fetchEventsForBranch();
    context.setRefetch(false);
  }, [context.refetch]);

  //Filter Data based on search
  const filterData = (filters) => {
    const newData = origData.filter(function(item) {
      var add = true;

      for (let key in filters) {
        if (filters[key] != "") {
          if (key === "id") {
            if (!(item["workshop_id"] + "").includes(filters[key])) {
              add = false;
            }
          }
          if (key === "date") {
            if (
              !moment(filters[key]).isBefore(
                moment(item["enddate"], "yyyy-MM-DD").add(1, "days")
              )
            ) {
              add = false;
            }
          }

          if (
            key !== "id" && key !== "date" &&
            (item[key] === undefined || item[key] != filters[key])
          ) {
            return false;
          }
        }
      }
      return add && true;
    });

    setEventData(newData);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          fullSuccessOpen
            ? fullSuccessOpen
            : notFullSuccessOpen
            ? notFullSuccessOpen
            : fullErrorOpen
            ? fullErrorOpen
            : notFullErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={fullSuccessOpen || notFullSuccessOpen ? "success" : "error"}
          message={
            fullSuccessOpen
              ? "Marked event full successfully"
              : fullSuccessOpen
              ? "Marked event not full successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Event Filters
            </span>
          </h3>
          <div className="card-toolbar">
            <Link
              to="/events/add-event"
              className="btn btn-primary font-weight-bolder font-size-sm"
            >
              Add New Event
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          <EventFilter
            filterData={filterData}
            origData={origData}
            loading={loading}
          />
        </div>
      </div>

      <EventTable events={eventData} showActions={true} loading={loading} />

      <ContentRoute path="/events/event-list/:id/delete-event">
        {({ history, match }) => (
          <EventDeleteDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/events/event-list");
            }}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/events/event-list/:id/activate-event">
        {({ history, match }) => (
          <EventActivateDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/events/event-list");
            }}
          />
        )}
      </ContentRoute>
      <ContentRoute path="/events/event-list/:id/event-full">
        {({ history, match }) => (
          <EventFullDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/events/event-list");
            }}
            setFullSuccessOpen={setFullSuccessOpen}
            setFullErrorOpen={setFullErrorOpen}
          />
        )}
      </ContentRoute>

      <ContentRoute path="/events/event-list/:id/event-not-full">
        {({ history, match }) => (
          <EventNotFullDialog
            history={history}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/events/event-list");
            }}
            setNotFullSuccessOpen={setNotFullSuccessOpen}
            setNotFullErrorOpen={setNotFullErrorOpen}
          />
        )}
      </ContentRoute>
    </div>
  );
};
