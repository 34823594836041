export const LMSvsPortalActivityHeaders = [
  {
    id: "student_id",
    numeric: false,
    disablePadding: false,
    label: "Student ID",
  },
  {
    id: "student_name",
    numeric: false,
    disablePadding: false,
    label: "Student Name",
  },
  {
    id: "batch.start_date",
    numeric: false,
    disablePadding: false,
    label: "Batch Start Date",
  },
  {
    id: "batch.end_date",
    numeric: false,
    disablePadding: false,
    label: "Batch End Date",
  },
  {
    id: "course_name",
    numeric: false,
    disablePadding: false,
    label: "Stream Name",
  },
  {
    id: "course_code",
    numeric: false,
    disablePadding: false,
    label: "Stream Code",
  },
  {
    id: "sub_course_code",
    numeric: false,
    disablePadding: false,
    label: "Sub Course",
  },

  
];