/* eslint-disable jsx-a11y/anchor-is-valid */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import csc from "country-state-city";
import { useFormik } from "formik";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { zip } from "lodash";
import { getFormattedNumber } from "../../helpers/Formatter";

let usStates = [
  { name: "Alabama", isoCode: "AL" },
  { name: "Alaska", isoCode: "AK" },
  { name: "Arizona", isoCode: "AZ" },
  { name: "Arkansas", isoCode: "AR" },
  { name: "California", isoCode: "CA" },
  { name: "Colorado", isoCode: "CO" },
  { name: "Connecticut", isoCode: "CT" },
  { name: "Delaware", isoCode: "DE" },
  { name: "District of Columbia", isoCode: "DC" },
  { name: "Florida", isoCode: "FL" },
  { name: "Georgia", isoCode: "GA" },
  { name: "Hawaii", isoCode: "HI" },
  { name: "Idaho", isoCode: "ID" },
  { name: "Illinois", isoCode: "IL" },
  { name: "Indiana", isoCode: "IN" },
  { name: "Iowa", isoCode: "IA" },
  { name: "Kansas", isoCode: "KS" },
  { name: "Kentucky", isoCode: "KY" },
  { name: "Louisiana", isoCode: "LA" },
  { name: "Maine", isoCode: "ME" },
  { name: "Maryland", isoCode: "MD" },
  { name: "Massachusetts", isoCode: "MA" },
  { name: "Michigan", isoCode: "MI" },
  { name: "Minnesota", isoCode: "MN" },
  { name: "Mississippi", isoCode: "MS" },
  { name: "Missouri", isoCode: "MO" },
  { name: "Montana", isoCode: "MT" },
  { name: "Nebraska", isoCode: "NE" },
  { name: "Nevada", isoCode: "NV" },
  { name: "New Hampshire", isoCode: "NH" },
  { name: "New Jersey", isoCode: "NJ" },
  { name: "New Mexico", isoCode: "NM" },
  { name: "New York", isoCode: "NY" },
  { name: "North Carolina", isoCode: "NC" },
  { name: "North Dakota", isoCode: "ND" },
  { name: "Ohio", isoCode: "OH" },
  { name: "Oklahoma", isoCode: "OK" },
  { name: "Oregon", isoCode: "OR" },
  { name: "Pennsylvania", isoCode: "PA" },
  { name: "Rhode Island", isoCode: "RI" },
  { name: "South Carolina", isoCode: "SC" },
  { name: "South Dakota", isoCode: "SD" },
  { name: "Tennessee", isoCode: "TN" },
  { name: "Texas", isoCode: "TX" },
  { name: "Utah", isoCode: "UT" },
  { name: "Vermont", isoCode: "VT" },
  { name: "Virginia", isoCode: "VA" },
  { name: "Washington", isoCode: "WA" },
  { name: "West Virginia", isoCode: "WV" },
  { name: "Wisconsin", isoCode: "WI" },
  { name: "Wyoming", isoCode: "WY" },
];

var phoneRegExp = /^\([0-9]{3}\) - [0-9]{3} - [0-9]{4}/;
const Schema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  mobile: Yup.string()
  .matches(phoneRegExp, "Please enter a phone number")
  .min(18, "Phone Number must be exactly 10 digits")
  .required("Phone Number is required")
  .test(
    "valid-number",
    "Sorry, only numbers (0-9) are allowed",
    (value, context) => {
      return (
        value &&
        value.split("@").length > 0 &&
        value.split("@")[0].match(/[(0-9)(\-)(\()(\))( ))]*/g)[0] ==
          value.split("@")[0]
      );
    }
  ),
  homephone: Yup.string(),
  address: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string(),
  zip: Yup.string().required("Zip is required"),
  refferal: Yup.string(),
  email: Yup.string()
    .email("Wrong email format")
    .required("Email is required"),
  alternate_email: Yup.string().email("Wrong email format"),
  password: Yup.string().required("Password is required"),
  alt_refferal: Yup.string(),
});

export const AddParentForm = ({
  history,
  id,
  initialValues,
  parents,
  setParent,
  duplicateCheck,
  saveParent,
  students,
  existingStudents,
  parent,
  isNew,
}) => {
  // Fields
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [states, setStates] = useState();
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [studentChosen, setStudentChosen] = useState([]);
  const familyStudents = useSelector((state) => state.family.students);
  const searchId = useSelector((state) => state.family.searchid);
  const [showingStudents, setShowingStudents] = useState([]);
  const [branch, setBranch] = useState();

  //getbranch
  const branchId = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  // useEffect(() => {
  //   console.log("formik.touched: ", formik.touched);
  //   console.log("formik.values.parentType: ", formik.values.parentType);
  //   console.log("formik.values: ", formik.values);
  //   console.log("formik.values: ", formik.values);
  //   console.log("formik.values: ", formik.values);
  //   console.log("formik.values.parentType: ", formik.values.parentType);
  //   console.log("parent", parent);
  //   console.log("formik.isValid", formik.isValid);
  //   console.log("formik.isSubmitting", formik.isSubmitting);
  // }, [formik ? formik.values : branchId]);

  useEffect(() => {
    const getBranchCountry = async () => {
      const result = await axios.get(
        "api/branches/details/" + branchId.branch_id
      );
      setBranch(result.data.results[0]);
    };

    getBranchCountry();
  }, [branchId]);

  // Methods
  //get all countries
  useEffect(() => {
    const localcountries = csc.getAllCountries();

    let newList = localcountries.filter((x) => {
      return x.name == "Canada" || x.name == "United States";
    });
    newList.push(
      ...localcountries.filter((x) => {
        return x.name != "Canada" && x.name != "United States";
      })
    );

    setCountries(newList);
    if (id && initialValues && initialValues.country) {
      let dbCountry = newList.filter((x) => {
        return (
          x.isoCode == initialValues.country || x.name == initialValues.country
        );
      })[0].isoCode;

      getStates(dbCountry);
    } else if (branch) {
      // console.log(
      //   branch,
      //   branch.country_name,
      //   csc.getAllCountries(),
      //   csc.getAllCountries().filter((x) => {
      //     return (
      //       x.name.toUpperCase() == branch.country_name.toUpperCase() ||
      //       (branch.country_name.toUpperCase() == "USA" &&
      //         x.name == "United States")
      //     );
      //   })
      // );
      let country = csc.getAllCountries().filter((x) => {
        return (
          x.name.toUpperCase() == branch.country_name.toUpperCase() ||
          (branch.country_name.toUpperCase() == "USA" &&
            x.name == "United States")
        );
      })[0];
      getStates(country.isoCode);
    } else {
      getStates("CA");
    }
  }, [branch]);

  useEffect(() => {
    // console.log("isNew", isNew);
    if (isNew == "new") {
      formik.values.parentType = "new";
    }
  }, []);

  useEffect(() => {
    if (existingStudents) {
      let show = [];
      show = familyStudents.map((s) => {
        return { value: s.user_id, label: s.name };
      });
      show.push(
        ...existingStudents
          .filter((s2) => {
            return (
              familyStudents.filter((s) => {
                return s2.user_id == s.user_id;
              }).length == 0
            );
          })
          .map((s) => {
            return { value: s.user_id, label: s.studentName };
          })
      );
      setStudentChosen(show);
    } else {
      let show = familyStudents.map((s) => {
        return { value: s.user_id, label: s.name };
      });
      setStudentChosen(show);
    }
  }, [existingStudents]);

  //get all states of country
  const getStates = (country) => {
    setSelectedCountry(country);

    // console.log(country);
    let localStates =
      country == "US" || country == "USA"
        ? usStates
        : csc.getStatesOfCountry(country);
    setStates(localStates);

    if (id && initialValues && initialValues.state) {
      // console.log(initialValues.state, localStates);
      let dbState = localStates.filter((x) => {
        return (
          x.name == initialValues.state || x.isoCode == initialValues.state
        );
      })[0];

      dbState && setSelectedState(dbState.name);
    } else if (branch) {
      // console.log(
      //   branch.state,
      //   localStates,
      //   localStates.filter((x) => {
      //     return (
      //       x.isoCode == branch.state ||
      //       x.name.toUpperCase() == branch.state.toUpperCase()
      //     );
      //   })
      // );
      let branchState = localStates.filter((x) => {
        return (
          x.name == branch.state ||
          x.name.toUpperCase() == branch.state.toUpperCase() ||
          x.isoCode.toUpperCase() == branch.state.toUpperCase()
        );
      })[0];
      branchState
        ? setSelectedState(branchState.name)
        : setSelectedState(localStates[0].name);
      formik.setFieldValue(
        "state",
        branchState ? branchState.name : localStates[0].name
      );
    } else {
      setSelectedState(localStates[0].name);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const existingParentAutocomplete = () => {
    return (
      <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Select a Parent
        </label>
        <div className="col-lg-9 col-xl-6">
          <Autocomplete
            options={parents}
            getOptionLabel={(p) => p.parentName}
            id="parents"
            //value={student}
            onChange={(event, newParent) => {
              if (newParent) {
                setParent(newParent);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </div>
      </div>
    );
  };

  const studentMultiSelect = () => {
    return (
      <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Select a Student
        </label>
        <div className="col-lg-9 col-xl-6">
          <Select
            key={""}
            name="students"
            className="react-select"
            options={students.map((s) => {
              return { value: s.user_id, label: s.studentName };
            })}
            onChange={(val) => {
              if (val) {
                // console.log("val",val)
                // let newList = []
                // let alreadyInList = studentChosen.filter((student) => {return student.value != val.value})
                // alreadyInList.length == studentChosen.length ? console.log("Already in list") : console.log("Not already in list")
                // newList = alreadyInList.length != studentChosen.length ? [...val, ...studentChosen] : [alreadyInList]
                // console.log("newList", newList, "alreadyInList",alreadyInList)
                setStudentChosen(val);
              }
            }}
            value={studentChosen}
            isMulti
          />
        </div>
        {studentChosen.length < 1 ? (
          <div className="text-default">Please Select At Least One Parent</div>
        ) : null}
      </div>
    );
  };

  const mainForm = () => {
    return (
      // console.log(formik.errors),
      (
        <div>
          {/* begin::Heading */}

          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              First Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "firstname"
                  )}`}
                  name="firstname"
                  {...formik.getFieldProps("firstname")}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="invalid-feedback">
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Last Name
            </label>

            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "lastname"
                  )}`}
                  name="lastname"
                  {...formik.getFieldProps("lastname")}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="invalid-feedback">
                    {formik.errors.lastname}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Email Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Phone
            </label>

            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "mobile"
                  )}`}
                  name="mobile"
                  {...formik.getFieldProps("mobile")}
                  onChange={(e) => {
                    let phoneNumber = getFormattedNumber(e.target.value);
                    formik.setFieldValue("mobile", phoneNumber);
                  }}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="invalid-feedback">{formik.errors.mobile}</div>
                ) : null}
              </div>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Gender</label>
            <div className="col-lg-9 col-xl-6">
              <select
                className="form-control form-control-lg form-control-solid"
                name="gender"
                {...formik.getFieldProps("gender")}
              >
                <option value="">Select Gender...</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="separator separator-dashed my-5"></div>
          {/* begin::Form Group */}
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Address:</h5>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Street
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Street"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "address"
                )}`}
                name="address"
                {...formik.getFieldProps("address")}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="invalid-feedback">{formik.errors.address}</div>
              ) : null}
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              City
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="City"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "city"
                )}`}
                name="city"
                {...formik.getFieldProps("city")}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="invalid-feedback">{formik.errors.city}</div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Country
            </label>
            <div className="col-lg-9 col-xl-6">
              {/* <input
            type="text"
            placeholder="Country"
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "country"
            )}`}
            name="country"
            {...formik.getFieldProps("country")}
          /> */}
              <select
                name="country"
                label="Country"
                value={selectedCountry}
                onChange={(e) => {
                  formik.setFieldValue(
                    "country",
                    countries.filter((c) => {
                      return c.isoCode == e.target.value;
                    })[0].name
                  );
                  getStates(e.target.value);
                }}
                className="form-control form-control-lg form-control-solid"
              >
                {countries &&
                  countries.map((a) => (
                    <option key={a.isoCode} value={a.isoCode}>
                      {a.name}
                    </option>
                  ))}
              </select>
              {formik.touched.country && formik.errors.country ? (
                <div className="invalid-feedback">{formik.errors.country}</div>
              ) : null}
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              State
            </label>
            <div className="col-lg-9 col-xl-6">
              {/* <input
            type="text"
            placeholder="State"
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "state"
            )}`}
            name="state"
            {...formik.getFieldProps("state")}
          /> */}
              <select
                name="state"
                label="State"
                value={selectedState}
                className="form-control form-control-lg form-control-solid"
                onChange={(e) => {
                  formik.setFieldValue(
                    "state",
                    states.filter((c) => {
                      return (
                        c.name == e.target.value || c.isoCode == e.target.value
                      );
                    })[0].name
                  );
                  setSelectedState(
                    states.filter((c) => {
                      return (
                        c.name == e.target.value || c.isoCode == e.target.value
                      );
                    })[0].name
                  );
                }}
              >
                {states &&
                  states.map((a) => (
                    <option key={a.isoCode} value={a.name}>
                      {a.name}
                    </option>
                  ))}
              </select>
              {formik.touched.state && formik.errors.state ? (
                <div className="invalid-feedback">{formik.errors.state}</div>
              ) : null}
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label required-field">
              Zip
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="zip"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "zip"
                )}`}
                name="zip"
                {...formik.getFieldProps("zip")}
              />
              {formik.touched.zip && formik.errors.zip ? (
                <div className="invalid-feedback">{formik.errors.zip}</div>
              ) : null}
            </div>
          </div>

          {/* begin::Form Group */}
          <div className="separator separator-dashed my-5"></div>
          {/* begin::Form Group */}
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Optional:</h5>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Alternate Phone
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Alternate Phone"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "homephone"
                )}`}
                name="homephone"
                {...formik.getFieldProps("homephone")}
                onChange={(e) => {
                  let phoneNumber = getFormattedNumber(e.target.value);
                  formik.setFieldValue("homephone", phoneNumber);
                }}
              />
              {formik.touched.homephone && formik.errors.homephone ? (
                <div className="invalid-feedback">
                  {formik.errors.homephone}
                </div>
              ) : null}
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Alternate Email
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Alternate Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "alternate_email"
                  )}`}
                  name="alternate_email"
                  {...formik.getFieldProps("alternate_email")}
                />
                {formik.touched.alternate_email &&
                formik.errors.alternate_email ? (
                  <div className="invalid-feedback">
                    {formik.errors.alternate_email}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              How Did You Hear About Us?
            </label>
            <div className="col-lg-9 col-xl-6">
              <select
                className="form-control form-control-lg form-control-solid"
                name="refferal"
                {...formik.getFieldProps("refferal")}
              >
                {[
                  "Google Search",
                  "Friends and Family",
                  "Social Media",
                  "Drive Thru",
                  "Company Email",
                  "Returning Student",
                  "Other",
                ].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </select>
              {formik.touched.refferal && formik.errors.refferal ? (
                <div className="invalid-feedback">{formik.errors.refferal}</div>
              ) : null}
            </div>
          </div>
          {formik.values.refferal == "Other" ? (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label"></label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder="How Did You Hear About Us?"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "alt_refferal"
                  )}`}
                  name="alt_refferal"
                  {...formik.getFieldProps("alt_refferal")}
                />
                {formik.touched.alt_refferal && formik.errors.alt_refferal ? (
                  <div className="invalid-feedback">
                    {formik.errors.alt_refferal}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )
    );
  };

  return (
    <form className="card card-custom">
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Parent</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            {id ? "Edit" : "Add"} parent information
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-success mr-2"
            disabled={
              !formik.touched || formik.values.parentType == ""
                ? true
                : formik.values && formik.values.parentType == "existing"
                ? id
                  ? !parent || !formik.isValid
                  : !parent && !formik.isValid
                : formik.isSubmitting || !formik.isValid
            }
            onClick={() => {
              duplicateCheck(
                {
                  ...formik.values,
                  country: countries.filter((c) => {
                    return c.isoCode == selectedCountry;
                  })[0].name,
                },
                formik.setStatus,
                formik.setSubmitting,
                studentChosen
              );
            }}
          >
            {id ? "Save" : "Add"}
            {formik.isSubmitting}
          </button>
          <Link
            to={searchId ? "/family-profile/" + searchId + "/" : "/dashboard"}
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          {id ? null : (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Add an...
              </label>

              <div className="col-lg-9 col-xl-6">
                <select
                  name="parentType"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "parentType"
                  )}`}
                  {...formik.getFieldProps("parentType")}
                >
                  {isNew != "new" ? <option value="">Choose...</option> : ""}
                  {isNew != "new" ? (
                    <option value="existing">Existing Parent</option>
                  ) : (
                    ""
                  )}

                  <option value="new">New Parent</option>
                </select>
                {formik.touched.parentType && formik.errors.parentType ? (
                  <div className="invalid-feedback">
                    {formik.errors.parentType}
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {/* Show the entire form if editing a student or adding a new student, but not if adding an existing student */}
          {(!id && formik.values.parentType == "new") || id ? mainForm() : null}
          <>
            {/* Show an autocomplete if adding an existing student */}
            {formik.values.parentType == "existing" && !id
              ? existingParentAutocomplete()
              : null}
            {students &&
            (formik.values.parentType == "new" ||
              formik.values.parentType == "existing")
              ? studentMultiSelect()
              : null}
          </>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
};
export default AddParentForm;
