/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export const ClassTableActions = ({
  row,
  openEditCampPage,
  openDeleteCampDialog,
  openDuplicateCampPage,
  openActivateCampDialog,
  openPointsDialog,
  openEmailDialog,
}) => {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  return (
    <>
      {access["batches"] == 0 ? (
        <div>
          {" "}
          <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Edit camp</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                // console.log(row);
                openEditCampPage(row.camp_id);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
          <> </>
          {row.active_value === "Active" ? (
            <span>
              {/* Asad remember to add stuff to reactivate */}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-delete-tooltip">
                    Deactivate Camp
                  </Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() => openDeleteCampDialog(row.camp_id)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <i className="fa fa-power-off text-success"></i>
                  </span>
                </a>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip id="products-duplicate-tooltip">
                    Duplicate Camp
                  </Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() => openDuplicateCampPage(row.camp_id)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <i className="far fa-copy text-success"></i>
                  </span>
                </a>
              </OverlayTrigger>
            </span>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">Activate Camp</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => openActivateCampDialog(row.camp_id)}
              >
                <i className="fa fa-power-off"></i>
              </a>
            </OverlayTrigger>
          )}{" "}
          <OverlayTrigger
            overlay={
              <Tooltip id="products-delete-tooltip">Add/Remove Points</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
              onClick={() => openPointsDialog(row.camp_id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}
                ></SVG>
              </span>
            </a>
          </OverlayTrigger>
          {access["batches"] == 0 && (
            <OverlayTrigger
              overlay={<Tooltip id="products-delete-tooltip">Email</Tooltip>}
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
                onClick={() => openEmailDialog(row.camp_id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Mail.svg"
                    )}
                  ></SVG>
                </span>
              </a>
            </OverlayTrigger>
          )}
        </div>
      ) : (
        <div className="badge badge-primary text-white"> Read Only Access</div>
      )}
    </>
  );
};
