import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link, Redirect, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { resetPassword } from "../_redux/authCrud";
import axios from "axios";

const initialValues = {
  username: "",
  newPassword: "",
};

function ResetPassword(props) {
  const { intl } = props;
  let { token } = useParams();
  const [isReset, setIsReset] = useState(false);

  const [tokenError, setTokenError] = useState(false);

  // console.log(token);
  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(20, "Maximum 20 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  //set the token as param
  useEffect(() => {
    try {
      async function reset() {
        const res = await axios.get(`/api/auth/reset-password`, {
          params: {
            //set password reset token from the url parameter
            resetPasswordToken: token,
          },
        });
        console.log(res);
        res.data.message === "Password reset link has been verified"
          ? setTokenError(false)
          : setTokenError(true);
        initialValues.username = res.data.username;
      }
      reset();
    } catch (err) {
      setTokenError(true);
      console.error(err.response.data);
    }
    // eslint-disable-next-line
  }, []);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      resetPassword(values)
        .then(() => setIsReset(true))
        .catch((err) => {
          console.log(err);
          setIsReset(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_PASSWORD_FIELD" })
          );
        });
    },
  });

  return (
    <>
      {isReset && <Redirect to="/auth" />}
      {!isReset && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          {!tokenError && (
            <div>
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Reset Password</h3>
                <div className="text-muted font-weight-bold">
                  Enter your new password.
                </div>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="newPassword"
                    {...formik.getFieldProps("newPassword")}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.newPassword}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </button>
                  <Link to="/auth">
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          )}

          {tokenError && (
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Invalid Reset Link</h3>
              <div className="text-muted font-weight-bold">
                This reset password link is either invalid or expired.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default injectIntl(ResetPassword);
