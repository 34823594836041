import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, useSubheader } from "../../../../_metronic/layout";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import { TimesheetFilter } from "../Components/TimesheetFilter";
import TimesheetTable from "../Components/TimesheetTable";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { Button } from "react-bootstrap";
import { FieldArray, useFormik, FormikProvider } from "formik";
import ConfirmationModal from "../Components/ConfirmationModal";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { InputWithLimit } from "../../../../_metronic/_partials/controls/forms/InputWithLimit";
import { checkAndReplace } from "../../../helpers/Formatter";


const TIMESHEET_URL = "api/timesheet";

const INITIAL_VALUES = {                                                                                                                                                                                                       
  user_id: 0,
  timesheet_week_id: 0,
  comment: "", 
  timesheet_tasks: [],
  date: moment().startOf("week").day(1).format("yyyy-MM-DD"),
  total_hours: 0,
  created_by: 0,
  modified_by: 0,
  approve_on: null, 
  approve_by: 0,
  request_approve_on: null,
};

const NEW_TIMESHEET_TASK = {
  task_id: 0,
  branch_id: 0,
  created_on: moment().format("yyyy-MM-DD"),
  modified_on: moment().format("yyyy-MM-DD"),
  is_default: false,
  timesheet_entries: {
    0: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    1: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    2: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    3: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    4: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    5: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
    6: { timesheet_entry_id: 0, hours: 0, date: moment().format("yyyy-MM-DD"), },
  },
};

const yup_schema = Yup.object().shape({
  rows: Yup.array().of(
    Yup.object().shape({
      task_id: Yup.number().required(),
      branch_id: Yup.number().required(),
      timesheet_entries: Yup.array().of(
        Yup.object().shape({
          hours: Yup.number().required()
          .min(0, "Hours must be greater than 0")
          .max(24, "Hours must be less than 24"),
          date: Yup.string().required(),
        }),
      ),
    }),
  ),
});

/**
 * Update all dates to format (yyyy-MM-DD) in the timesheet values object
 * @date 2022-07-12
 * @param {timesheet} timesheet - The timesheet object to update
 */
const updateTimesheetDatesToLocal = (timesheet) => {
  
  timesheet.created_on = moment(timesheet.created_on).format("yyyy-MM-DD");
  timesheet.date = moment(timesheet.date).format("yyyy-MM-DD");
  // timesheet.modified_on = moment(timesheet.modified_on).format("yyyy-MM-DD");
  timesheet.request_approve_on = timesheet.request_approve_on ? moment(timesheet.request_approve_on).format("yyyy-MM-DD") : null;

  for (const task of timesheet.timesheet_tasks) {
    // task.created_on = moment(task.created_on).format("yyyy-MM-DD");
    // task.modified_on = moment(task.modified_on).format("yyyy-MM-DD");
    for (const entry of Object.values(task.timesheet_entries)) {
      entry.date = moment(entry.date).format("yyyy-MM-DD");
    }
  }
  return timesheet;
}

export const TimesheetList = ({
  history,
}) => {
  const timesheetStates = useTimesheetContext().states;
  const [loading, setLoading] = useState(false);
  const [timesheetTasks, setTimesheetTasks] = useState([]);
  const [timesheetValues, setTimesheetValues] = useState(INITIAL_VALUES);

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { currentBranch, branchAccessList, userId } = useSelector(
    ({ auth }) => ({
      currentBranch: auth.branches.currentBranch,
      branchAccessList: auth.branches && auth.branches.branchList,
      userId: auth.user && auth.user[0].user_id,
    }),
    shallowEqual
  );

  const suhbeader = useSubheader();
  suhbeader.setTitle("Timesheet List");

  const formik = useFormik({
    initialValues: timesheetValues,
    validationSchema: yup_schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      // console.log(values);
      setLoading(false);
    }
  });

  /**
   * Helper function to update snackbar state
   * @date 2022-07-12
   */
  const handleClose = () => {
    setSuccessSnackbarMessage("");
    setErrorSnackbarMessage("");
    setShowSnackbar(false);
  };

  /**
   * Update timesheet user id values to the current user id - used for creating new timesheets in initization
   * @date 2022-07-12
   * @param {timesheet} timesheet
   */
  const updateTimesheetWeekIds = (timesheet) => {
    if (timesheet.user_id == 0) {
      timesheet.user_id = userId
      timesheet.created_by = userId
      timesheet.modified_by = userId
    }
  };

  /**
   * Update timesheet task entries according to current date used in DataContext (timesheetStates.date)
   * @date 2022-07-12
   * @param {timesheet} timesheet - The timesheet object to update
   */
  const updateTimesheetEntryDates = (timesheet_task) => {
    for (let i = 0; i <= 6; i++) {
      timesheet_task.timesheet_entries[i].date = moment(timesheetStates.date).day(i+1).format("yyyy-MM-DD");
    }
    return timesheet_task;
  }


  /**
   * Update timesheet tasks with branch id set to null to -1, to be interpreted as "coporate"
   * @date 2022-07-18
   * @param {timesheet_task} timesheet_task
   */
  const updateCorporateTask = (timesheet_task) => {
    if (timesheet_task.branch_id == null) {
      timesheet_task.branch_id = -1;
    }
    return timesheet_task;
  }

  /**
   * Refresh the timesheet object with the current date/week and user id
   * @date 2022-07-12
   */
  const fetchTimesheetsForBranch = async () => {
    setLoading(true);
    const response = await axios.get(`${TIMESHEET_URL}/${userId}/${timesheetStates.date}`);
    // console.log("response", response.data);

    // if theres is a timesheet record for the current date/week, populate the form with the values
    if (response.data.timesheet_week_id != 0) {
      const timesheet_values = updateTimesheetDatesToLocal(response.data);
      if (timesheet_values.timesheet_tasks.length === 0) {
        timesheet_values.timesheet_tasks = [Object.assign({}, updateTimesheetEntryDates(NEW_TIMESHEET_TASK))];
      } else {

        // should sort week tasks.branches according to the date created
        timesheet_values.timesheet_tasks.sort((a, b) => {
          return moment(a.created_on).diff(moment(b.created_on));
        });

        timesheet_values.timesheet_tasks.forEach((task) => {
          updateTimesheetEntryDates(task);
        });

        timesheet_values.timesheet_tasks.forEach((task) => {
          updateCorporateTask(task);
        });
      }

      // timesheet_values.timesheet_tasks = timesheet_values.timesheet_tasks.filter((task) => task.task_id != 0 || task.branch_id != 0);

      // console.log("timesheet_values", timesheet_values);

      setTimesheetValues(timesheet_values);

    } else {
      const new_timesheet_values = Object.assign({}, INITIAL_VALUES);
      if (response.data.timesheet_tasks.length === 0) {
        new_timesheet_values.timesheet_tasks = [updateTimesheetEntryDates(Object.assign({}, NEW_TIMESHEET_TASK))];
      } else {
        new_timesheet_values.timesheet_tasks = response.data.timesheet_tasks.map(updateTimesheetEntryDates);
        new_timesheet_values.timesheet_tasks.forEach((task) => {
          updateCorporateTask(task);
        });
      }
      new_timesheet_values.date = timesheetStates.date;
      updateTimesheetEntryDates(new_timesheet_values.timesheet_tasks[0]);
      updateTimesheetWeekIds(new_timesheet_values);
      setTimesheetValues(new_timesheet_values);
    }

    setLoading(false);
  }

  
  /**
   * Filter function to set the timesheet object to the current date/week - used in TimesheetFilter
   * @date 2022-07-12
   * @param {filters} filters
   */
  const filterData = (filters) => {
    timesheetStates.setDate(filters.date);
  };

  /**
   * Add a new timesheet task to the timesheet object
   * @date 2022-07-12
   */
  const addNewTimesheetRow = () => {
    formik.setFieldValue("timesheet_tasks", [...formik.values.timesheet_tasks, updateTimesheetEntryDates(Object.assign({}, NEW_TIMESHEET_TASK))]);
  };

  /**
   * Remove a timesheet task from the timesheet object
   * @date 2022-07-12
   * @param {index} index - The index of the timesheet task to remove
   */
  const removeTimesheetRow = (index) => {
    const new_timesheet_tasks = formik.values.timesheet_tasks.filter((_, i) => i !== index);
    formik.setFieldValue("timesheet_tasks", new_timesheet_tasks);
  };

  /**
   * Make a request to the database to set request approval date to the current datetime
   * @date 2022-07-12
   * @param {timesheet} timesheet
   */
  const handleSubmit = async (id,request) => {
    setLoading(true);
    await saveTimesheet(formik.values);
    await axios.post(`${TIMESHEET_URL}/${id}/request?status=${request}`);
    fetchTimesheetsForBranch();
    setLoading(false);
  }

  /**
   * Return boolean if there are duplicate tasks for the same branch
   * @date 2022-07-13
   * @param {timesheet} timesheet
   */
  const isTaskBranchDuplicates = (timesheet) => {
    let duplicates = false;
    let task_branch_ids = [];
    for (const task of timesheet.timesheet_tasks) {
      if (task_branch_ids.find(logged_task => logged_task.task_id == task.task_id && logged_task.branch_id == task.branch_id)) {
        duplicates = true;
      } else {
        task_branch_ids.push({task_id: task.task_id, branch_id: task.branch_id});
      }
    }
    return duplicates;
  }

  /**
   * Return boolean if the task hours are valid
   * @date 2022-07-13
   * @param {timesheet} timesheet
   */
  const isTaskBranchHoursValid = (timesheet) => {
    // console.log("validateTaskHours", timesheet);
    let valid = true;
    for (let i = 0; i < timesheet.timesheet_tasks.length; i++) {
      for (const entry of Object.values(timesheet.timesheet_tasks[i].timesheet_entries)) {
        if (entry.hours < 0 || entry.hours > 24) {
          valid = false;
        }
      }
    }
    return valid;
  };

  /**
   * Return boolean if the task and branch options are valid
   * @date 2022-07-13
   * @param {timesheet} timesheet
   */
  const isTaskBranchOptionsValid = (timesheet) => {
    let valid = true;
    for (let i = 0; i < timesheet.timesheet_tasks.length; i++) {
      if ((timesheet.timesheet_tasks[i].branch_id == 0 && timesheet.timesheet_tasks[i].task_id != 0) || (timesheet.timesheet_tasks[i].branch_id != 0 && timesheet.timesheet_tasks[i].task_id == 0)) {
        valid = false;
      }
    }
    return valid;
  };

  /**
   * Return boolean if the task and branch options are not valid but hours are set
   * @date 2022-07-26
   * @param {timesheet} timesheet
   */
  const isHoursSetWithoutTaskBranch = (timesheet) => {
    let valid = false;
    for (let i = 0; i < timesheet.timesheet_tasks.length; i++) {
      let task_hours = 0;
      for (const entry of Object.values(timesheet.timesheet_tasks[i].timesheet_entries)) {
        task_hours += entry.hours;
      }
      if (task_hours > 0 && (timesheet.timesheet_tasks[i].branch_id == 0 && timesheet.timesheet_tasks[i].task_id == 0)) {
        valid = true;
      }
    }
    return valid;
  }

  /**
   * Return boolean if the task and branch options are not valid but default (is_default) was set to true
   * @date 2022-07-26
   * @param {timesheet} timesheet
   */
  const isDefaultSetWithTaskBranch = (timesheet) => {
    let valid = true;
    for (let i = 0; i < timesheet.timesheet_tasks.length; i++) {
      if (timesheet.timesheet_tasks[i].is_default && (timesheet.timesheet_tasks[i].branch_id == 0 && timesheet.timesheet_tasks[i].task_id == 0)) {
        valid = false;
      }
    }
    return valid;
  }


  /**
   * Return boolean if there is only comments without task and branch options
   * @date 2024-07-15
   * @param {timesheet} timesheet
   */
  const isCommentSetWithoutTaskBranchHours = (timesheet) => {
    let disable = false;
    if (timesheet.comment.length > 0 ) {
      if(timesheet.timesheet_tasks.length == 0) {
        disable = true;
      } else {
        timesheet.timesheet_tasks.forEach(task => {
          if (task.branch_id == 0 && task.task_id == 0) {
              disable = true;
          }
        });
      }
    } 
    return disable;
  }

   /**
   * Save the timesheet object to the database
   * @date 2022-07-12
   * @param {timesheet} timesheet
   */
    const saveTimesheet = async (timesheet) => {
      setLoading(true);
      //console.log("saving timesheet", timesheet);
      let result = null;
      
      if (!isDefaultSetWithTaskBranch(timesheet)) {
        setErrorSnackbarMessage("You cannot set a favorite task with no task or branch selected");
      } else if (isHoursSetWithoutTaskBranch(timesheet)) {
        setErrorSnackbarMessage("You must select a task and branch for each row when hours set");
      } else if (isTaskBranchDuplicates(timesheet)) {
        setErrorSnackbarMessage("Duplicate tasks for the same branch");
      } else if (!isTaskBranchOptionsValid(timesheet)) {
        setErrorSnackbarMessage("Please select a task and branch for each row");
      } else if (!isTaskBranchHoursValid(timesheet)) {
        setErrorSnackbarMessage("Task hours must be between 0 and 24");
      } else if (isCommentSetWithoutTaskBranchHours(timesheet)) {
        setErrorSnackbarMessage("To save or submit comments, please make sure to select both a task and a branch.");
      } else {
        
        timesheet.comment = checkAndReplace(timesheet.comment)
        try {
          if (timesheet.timesheet_week_id === 0) {
            result = await axios.post(`${TIMESHEET_URL}/save`, timesheet);
          } else {
            result = await axios.put(`${TIMESHEET_URL}/save`, timesheet);
          }

          console.log("response", result);

          if (result.status === 200) { setSuccessSnackbarMessage("Timesheet saved successfully"); }

        } catch (error) {
          console.log("error", error);
          setErrorSnackbarMessage("Something went wrong");
        }
    
        fetchTimesheetsForBranch();
      }
      
      setLoading(false);
    };

  useEffect(() => {
      userId && fetchTimesheetsForBranch();
  }, [userId]);

  useEffect(() => {
    userId && fetchTimesheetsForBranch();
  }, [timesheetStates.date]);

  useEffect(() => {
    const loadSnackbarSuccess = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    successSnackbarMessage && successSnackbarMessage != "" && loadSnackbarSuccess();
  }, [successSnackbarMessage]);

  useEffect(() => {
    const loadSnackbarError = async () => {
      setShowSnackbar(true);
      setLoading(false);
    };
    errorSnackbarMessage && errorSnackbarMessage != "" && loadSnackbarError();
  }, [errorSnackbarMessage]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successSnackbarMessage != "" ? "success" : "error"}
          message={successSnackbarMessage != "" ? successSnackbarMessage : errorSnackbarMessage}
        />
      </Snackbar>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Timesheet Filters
            </span>
          </h3>
          <div className="card-toolbar">
            {/* <Button
              className="btn btn-secondary font-weight-bolder font-size-sm mr-2"
            >
              Clear Form
            </Button> */}
            <Button
              className="btn btn-secondary font-weight-bolder font-size-sm mr-2"
              onClick={() => {
                saveTimesheet(formik.values);
                //console.log("saving timesheet", formik.values);
                history.push("/timesheet/time");
              }}
              disabled={formik.values.request_approve_on || isHoursSetWithoutTaskBranch(formik.values) || isTaskBranchDuplicates(formik.values) || !isTaskBranchOptionsValid(formik.values) || !isTaskBranchHoursValid(formik.values) || !isDefaultSetWithTaskBranch(formik.values) || loading}
              style={{
               cursor: formik.values.request_approve_on ? "not-allowed" : "pointer"
              }}
            >
              Save Draft
            </Button>
            <Link to={timesheetValues.request_approve_on ? `/timesheet/time/${formik.values.timesheet_week_id}/cancel` :`/timesheet/time/${formik.values.timesheet_week_id}/submit`} disabled={formik.values.timesheet_week_id == 0 || loading}>
              <Button
                className="btn font-weight-bolder font-size-sm"
                disabled={formik.values.timesheet_week_id == 0 || timesheetValues.approve_on !== null || isHoursSetWithoutTaskBranch(formik.values) || isTaskBranchDuplicates(formik.values) || !isTaskBranchOptionsValid(formik.values) || !isTaskBranchHoursValid(formik.values) || !isDefaultSetWithTaskBranch(formik.values) || isCommentSetWithoutTaskBranchHours(formik.values) || loading}
                style={{
                  cursor: formik.values.timesheet_week_id == 0 || timesheetValues.approve_on !== null ? "not-allowed" : "pointer",
                  backgroundColor: timesheetValues.request_approve_on ? timesheetValues.approve_on ? "#a0b531" : "#ff9800" : "#00bcd4",
                  borderColor: timesheetValues.request_approve_on ? timesheetValues.approve_on ? "#a0b531" : "#ff9800" : "#00bcd4"
                }}
              >
                {timesheetValues.request_approve_on ? timesheetValues.approve_on ? "Approved" : "Cancel Approval" : "Submit for Approval"}
              </Button>
            </Link>
          </div>
        </div>
        {/* end::Header

        {/* begin::Body */}
        <div className="card-body py-0">
          <TimesheetFilter
            filterData={filterData}
            origData={timesheetTasks}
            loading={loading}
            history={history}
            branchAccessList={branchAccessList}
            currentBranch={currentBranch}
          />
        </div>
      </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FieldArray 
              name="rows"
              render={(arrayHelpers) => (
                <fieldset className="mb-0" disabled={loading || formik.isSubmitting || formik.values.request_approve_on}>
                  <TimesheetTable
                    rows={formik.values.timesheet_tasks} 
                    addRow={addNewTimesheetRow}
                    removeRow={removeTimesheetRow}
                    save={saveTimesheet} 
                    editMode={true} 
                    loading={loading}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    errors={formik.errors}
                  />

                  <div className="card card-custom">
                    {/* begin::Header */}
                    <div className="card-header border-0 py-5 d-flex flex-column flex-wrap">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                          Weekly Comment
                        </span>
                      </h3>
                      <div className="card-toolbar">
                      </div>

                      <div className="card-body p-0">
                        <InputWithLimit
                        type="textarea"
                          label=""
                          name="comment"
                          id="comment"
                          className="form-control mb-5"
                          maxLength={250}
                          displaylimit={"true"}
                          rows="5"
                          value={formik.values.comment}
                          touched={formik.touched.comment}
                          errors={formik.errors.comment}
                          onChange={(e) => {
                            formik.setFieldValue("comment", e.target.value);
                          }}
                        />
                        <span className="text-danger pl-2">
                        { isCommentSetWithoutTaskBranchHours(formik.values) ? "Please ensure you select both a task and a branch before submitting your timesheet with comments." : " " }
                        </span>
                      </div>
                    </div>
                    {/* end::Header */}
                  </div>
                </fieldset>
              )}
            />
          </form>
        </FormikProvider>

        <ContentRoute path="/timesheet/time/:timesheet_week_id/submit">
          {({ history, match }) => (
            <ConfirmationModal
              show={match != null}
              id={match && match.params.id}
              setSuccessMessage={setSuccessSnackbarMessage}
              setErrorMessage={setErrorSnackbarMessage}
              onHide={() => {
                history.push("/timesheet/time");
              }}
              title="Submit Timesheet"
              message="Are you sure you want to submit this timesheet?"
              hint="This will send the timesheet to the approver for approval and will prevent you from editing it."
              handleAction={() => {
                match && handleSubmit(match.params.timesheet_week_id,true);
                history.push("/timesheet/time");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/timesheet/time/:timesheet_week_id/cancel">
          {({ history, match }) => (
            <ConfirmationModal
              show={match != null}
              id={match && match.params.id}
              setSuccessMessage={setSuccessSnackbarMessage}
              setErrorMessage={setErrorSnackbarMessage}
              onHide={() => {
                history.push("/timesheet/time");
              }}
              title="Cancel Timesheet"
              message="Are you sure you want to cancel this timesheet?"
              hint="Canceling your timesheet submission will require you to submit it again."
              handleAction={() => {
                match && handleSubmit(match.params.timesheet_week_id,false);
                history.push("/timesheet/time");
              }}
            />
          )}
        </ContentRoute>
    </div>
    
  );
};
