// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { useCouponContext } from "./CouponDataContext";
import { PersistFormikValues } from "formik-persist-values";

// Validation schema
const CouponEditSchema = Yup.object().shape({
  startdate: Yup.date().required("Validity Start Date is required"),
  enddate: Yup.date()
    .required("Validity End Date is required")
    .test("is-greater", "end date should be greater", function(value) {
      const { startdate } = this.parent;
      return moment(value, "yyyy-mm-dd").isAfter(
        moment(startdate, "yyyy-mm-dd")
      );
    }),

  coupon_value: Yup.number()
    .min(1, "1 is minimum")
    .required("Coupon Value is required"),
  coupon_name: Yup.string().required("Coupon Name is required"),
  discount_type_id: Yup.string(),
  active_id: Yup.string(),
  showing: Yup.string(),
  program_type_id: Yup.boolean(),
});

export function CouponEditForm({ coupon, btnRef, saveCoupon }) {
  const DROPDOWN_TYPE = useCouponContext().TYPES;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={coupon}
        validationSchema={CouponEditSchema}
        onSubmit={(values) => {
          // console.log("values", values);
          saveCoupon(values);
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-md-3">
                  <Select name={`active_id`} label="Coupon Active">
                    {DROPDOWN_TYPE.ACTIVE &&
                      DROPDOWN_TYPE.ACTIVE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-md-3">
                  <Select name={`showing`} label="Availability for Website">
                    {DROPDOWN_TYPE.SHOWING &&
                      DROPDOWN_TYPE.SHOWING.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-md-3">
                  <Select name={`batch_type`} label="Program Type">
                    {DROPDOWN_TYPE.BATCH_TYPE &&
                      DROPDOWN_TYPE.BATCH_TYPE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-md-3">
                  <Select name={`discount_type_id`} label="Discount Type">
                    {DROPDOWN_TYPE.DISCOUNT_TYPE &&
                      DROPDOWN_TYPE.DISCOUNT_TYPE.map((a) => (
                        <option
                          key={a.id}
                          //disabled={coupon.coupon_id ? true : false}
                          value={a.id}
                        >
                          {typeof a.value === "string"
                            ? a.value.charAt(0).toUpperCase() + a.value.slice(1)
                            : a.value}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              {/* {console.log(errors)} */}
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Coupon Name</label>
                  <Field
                    name="coupon_name"
                    component={Input}
                    placeholder="Coupon Name"
                    type="text"
                  />
                </div>
                <div className="col-lg-6">
                  <label className={"required-field"}>Enter Coupon Value</label>
                  <Field
                    name="coupon_value"
                    component={Input}
                    placeholder="Coupon Value"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className={"col-lg-6"}>
                  <label className={"required-field"}>
                    Coupon Validity Start Date
                  </label>
                  <Field
                    name="startdate"
                    component={Input}
                    type="date"
                    value={values.startdate.split("T")[0]}
                  />
                </div>
                <div className={"col-lg-6"}>
                  <label className={"required-field"}>
                    Enter Coupon Validity End Date
                  </label>
                  <Field
                    name="enddate"
                    component={Input}
                    type="date"
                    value={values.enddate.split("T")[0]}
                  />
                </div>
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
              {/* {coupon.coupon_id && <PersistFormikValues name="coupon-form" />} */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
