/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useBatchContext } from "./BatchDataContext";

export function BatchDeleteDialog({ history, id, active_value, show, onHide }) {
  const [activity, setActivity] = useState(false);
  const context = useBatchContext();
  const BATCHES_URL = "api/batches";
  // Batch Context

  async function fetchStudentsInBatch() {
    const response = await axios.get(`${BATCHES_URL}/${id}/all-students`);
    // console.log("response", response.data.results);
    let activityVal =
      response.data.results.batch_students.length > 0 ||
      response.data.results.lead_students.length > 0 ||
      response.data.results.makeup_students.length > 0;
    setActivity(activityVal);
  }

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      fetchStudentsInBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deactivateBatch = async () => {
    //edit batch post
    const result = await axios.patch(BATCHES_URL + "/deactivate/" + id);
    if (result.data === "success") {
      //setEditSuccessOpen(true);
      console.log("inactivated");
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/batches/batch-list");
      }, 1000);
    } else {
      //setEditErrorOpen(true);
    }
    // server request for deleting product by id
    // refresh list after deletion

    // closing delete modal
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Batch De-Activate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {activity ? (
          <>
            <p className="text-danger"> Batch can't be de-activated.</p>{" "}
            <p>Check to see if:</p>
            <ul>
              <li> students enrolled in this batch</li>
              <li> a trial class is booked for this batch</li>
              <li> a make-up class is booked for this batch</li>
            </ul>
          </>
        ) : (
          <p>Are you sure to de-activate this batch?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {activity ? (
            <button
              type="button"
              className="btn btn-danger btn-elevate"
              disabled
            >
              Deactivate
            </button>
          ) : (
            <button
              type="button"
              onClick={deactivateBatch}
              className="btn btn-danger btn-elevate"
            >
              Deactivate
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
