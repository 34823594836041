import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../helpers/TimeConvert";
import { useCouponContext } from "../Components/CouponDataContext";
import { CouponTableActions } from "./ActionFormatter/CouponTableActions";
import moment from "moment";
import { Badge } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function CouponRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState("");
  const couponContext = useCouponContext();

  // useEffect(() => {
  //   couponOpen && fetchStudentsInCoupon();
  // }, [couponOpen]);

  const handleRowClick = (coupon_id) => {
    setOpen(!open);
    setCouponOpen(coupon_id);
    //console.log("here" + coupon_id);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={row.coupon_id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(row.coupon_id)}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton> */}
          {row.coupon_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.coupon_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Badge pill className={classes.successRatio}>
            {row.coupon_value}
          </Badge>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.discount_type_id === 1 ? (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i className="fa fa-money-bill-wave text-primary"></i>
            </span>
          ) : (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i className="fa fa-percent text-primary"></i>
            </span>
          )}
        </TableCell>
        <TableCell align="center">
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.startdate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>{" "}
          -{" "}
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.enddate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <CouponTableActions
              row={row}
              openEditCouponPage={couponContext.openEditCouponPage}
              openDeleteCouponDialog={couponContext.openDeleteCouponDialog}
              openActivateCouponDialog={couponContext.openActivateCouponDialog}
              openCouponShowDialog={couponContext.openCouponShowDialog}
              openDuplicateCouponPage={couponContext.openDuplicateCouponPage}
              openCouponHideDialog={couponContext.openCouponHideDialog}
            />
          </TableCell>
        )}
      </TableRow>
      {/* <TableRow key={"details" + row.coupon_family}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Container>
                <Row>
                  <Col>
                    <CouponCoachTable instructors={row.instructor} />
                  </Col>
                  <Col>
                    <CouponStudentTable students={studentList} />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </Fragment>
  );
}
