import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import SelectField from "react-select";

export function Select({
  label,
  isLabelListCount = false,
  labelClasses,
  options,
  withFeedbackLabel = true,
  validationColorScheme = true,
  customFeedbackLabel,
  isMulti = false,
  ...props
}) {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched } = helpers;
  const { touched, error, value } = meta;

  const style = {
    control: (base) => ({
      ...base,
      border: meta.touched
        ? meta.error
          ? "1px solid #F64E60"
          : "1px solid #A0B432"
        : "1px solid #E4E6EF",
      borderRadius: "0.42rem",
      "&:hover": {
        borderColor: "none",
      },
      boxShadow: "none",
    }),
    option: (base, { isDisabled }) => ({
      ...base,
      backgroundColor: "#FFF",
      // color: "#212529",
      color: isDisabled ? "#C0C0C0" : "#212529",
      "&:hover": {
        backgroundColor: isDisabled ? "#FFF" : "#F0F0F0",
        color: isDisabled ? "#C0C0C0" : "#212529",
        cursor: isDisabled ? "not-allowed" : "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0",
    }),
  };

  /**
   * Function will return the text (label) within a option element
   * @date 2022-06-21
   * @param {option} option the respected option to return the label from
   */
  const getOptionLabel = (option) => {
    // console.log("option", option);
    if (Array.isArray(option.props.children)) {
      return option.props.children.find((value) => {
        if (value.trim().length > 0) {
          return value;
        }
      });
    } else {
      return option.props.children;
    }
  };

  /**
   * Function sets the updated values in respect to the option(s) selected.
   * Multi-options are supported, meaning if isMulti (mutiple options) is set to true, field values will correspond to multiple values.
   * @date 2022-06-21
   * @param {changes} changes changes corresponds to the option(s) that are selected, if isMulti = true; changes are options OR if isMulti = false; changes = option
   */
  const onChange = (changes) => {
    if (changes && isMulti) {
      let values = [];
      changes.forEach((option) => {
        values.push({ value: option.value });
      });
      setValue(values);
    } else if (changes) {
      setValue(changes.value);
    } else if (isMulti){
      setValue([]);
    } else {
      setValue("");
    }
  };

  /**
   * Function is used to set current field value(s) to the respected objects populated within currentOptions.
   * Multi-values are supported.
   * @date 2022-06-21
   */
  const setFieldValues = () => {
    if (currentOptions && field.value != undefined) {
      if (Array.isArray(field.value)) {
        let selectedOptions = [];
        field.value.forEach((item) => {
          selectedOptions.push(
            currentOptions.find(
              (option) =>
                option.value != undefined &&
                item.value != undefined &&
                option.value.toString().toLowerCase() == item.value.toString().toLowerCase()
            ) || ""
          );
        });
        return selectedOptions;
      } else {
        return (currentOptions ? currentOptions.find((option) => option && option.value != undefined && field.value != undefined && option.value.toString().toLowerCase() == field.value.toString().toLowerCase()) : '')
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    // console.log("field & options", field.name, props.children);
    const getOptionElements = (options) => {
      let res_options = [];

      if (Array.isArray(options) && options.length > 0) {
        options.map((option) => {
          if (option.type === "option") {
            // console.log("Select.js > getOptionElements ==>", field.name, option);
            res_options.push({
              value: option.props.value.toString(),
              label: getOptionLabel(option).toString(),
              isDisabled: option.props.disabled,
            });
          } else if (Array.isArray(option) && option.length > 0) {
            res_options.push(...getOptionElements(option));
          }
        });
      }
      return res_options;
    };

    if (
      options === undefined &&
      Array.isArray(props.children) &&
      props.children.length > 0
    ) {
      setCurrentOptions(getOptionElements(props.children));
    } else if (Array.isArray(options)) {
      // console.log("Select.js > Array.isArray(options) ==>", field.name, options);
      setCurrentOptions(
        options.map((option) => ({
          value: option.value.toString(),
          label: option.label.toString(),
          isDisabled: option.disabled,
        }))
      );
    } else {
      setCurrentOptions([]);
    }
  }, [options, props.children]);

  return (
    <>
      {label && <label className={labelClasses}>Select {isLabelListCount && currentOptions.length > 0 ? `${label} - (${currentOptions.length})` : `${label}`}</label>}
      <SelectField
        name={field.name}
        options={currentOptions}
        value={setFieldValues()}
        defaultValue={setFieldValues()}
        onChange={onChange}
        onBlur={() => {
          setTouched(true);
          // console.log("onBlur", field);
        }}
        isMulti={isMulti}
        styles={validationColorScheme ? style : undefined}
        isDisabled={props.disabled}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}