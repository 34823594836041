import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { RedoTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  attendanceStatusBadgeSuccess: {
    backgroundColor: "#54afe2",
    color: "white",
  },
  attendanceStatusBadgeDanger: {
    backgroundColor: "#dc3545",
    color: "white",
  },
}));

export default function CampEnrollmentVsDobRowReport(props) {
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
            {row.student_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {`${row.firstname} ${row.lastname}`}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            { row.dob ? `${moment(row.dob).format("MMM D, YYYY")}` : "N/A"}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.age}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.calculated_grade}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.stream_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {`${row.stream_grade_min} ${row.stream_grade_max ? " - " + row.stream_grade_max : "+"}`}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.camp_week}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
