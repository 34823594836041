export const paymentHeaders = [
    {
      key: "portal_transaction_parent_id",
      label: "ID",
    },
    {
      key: "type",
      label: "Type",
    },
    {
      key: "payment_date",
      label: "Payment Date",
    },
    {
      key: "payment_startdate",
      label: "Payment Start Date",
    },
    {
      key: "payment_enddate",
      label: "Payment End Date",
    },
    {
      key: "payment_method",
      label: "Payment Method",
    },
    {
      key: "day",
      label: "Day",
    },
    {
      key: "start_time",
      label: "Start Time",
    },
    {
      key: "end_time",
      label: "End Time",
    },
    {
      key: "student_ids",
      label: "Student IDs",
    },
    {
      key: "student_names",
      label: "Student Names",
    },
    {
      key: "parent_name",
      label: "Parent Name",
    },
    {
      key: "parent_email",
      label: "email",
    },
    {
      key: "address",
      label: "Address",
    },
    {
      key: "parent_phone_number",
      label: "Parent Phone No.",
    },
    {
      key: "programs_abbrv",
      label: "Programs (abbrv)",
    },
    {
      key: "payment_source",
      label: "Payment Source",
    },
    {
      key: "coupon_code",
      label: "Coupon Code",
    },
    {
      key: "discount_amount",
      label: "Discount Amount",
    },
    {
      key: "final_total",
      label: "Final Total",
    },
    {
      key: "tax_amount",
      label: "Tax Amount",
    },
    {
      key: "notes",
      label: "Internal Notes",
    },
    {
      key: "notes_external",
      label: "External Notes",
    },
  ];