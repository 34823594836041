export const lmsHeaders = [
  {
    key: "user_name",
    label: "Student Name",
  },
  {
    key: "unique_id",
    label: "Student ID",
  },
  {
    key: "added_to_course_at",
    label: "Start Date",
  },
  {
    key: "last_login_at",
    label: "Last Login",
  },
  {
    key: "last_submission",
    label: "Last Submission Date",
  },
  {
    key: "course_name",
    label: "Course Name",
  },
  {
    key: "title",
    label: "Challenge Title",
  },
];
