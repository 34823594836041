// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import React from "react";
import { useReportContext } from "../ReportDataContext";
export function CourseChallengeReportForm({
  reportResults,
  setReportResults,
  values,
  errors,
  touched,
  setReportTypeId,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;
  return (
    <>
      <div className="form-group row">
        <div className={"col-lg-6"}>
          <label className={"required-field"}>Student Id</label>
          <input
            type="text"
            className={"form-control"}
            value={values.studentId ? values.studentId : ""}
            onChange={(e) => {
              setFieldValue("studentId", e.target.value);
              setFieldValue("batchId", "");
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          ></input>
          <ErrorMessage
            name={`studentId`}
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className={"col-lg-6"}>
          <label className={"required-field"}>Batch Id</label>
          <input
            type="text"
            className={"form-control"}
            value={values.batchId ? values.batchId : ""}
            onChange={(e) => {
              setFieldValue("batchId", e.target.value);
              setFieldValue("studentId", "");
              if (reportResults && reportResults.length > 0) {
                setReportResults();
              }
            }}
          ></input>
          <ErrorMessage
            name={`batchId`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </>
  );
}
