/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export const BranchTableActions = ({
  row,
  openDeleteBranchCourseDialog,
  openActivateBranchCourseDialog,
  openCallOnBranchCourseDialog,
  openCallOffBranchCourseDialog,
  openEditBranchCoursePage,
}) => {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  return (
    <>
      <> </>
      {row.course_active_id != 0 ? (
        <div>
          {" "}
          {row.active_id === 1 ? (
            <span>
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">Edit Stream Fees</Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() => {
                    console.log(row);
                    openEditBranchCoursePage(row.branch_course_id);
                  }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
              {row.call_to_enroll_ind === 1 ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-delete-tooltip">
                      Turn Call to Enroll Off
                    </Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    onClick={() =>
                      openCallOffBranchCourseDialog(row.branch_course_id)
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <i className="fa fa-phone text-danger"></i>
                    </span>
                  </a>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-delete-tooltip">
                      Turn Call to Enroll On
                    </Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() =>
                      openCallOnBranchCourseDialog(row.branch_course_id)
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="fa fa-phone-slash text-primary"></i>
                    </span>
                  </a>
                </OverlayTrigger>
              )}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-delete-tooltip">
                    Deactivate Branch Stream
                  </Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() =>
                    openDeleteBranchCourseDialog(row.branch_course_id)
                  }
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <i className="fa fa-power-off text-success"></i>
                  </span>
                </a>
              </OverlayTrigger>
            </span>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-delete-tooltip">
                  Activate Branch Stream
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() =>
                  openActivateBranchCourseDialog(row.branch_course_id)
                }
              >
                <i className="fa fa-power-off"></i>
              </a>
            </OverlayTrigger>
          )}{" "}
        </div>
      ) : (
        <div className="badge badge-primary text-white"> In-Active Stream</div>
      )}
    </>
  );
};
