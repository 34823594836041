import axios from "axios";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import { attendanceHeaders } from "./AttendanceReport/AttendanceCSVHeaders";
import { campAttendanceHeaders } from "./CampAttendanceReport/CampAttendanceCSVHeaders";
import { campEnrollmentVsDobHeaders } from "./CampEnrollmentVsDobReport/CampEnrollmentVsDobCSVHeaders";
import { campHeaders } from "./CampReport/CampCSVHeaders";
import { classHeaders } from "./ClassReport/ClassCSVHeaders";
import { CompletedCoursesHeaders } from "./CompletedCoursesReport/CompletedCoursesCSVHeaders";
import { CourseChallengeHeaders } from "./CourseChallengeReport/CourseChallengeCSVHeaders";
import { courseEnrollmentVsDobHeaders } from "./CourseEnrollmentVsDobReport/CourseEnrollmentVsDobCSVHeaders";
import { LMSAccountsHeaders } from "./LMSAccountsReport/LMSAccountsCSVHeaders";
import { LMSvsPortalActivityHeaders } from "./LMSvsPortalActivityReport/LMSvsPortalActivityCSVHeaders";
import { lmsHeaders } from "./LmsReport/LmsCSVHeaders";
import { oooHeaders } from "./OOOReport/OOOCSVHeaders";
import { paymentHeaders } from "./PaymentReport/PaymentCSVHeaders";
import {
  campUnpaidHeaders,
  unpaidHeaders,
} from "./UnpaidReport/UnpaidCSVHeaders";

const ACTIVE_URL = "api/reports/active";
const DELIVERY_TYPE_URL = "api/reports/delivery-type";
const PROGRAM_TYPE_URL = "api/reports/program-type";
const CLASSCOUPON_NAME_URL = "api/reports/coupon-name/1/";
const CAMPCOUPON_NAME_URL = "api/reports/coupon-name/2/";
const BATCHES_URL = "api/batches";
const LMS_REPORT_URL = "api/reports/lmsreport";
// const REPORT_TYPE_URL = "api/reports/report-type";

export const getReportEditSchema = (reportTypeId) => {
  if (reportTypeId == "1") {
    return Yup.object().shape({
      report_type_id: Yup.string(),
      active_id: Yup.string(),
      program_type_id: Yup.string(),
      delivery_type_id: Yup.string(),
      day: Yup.string(),
    });
  }
  if (reportTypeId == "2") {
    return Yup.object().shape({
      startdate: Yup.date()
        .required("Camp Start Date is Required")
        .nullable(),
      enddate: Yup.date()
        .required("Camp End Date is Required")
        .test(
          "is-greater",
          "end date should be greater and within 6 months",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isAfter(
              moment(startdate, "yyyy-mm-dd")
            );
          }
        )
        .test(
          "is-between-six-months",
          "end date should be within 6 months of start date",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isBefore(
              moment(startdate, "yyyy-mm-dd")
                .add(6, "months")
                .add(1, "days")
            );
          }
        )
        .nullable(),
      report_type_id: Yup.string(),
      delivery_type_id: Yup.string(),
      program_type_id: Yup.string(),
    });
  }
  if (reportTypeId == "3") {
    return Yup.object().shape({
      selected_date: Yup.date().required("Selected Date is Required"),
      coupon_name: Yup.string()
        .test(
          "non-default",
          "Please select a coupon",
          (coupon_name) => coupon_name != "default"
        )
        .required("Please select a coupon")
        .nullable(),
      report_type_id: Yup.string(),
      batch_type: Yup.string(),
    });
  }
  if (reportTypeId == "5") {
    return Yup.object().shape({
      selected_date: Yup.date().required("Selected Date is Required"),
      report_type_id: Yup.string(),
    });
  }
  if (reportTypeId == "6") {
    return Yup.object().shape({
      startdate: Yup.date().required("Camp Start Date is Required"),
      enddate: Yup.date()
        .required("Camp End Date is Required")
        .test(
          "is-greater",
          "end date should be greater and within 6 months",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isAfter(
              moment(startdate, "yyyy-mm-dd")
            );
          }
        )
        .test(
          "is-between-six-months",
          "end date should be within 6 months of start date",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isBefore(
              moment(startdate, "yyyy-mm-dd")
                .add(6, "months")
                .add(1, "days")
            );
          }
        ),

      report_type_id: Yup.string(),
      student_id: Yup.string(),
    });
  }
  if (reportTypeId == "7") {
    return Yup.object().shape({
      selected_month: Yup.string().required("Month is Required"),
      report_type_id: Yup.string(),
      batch_type: Yup.string(),
    });
  }
  if (reportTypeId == "8") {
    return Yup.object().shape({
      report_type_id: Yup.string(),
    });
  }
  if (reportTypeId == "9") {
    return Yup.object().shape({
      report_type_id: Yup.string(),
    });
  }

  if (reportTypeId == "10") {
    return Yup.object().shape({
      report_type_id: Yup.string(),
      startdate: Yup.date()
        .required("Camp Start Date is Required")
        .nullable(),
      enddate: Yup.date()
        .required("Camp End Date is Required")
        .test(
          "is-greater",
          "end date should be greater and within 6 months",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isAfter(
              moment(startdate, "yyyy-mm-dd")
            );
          }
        )
        .test(
          "is-between-six-months",
          "end date should be within 6 months of start date",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isBefore(
              moment(startdate, "yyyy-mm-dd")
                .add(6, "months")
                .add(1, "days")
            );
          }
        )
        .nullable(),
    });
  }

  if (reportTypeId == "12") {
    return Yup.object().shape({
      report_type_id: Yup.string(),
    });
  }
  if (reportTypeId == "13") {
    return Yup.object().shape({
      startdate: Yup.date().required("Start Date is Required"),
      enddate: Yup.date().required("End Date is Required"),

      report_type_id: Yup.string(),
    });
  }

  if (reportTypeId == "14") {
    return Yup.object().shape({
      startdate: Yup.date().required("Camp Start Date is Required"),
      enddate: Yup.date()
        .required("Camp End Date is Required")
        .test(
          "is-greater",
          "end date should be greater and within 6 months",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isAfter(
              moment(startdate, "yyyy-mm-dd")
            );
          }
        )
        .test(
          "is-between-six-months",
          "end date should be within 6 months of start date",
          function(value) {
            const { startdate } = this.parent;
            return moment(value, "yyyy-mm-dd").isBefore(
              moment(startdate, "yyyy-mm-dd")
                .add(6, "months")
                .add(1, "days")
            );
          }
        ),

      report_type_id: Yup.string(),
      student_id: Yup.string(),
    });
  }

  if (reportTypeId == "15") {
    return Yup.object().shape({
      startdate: Yup.date().required("Course Start Date is Required"),
      report_type_id: Yup.string(),
    });
  }

  if (reportTypeId == "16") {
    return Yup.object().shape({
      startdate: Yup.date().required("Camp Start Date is Required"),
      report_type_id: Yup.string(),
    });
  }

  if (reportTypeId == "17") {
    return Yup.object().shape({
      startdate: Yup.date().required("Camp Start Date is Required"),

      report_type_id: Yup.string(),
      student_id: Yup.string(),
    });
  }

  if (reportTypeId == "18") {
    return Yup.object().shape({
      startdate: Yup.date().required("Start Date is Required"),

      report_type_id: Yup.string(),
      student_id: Yup.string(),
    });
  }

  return Yup.object().shape({
    report_type_id: Yup.string(),
  });
};

export function getMonths(pastX) {
  let date = new Date();
  let monthEnd = moment(date).format("M") * 1;
  let monthStart =
    moment(date)
      .subtract(pastX, "months")
      .format("M") * 1;
  let months = moment.months();
  let retVal = [];

  if (monthEnd > monthStart) {
    for (let i = monthStart; i < monthEnd; i++) {
      retVal.push(
        `${months[i]} ${moment(date)
          .subtract(monthEnd - i, "months")
          .format("YYYY")}`
      );
    }
    retVal = retVal.reverse();
  } else {
    for (let i = 0; i < 6; i++) {
      retVal.push(
        `${moment(date)
          .subtract(i, "months")
          .format("MMMM")} ${moment(date)
          .subtract(i, "months")
          .format("YYYY")}`
      );
    }
  }
  return retVal;
}

export const initReport = {
  report_type_id: "0",
  delivery_type_id: "default",
  branch_id: 0,
  program_type_id: "default",
};

export const initClassReport = {
  report_type_id: "1",
  active_id: "1",
  delivery_type_id: "default",
  branch_id: 0,
  program_type_id: "default",
  regular_from_date: "",
  regular_to_date: "",
  day: "All",
  include_extra: false,
};

export const initCampReport = {
  report_type_id: "2",
  delivery_type_id: "default",
  branch_id: 0,
  program_type_id: "default",
  startdate: "",
  enddate: "",
};

export const initCouponReport = {
  report_type_id: "3",
  batch_type: "1",
  coupon_name: "",
  branch_id: 0,
  selected_date: "",
};

export const initTrialReport = {
  report_type_id: "4",
  branch_id: 0,
  completed: "default",
};

export const initPaymentReport = {
  report_type_id: "5",
  branch_id: 0,
  selected_date: "",
};

export const initAttendanceReport = {
  report_type_id: "6",
  startdate: "",
  enddate: "",
  student_id: "",
  branch_id: 0,
};

export const initUnpaidReport = {
  report_type_id: "7",
  batch_type: "1",
  selected_month: moment(new Date()).format("MMMM YYYY"),
  branch_id: 0,
};

export const initCompletedCoursesReport = {
  report_type_id: "8",
};

export const initCourseChallengeReport = {
  report_type_id: "9",
};

export const initOOOReport = {
  report_type_id: "10",
  startdate: "",
  enddate: "",
};

export const initStudentFactReport = {
  report_type_id: "11",
};

export const initMaintenanceReport = {
  report_type_id: "12",
};

export const initLmsReport = {
  report_type_id: "13",
  startdate: "",
  enddate: "",
  branch_id: 0,
};
export const initCampAttendanceReport = {
  report_type_id: "14",
  startdate: "",
  enddate: "",
  student_id: "",
  branch_id: 0,
};

export const initCourseEnrollmentVsDobReport = {
  report_type_id: "15",
  startdate: moment().format("yyyy-MM-DD"),
  branch_id: 0,
};

export const initCampEnrollmentVsDobReport = {
  report_type_id: "16",
  startdate: moment().format("yyyy-MM-DD"),
  branch_id: 0,
};

export const initLMSAccountsReport = {
  report_type_id: "17",
  startdate: "",
  enddate: "",
  student_id: "",
  branch_id: 0,
};

export const initLMSvsPortalActivityReport = {
  report_type_id: "18",
  startdate: "",
  enddate: "",
  student_id: "",
  branch_id: 0,
};

export let REPORT_TYPE = [];

export const COMPLETED = [
  { id: "0", value: "In-Complete" },
  { id: "1", value: "Complete" },
];

const ReportContext = createContext();

export function useReportContext() {
  return useContext(ReportContext);
}

export const ReportConsumer = ReportContext.Consumer;

export function ReportContextProvider({ reportUIEvents, children }) {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const [ACTIVE, SETACTIVE] = useState([]);
  const [PROGRAM_TYPE, SETPROGRAM_TYPE] = useState([]);
  const [DELIVERY_TYPE, SETDELIVERY_TYPE] = useState([]);
  const [CLASSCOUPON_NAME, SETCLASSCOUPON_NAME] = useState([]);
  const [CAMPCOUPON_NAME, SETCAMPCOUPON_NAME] = useState([]);
  const [STUDENT_NAME, SETSTUDENT_NAME] = useState([]);
  const [LMS_REPORT, SETLMS_REPORT] = useState([]);

  const STATS = [
    "New",
    "Open",
    "Trial Scheduled",
    "Trial Taken",
    "Registered",
    "Not Interested",
    "On-Hold",
  ];

  const { branch, userAuth } = useSelector(
    (state) => ({
      branch: state.auth.branches.currentBranch,
      userAuth: state.auth.user,
    }),
    shallowEqual
  );
  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
        let res_program = await axios(PROGRAM_TYPE_URL);
        SETPROGRAM_TYPE(res_program.data.results);
        let res_delivery = await axios(DELIVERY_TYPE_URL);
        SETDELIVERY_TYPE(res_delivery.data.results);
        let res_classcoupon = await axios(
          CLASSCOUPON_NAME_URL + branch.branch_id
        );
        SETCLASSCOUPON_NAME(res_classcoupon.data.results);
        let res_campcoupon = await axios(
          CAMPCOUPON_NAME_URL + branch.branch_id
        );
        SETCAMPCOUPON_NAME(res_campcoupon.data.results);
        let res_att_students = await axios(
          `${BATCHES_URL}/${
            userAuth[0].user_id
          }/attendance/options/students/name?startDate=${moment().format(
            "yyyy-MM-DD"
          )}&endDate=${moment().format("yyyy-MM-DD")}&branch_id=${
            branch.branch_id
          }`
        );
        SETSTUDENT_NAME(res_att_students.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    REPORT_TYPE = [
      {
        label: "",
        options: [
          {
            value: "0",
            label: "Select A Report Type...",
            order: "0",
            desc: "Select A Report",
            csvHeaders: "",
          },
        ],
      },
      {
        label: "OPERATIONS - Regular Class",
        options: [
          {
            value: "1",
            label: "Registered Student List",
            order: "1",
            desc: "List of registered students attending our regular classes ",
            csvHeaders: classHeaders,
          },
          {
            value: "6",
            label: "Class Attendance",
            order: "5",
            desc: "Regular class student attendance",
            csvHeaders: attendanceHeaders,
          },
          {
            value: "15",
            label: "Enrollment Vs DOB",
            order: "12",
            desc: "Mismatch between student’s age v/s age recommendation.",
            csvHeaders: courseEnrollmentVsDobHeaders,
          },
        ],
      },
      {
        label: "OPERATIONS - Camp",
        options: [
          {
            value: "2",
            label: "Registered Student List",
            order: "2",
            desc: "List of students registered for Camps",
            csvHeaders: campHeaders,
          },
          {
            value: "14",
            label: "Attendance Template",
            order: "11",
            desc: "Camp attendance template",
            csvHeaders: campAttendanceHeaders,
          },
          {
            value: "16",
            label: "Enrollment Vs DOB",
            order: "13",
            desc: "Mismatch between student’s age v/s age recommendation.",
            csvHeaders: campEnrollmentVsDobHeaders,
          },
        ],
      },
      {
        label: "SALES",
        options: [
          // {
          //   value: "3",
          //   label: "Coupon Usage",
          //   order: "3",
          //   desc: "Coupon Usage",
          //   csvHeaders: couponHeaders,
          // },
          {
            value: "5",
            label: "Payment History",
            order: "4",
            desc: "Payment history (classes and camps) after a specific date",
            csvHeaders: paymentHeaders,
          },
          {
            value: "7",
            label: "Unpaid Report",
            order: "6",
            desc:
              "Missing payments for classes and camps, including partial payments, or extra payments",
            csvHeaders: [unpaidHeaders, campUnpaidHeaders],
          },
        ],
      },
      {
        label: "LMS",
        options: [
          {
            value: "8",
            label: "Student Course Completion",
            order: "7",
            desc: "All students that submitted a Challenge #1000",
            csvHeaders: CompletedCoursesHeaders,
          },
          {
            value: "9",
            label: "Last Challenge Completed",
            order: "8",
            desc: "The date when the last challenge was submitted.",
            csvHeaders: CourseChallengeHeaders,
          },
          {
            value: "13",
            label: "Challenge Submission History",
            order: "10",
            desc: "Challenge Submission history for all active students",
            csvHeaders: lmsHeaders,
          },
          {
            value: "17",
            label: "Pending LMS Accounts",
            order: "14",
            desc: "Enrolled students that don’t have an LMS Account",
            csvHeaders: LMSAccountsHeaders,
          },
          {
            value: "18",
            label: "LMS v/s Portal Data Mismatch",
            order: "15",
            desc: "LMS and Portal data mismatch",
            csvHeaders: LMSvsPortalActivityHeaders,
          },
        ],
      },
      {
        label: "STAFF",
        options: [
          {
            value: "10",
            label: "Out Of Office Report",
            order: "9",
            desc: "Employee out of office",
            csvHeaders: oooHeaders,
          },
        ],
      },
    ];

    userAuth && INIT_HELPERS();
  }, [userAuth]);

  const reportContext = {
    newReportButtonClick: reportUIEvents.newReportButtonClick,
    generateReportingButtonClick: reportUIEvents.generateReportingButtonClick,
    downloadReportingButtonClick: reportUIEvents.downloadReportingButtonClick,
    refetch: reportUIEvents.refetch,
    setRefetch: reportUIEvents.setRefetch,
    getReportEditSchema,
    getMonths,
    TYPES: {
      REPORT_TYPE,
      // SETREPORT_TYPE,
      ACTIVE,
      SETACTIVE,
      DELIVERY_TYPE,
      SETDELIVERY_TYPE,
      PROGRAM_TYPE,
      SETPROGRAM_TYPE,
      CLASSCOUPON_NAME,
      SETCLASSCOUPON_NAME,
      CAMPCOUPON_NAME,
      SETCAMPCOUPON_NAME,
      STUDENT_NAME,
      SETSTUDENT_NAME,
      STATS,
      LMS_REPORT,
      SETLMS_REPORT,
    },
    URLS: {
      ACTIVE_URL,
      PROGRAM_TYPE_URL,
      DELIVERY_TYPE_URL,
      CLASSCOUPON_NAME_URL,
      CAMPCOUPON_NAME_URL,
      BATCHES_URL,
      LMS_REPORT_URL,
    },
    AUTH: {
      branch,
      userAuth,
    },
    formInitValues: {
      initReport,
      initClassReport,
      initCampReport,
      initCouponReport,
      initTrialReport,
      initPaymentReport,
      initAttendanceReport,
      initUnpaidReport,
      initCompletedCoursesReport,
      initCourseChallengeReport,
      initOOOReport,
      initStudentFactReport,
      initMaintenanceReport,
      initLmsReport,
      initCampAttendanceReport,
      initCourseEnrollmentVsDobReport,
      initCampEnrollmentVsDobReport,
      initLMSAccountsReport,
      initLMSvsPortalActivityReport,
    },
  };

  return (
    <ReportContext.Provider value={reportContext}>
      {children}
    </ReportContext.Provider>
  );
}
