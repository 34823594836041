/* eslint-disable no-restricted-imports */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export function WebTransactionManuallyEntered({ history, id, show, onHide }) {
  const [error, setError] = useState("");
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      setError("");
    }
  }, [id]);

  const markEnrolled = async () => {
    axios
      .patch("api/web-transactions", {
        web_transaction_parent_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          setError("");
          setTimeout(() => {
            history.push("/web-transactions");
          }, 1000);
        } else {
          setError(
            "Failed to mark entered, please try again. If the error persists, take out a ticket"
          );
        }
      })
      .catch((err) => {
        setError(
          "Failed to mark entered, please try again. If the error persists, take out a ticket"
        );
      });
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Mark Manually Entered
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Use this in the case where:</span>
        <ul>
          <li>
            You have entered the transaction manually and now want to hide it
          </li>
          <li>This was a test/garbage transactions</li>
          <li>
            You just don't want to accept it automatically for <b>ANY</b> reason
          </li>
        </ul>
        <p className="text-danger">{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={markEnrolled}
            className={`btn btn-primary`}
          >
            Mark Entered
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
