import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { ReportContextProvider } from "./Components/ReportDataContext";
import { NewReport } from "./NewReport.js";

export default function ReportingPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const reportUIEvents = {
    newReportButtonClick: () => {
      history.push("/reporting/new-report");
    },
    generateReportingButtonClick: () => {
      history.push("/reporting/generate-report");
    },
    downloadReportingButtonClick: () => {
      history.push("/reporting/download-report");
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <ReportContextProvider reportUIEvents={reportUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            <Redirect
              exact={true}
              from="/reporting"
              to="/reporting/new-report"
            />
          }
          <ContentRoute path="/reporting/new-report" component={NewReport} />
        </Switch>
      </Suspense>
    </ReportContextProvider>
  );
}
