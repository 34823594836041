import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { UserEdit } from "./UserEdit";
import { UserList } from "./UserList";
import { UserContextProvider } from "./Components/UserDataContext";
import { useUserContext } from "./Components/UserDataContext";

export default function UserPage({ history }) {
  // const [refetch, setRefetch] = useState(false);
  const userUIUsers = {
    newUserButtonClick: () => {
      history.push("/users/add-user");
    },
    openEditUserPage: (id) => {
      history.push(`/users/${id}/edit-user`);
    },
    openActivateUserDialog: (id, isActive) => {
      history.push(`/users/user-list/${id}/activate-user/${isActive}`);
    },
    openDeleteUserDialog: (id) => {
      history.push(`/users/user-list/${id}/delete-user`);
    },
    // openUserFullDialog: (id) => {
    //   history.push(`/users/user-list/${id}/user-full`);
    // },
    // openUserNotFullDialog: (id) => {
    //   history.push(`/users/user-list/${id}/user-not-full`);
    // },
    // openDuplicateUserPage: (id) => {
    //   history.push(`/users/${id}/duplicate-user/`);
    // },
    // refetch: refetch,
    // setRefetch: setRefetch,
  };

  // context.setRefetch(
  //   {
  //     users: false,
  //     actives: false,
  //     roles: false,
  //     branches: false,
  //   }
  // )

  return (
    <UserContextProvider userUIUsers={userUIUsers}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/users" to="/users/user-list" />}
          <ContentRoute path="/users/add-user" component={UserEdit} />

          <ContentRoute
            path="/users/:id/edit-user"
            component={UserEdit}
          />

          <ContentRoute path="/users/user-list" component={UserList} />

          {/* <ContentRoute
            exact
            path="/events/:id/:duplicate/:dest_id"
            component={EventEdit}
          /> */}

          {/* <ContentRoute
            exact
            path="/events/:id/:duplicate"
            component={EventEdit}
          /> */}
        </Switch>
      </Suspense>
    </UserContextProvider>
  );
}
