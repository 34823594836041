// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function CampReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return (
    <>
      <div className="form-group row">
        {
          <>
            <div className={"col-md-4"}>
              <Select
                name={`program_type_id`}
                label="Program Type"
                onChange={(e) => {
                  setFieldValue("program_type_id", e.value);

                  if (reportResults && reportResults.length > 0) {
                    setReportResults();
                  }
                }}
                validationColorScheme={false}
              >
                <option value={"default"}>All</option>
                {DROPDOWN_TYPE.PROGRAM_TYPE &&
                  DROPDOWN_TYPE.PROGRAM_TYPE.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.value}
                    </option>
                  ))}
              </Select>
            </div>
            <div className={"col-md-4"}>
              <Select
                name={`delivery_type_id`}
                label="Delivery Type"
                onChange={(e) => {
                  setFieldValue("delivery_type_id", e.value);
                  if (reportResults && reportResults.length > 0) {
                    setReportResults();
                  }
                }}
                validationColorScheme={false}
              >
                <option value={"default"}>All</option>
                {DROPDOWN_TYPE.DELIVERY_TYPE &&
                  DROPDOWN_TYPE.DELIVERY_TYPE.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.value}
                    </option>
                  ))}
              </Select>
            </div>
          </>
        }
      </div>
      {
        <div className="form-group row">
          <div className={"col-lg-6"}>
            <label className={"required-field pr-2"}>Start Date</label>
            <OverlayTrigger overlay={<Tooltip>Camp Start Date</Tooltip>}>
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </OverlayTrigger>
            <input
              type="date"
              className={
                "form-control" +
                (errors.startdate && touched.startdate ? " is-invalid" : "")
              }
              value={values.startdate ? values.startdate.split("T")[0] : ""}
              onChange={(e) => {
                setFieldValue("startdate", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            ></input>
            <ErrorMessage
              name={`startdate`}
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"required-field pr-2"}>End Date</label>
            <OverlayTrigger overlay={<Tooltip>Camp End Date</Tooltip>}>
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </OverlayTrigger>
            <input
              type="date"
              className={
                "form-control" +
                (errors.enddate && touched.enddate ? " is-invalid" : "")
              }
              value={values.enddate ? values.enddate.split("T")[0] : ""}
              onChange={(e) => {
                setFieldValue("enddate", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            ></input>
            <ErrorMessage
              name={`enddate`}
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
      }
    </>
  );
}
