import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { userSlice } from "../app/_redux/userSlice";
import { familySlice } from "../app/modules/FamilyProfile/_redux/FamilySlice";
import { familySaga } from "../app/modules/FamilyProfile/_redux/FamilySaga";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: userSlice.reducer,
  family: familySlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga(), familySaga()]);
}
