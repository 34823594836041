import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardActionsWidget } from "../modules/Dashboard/Components/DashboardActions";
import { BranchBarGraph } from "../modules/Dashboard/widgets/BranchBarGraph";

export function Dashboard() {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  return (
    <>
      {/* {console.log("On Dashboard Component")} */}
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row mb-3">
        <div className="col-xl-4">
          <DashboardActionsWidget className="gutter-b " chartColor="danger" />
        </div>
        {access["dashboard_report"] != undefined && (
          <div className="col-xl-8">
            <BranchBarGraph
              type={"Course"}
              bid={branch.branch_id}
            ></BranchBarGraph>
          </div>
        )}
      </div>

      {access["dashboard_report"] != undefined && (
        <>
          <div className="row g-5 ">
            <div className="col-xl-6">
              <BranchBarGraph
                type={"Day"}
                bid={branch.branch_id}
              ></BranchBarGraph>
            </div>
            {/* <div className="col-xl-6">
              <BranchBarGraph
                type={"Alt"}
                bid={branch.branch_id}
              ></BranchBarGraph>
            </div> */}

            <div className="col-xl-6">
              <BranchBarGraph
                type={"Count"}
                bid={branch.branch_id}
              ></BranchBarGraph>
            </div>
          </div>
        </>
      )}

      {/* <div className="col-xl-8">
          {access["search_bar"] != undefined && (
            <div className="row">
              <div className="col-xl-12">
                <StudentSearchWidget
                  className="gutter-b card-stretch"
                  iconColor="success"
                  widgetHeight="150px"
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xl-6">
              <StudentCountWidget
                className="gutter-b"
                baseColor="primary"
                widgetHeight="150px"
              />
            </div>
            <div className="col-xl-6">
              <ClassCountWidget
                className="gutter-b"
                iconColor="success"
                widgetHeight="150px"
              />
            </div>
          </div>
        </div> */}

      {/* end::Dashboard */}
    </>
  );
}
export default Dashboard;
