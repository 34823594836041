import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import { CampContextProvider } from "./CampComponents/CampDataContext";
import { CampEdit } from "./CampEdit";
import { CampList } from "./CampList";

export default function CampPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const campUIEvents = {
    newCampButtonClick: () => {
      history.push("/camps/add-page");
    },
    openEditCampPage: (id) => {
      history.push(`/camps/${id}/edit-camp`);
    },
    openDeleteCampDialog: (id) => {
      history.push(`/camps/camp-list/${id}/delete-camp`);
    },
    openActivateCampDialog: (id) => {
      history.push(`/camps/camp-list/${id}/activate-camp`);
    },
    openDuplicateCampPage: (id) => {
      history.push(`/camps/${id}/duplicate-camp/`);
    },
    openPointsDialog: (id) => {
      history.push(`/camps/camp-list/${id}/points`);
    },
    openEmailDialog: (id) => {
      history.push(`/camps/camp-list/${id}/email`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <CampContextProvider campUIEvents={campUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/camps" to="/camps/camp-list" />}
          <ContentRoute path="/camps/add-camp" component={CampEdit} />

          <ContentRoute path="/camps/:id/edit-camp" component={CampEdit} />

          <ContentRoute path="/camps/camp-list" component={CampList} />

          <ContentRoute
            exact
            path="/camps/:id/:duplicate/:dest_id"
            component={CampEdit}
          />

          <ContentRoute
            exact
            path="/camps/:id/:duplicate"
            component={CampEdit}
          />
        </Switch>
      </Suspense>
    </CampContextProvider>
  );
}
