/* eslint eqeqeq: "off" */
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { getFormattedNumber } from "../../../../helpers/Formatter";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
}));

export function CampCoachTable({ instructors }) {
  return instructors ? (
    <Fragment>
      <h4> Coaches</h4>
      {instructors?.length > 0 ? (
        instructors.map((instructor, index) => {
          return <p> {instructor.instructor}</p>;
        })
      ) : (
        <p>No Coaches Added</p>
      )}
    </Fragment>
  ) : null;
}

export function CampStudentTable({ students }) {
  return students ? (
    <Fragment>
      <h4> Students </h4>
      {students?.length > 0 ? (
        students.map((student) => {
          return (
            <p key={student.student_camp_id}>
              {student.student_user_id} - {student.student}(
              <b>{student.course_code}</b>)
            </p>
          );
        })
      ) : (
        <p> No Students In The class</p>
      )}
    </Fragment>
  ) : null;
}

export function TimedCampStudentTable({ students }) {
  const largest = Math.max(
    students.filter(
      (student) => student.half_day_AM_ind == 0 && student.half_day_PM_ind == 0
    ).length,
    students.filter((student) => student.half_day_AM_ind == 1).length,
    students.filter((student) => student.half_day_PM_ind == 1).length
  );
  return students ? (
    <Fragment>
      <h4> Students </h4>
      {students?.length > 0 ? (
        <Table responsive className="table-borderless">
          <thead>
            {/* FD */}
            <tr key={1}>
              {students.filter(
                (student) =>
                  student.half_day_AM_ind == 0 && student.half_day_PM_ind == 0
              ).length > 0 && (
                <th style={{ padding: "0px" }}>
                  FD (
                  {
                    students.filter(
                      (student) =>
                        student.half_day_AM_ind == 0 &&
                        student.half_day_PM_ind == 0
                    ).length
                  }
                  )
                </th>
              )}
              {/* FD */}
              {students.filter((student) => student.half_day_AM_ind == 1)
                .length > 0 && (
                <th style={{ padding: "0px" }}>
                  AM (
                  {
                    students.filter((student) => student.half_day_AM_ind == 1)
                      .length
                  }
                  )
                </th>
              )}
              {/* PM */}
              {students.filter((student) => student.half_day_PM_ind == 1)
                .length > 0 && (
                <th style={{ padding: "0px" }}>
                  PM (
                  {
                    students.filter((student) => student.half_day_PM_ind == 1)
                      .length
                  }
                  )
                </th>
              )}
              {/* EC */}
              {students.filter((student) => student.extended_care_ind == 1)
                .length > 0 && (
                <th style={{ padding: "0px" }}>
                  EC (
                  {
                    students.filter((student) => student.extended_care_ind == 1)
                      .length
                  }
                  )
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(largest).keys()).map((i) => (
              <tr key={i}>
                {students.filter(
                  (student) =>
                    student.half_day_AM_ind == 0 && student.half_day_PM_ind == 0
                ).length > 0 && (
                  <td style={{ padding: "0px" }}>
                    {students.filter(
                      (student) =>
                        student.half_day_AM_ind == 0 &&
                        student.half_day_PM_ind == 0
                    )[i] ? (
                      <p
                        key={
                          students.filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )[i].student_camp_id
                        }
                      >
                        {
                          students.filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )[i].student_user_id
                        }{" "}
                        -{" "}
                        {
                          students.filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )[i].student
                        }{" "}
                        (
                        <b>
                          {
                            students.filter(
                              (student) =>
                                student.half_day_AM_ind == 0 &&
                                student.half_day_PM_ind == 0
                            )[i].course_code
                          }
                        </b>
                        )
                        {students.filter(
                          (student) =>
                            student.half_day_AM_ind == 0 &&
                            student.half_day_PM_ind == 0
                        )[i].allergies &&
                        students
                          .filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )
                          [i].allergies.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) =>
                                      student.half_day_AM_ind == 0 &&
                                      student.half_day_PM_ind == 0
                                  )[i].allergies
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Food/peanut.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) =>
                            student.half_day_AM_ind == 0 &&
                            student.half_day_PM_ind == 0
                        )[i].special_need &&
                        students
                          .filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )
                          [i].special_need.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) =>
                                      student.half_day_AM_ind == 0 &&
                                      student.half_day_PM_ind == 0
                                  )[i].special_need
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Clipboard.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) =>
                            student.half_day_AM_ind == 0 &&
                            student.half_day_PM_ind == 0
                        )[i].mobile &&
                        students
                          .filter(
                            (student) =>
                              student.half_day_AM_ind == 0 &&
                              student.half_day_PM_ind == 0
                          )
                          [i].mobile.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) =>
                                      student.half_day_AM_ind == 0 &&
                                      student.half_day_PM_ind == 0
                                  )[i].parent
                                }
                                <br />
                                Mobile:{" "}
                                {getFormattedNumber(
                                  students.filter(
                                    (student) =>
                                      student.half_day_AM_ind == 0 &&
                                      student.half_day_PM_ind == 0
                                  )[i].mobile
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Active-call.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                )}
                {students.filter((student) => student.half_day_AM_ind == 1)
                  .length > 0 && (
                  <td style={{ padding: "0px" }}>
                    {students.filter((student) => student.half_day_AM_ind == 1)[
                      i
                    ] ? (
                      <p
                        key={
                          students.filter(
                            (student) => student.half_day_AM_ind == 1
                          )[i].student_camp_id
                        }
                      >
                        {
                          students.filter(
                            (student) => student.half_day_AM_ind == 1
                          )[i].student_user_id
                        }{" "}
                        -{" "}
                        {
                          students.filter(
                            (student) => student.half_day_AM_ind == 1
                          )[i].student
                        }{" "}
                        (
                        <b>
                          {
                            students.filter(
                              (student) => student.half_day_AM_ind == 1
                            )[i].course_code
                          }
                        </b>
                        )
                        {students.filter(
                          (student) => student.half_day_AM_ind == 1
                        )[i].allergies &&
                        students
                          .filter((student) => student.half_day_AM_ind == 1)
                          [i].allergies.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_AM_ind == 1
                                  )[i].allergies
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Food/peanut.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.half_day_AM_ind == 1
                        )[i].special_need &&
                        students
                          .filter((student) => student.half_day_AM_ind == 1)
                          [i].special_need.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_AM_ind == 1
                                  )[i].special_need
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Clipboard.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.half_day_AM_ind == 1
                        )[i].mobile &&
                        students
                          .filter((student) => student.half_day_AM_ind == 1)
                          [i].mobile.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_AM_ind == 1
                                  )[i].parent
                                }
                                <br />
                                Mobile:{" "}
                                {getFormattedNumber(
                                  students.filter(
                                    (student) => student.half_day_AM_ind == 1
                                  )[i].mobile
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Active-call.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                )}
                {students.filter((student) => student.half_day_PM_ind == 1)
                  .length > 0 && (
                  <td style={{ padding: "0px" }}>
                    {students.filter((student) => student.half_day_PM_ind == 1)[
                      i
                    ] ? (
                      <p
                        key={
                          students.filter(
                            (student) => student.half_day_PM_ind == 1
                          )[i].student_camp_id
                        }
                      >
                        {
                          students.filter(
                            (student) => student.half_day_PM_ind == 1
                          )[i].student_user_id
                        }{" "}
                        -{" "}
                        {
                          students.filter(
                            (student) => student.half_day_PM_ind == 1
                          )[i].student
                        }{" "}
                        (
                        <b>
                          {
                            students.filter(
                              (student) => student.half_day_PM_ind == 1
                            )[i].course_code
                          }
                        </b>
                        )
                        {students.filter(
                          (student) => student.half_day_PM_ind == 1
                        )[i].allergies &&
                        students
                          .filter((student) => student.half_day_PM_ind == 1)
                          [i].allergies.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_PM_ind == 1
                                  )[i].allergies
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Food/peanut.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.half_day_PM_ind == 1
                        )[i].special_need &&
                        students
                          .filter((student) => student.half_day_PM_ind == 1)
                          [i].special_need.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_PM_ind == 1
                                  )[i].special_need
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Clipboard.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.half_day_PM_ind == 1
                        )[i].mobile &&
                        students
                          .filter((student) => student.half_day_PM_ind == 1)
                          [i].mobile.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.half_day_PM_ind == 1
                                  )[i].parent
                                }
                                <br />
                                Mobile:{" "}
                                {getFormattedNumber(
                                  students.filter(
                                    (student) => student.half_day_PM_ind == 1
                                  )[i].mobile
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Active-call.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                )}
                {students.filter((student) => student.extended_care_ind == 1)
                  .length > 0 && (
                  <td style={{ padding: "0px" }}>
                    {students.filter(
                      (student) => student.extended_care_ind == 1
                    )[i] ? (
                      <p
                        key={
                          students.filter(
                            (student) => student.extended_care_ind == 1
                          )[i].student_camp_id
                        }
                      >
                        {
                          students.filter(
                            (student) => student.extended_care_ind == 1
                          )[i].student_user_id
                        }{" "}
                        -{" "}
                        {
                          students.filter(
                            (student) => student.extended_care_ind == 1
                          )[i].student
                        }{" "}
                        (
                        <b>
                          {
                            students.filter(
                              (student) => student.extended_care_ind == 1
                            )[i].course_code
                          }
                        </b>
                        )
                        {students.filter(
                          (student) => student.extended_care_ind == 1
                        )[i].allergies &&
                        students
                          .filter((student) => student.extended_care_ind == 1)
                          [i].allergies.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.extended_care_ind == 1
                                  )[i].allergies
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Food/peanut.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.extended_care_ind == 1
                        )[i].special_need &&
                        students
                          .filter((student) => student.extended_care_ind == 1)
                          [i].special_need.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) => student.extended_care_ind == 1
                                  )[i].special_need
                                }
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Clipboard.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {students.filter(
                          (student) => student.extended_care_ind == 1
                        )[i].mobile &&
                        students
                          .filter((student) => student.extended_care_ind == 1)
                          [i].mobile.trim().length > 0 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                {
                                  students.filter(
                                    (student) =>
                                      student.half_day_AM_ind == 0 &&
                                      student.half_day_PM_ind == 0
                                  )[i].parent
                                }
                                <br />
                                Mobile:{" "}
                                {getFormattedNumber(
                                  students.filter(
                                    (student) => student.extended_care_ind == 1
                                  )[i].mobile
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Active-call.svg"
                                )}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p> No Students Enrolled In The Camp</p>
      )}
    </Fragment>
  ) : null;
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
