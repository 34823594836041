/*
Reference: https://medium.com/@aalam-info-solutions-llp/how-to-build-a-text-editor-in-react-js-d5c7fdb321ef
*/
import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ name, value, setValue, setPlainTextBody, onBlur }) => {
  var modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "link",
        { list: "ordered" },
        { list: "bullet" },
        { align: [] },
      ],
      //   [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "size",
  ];
  const quillRef = useRef();

  const handleProcedureContentChange = (content) => {
    // console.log("content---->", content);
    setValue(name, content);
    setValue(
      "bodyLength",
      quillRef.current.unprivilegedEditor.getText().length
    );
    // console.log("local", quillRef.current.unprivilegedEditor.getText());
    setPlainTextBody(quillRef.current.unprivilegedEditor.getText());
  };

  return (
    <>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        ref={quillRef}
        value={value}
        placeholder="write your content ...."
        onChange={handleProcedureContentChange}
        style={{ height: "220px" }}
        onBlur={onBlur}
      ></ReactQuill>
    </>
  );
};

export default TextEditor;
