import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export default function CourseRowAccordian({
  row,
  open,
  setOpen,
  handleRowClick,
}) {
  // console.log(row);
  const getFeeString = (fee) => {
    if (fee.duration === "FD") {
      return `Full Day: $${fee.fee} EX: $${fee.added_fee} (${fee.frequency_nbr}/Week)`;
    } else if (fee.duration === "HD") {
      return `Half Day: $${fee.fee} EX: $${fee.added_fee} (${fee.frequency_nbr}/Week)`;
    }
    else {
      return `Regular Class: $${fee.fee} (${fee.frequency_nbr}/Month) [${fee.duration} hours]`;
    }
  };

  let batch_type_values = row.fees
  .sort((a, b) => {
    return a.batch_type == b.batch_type ? 0 : a.batch_type > b.batch_type ? 1 : -1;
  })
    .map((fee) => {
      return fee.batch_type_value;
    })
    .filter((bT, index, self) => {
      return self.indexOf(bT) == index;
    });

  return (
    <TableRow key={"details" + row.branch_course_id}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box padding={2} margin={3}>
            <Row>
              {batch_type_values.map((bT, index) => {
                return (
                  <Col key={bT}>
                    <Box
                      padding={2}
                      style={{
                        borderRadius: "0.5rem",
                      }}
                      className={
                        index % 2 ? "bg-light-success" : "bg-light-primary"
                      }
                    >
                      <Container>
                        <h5>{bT}</h5>
                        {row.fees
                          .filter((fee) => {
                            return fee.batch_type_value == bT;
                          })
                          .map((fee, index) => (
                            <p key={index}>
                              {fee.delivery_type_id == 1 ? (
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <i className="fas fa-user-friends"></i>
                                </span>
                              ) : (
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <i className="fas fa-laptop"></i>
                                </span>
                              )}{" "}
                              {getFeeString(fee)}
                            </p>
                          ))}
                      </Container>
                    </Box>
                  </Col>
                );
              })}
            </Row>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
