/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";

export function BranchMaintenanceDash({ className }) {
  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Branch Maintenance Dashboard
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2">
          {/* begin::Item */}
          <div className="d-flex flex-wrap align-items-center mb-10">
            <p>
              Welcome to your Branch Maintenance Dashboard. From here you will
              be able to edit content on the website specific to your branch
              allowing you to have more control over how customers expierence
              your individual pages.
            </p>
          </div>
          <div className="d-flex flex-wrap align-items-center mb-10">
            <p>Currently you can customize</p>
          </div>
          <div className="d-flex flex-wrap align-items-center mb-10">
            <br />
            <ul>
              <li>
                Your unit's Busisness hours from the "Hours of Operation" tab
              </li>
              <li>
                The messages under the prices of your streams on the website
              </li>
            </ul>
          </div>
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
