/* eslint-disable no-restricted-imports */

import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import { useBranchCourseContext } from "../BranchCourseDataContext";
import { BranchCourseFeeEditForm } from "./BranchCourseFeeEditForm";

export function BranchCourseFeeEdit({ id, show, onHide }) {
  const [loading, setLoading] = useState();
  const [values, setValues] = useState();
  const [deleted, setDeleted] = useState([]);
  const context = useBranchCourseContext();
  const btnRef = useRef();

  const backToBranchCourseList = () => {
    //add functionality
    onHide();
  };
  const saveFeeClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const saveBranchCourseFees = async (values) => {
    // console.log("pressed submit");
    if (id) {
      const data = { ...values, deleted_fees: deleted };
      //switching true/false values to 0/1, adding duration
      const edited_fees = values.fees.map((fee) => ({
        ...fee,
        delivery_type_id: fee.delivery_type_id,
        fee_tax_percentage: values.fee_tax_ind,
        added_fee: fee.added_fee != "" ? fee.added_fee : 0,
        half_day_ind: fee.half_day_ind ? 1 : 0,
        price_range_ind: fee.price_range_ind ? 1 : 0,
      }));
      data.fees = edited_fees;

      //save new fee edits
      // console.log(data);
      const url = "api/branch-course/branch-course-fees";
      const result = await axios.post(url, data);
      if (result.data != "failed") {
        setTimeout(() => {
          onHide();
          setDeleted([]);
          context.setRefetch(true);
        }, 1000);
        context.setSuccessOpen(true);
      } else {
        setDeleted([]);
        context.setErrorOpen(true);
      }
    }
  };

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getBranchCourseDetails = async () => {
      setLoading(true);
      const url = `api/branch-course/branch-course-detail/${id}`;
      const res = await axios(url)
        .then((res) => {
          setValues(res.data[0]);

          setLoading(false);
        })
        .catch((e) => console.log(e));
    };
    id && getBranchCourseDetails();
  }, [id]);

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-xl"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
            Stream Fees - {values ? values.course_name : "Stream Not Selected"}{" "}
            {values && values.frequency_unit != "monthly"
              ? "(Camp)"
              : "(Regular Class)"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <Card>
              <CardBody>
                {loading ? (
                  <div> Fetching data </div>
                ) : (
                  <span>
                    <div className="mt-5">
                      {values && id && (
                        <BranchCourseFeeEditForm
                          //actionsLoading={actionsLoading}
                          //Pass in the edit batch OR new batch
                          id={id}
                          values={values}
                          deleted={deleted}
                          setDeleted={setDeleted}
                          btnRef={btnRef}
                          saveFees={saveBranchCourseFees}
                          //left off here, haven't gone into EditForm
                        />
                      )}
                    </div>
                  </span>
                )}
              </CardBody>
            </Card>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={backToBranchCourseList}
              className="btn btn-light btn-elevate"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            <> </>
            <button
              type="button"
              onClick={saveFeeClick}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
