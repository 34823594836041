import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { getUserByToken, getUserBranches, getUserAccess } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetBranch: "[Set Branch] Action",
  BranchesLoaded: "[Load Branch] Action",
  AccessLoaded: "[Load Access] Action",
};

const initialCurrentBranch = {
  branch_id: -1,
  branch_name: "No Branch Selected",
};
const initialAuthState = {
  user: undefined,
  authToken: undefined,
  branches: { currentBranch: initialCurrentBranch, branchList: [] },
  access: {},
};
const checkBranchExists = (state, branches) => {
  // console.log(
  //   branches.filter(
  //     (branch) => branch.branch_id == state.branches.currentBranch.branch_id
  //   )
  // );
  return state.branches.currentBranch &&
    branches.filter(
      (branch) => branch.branch_id == state.branches.currentBranch.branch_id
    ).length == 1
    ? state.branches.currentBranch
    : initialCurrentBranch;
};

export const reducer = persistReducer(
  {
    storage,
    key: "v713-zebra-auth",
    whitelist: ["authToken", "branches"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        console.log("login action ");
        const { authToken } = action.payload;
        return { ...initialAuthState, authToken };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.BranchesLoaded: {
        const { branches } = action.payload;
        const branchesObj = {
          currentBranch: checkBranchExists(state, branches),
          branchList: branches,
        };

        // console.log({ ...state, branches });
        return { ...state, branches: branchesObj };
      }
      case actionTypes.SetBranch: {
        const { branch } = action.payload;
        const branchesObj = {
          ...state.branches,
          currentBranch: branch,
        };
        return { ...state, branches: branchesObj };
      }
      case actionTypes.AccessLoaded: {
        const { access } = action.payload;
        // console.log({ ...state, access });
        return { ...state, access };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (authToken) => ({
    type: actionTypes.UserRequested,
    payload: { authToken },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillBranch: (branches) => ({
    type: actionTypes.BranchesLoaded,
    payload: { branches },
  }),
  setBranch: (branch) => ({
    type: actionTypes.SetBranch,
    payload: { branch },
  }),
  fulfillAccess: (access) => ({
    type: actionTypes.AccessLoaded,
    payload: { access },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const state = yield select();
    //console.log(state);
    //TOD0: Add validation for user found.
    try {
      const { data: user } = yield getUserByToken(state.auth.authToken);
      //console.log(user);
      const { data: branches } = yield getUserBranches(
        state.auth.authToken,
        user[0].user_id
      );
      //console.log(branches);
      const { data: access } = yield getUserAccess(
        state.auth.authToken,
        user[0].user_id
      );
      yield put(actions.fulfillUser(user));
      yield put(actions.fulfillBranch(branches));
      yield put(actions.fulfillAccess(access));
    } catch (e) {
      console.log("Something went wrong");
      yield put(actions.logout());
    }
  });
}
