// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import { useBatchContext } from "./BatchDataContext";

// Validation schema
const BatchEditSchema = Yup.object().shape({
  //   startdate: Yup.date().required("Start Date is required"),
  //   enddate: Yup.date()
  //     .required("End Date is required")
  //     .test("is-greater", "end date should be greater", function(value) {
  //       const { startdate } = this.parent;
  //       return moment(value, "yyyy-mm-dd").isAfter(
  //         moment(startdate, "yyyy-mm-dd")
  //       );
  //     }),
  start_time: Yup.string().required("Start time is required"),
  end_time: Yup.string()
    .required("End time cannot be empty")
    .test("is-greater", "end time should be greater", function(value) {
      const { start_time } = this.parent;
      return moment(value, "hh:mm").isAfter(moment(start_time, "hh:mm"));
    }),
  maximum_student: Yup.number()
    .min(1, "1 is minimum")
    .max(100, "100 is maximum")
    .required("Max Students is required"),
  day: Yup.string().required(),
  program_type_id: Yup.string(),
  active_id: Yup.string(),
  delivery_type_id: Yup.string(),
});

export function BatchEditForm({ batch, btnRef, saveBatch }) {
  const DROPDOWN_TYPE = useBatchContext().TYPES;
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={batch}
        validationSchema={BatchEditSchema}
        onSubmit={(values) => {
          saveBatch(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="program_type_id" label="Program Type">
                    {DROPDOWN_TYPE.PROGRAM_TYPE &&
                      DROPDOWN_TYPE.PROGRAM_TYPE.map((a) => (
                        <option
                          key={a.id}
                          disabled={batch.batch_id ? true : false}
                          value={a.id}
                        >
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>

                <div className="col-lg-6">
                  <Select name="active_id" label="Batch Status">
                    {DROPDOWN_TYPE.ACTIVE &&
                      DROPDOWN_TYPE.ACTIVE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="maximum_student"
                    component={Input}
                    placeholder="Max Students"
                    label="Max Students"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <Select name="day" label="Day">
                    <option key="none" value="">
                      --
                    </option>
                    {DROPDOWN_TYPE.DAYS &&
                      DROPDOWN_TYPE.DAYS.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
              {/* <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="startdate"
                    component={Input}
                    type="date"
                    placeholder="Start Date"
                    label="Start Date"
                  />
                </div>

                <div className="col-lg-6">
                  <Field
                    name="enddate"
                    component={Input}
                    type="date"
                    placeholder="End Date"
                    label="End Date"
                  />
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="start_time"
                    component={Input}
                    type="time"
                    placeholder="Start Time"
                    label="Start Time"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="end_time"
                    component={Input}
                    type="time"
                    placeholder="End Time"
                    label="End Time"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <Select name="delivery_type_id" label="Delivery Type">
                    {/* <option key="none" value="">
                      {" "}
                      --
                    </option> */}
                    {DROPDOWN_TYPE.DELIVERY_TYPE &&
                      DROPDOWN_TYPE.DELIVERY_TYPE.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.value}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="col-lg-6">
                  <Select name="batch_full_ind" label="Batch Full?">
                    {/* <option key="none" value="">
                      {" "}
                      --
                    </option> */}
                    <option key={0} value={0}>
                      {" "}
                      Not Full
                    </option>
                    <option key={1} value={1}>
                      {" "}
                      Full
                    </option>
                  </Select>
                </div>
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
              {/* {batch.batch_id && <PersistFormikValues name="batch-form" />} */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
