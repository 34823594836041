/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const UserTableActions = ({
  row,
  openEditUserPage,
  openDeleteUserDialog,
  openActivateUserDialog,
  // openUserFullDialog,
  // openUserNotFullDialog,
  // openDuplicateUserPage,
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit User</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
          onClick={() => {
            openEditUserPage(row.id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      {row.active_id === 1 ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Deactivate User</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
            // onClick={() => openActivateUserDialog(row.id)
              onClick={() => {
                // console.log("Deactivate User", openActivateUserDialog);
                openActivateUserDialog(row.id, false)
              }}
          >
              <i className="fa fa-power-off text-success" ></i>
          </a>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Activate User</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
            onClick={() => {
              openActivateUserDialog(row.id, true)
            }}
          >
            
              <i className="fa fa-power-off"></i>
            
          </a>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">
            Remove User
          </Tooltip>
        }
      >
        <a
          onClick={() => {
            // setShowDeleteModal(true);
            openDeleteUserDialog(row.id);
          }}
          className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1 py-2 px-3 px-xxl-5 my-1"
        >
          
          <i className="fa fa-trash text-danger"></i>
          
        </a>
        </OverlayTrigger>
    </>
  );
};
