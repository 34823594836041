/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getFormattedNumber } from "../../../../../../app/helpers/Formatter";
import { getSearchedUsers } from "../../../../../_helpers/user";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ users, keyword }) {
  if (
    users &&
    users.students.length === 0 &&
    users.parents.length === 0 &&
    users.instructors.length === 0
  ) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No records found</div>
        </div>
      </div>
    );
  } else if (keyword) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          className="scroll"
          style={{ maxHeight: "325px", position: "relative" }}
        >
          <div className="quick-search-result">
            {users && users.students && users.students.length > 0 ? (
              <span>
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Students
                </div>
                <div className="mb-10">
                  {getSearchedUsers(users.students, keyword).map(
                    (user, index) => {
                      return (
                        <div
                          className="d-flex align-items-center flex-grow-1 mb-2"
                          key={user.user_id}
                        >
                          <div className=" bg-transparent flex-shrink-0">
                            <Avatar
                              className={`${
                                user.active_id == 1 ? "bg-success" : "bg-mute"
                              } font-size-sm`}
                            >
                              {user.user_id}
                            </Avatar>
                          </div>
                          <div className="d-flex flex-column ml-3 mt-2 mb-2">
                            {user.parent_user_id ? (
                              <a
                                href={
                                  "/family-profile/" +
                                  user.parent_user_id +
                                  "/family-overview"
                                }
                                className="font-weight-bold text-dark text-hover-primary"
                              >
                                {user.firstname + " " + user.lastname}
                              </a>
                            ) : (
                              ""
                            )}

                            <span className="font-size-sm font-weight-bold text-muted">
                              {user.active_id === 1 ? (
                                <span className="text-success"> Active </span>
                              ) : (
                                <span> Inactive </span>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </span>
            ) : (
              ""
            )}

            {users && users.parents && users.parents.length > 0 ? (
              <span>
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Parents
                </div>
                <div className="mb-10">
                  {getSearchedUsers(users.parents, keyword).map(
                    (user, index) => {
                      return (
                        <div
                          className="d-flex align-items-center flex-grow-1 mb-2"
                          key={user.user_id}
                        >
                          <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                            <Avatar
                              className={`${
                                user.active_id == 1 ? "bg-primary" : "bg-mute"
                              } font-size-sm`}
                            >
                              {user.user_id}
                            </Avatar>
                          </div>
                          <div className="d-flex flex-column ml-3 mt-2 mb-2">
                            <a
                              href={
                                "/family-profile/" +
                                user.user_id +
                                "/family-overview"
                              }
                              className="font-weight-bold text-dark text-hover-primary"
                            >
                              {user.firstname + " " + user.lastname}
                              {user.mobile.length > 0 ? (
                                <>
                                  <br />
                                  <small>
                                    {getFormattedNumber(user.mobile)}
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                            </a>
                            <span className="font-size-sm font-weight-bold text-muted">
                              {user.active_id === 1 ? (
                                <span className="text-success"> Active </span>
                              ) : (
                                <span> Inactive </span>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </span>
            ) : (
              ""
            )}

            {users && users.instructors && users.instructors.length > 0 ? (
              <span>
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Instructors
                </div>
                <div className="mb-10">
                  {getSearchedUsers(users.instructors, keyword).map(
                    (user, index) => {
                      return (
                        <div
                          className="d-flex align-items-center flex-grow-1 mb-2"
                          key={user.user_id}
                        >
                          <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                            <Avatar
                              className={`${
                                user.active_id == 1 ? "bg-danger" : "bg-mute"
                              } font-size-sm`}
                            >
                              {user.user_id}
                            </Avatar>
                          </div>
                          <div className="d-flex flex-column ml-3 mt-2 mb-2">
                            {user.firstname + " " + user.lastname}

                            <span className="font-size-sm font-weight-bold text-muted">
                              {user.active_id === 1 ? (
                                <span className="text-success"> Active </span>
                              ) : (
                                <span> Inactive </span>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </span>
            ) : (
              ""
            )}
            {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
              Other
            </div>
            <div className="mb-10">
              {getSearchedUsers(users, keyword)
                .filter((user) => {
                  return user.role_id < 5;
                })
                .map((user, index) => {
                  return (
                    <div
                      className="d-flex align-items-center flex-grow-1 mb-2"
                      key={index}
                    >
                      <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/shapes/abstract-8.svg"
                          )}
                        ></SVG>
                      </div>
                      <div className="d-flex flex-column ml-3 mt-2 mb-2">
                        {user.user_id}

                        <span className="font-size-sm font-weight-bold text-muted">
                          {user.firstname + " " + user.lastname}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }

  return "";
}
