// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../../../../../_metronic/_partials/controls";
import { useReportContext } from "../ReportDataContext";

export function TrialReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const DROPDOWN_TYPE = useReportContext().TYPES;

  return (
    <>
      <div className="form-group row">
        {
          <div className={"col-md-3"}>
            <Select
              name={`completed`}
              label="Status"
              onChange={(e) => {
                setFieldValue("completed", e.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
              validationColorScheme={false}
            >
              <option value={"default"}>All</option>
              {DROPDOWN_TYPE.STATS &&
                DROPDOWN_TYPE.STATS.map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
            </Select>
          </div>
        }
        {
          <div className={"col-lg-3"}>
            <label className={"required-field"}>
              {DROPDOWN_TYPE.STATS[4] == values.completed
                ? "Registration Date"
                : [DROPDOWN_TYPE.STATS[2], DROPDOWN_TYPE.STATS[3]].includes(
                    values.completed
                  )
                ? "Trial Date"
                : "Lead Date"}
            </label>

            <OverlayTrigger
              overlay={
                <Tooltip>
                  The first day of class or camp for the student
                </Tooltip>
              }
            >
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </OverlayTrigger>
            <input
              type="date"
              className={
                "form-control" +
                (errors.selected_date && touched.selected_date
                  ? " is-invalid"
                  : "")
              }
              value={
                values.selected_date ? values.selected_date.split("T")[0] : ""
              }
              onChange={(e) => {
                setFieldValue("selected_date", e.target.value);
                if (reportResults && reportResults.length > 0) {
                  setReportResults();
                }
              }}
            ></input>
            {errors && errors.selected_date && (
              <small className="text-danger">{errors.selected_date}</small>
            )}
          </div>
        }
      </div>
    </>
  );
}
