import { TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useBranchCourseContext } from "../Components/BranchCourseDataContext";
import { BranchTableActions } from "./ActionFormatter/BranchTableActions";
import CourseRowAccordian from "./CourseRowAccordian";

export default function CourseRow(props) {
  const { row, showActions } = props;
  const [open, setOpen] = useState(false);
  const branchCourseContext = useBranchCourseContext();

  const getFeeRange = (row) => {
    let sorted = row.fees
      .filter((fee, index) => {
        return fee.price_range_ind == 1;
      })
      .sort((a, b) => {
        return a.fee == b.fee ? 0 : a.fee < b.fee ? -1 : 1;
      });
    return "$" + sorted[0].fee + " - $" + sorted[sorted.length - 1].fee;
  };

  const getIcons = () => {
    let batch_type_id = row.fees
      .sort((a, b) => {
        return a.batch_type == b.batch_type
          ? 0
          : a.batch_type > b.batch_type
          ? 1
          : -1;
      })
      .map((fee) => {
        return { id: fee.batch_type, value: fee.batch_type_value };
      })
      .filter((bT, index, self) => {
        return (
          self
            .map((b) => {
              return b.id;
            })
            .indexOf(bT.id) == index
        );
      });

    let icons = {
      1: {
        icon: "fas fa-school",
        value: "Regular Class",
        css: "svg-icon-muted",
        icon_css: "text-muted",
      },
      2: {
        icon: "fas fa-sun",
        value: "Summer Camp",
        css: "svg-icon-muted",
        icon_css: "text-muted",
      },
      3: {
        icon: "fas fa-running",
        value: "Trackout Camp",
        css: "svg-icon-danger",
        icon_css: "text-danger",
      },
      5: {
        icon: "fas fa-snowflake",
        value: "Winter Camp",
        css: "svg-icon-primary",
        icon_css: "text-primary",
      },
      6: {
        icon: "fas fa-seedling",
        value: "Spring Break Camp",
        css: "svg-icon-success",
        icon_css: "text-success",
      },
    };

    return batch_type_id.map((fee, fee_key_index) => {
      return (
        <span
          className={`svg-icon svg-icon-md pl-1 ${icons[fee.id].css}`}
          key={fee_key_index}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id="products-edit-tooltip">
                {icons[fee.id].value}
              </Tooltip>
            }
          >
            <i
              className={`${icons[fee.id].icon} ${icons[fee.id].icon_css}`}
            ></i>
          </OverlayTrigger>
        </span>
      );
    });
  };
  const handleRowClick = (branch_course_id) => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <TableRow key={row.branch_course_id} hover tabIndex={-1}>
        <TableCell component="th" scope="row" align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(row.branch_course_id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.branch_course_id}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {row.frequency_unit == "weekly" ? (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i className="fas fa-campground"></i>
            </span>
          ) : (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i className="fas fa-chalkboard-teacher"></i>
            </span>
          )}
        </TableCell>

        <TableCell align="left">
          <p>{row.course_name}</p>
          <div className="badge badge-primary text-white">
            {" "}
            {row.course_code}{" "}
          </div>
        </TableCell>
        <TableCell align="center">{row.program_name.join(", ")}</TableCell>

        <TableCell align="center">{getIcons()}</TableCell>
        <TableCell align="center">{getFeeRange(row)}</TableCell>
        {showActions && (
          <TableCell align="center">
            <BranchTableActions
              row={row}
              openActivateBranchCourseDialog={
                branchCourseContext.openActivateBranchCourseDialog
              }
              openDeleteBranchCourseDialog={
                branchCourseContext.openDeleteBranchCourseDialog
              }
              openCallOnBranchCourseDialog={
                branchCourseContext.openCallOnBranchCourseDialog
              }
              openCallOffBranchCourseDialog={
                branchCourseContext.openCallOffBranchCourseDialog
              }
              openEditBranchCoursePage={
                branchCourseContext.openEditBranchCoursePage
              }
            />
          </TableCell>
        )}
      </TableRow>
      <CourseRowAccordian
        key={"details" + row.branch_course_id}
        row={row}
        open={open}
        setOpen={setOpen}
        handleRowClick={handleRowClick}
      />
    </Fragment>
  );
}
