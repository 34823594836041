import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { EmailEdit } from "./EmailEdit";

export default function EmailPage({ history }) {
  const [refetch, setRefetch] = useState(false);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/emails" to="/emails/send-email" />}
          <ContentRoute path="/emails/send-email" component={EmailEdit} />
        </Switch>
      </Suspense>
    </>
  );
}
