/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useCustomerLeadsContext } from "./CustomerLeadsDataContext";

export function CustomerLeadsDeleteDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const context = useCustomerLeadsContext();
  

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  const deactivateCustomerLeads = async () => {
    const deactivateUrl = "api/customer-leads/lead/";

    //edit lead post
    const result = await axios.delete(deactivateUrl + id);
    if (result.data === "success") {
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/customer-leads/customer-leads-list");
      }, 1000);
    }
    
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Permanently Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to delete this lead?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
          variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <> </>
          <Button
          variant="danger"
            onClick={deactivateCustomerLeads}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
