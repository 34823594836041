/* eslint-disable no-restricted-imports */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";

export function WebTransactionsTriggerEmail({
  history,
  id,
  show,
  onHide,
  setEmailFailed,
  setEmailSent,
}) {
  const [showButton, setShowButton] = useState(true);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const sendEmail = async () => {
    const batchNotFullUrl = "api/checkout/send_confirmation";
    setShowButton(false);
    const result = await axios.post(batchNotFullUrl, {
      transaction_id: id,
      branch_id: branch.branch_id,
      resend: true,
    });
    if (result.data === "Email Sent") {
      setEmailSent(true);

      setTimeout(() => {
        history.push("/web-transactions/web-transactions-list/");
      }, 1000);
    } else {
      setEmailFailed(true);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Send Transactions Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to trigger this email?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={sendEmail}
            className={`btn ${
              !showButton ? "btn-delete" : "btn-primary"
            } btn-elevate`}
          >
            {showButton ? "Send Email" : "Sending..."}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
