export const classHeaders = [
  {
    key: "delivery_type",
    label: "Delivery Type",
  },
  {
    key: "program_type",
    label: "Program Type",
  },
  {
    key: "day",
    label: "Day",
  },
  {
    key: "times",
    label: "Times",
  },
  {
    key: "course_abbr",
    label: "Stream (Abbr)",
  },
  {
    key: "sub_course_code",
    label: "Course (Code)",
  },
  {
    key: "student_id",
    label: "Student ID",
  },
  {
    key: "student_name",
    label: "Student Name",
  },
  {
    key: "gender",
    label: "Gender",
  },
  {
    key: "instructor_name",
    label: "Instructor Name",
  },
  {
    key: "parent_name",
    label: "Parent Name",
  },
  {
    key: "phone",
    label: "Phone Number",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "course_status",
    label: "Stream Status",
  },
  {
    key: "start_date",
    label: "Start Date",
  },
  {
    key: "end_date",
    label: "End Date",
  },
  {
    key: "alternate_emails",
    label: "Alternate Emails",
  },
  {
    key: "make_up_date",
    label: "Make Up Date",
  },
  {
    key: "trial_date",
    label: "Trial Date",
  },
  {
    key: "dob",
    label: "DOB",
  },
  {
    key: "allergies",
    label: "Allergies",
  },
  {
    key: "special_need",
    label: "Special Needs",
  },
];

