export const CompletedCoursesHeaders = [
  {
    key: "Student_Id",
    label: "Student Id"
  },
  {
    key: "Student_Name",
    label: "Student Name"
  },
  {
    key: "Course_Code",
    label: "Course Code"
  },
  {
    key: "Course_Name",
    label: "Course Name"
  },
  {
    key:  "Submission_Date",
    label: "Submission Date"
  }
];
