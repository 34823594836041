
export const paymentHeaders = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
    },
      {
        id: "payment_date",
        numeric: false,
        disablePadding: true,
        label: "Payment Date",
      },
      {
        id: "payment_month",
        numeric: false,
        disablePadding: true,
        label: "Payment Month",
      },
      {
        id: "student_names",
        numeric: false,
        disablePadding: true,
        label: "Student Names",
      },
      {
        id: "parent_name",
        numeric: false,
        disablePadding: true,
        label: "Parent Name",
      },
      {
        id: "programs_abbrv",
        numeric: false,
        disablePadding: true,
        label: "Programs (abbrv)",
      },
      {
        id: "payment_source",
        numeric: false,
        disablePadding: true,
        label: "Payment Source",
      },
      {
        id: "coupon_code",
        numeric: false,
        disablePadding: true,
        label: "Coupon Code",
      },
      {
        id: "discount_amount",
        numeric: false,
        disablePadding: true,
        label: "Discount Amount",
      },
      {
        id: "final_total",
        numeric: false,
        disablePadding: true,
        label: "Final Total",
      },
  ]