export const campEnrollmentVsDobHeaders = [
    {
        key: "student_id",
        label: "Student ID"
    },
    {
        key:"firstname",
        label:"First Name",
    },
    {
        key:"lastname",
        label:"Last Name",
    },
    {
        key:"dob",
        label:"DOB"

    },
    {
        key:"age",
        label:"Age"
    },
    {
        key:"calculated_grade",
        label: "Grade"
    },
    {
        key:"stream_code",
        label:"Stream Code"
    },
    {
        key:"stream_name",
        label:"Stream Name",
    },
    {
        key:"stream_grade_min",
        label:"Stream Min Grade",
    },
    {
        key:"stream_grade_max",
        label:"Stream Max Grade",
    },
    {
        key:"camp_week",
        label:"Camp Week",
    },
  ];