import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useFormik, FormikProvider } from "formik";
import { date } from "yup";
import moment from "moment";

export function CustomerLeadsDownloadDialog({
  history,
  show,
  onHide,
  setDownloadSuccessOpen,
  setDownloadErrorOpen,
}) {
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const [trialData, setTrialData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState({});
  const [endDate, setEndDate] = useState("");
  const downloadBtnRef = useRef();

  const handleDownload = async (requirements) => {
    const response = await axios.get(
      `api/reports/trial/download/${branch.branch_id}/${startDate}/${endDate}`
    );
    if (response.status === 200) {
      setDownloadSuccessOpen(true);
    } else {
      setDownloadErrorOpen(false);
    }
    setTrialData(response.data.results);
  };

  let trialHeaders = [
    {
      key: "lead_id",
      label: "ID",
    },
    {
      key: "lead_date",
      label: "Lead Date",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "parent_name",
      label: "Parent Name",
    },
    {
      key: "phone",
      label: "Phone",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "zip",
      label: "Zip",
    },
    {
      key: "refferal",
      label: "Referral",
    },
    {
      key: "alt_refferal",
      label: "Referral (Other)",
    },
    {
      key: "student_name",
      label: "Student Name",
    },
    {
      key: "student_grade",
      label: "Student Grade",
    },
    {
      key: "delivery_type_value",
      label: "Delivery Type",
    },
    {
      key: "course_of_interest",
      label: "Stream Of Interest",
    },
    {
      key: "trial_course",
      label: "Trial Stream",
    },
    {
      key: "batch_id",
      label: "Batch Id",
    },
    {
      key: "day",
      label: "Trial Day",
    },
    {
      key: "start_time",
      label: "Trial Start Time",
    },
    {
      key: "end_time",
      label: "Trial End Time",
    },
    {
      key: "trial_date",
      label: "Trial Date",
    },
    {
      key: "registration_date",
      label: "Registration Date",
    },
    {
      key: "notes",
      label: "Notes",
    },
  ];

  useEffect(() => {
    if (trialData.length > 0) {
      downloadBtnRef.current.link.click();
    }
  }, [trialData]);

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Download Leads</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <p>
            Select the start and end dates for the leads you wish to download.
          </p>
          <>
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                className={`form-control`}
                placeholder="Date"
                name="startDate"
                onChange={(e) => {
                  if (endDate) {
                    let a = moment(e.target.value);
                    let b = moment(endDate);
                    error.endDate =
                      Math.abs(a.diff(b, "months")) > 6
                        ? "Dates must be within 6 months of each other"
                        : "";
                  }
                  setTouched(true);
                  setStartDate(e.target.value);
                }}
                value={startDate}
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                className={`form-control`}
                placeholder="Date"
                name="endDate"
                onChange={(e) => {
                  if (startDate) {
                    let a = moment(startDate);
                    let b = moment(e.target.value);
                    error.endDate =
                      Math.abs(a.diff(b, "months")) > 6
                        ? "Dates must be within 6 months of each other"
                        : "";
                  }
                  setTouched(true);
                  setEndDate(e.target.value);
                }}
                value={endDate}
              />
            </div>
          </>

          {error.endDate && (
            <div className="text-danger small">{error.endDate}</div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleDownload}
          disabled={
            !touched || error.endDate || startDate == "" || endDate == ""
          }
        >
          <span>
            <i className="fa fa-solid fa-download"></i>
            Download
          </span>
        </Button>
        {trialData && trialData.length > 0 && (
          <CSVLink
            filename={`customer_leads_${
              branch.branch_name
            }_${startDate.replaceAll("-", "_")}_${endDate.replaceAll(
              "-",
              "_"
            )}.csv`}
            headers={trialHeaders}
            ref={downloadBtnRef}
            data={trialData}
          ></CSVLink>
        )}
      </Modal.Footer>
    </Modal>
  );
}
