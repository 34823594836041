export const CompletedCoursesHeaders = [
  {
    id: "Student_Id",
    numeric: false,
    disablePadding: true,
    label: "Student Id"
  },
  {
    id: "Student_Name",
    numeric: false,
    disablePadding: true,
    label: "Student Name"
  },
  {
    id: "Course_Code",
    numeric: false,
    disablePadding: true,
    label: "Course Code"
  },
  {
    id: "Course_Name",
    numeric: false,
    disablePadding: true,
    label: "Course Name"
  },
  {
    id: "Submission_Date",
    numeric: false,
    disablePadding: true,
    label: "Submission Date"
  },
]