import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../../helpers/TimeConvert";
import { useReportContext } from "../ReportDataContext";
import moment from "moment";
import { Badge } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function UnpaidReportRow(props) {
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);

  const handleRowClick = () => {
    setOpen(!open);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow
        key={`${row.portal_transaction_parent_id}`}
        hover
        tabIndex={-1}
      >
        <TableCell align="center" component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick()}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.student_course_fee_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.student_user_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.student_name}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.course_abbr}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.final_total}
        </TableCell>
      </TableRow>
      <TableRow key={"details" + row.student_course_fee_id}>
        <TableCell colSpan={10} className="pb-0 pt-0">
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Row>
                <div className="col-lg-6">
                  <Container>
                    <h4>Notes</h4>
                    <p>{row.notes}</p>
                  </Container>
                </div>
              </Row>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
