import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { CustomerLeadsEdit } from "./CustomerLeadsEdit";
import { CustomerLeadsList } from "./CustomerLeadsList";
import { CustomerLeadsContextProvider } from "./Components/CustomerLeadsDataContext";
import { CustomerLeadsDeleteDialog } from "./Components/CustomerLeadsDeleteDialog";

export default function CustomerLeadsPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const leadUICustomerLeads = {
    newCustomerLeadsButtonClick: () => {
      history.push("/customer-leads/add-customer-leads");
    },
    openEditCustomerLeadsPage: (id) => {
      history.push(`/customer-leads/${id}/edit-customer-leads`);
    },
    openDeleteCustomerLeadsDialog: (id) => {
      history.push(`/customer-leads/customer-leads-list/${id}/delete-customer-leads`);
    },
    openDuplicateCustomerLeadsPage: (id) => {
      history.push(`/customer-leads/${id}/duplicate-customer-leads/`);
    },
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <CustomerLeadsContextProvider leadUICustomerLeads={leadUICustomerLeads}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/customer-leads" to="/customer-leads/customer-leads-list" />}
          <ContentRoute path="/customer-leads/add-customer-leads" component={CustomerLeadsEdit} />

          <ContentRoute
            path="/customer-leads/:id/edit-customer-leads"
            component={CustomerLeadsEdit}
          />

          <ContentRoute path="/customer-leads/customer-leads-list" component={CustomerLeadsList} />

          <ContentRoute
            exact
            path="/customer-leads/:id/:duplicate/:dest_id"
            component={CustomerLeadsEdit}
          />

          <ContentRoute
            exact
            path="/customer-leads/:id/:duplicate"
            component={CustomerLeadsEdit}
          />

        
        <ContentRoute
            exact
            path="/customer-leads/customer-leads-list/:id/delete-customer-leads"
            component={CustomerLeadsDeleteDialog}
          />
        </Switch>
      </Suspense>
    </CustomerLeadsContextProvider>
  );
}
