/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import {
  Form as BForm,
  Button,
  Col,
  Container,
  InputGroup,
  Row,
} from "react-bootstrap";
import { getFormattedNumber } from "../../../helpers/Formatter";
import WebTransactionParentMatch from "../Modals/WebTransactionParentMatch";
import WebTransactionStudentMatch from "../Modals/WebTransactionStudentMatch";
import { useWebTransactionsContext } from "./WebTransactionsDataContext";

const ParentMapping = ({ data, user, setUser }) => {
  const { values, errors } = useFormikContext();
  const {
    defaultSelectValue,
    newSelectValue,
    warnings,
  } = useWebTransactionsContext();
  const [open, setOpen] = useState(false);

  

  return (
    <div className="border border-2 p-10">
      <h4>Parents</h4>
      <hr />{" "}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.parents.map((m, i) => {
            return (
              <React.Fragment key={i}>
                <TableRow key={i}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        open === i ? setOpen(false) : setOpen(i);
                      }}
                    >
                      {open === i ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    {m.firstName + " " + m.lastName}
                  </TableCell>
                  <TableCell>{m.email}</TableCell>
                  <TableCell>{getFormattedNumber(m.phone)}</TableCell>
                  <TableCell>
                    <InputGroup>
                      <BForm.Control
                        type="text"
                        disabled={true}
                        className={
                          errors &&
                          errors.parents &&
                          errors.parents.length > i &&
                          errors.parents[i] &&
                          errors.parents[i].length > 0
                            ? "text-danger"
                            : ""
                        }
                        value={
                          values.parents[i].status !== defaultSelectValue &&
                          values.parents[i].status !== newSelectValue
                            ? `${values.parents[i].status.firstName} ${values.parents[i].status.lastName} (${values.parents[i].status.userId})`
                            : values.parents[i].status
                        }
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          let user = { ...m, index: i, isParent: true};
                          setUser(user);

                        }}
                      >
                        
                        <KeyboardArrowDownIcon />
                      </Button>
                    </InputGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    {" "}
                    <Collapse
                      in={open === i}
                      style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <Container className="pt-10">
                        <Row>
                          <Col>
                            <h2>Transaction Data</h2>
                            <p>
                              <b>Email: </b> {values.parents[i].email}
                            </p>
                            <p>
                              <b>Phone: </b>{" "}
                              {getFormattedNumber(values.parents[i].phone)}
                            </p>
                            <p>
                              <b>Address:</b>
                              <br />
                              {values.parents[i].address1}{" "}
                              {values.parents[i].address2}
                              <br />
                              {values.parents[i].city},{" "}
                              {values.parents[i].state},{" "}
                              {values.parents[i].country}{" "}
                              {values.parents[i].zip}
                            </p>
                          </Col>
                          <Col>
                            <h2>Portal Data</h2>
                            {values.parents[i].status !== defaultSelectValue &&
                            values.parents[i].status !== newSelectValue ? (
                              <>
                                <p>
                                  <b>Email: </b>{" "}
                                  {values.parents[i].status.email}
                                </p>
                                <p>
                                  <b>Phone: </b>{" "}
                                  {getFormattedNumber(
                                    values.parents[i].status.phone
                                  )}
                                </p>
                                <p>
                                  <b>Address:</b>
                                  <br />
                                  {values.parents[i].status.address}
                                  <br />
                                  {values.parents[i].status.city},{" "}
                                  {values.parents[i].status.state},{" "}
                                  {values.parents[i].status.country}{" "}
                                  {values.parents[i].status.zip}
                                </p>
                                <p>
                                  {" "}
                                  <b>Student(s): </b>
                                  {values.parents[i].status.students.length ===
                                    0 && "No Students Added"}
                                  {values.parents[i].status.students
                                    .map((s) => {
                                      return `${s.studentFirstName} ${s.studentLastName} (${s.studentId})`;
                                    })
                                    .join(", ")}
                                </p>
                              </>
                            ) : (
                              <p> Select an Existing User </p>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Collapse>
                  </TableCell>
                </TableRow>

                {errors &&
                  errors.parents &&
                  errors.parents.length > i &&
                  errors.parents[i] &&
                  errors.parents[i].length > 0 && (
                    <TableRow key={`${i}-error`}>
                      <TableCell colSpan={4}>
                        <small className="text-danger">
                          ERROR: {errors.parents[i]}
                        </small>
                      </TableCell>
                    </TableRow>
                  )}

                {warnings &&
                  warnings.parents &&
                  warnings.parents.length > i &&
                  warnings.parents[i] &&
                  warnings.parents[i].length > 0 && (
                    <TableRow key={`${i}-warnings`}>
                      <TableCell colSpan={4}>
                      <small className="text-warning">
                        WARNING: 
                        <ul>
                          {warnings.parents[i].map((warning, index) => (
                            <li key={index}>{warning}</li>
                          ))}
                        </ul>
                      </small>
                      </TableCell>
                    </TableRow>
                  )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
const StudentMapping = ({
  data,
  user,
  setUser,
  buttonEnable,
  setButtonEnable,
}) => {
  const { values, errors } = useFormikContext();
  const {
    defaultSelectValue,
    newSelectValue,
    warnings,
  } = useWebTransactionsContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkForm = () => {
      let numberOfUsers = values.parents.length + values.students.length;
      let allStatusSet =
        values.parents.filter((p) => {
          return p.status !== defaultSelectValue;
        }).length +
        values.students.filter((s) => {
          return s.status !== defaultSelectValue;
        }).length;

      let errorsDoNotExist =
        Object.keys(errors).length == 0 ||
        ((errors.parents === undefined ||
          errors.parents.filter((e) => {
            console.log(e);
            return e && e.length > 0;
          }).length === 0) &&
          (errors.students === undefined ||
            errors.students.filter((e) => {
              console.log(e);
              return e && e.length > 0;
            }).length === 0));

      if (numberOfUsers === allStatusSet && errorsDoNotExist) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    };
    checkForm();
  }, [values, errors]);

  return (
    <div className="border border-2 p-10">
      <h4>Students</h4>
      <hr />{" "}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>DOB</TableCell>
            <TableCell>Courses</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Primary Parent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.students.map((m, i) => {
            return (
              <React.Fragment key={i}>
                <TableRow key={i}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        open === i ? setOpen(false) : setOpen(i);
                      }}
                    >
                      {open === i ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    {m.firstName + " " + m.lastName}
                  </TableCell>
                  <TableCell>{m.dob}</TableCell>
                  <TableCell>
                    {m.courses
                      .map((c) => {
                        return c.courseName;
                      })
                      .join(", ")}
                  </TableCell>
                  <TableCell>
                    <InputGroup>
                      <BForm.Control
                        type="text"
                        disabled={true}
                        value={
                          values.students[i].status !== defaultSelectValue &&
                          values.students[i].status !== newSelectValue
                            ? `${values.students[i].status.firstName} ${values.students[i].status.lastName} (${values.students[i].status.userId})`
                            : values.students[i].status
                        }
                      ></BForm.Control>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          let user = {
                            ...m,
                            index: i,
                            isParent: false,
                            transactionPrimaryParent: { ...data.parents[0] },
                          };
                          setUser(user);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </Button>
                    </InputGroup>
                  </TableCell>
                  <TableCell>
                    <InputGroup>
                      <BForm.Control
                        type="text"
                        disabled={true}
                        value={
                          values.students[i].primaryParent !==
                          defaultSelectValue
                            ? `${values.students[i].primaryParent.firstName} ${
                                values.students[i].primaryParent.lastName
                              } (${
                                values.students[i].primaryParent.userId
                                  ? values.students[i].primaryParent.userId
                                  : "new"
                              })`
                            : values.students[i].primaryParent
                        }
                      ></BForm.Control>
                      {values.students[i].primaryParent === newSelectValue && (
                        <Button variant="outline-primary">
                          <KeyboardArrowDownIcon />
                        </Button>
                      )}
                    </InputGroup>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    {" "}
                    <Collapse
                      in={open === i}
                      style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <Container className="pt-10">
                        <Row>
                          <Col>
                            <h2>Transaction Data</h2>
                            <p>
                              <b>Date of Birth:</b> {values.students[i].dob}
                            </p>
                            <p>
                              <b>Allergies:</b> {values.students[i].allergies}
                            </p>
                            <p>
                              <b>Special Needs:</b>{" "}
                              {values.students[i].special_need}
                            </p>
                            <p>
                              <b>Primary Parent(s): </b>
                              {values.parents[0].firstName +
                                " " +
                                values.parents[0].lastName}
                            </p>
                            <p>
                              <b>Alt Parent(s): </b>
                              {values.parents[1].firstName +
                                " " +
                                values.parents[1].lastName}
                            </p>
                          </Col>
                          <Col>
                            <h2>Portal Data</h2>
                            {values.students[i].status !== defaultSelectValue &&
                            values.students[i].status !== newSelectValue ? (
                              <>
                                <p>
                                  <b>Date of Birth:</b>{" "}
                                  {values.students[i].status.dob}
                                </p>
                                <p>
                                  <b>Allergies:</b>{" "}
                                  {values.students[i].status.allergies}
                                </p>
                                <p>
                                  <b>Special Needs:</b>{" "}
                                  {values.students[i].status.special_need}
                                </p>
                                <p>
                                  <b>Primary Parent(s): </b>
                                  {values.students[i].status.parents
                                    .filter((p) => {
                                      return p.primary_ind === 1;
                                    })
                                    .map((p) => {
                                      return `${p.firstName} ${p.lastName} (${p.userId})`;
                                    })
                                    .join(", ")}
                                </p>
                                <p>
                                  <b>Alt Parent(s): </b>
                                  {values.students[i].status.parents
                                    .filter((p) => {
                                      return p.primary_ind === 0;
                                    })
                                    .map((p) => {
                                      return `${p.firstName} ${p.lastName} (${p.userId})`;
                                    })
                                    .join(", ")}
                                </p>
                              </>
                            ) : (
                              <p> Select an Exsiting User </p>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Collapse>
                  </TableCell>
                </TableRow>

                {errors &&
                  errors.students &&
                  errors.students.length > i &&
                  errors.students[i] &&
                  errors.students[i].length > 0 && (
                    <TableRow key={`${i}-error`}>
                      <TableCell colSpan={4}>
                        <small className="text-danger">
                          ERROR: {errors.students[i]}
                        </small>
                      </TableCell>
                    </TableRow>
                  )}
                {warnings &&
                  warnings.students &&
                  warnings.students.length > i &&
                  warnings.students[i] &&
                  warnings.students[i].length > 0 && (
                    <TableRow key={`${i}-warnings`}>
                      <TableCell colSpan={4}>
                      <small className="text-warning">
                        WARNING: 
                        <ul>
                          {warnings.students[i].map((warning, index) => (
                            <li key={index}>{warning}</li>
                          ))}
                        </ul>
                      </small>
                      </TableCell>
                    </TableRow>
                  )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export const UserMapping = ({
  data,
  btnRef,
  saveEnrollment,
  initialValues,
  buttonEnable,
  setButtonEnable,
}) => {
  const [user, setUser] = useState({});

  return (
    <div>
      {data && initialValues && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={saveEnrollment}
        >
          {({ handleSubmit }) => (
            <Form className="form form-label-right">
              <WebTransactionParentMatch  user={user} setUser={setUser} />
              <WebTransactionStudentMatch user={user} setUser={setUser} />

              <ParentMapping data={data} user={user} setUser={setUser} />
              <StudentMapping
                data={data}
                user={user}
                setUser={setUser}
                buttonEnable={buttonEnable}
                setButtonEnable={setButtonEnable}
              />

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
