import React, { useEffect, useState, useRef } from "react";
import { useFormik, FormikProvider } from "formik";
import { useTimesheetContext } from "./TimesheetDataContext";
import moment from "moment";
import { Select } from "../../../../_metronic/_partials/controls";


export function TimesheetFilter({
  filterData,
  origData,
  isLoading,
  history,
  branchAccessList,
  currentBranch,
}) {
  const formRef = useRef();
  const timesheetStates = useTimesheetContext().states;
  const [users, setUsers] = useState([]);
  const [isBranchDiff, setIsBranchDiff] = useState(true);
  const [initialFlag, setInitialFlag] = useState(false);

  const prepareFilter = (values) => {
    const {
      dateFilter,
      startDateFilter,
      endDateFilter,
      userFilter,
      branchFilter,
      approvedFilter,
    } = values;
    const filter = {};
    filter.date = dateFilter !== "" ? dateFilter : "";
    filter.start_date = startDateFilter !== "" ? startDateFilter : "";
    filter.end_date = endDateFilter !== "" ? endDateFilter : "";
    filter.user_name = userFilter !== "" ? userFilter : "";
    filter.branch_ids = branchFilter !== "" ? [branchFilter] : [branchAccessList.map(b => b.branch_id)];
    filter.approved = approvedFilter !== "" ? approvedFilter : "";
    return filter;
  };

  const applyFilter = async (values) => {
    await filterData(prepareFilter(values), isBranchDiff);
    setIsBranchDiff(false);
  };

  // initial values for the filter form
  const initialValues = {
    idFilter: "",
    taskFilter: "",
    branchFilter: currentBranch.branch_id,
    startDateFilter: timesheetStates.startDate,
    endDateFilter: timesheetStates.endDate,
    userFilter: "",
    approvedFilter: "2",
    dateFilter: timesheetStates.date,
  };

  const handleSubmit = async (values) => {
    await applyFilter(values);
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  /**
   * Return a list of users within the timesheets list
   * @date 2022-07-25
   * @param {timesheets} timesheets
   */
  const getTimesheetUsers = (timesheets) => {
    const users = [];
    for (const timesheet of timesheets) {
      if (!users.includes(timesheet.user_name)) {
        users.push(timesheet.user_name);
      }
    }
    return users;
  };

  useEffect(() => {
    if (Array.isArray(origData)) {
      if (!initialFlag) {
        applyFilter(initialValues);
        setInitialFlag(true);
      } else {
        setIsBranchDiff(true);
        applyFilter(formik.values);
      }
      setUsers(getTimesheetUsers(origData));
    }
  }, [origData]);

  useEffect(() => {
    if (timesheetStates.refetch || timesheetStates.userApprover) {
      setIsBranchDiff(true);
      formRef.current.click();
    }
  }, [timesheetStates.refetch]);

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} className="form form-label-right">
          {timesheetStates.userApprover &&
          history.location.pathname.includes("/timesheet/submissions") ? (
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Date"
                  name="startDateFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (
                      moment(e.target.value).isAfter(formik.values.endDateFilter)
                    ) {
                      let monday = moment(formik.values.endDateFilter)
                        .day(-6)
                        .format("yyyy-MM-DD");
                      formik.setFieldValue("startDateFilter", monday);
                      formik.handleSubmit();
                      timesheetStates.setStartDate(monday);
                    } else {
                      let monday =
                        moment(e.target.value).day() == 0
                          ? moment(e.target.value)
                              .day(-6)
                              .format("yyyy-MM-DD")
                          : moment(e.target.value)
                              .day(1)
                              .format("yyyy-MM-DD");
                      formik.setFieldValue("startDateFilter", monday);
                      formik.handleSubmit();
                      timesheetStates.setStartDate(monday);
                    }
                  }}
                  value={formik.values.startDateFilter}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> Start Date
                </small>
              </div>

              <div className="col-lg-2">
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Date"
                  name="endDateFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (
                      moment(e.target.value).isBefore(formik.values.startDateFilter)
                    ) {
                      let sunday = moment(formik.values.startDateFilter)
                        .startOf("week")
                        .day(7)
                        .format("yyyy-MM-DD");
                      formik.setFieldValue("endDateFilter", sunday);
                      formik.handleSubmit();
                      timesheetStates.setEndDate(sunday);
                    } else {
                      let sunday =
                        moment(e.target.value).day() == 0
                          ? moment(e.target.value).format("yyyy-MM-DD")
                          : moment(e.target.value)
                              .day(7)
                              .format("yyyy-MM-DD");
                      formik.setFieldValue("endDateFilter", sunday);
                      formik.handleSubmit();
                      timesheetStates.setEndDate(sunday);
                    }
                  }}
                  value={formik.values.endDateFilter}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> End Date
                </small>
              </div>

              <div className="col-lg-2">
                <Select
                  placeholder="User"
                  name="userFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (e == null) {
                      formik.setFieldValue("userFilter", "");
                      formik.handleSubmit();
                    } else {
                      formik.setFieldValue("userFilter", e.value);
                      formik.handleSubmit();
                    }
                  }}
                  isClearable={true}
                  validationColorScheme={false}
                  isDisabled={isLoading}
                >
                  {Array.isArray(users) &&
                    users.map((user, index) => (
                      <option key={user + "_" + index} value={user}>
                        {user}
                      </option>
                    ))}
                </Select>
                <small className="form-text text-muted">
                  <b>Filter</b> by User
                </small>
              </div>

              <div className="col-lg-2">
                <Select
                  placeholder="Approval"
                  name="approvedFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (e == null) {
                      formik.setFieldValue("approvedFilter", "2"); // 2 is for both
                    } else {
                      formik.setFieldValue("approvedFilter", e.value);
                    }
                    formik.handleSubmit();
                  }}
                  validationColorScheme={false}
                  isDisabled={isLoading}
                  isClearable={true}
                >
                  <option value="1">Approved</option>
                  <option value="0">Unapproved</option>
                </Select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Approval
                </small>
              </div>

              <div className="col-lg-2">
                <Select
                  placeholder="Branch"
                  name="branchFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (e && e.value != formik.values.branchFilter) {
                      setIsBranchDiff(true);
                      formik.setFieldValue("branchFilter", e.value);
                      formik.handleSubmit();
                    } else {
                      setIsBranchDiff(true);
                      formik.setFieldValue("branchFilter", "");
                      formik.handleSubmit();
                    }
                  }}
                  validationColorScheme={false}
                  isDisabled={branchAccessList.length <= 1 || isLoading}
                  isClearable={true}
                >
                  {branchAccessList.map((a) => (
                    <option key={a.branch_id} value={a.branch_id}>
                      {a.branch_name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Filter by Date"
                  name="dateFilter"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let monday =
                      moment(e.target.value).day() == 0
                        ? moment(e.target.value)
                            .day(-6)
                            .format("yyyy-MM-DD")
                        : moment(e.target.value)
                            .day(1)
                            .format("yyyy-MM-DD");
                    formik.setFieldValue("dateFilter", monday);
                    formik.handleSubmit();
                    timesheetStates.setDate(monday);
                  }}
                  value={formik.values.dateFilter}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
            </div>
          )}

          <button
            type={"submit"}
            ref={formRef}
            style={{ display: "none" }}
            onClick={formik.handleSubmit}
          />
        </form>
      </FormikProvider>
    </>
  );
}
