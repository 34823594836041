/* eslint eqeqeq: "off" */
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { convertFrom24To12Format } from "../../../../helpers/TimeConvert";
import {
  BatchCoachTable,
  BatchStudentTable,
} from "../Components/BatchCoachAndStudentTables";
import { useBatchContext } from "../Components/BatchDataContext";
import { ClassTableActions } from "./ActionFormatter/ClassTableActions";

const BATCHES_URL = "/api/batches";

const useStyles = makeStyles((theme) => ({
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function BatchRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [batchOpen, setBatchOpen] = useState("");
  const [batchStudentList, setBatchStudentList] = useState([]);
  const [classRatio, setClassRatio] = useState({
    ratio: 0,
    numerator: 0,
    denominator: 0,
    margin: 0,
  });
  const [trialStudentList, setTrialStudentList] = useState([]);
  const [makeupStudentList, setMakeupStudentList] = useState([]);

  const batchContext = useBatchContext();
  const getCoursesShowing = (courses) => {
    let text = "";
    courses
      .filter((c) => {
        return c.show_ind === 1 && c.display_ind === 1;
      })
      .forEach((c, index) => {
        text +=
          index % 3 === 0 && index !== 0
            ? c.course_code + "\n"
            : index === courses.length - 1
            ? c.course_code
            : c.course_code + ", ";
      });
    return text.split("\n");
  };
  const getCoursesHiding = (courses) => {
    let text = "";
    courses
      .filter((c) => {
        return c.show_ind !== 1 && c.display_ind === 1;
      })
      .forEach((c, index) => {
        text +=
          index % 3 === 0 && index !== 0
            ? c.course_code + "\n"
            : index === courses.length - 1
            ? c.course_code
            : c.course_code + ", ";
      });
    return text.split("\n");
  };

  async function fetchStudentsInBatch() {
    const response = await axios.get(
      `${BATCHES_URL}/${row.batch_id}/all-students`
    );
    // console.log("response", response.data.results);
    setBatchStudentList(response.data.results.batch_students);
    setTrialStudentList(response.data.results.lead_students);
    setMakeupStudentList(response.data.results.makeup_students);
  }

  useEffect(() => {
    batchOpen && fetchStudentsInBatch();
    // eslint-disable-next-line
  }, [batchOpen]);

  useEffect(() => {
    fetchStudentsInBatch();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    let today = moment(new Date());
    let startOfWeek = moment(new Date())
      .day(1)
      .format("YYYY-MM-DD");
    let endOfWeek = moment(new Date())
      .day(7)
      .format("YYYY-MM-DD");
    let days = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };
    let batchDay = days[row.day.split(",")[0]];
    if (today.day() > batchDay) {
      // console.log("in if");
      startOfWeek = moment(new Date())
        .day(8)
        .format("YYYY-MM-DD");
      endOfWeek = moment(new Date())
        .day(14)
        .format("YYYY-MM-DD");
    }

    // console.log(today.day(), "vs", batchDay);
    // console.log(startOfWeek, "to", endOfWeek);

    const ratio = batchStudentList.length
      ? batchStudentList.length / row.maximum_student
      : 0 / row.maximum_student;
    let trials = trialStudentList.filter((t) => {
      return moment(t.date.split("T")[0], "YYYY-MM-DD").isBetween(
        startOfWeek,
        endOfWeek,
        undefined,
        "[]"
      );
    }).length;
    let makeUps = makeupStudentList.filter((m) => {
      return moment(m.date.split("T")[0], "YYYY-MM-DD").isBetween(
        startOfWeek,
        endOfWeek,
        undefined,
        "[]"
      );
    }).length;
    let final = {
      ratio: ratio,
      numerator: row.students ? batchStudentList.length : 0,
      denominator: row.maximum_student,
      margin: trials + makeUps,
    };
    setClassRatio(final);
  }, [batchStudentList, trialStudentList, makeupStudentList, row]);

  const handleRowClick = (batch_id) => {
    setOpen(!open);
    setBatchOpen(batch_id);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={row.batch_id} hover tabIndex={-1}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(row.batch_id)}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.batch_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <Tooltip className="mr-2" title={row.delivery_type_value}>
            {row.delivery_type_value.toLowerCase().includes("hybrid") ? (
              <i className="fas fa-laptop-house"></i>
            ) : row.delivery_type_value.toLowerCase().includes("virtual") ? (
              <i className="fas fa-laptop"></i>
            ) : (
              <i className="fas fa-user-friends"></i>
            )}
          </Tooltip>
          {row.program_name}
        </TableCell>
        <TableCell align="center">
          {
            <Badge
              pill
              className={
                classRatio.ratio < 0.4
                  ? classes.successRatio
                  : classRatio.ratio >= 0.4 && classRatio.ratio < 0.7
                  ? classes.warningRatio
                  : classes.dangerRatio
              }
            >
              {`${classRatio.numerator} ${
                classRatio.margin > 0 ? `(+${classRatio.margin})` : ``
              } / ${classRatio.denominator}`}
            </Badge>
          }
        </TableCell>
        <TableCell align="center">
          {row.day.includes(",")
            ? row.day.split(",").map((d, i) => <p key={i}>{d}</p>)
            : row.day}
        </TableCell>
        <TableCell align="center">
          {convertFrom24To12Format(row.start_time)} -{" "}
          {convertFrom24To12Format(row.end_time)}
        </TableCell>

        <TableCell align="center" component="th" scope="row">
          <p key={row.batch_id}>
            {getCoursesShowing(row.courses).map((course, index) => (
              <span key={index}>{course}</span>
            ))}
            {getCoursesHiding(row.courses).map((course, index) => (
              <span className="text-muted" key={index + row.batch_id}>
                {course}
              </span>
            ))}
          </p>
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <ClassTableActions
              style={{ display: "flex", justifyContent: "center" }}
              row={row}
              openEditBatchPage={batchContext.openEditBatchPage}
              openDeleteBatchDialog={batchContext.openDeleteBatchDialog}
              openActivateBatchDialog={batchContext.openActivateBatchDialog}
              openBatchFullDialog={batchContext.openBatchFullDialog}
              openDuplicateBatchPage={batchContext.openDuplicateBatchPage}
              openBatchNotFullDialog={batchContext.openBatchNotFullDialog}
              openAttendanceDialog={batchContext.openAttendanceDialog}
              openPointsDialog={batchContext.openPointsDialog}
              openEmailDialog={batchContext.openEmailDialog}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow key={"details" + row.batch_family}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={2}
              margin={3}
              style={{ backgroundColor: "#f5f5f5", borderRadius: "0.5rem" }}
            >
              <Container>
                <Row className="mb-3">
                  <Col>
                    <BatchCoachTable instructors={row.instructor} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* Batch Students */}
                    <BatchStudentTable
                      students={batchStudentList}
                      tableLabel={"Batch Students"}
                      firstAndLastName={true}
                    />
                  </Col>
                  <Col>
                    {/* Leads Students */}
                    <BatchStudentTable
                      students={trialStudentList}
                      tableLabel={"Trial Students"}
                    />
                  </Col>
                  <Col>
                    {/* Makeup Students */}
                    <BatchStudentTable
                      students={makeupStudentList}
                      tableLabel={"Makeup Students"}
                      firstAndLastName={true}
                    />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
