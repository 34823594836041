/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";

import { PersistFormikValues } from "formik-persist-values";

const HoursForm = ({ hours, saveHours, btnRef }) => {
  let hoursSchema = Yup.object().shape({
    monday_closed: Yup.boolean().required("How did you do this?"),
    monday_start_time: Yup.string().when(`monday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    monday_end_time: Yup.string().when(`monday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { monday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(monday_start_time, "hh:mm")
            );
          }
        ),
    }),
    tuesday_closed: Yup.boolean().required("How did you do this?"),
    tuesday_start_time: Yup.string().when(`tuesday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    tuesday_end_time: Yup.string().when(`tuesday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { tuesday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(tuesday_start_time, "hh:mm")
            );
          }
        ),
    }),
    wednesday_closed: Yup.boolean().required("How did you do this?"),
    wednesday_start_time: Yup.string().when(`wednesday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    wednesday_end_time: Yup.string().when(`wednesday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { wednesday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(wednesday_start_time, "hh:mm")
            );
          }
        ),
    }),
    thursday_closed: Yup.boolean().required("How did you do this?"),
    thursday_start_time: Yup.string().when(`thursday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    thursday_end_time: Yup.string().when(`thursday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { thursday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(thursday_start_time, "hh:mm")
            );
          }
        ),
    }),
    friday_closed: Yup.boolean().required("How did you do this?"),
    friday_start_time: Yup.string().when(`friday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    friday_end_time: Yup.string().when(`friday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { friday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(friday_start_time, "hh:mm")
            );
          }
        ),
    }),
    saturday_closed: Yup.boolean().required("How did you do this?"),
    saturday_start_time: Yup.string().when(`saturday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    saturday_end_time: Yup.string().when(`saturday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { saturday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(saturday_start_time, "hh:mm")
            );
          }
        ),
    }),
    sunday_closed: Yup.boolean().required("How did you do this?"),
    sunday_start_time: Yup.string().when(`sunday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required("Start time cannot be empty"),
    }),
    sunday_end_time: Yup.string().when(`sunday_closed`, {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("End time cannot be empty")
        .test(
          "is-greater",
          "end time should be greater than start time",
          function(value) {
            const { sunday_start_time } = this.parent;
            return moment(value, "hh:mm").isAfter(
              moment(sunday_start_time, "hh:mm")
            );
          }
        ),
    }),
  });
  
  const getIntialValues = (hours) => {
    let initialValues = {};
    hours &&
      hours.map((h) => {
        initialValues[h.day.toLowerCase() + "_start_time"] = h.start_time == null ? "" : h.start_time;
        initialValues[h.day.toLowerCase() + "_end_time"] = h.end_time == null ? "" : h.end_time;
        initialValues[h.day.toLowerCase() + "_closed"] = h.closed;
      });
      // console.log(initialValues)
    return initialValues;
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={getIntialValues(hours)}
      validationSchema={hoursSchema}
      onSubmit={(values) => {
        saveHours({
          ...values,
        });
      }}
    >
      {({ handleSubmit, values, errors, setFieldValue, getFieldProps }) => (
        <Form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-2 font-weight-bolder">Day</div>
            <div className="col-lg-2 text-center font-weight-bolder">
              Closed
            </div>
            <div className="col-lg-3 text-center font-weight-bolder">
              Start Time
            </div>
            <div className="col-lg-3 text-center font-weight-bolder">
              End Time
            </div>
          </div>
          {hours &&
            hours.map((h, h_index_key) => {
              return (
                <div className="form-group row" key={h_index_key}>
                  <div className="col-lg-2 font-weight-bold">
                    <label>{h.day}</label>
                  </div>
                  <div className="col-lg-2">
                    <Field
                      name={`${h.day.toLowerCase()}_closed`}
                      type="checkbox"
                      className={"form-control"}
                      checked={values[h.day.toLowerCase() + "_closed"]}
                      onClick={(e) => {
                        console.log("Changing to", e.target.value);
                        setFieldValue(
                          h.day.toLowerCase() + "_closed",
                          e.target.value
                        );

                        setFieldValue(h.day.toLowerCase() + "_start_time", "");
                        setFieldValue(h.day.toLowerCase() + "_end_time", "");
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name={`${h.day.toLowerCase()}_start_time`}
                      component={Input}
                      type="time"
                      disabled={values[h.day.toLowerCase() + "_closed"]}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name={`${h.day.toLowerCase()}_end_time`}
                      component={Input}
                      type="time"
                      disabled={values[h.day.toLowerCase() + "_closed"]}
                    />
                  </div>
                </div>
              );
            })}

          <button
            type="submit"
            style={{ display: "none" }}
            ref={btnRef}
            onSubmit={() => handleSubmit()}
          ></button>
          {/* {<PersistFormikValues name="hours-op-form" />} */}
        </Form>
      )}
    </Formik>
  );
};

export function HoursOfOperations({ history }) {
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [hours, setHours] = useState([]);
  const [hoursSuccessOpen, setHoursSuccessOpen] = useState(false);
  const [hoursErrorOpen, setHoursErrorOpen] = useState(false);
  const btnRef = useRef();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  useEffect(() => {
    const getHours = async () => {
      const result = await axios(
        "api/branches/branch-hours/view/" + branch.branch_id
      );
      // console.log(result.data.results);
      setHours(result.data.results);
    };

    branch && getHours();
  }, [branch]);

  const handleClose = () => {
    setHoursSuccessOpen(false);
    setHoursErrorOpen(false);
  };

  const saveHours = async (values) => {
    let body = [...hours];

    body.map((h) => {
      h.start_time = values[h.day.toLowerCase() + "_closed"]
        ? null
        : values[h.day.toLowerCase() + "_start_time"];
      h.end_time = values[h.day.toLowerCase() + "_closed"]
        ? null
        : values[h.day.toLowerCase() + "_end_time"];
      h.closed = values[h.day.toLowerCase() + "_closed"];
    });

    const patchUrl = "api/branches/branch-hours/view/";
    const result = await axios.patch(patchUrl, body);
    if (result.data != "failed") {
      setHoursSuccessOpen(true);
      setTimeout(() => {
        history.push(`/branch-maintenance`);
      }, 1000);
    } else {
      setHoursErrorOpen(true);
    }
  };

  const saveHoursClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveHours(null);
    }
  };
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={hoursSuccessOpen ? hoursSuccessOpen : hoursErrorOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={hoursSuccessOpen ? "success" : "error"}
          message={
            hoursSuccessOpen
              ? "Added payment successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={"Edit Hours Of Operation"}>
          <CardHeaderToolbar>
            {/* <Link
            to={id ? `/` : "/"}
            className="btn btn-primary"
          >
            {" "}
            Back{" "}
          </Link> */}
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveHoursClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link active`}
                data-toggle="tab"
                role="tab"
                aria-selected="true"
              >
                Hours
              </a>
            </li>
          </ul>

          <HoursForm hours={hours} saveHours={saveHours} btnRef={btnRef} />
        </CardBody>
      </Card>
    </Fragment>
    // <>
    //   {/* begin::List Widget 14 */}
    //   <div className={`card card-custom ${className}`}>
    //     {/* begin::Header */}
    //     <div className="card-header border-0">
    //       <h3 className="card-title font-weight-bolder text-dark">
    //          Manage Hours Of Operations
    //       </h3>
    //     </div>
    //     {/* end::Header */}

    //     {/* begin::Body */}
    //     <div className="card-body pt-2">
    //       {/* begin::Item */}
    //       <div className="d-flex flex-wrap align-items-center mb-10">

    //       </div>
    //       {/* end::Item */}
    //     </div>
    //     {/* end::Body */}
    //   </div>
    //   {/* end::List Widget 14 */}
    // </>
  );
}
