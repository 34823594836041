import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CourseRow from "./CourseRow";

let headRows = [
  {
    id: "bc_id",
    numeric: false,
    disablePadding: true,
    label: "BCID",
  },
  {
    id: "batch_type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "code",
    numeric: true,
    disablePadding: false,
    label: "Stream Name (Code)",
  },
  {
    id: "program_type",
    numeric: false,
    disablePadding: false,
    label: "Program",
  },
  {
    id: "camp_icon",
    numeric: false,
    disablePadding: false,
    label: "Camp Types",
  },
  {
    id: "range",
    numeric: true,
    disablePadding: false,
    label: "Display Range",
  },
];

function EnhancedTableHead(props) {
  const { showActions } = props;

  const addAccessCols = () => {
    if (headRows[headRows.length - 1].id != "actions") {
      headRows = [
        ...headRows,
        {
          id: "actions",
          numeric: false,
          disablePadding: false,
          label: "Actions",
        },
      ];
    }
  };
  if (showActions) {
    addAccessCols();
  }

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell key={index} align="center">
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function CourseTable({ courses, showActions, loading }) {
  const classes = useStyles();
  const rows = courses;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              rowCount={rows ? rows.length : 0}
              showActions={showActions}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <LinearProgress color="secondary" styles="width:100%" />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <CourseRow
                          key={row.branch_course_id}
                          row={row}
                          showActions={showActions}
                        />
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
