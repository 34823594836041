/* eslint-disable no-restricted-imports */
/**
 * This is the email pop-up used by the batches/camps to email all parents in that batch/camp
 * @author asads
 * @since 2024-07-09 asads
 * @updated 2024-07-09 asads
 * @params {history} [useHistory] - to redirect
 * @params {routePath} [string] - url to go to using history param
 * @params {camp_ind} [bool] - true if it is an email for a camp, false if it is an email for a batch
 * @params {id} [int] - the batch/camp id
 * @params {branch_id} [int] - the branch id
 * @params {show} [useState - bool] - whether to show the modal or not
 * @params {onHide} [function] - the onHide function for when the modal closes
 * @params {setEmailSuccessOpen} [useSate - bool] - the bool to used to check if the success modal should show
 * @params {setEmailErrorOpen} [useSate - bool] - the bool to used to check if the error modal should show
 * @doc https://docs.google.com/document/d/1Qixp6uy5Ycvc-9snGCDnwyXU9irHf1uGSm6WXYN5MPE/edit#heading=h.biq91ybcq8se
 */
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import TextEditor from "../_partials/TextEditor";

export function EmailDialog({
  history,
  routePath,
  camp_ind,
  id,
  branch_id,
  show,
  onHide,
  setEmailSuccessOpen,
  setEmailErrorOpen,
}) {
  /**
   Variables
   	* emails: [] <- [useState - Array of String] for all the emails in the batch
		* branchEmail: "" <- [useState - String] to keep the branch email
		* plainTextBody: "" <- [useState - String] used by the TextEditor component
		* yupSchema: [...] <- [Yup] used by the email form to validate subject and body
  **/
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [branchEmail, setBranchEmail] = useState("");
  const [plainTextBody, setPlainTextBody] = useState("");

  const yupSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    emailBody: Yup.string().required("Email body is required"),
  });

  /**
   Functions
  **/

  /**
   * getEmails - get all the parents' emails for the current batch/camp
   * @yields updates the emails useState
   */
  const getEmails = async () => {
    const response = await axios.get(
      `api/${camp_ind ? "camps" : "batches"}/${id}/all-emails${
        camp_ind ? "" : "/true/true"
      }`
    );

    /**
     * sample structure
     [
      {
        user_id: [int],
        firstname: [String],
        lastname: [String],
        parent: [String - combination of firstname and lastname],
        parent_email: [String]
      }
      , ...
     ]
    */
    setEmails(response.data.results);
  };

  /**
   * getEmails - get the current branches email
   * @yields updates the branchEmail useState
   */
  const getLocationEmail = async (values) => {
    const emailLink = `api/email-service/get-email?branchId=${branch_id}`;
    const result = await axios.get(emailLink);
    /**
     * sample structure
     [
      {
        email: [String],
      }
      , ...
     ]
    */
    setBranchEmail(result.data.results[0].email);
  };

  /**
   * The is the final submit function for this component.
   * This calls the postapi/email-service/send
   * @param {values} [Object] - the form values
   */
  const sendEmail = async (values) => {
    const sendUrl = "api/email-service/send";
    values.fromEmail = branchEmail;
    values.branchId = branch_id;
    values.to = [];
    values.bcc = [
      ...emails.map((e) => {
        return e.parent_email;
      }),
    ];

    const result = await axios.post(sendUrl, values);
    if (result.data !== "failed") {
      setEmailSuccessOpen(true);
      setTimeout(() => {
        history.push(routePath);
      }, 1000);
    } else {
      setEmailErrorOpen(true);
    }
  };

  /**
   useEffects
  **/
  useEffect(() => {
    id && branch_id && getEmails();
  }, [id, branch_id]);

  useEffect(() => {
    branch_id && getLocationEmail();
  }, [branch_id]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LinearProgress color="secondary" styles="width:100%" />
        ) : (
          <Formik
            initialValues={{ emailBody: "", subject: "" }}
            validationSchema={yupSchema}
            onSubmit={(values, { resetForm }) => {
              sendEmail(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
            }) => (
              <form>
                <div className="form-group row">
                  {" "}
                  <div className="col-lg-12">
                    <label htmlFor="to">Emails</label>
                    <textarea
                      // id="comment"
                      name="to"
                      className={`form-control`}
                      value={emails
                        .map((x) => {
                          return x.parent_email;
                        })
                        .join(", ")}
                      rows={emails.length / 2}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {" "}
                  <div className="col-lg-12">
                    <label htmlFor="subject">Subject</label>
                    <input
                      // id="comment"
                      name="subject"
                      className={`form-control ${isLoading ? "disabled" : ""}`}
                      placeholder="subject"
                      value={values.subject}
                      onChange={(e) => {
                        setFieldValue("subject", e.target.value);
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-danger form-text m-2">
                        {errors.subject ? errors.subject : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label htmlFor="emailBody">Email Body</label>
                    <TextEditor
                      name="emailBody"
                      value={values["emailBody"]}
                      setValue={setFieldValue}
                      setPlainTextBody={setPlainTextBody}
                      onBlur={(e) => {
                        setFieldTouched("emailBody", true);
                        setFieldTouched("bodyLength", true);
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-danger form-text m-2">
                        {errors.emailBody ? errors.emailBody : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="form-group row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    {" "}
                    <button
                      className="btn btn-muted"
                      type="button"
                      onClick={() => {
                        onHide();
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={!branchEmail || !emails.length > 0}
                      onClick={() => handleSubmit()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
