export const LMSAccountsHeaders = [
  {
    id: "student_user_id",
    numeric: false,
    disablePadding: false,
    label: "Student ID",
  },
  {
    id: "student_lastname",
    numeric: false,
    disablePadding: false,
    label: "Student Name",
  },
  {
    id: "course_name",
    numeric: false,
    disablePadding: false,
    label: "Stream Name",
  },
  {
    id: "course_code",
    numeric: false,
    disablePadding: false,
    label: "Stream Code",
  },
  {
    id: "sub_course_code",
    numeric: false,
    disablePadding: false,
    label: "Sub Course",
  },
  {
    id: "batch.start_day",
    numeric: false,
    disablePadding: false,
    label: "Starting Date",
  },
  
];