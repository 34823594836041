import { put, takeLatest, select, call, all } from "redux-saga/effects";
import { familySlice, callTypes } from "./FamilySlice";
import { getStudentCourses } from "./FamilyCrud";
import * as actions from "../_redux/FamilyActions";

export function* familySaga() {
  yield takeLatest(familySlice.actions.familyFetched, function* coursesSaga() {
    console.log("[SAGA] Family Saga");
    const students = yield select((state) => state.family.students);
    //console.log(newStudents);
    yield put(actions.fetchStudentCourses(students));
  });
}
