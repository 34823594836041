/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import * as auth from "../Auth";
import AddParentForm from "./AddParentForm";
import { ParentDuplicateDialog } from "./ParentDuplicateDialog";
import { getFormattedNumber, getVanillaPhoneNumber, checkAndReplace } from "../../helpers/Formatter";

// UI Helpers
const initialValues = {
  firstname: "",
  lastname: "",
  mobile: "",
  homephone: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  email: "",
  alternate_email: "",
  //zebra123 encrypted
  password: "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea",
  refferal: "Google Search",
  alt_refferal: "",
};

const getParentUrl = "/api/user/";
const parentsUrl = "/api/family-view/get/parent/";
const studentsUrl = "/api/family-view/get/student/";
const parentExistingStudentsUrl = "/api/family-view/get/parent/student/";
const duplicateCheckUrl = "/api/user/parent-duplicate-check"; //end point to get specfic student info

export const AddParent = ({
  history,
  match: {
    params: { id, isNew },
  },
}) => {
  // Fields

  const [parent, setParent] = useState();
  const [parents, setParents] = useState();
  const [duplicates, setDuplicates] = useState([]); //list of possible duplicate parents when adding a parent
  const [students, setStudents] = useState();
  const [existingStudents, setExistingStudents] = useState();
  const [existingValues, setExistingValues] = useState();
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [duplicateKnown, setDuplicateKnown] = useState(false);
  // const primaryParent = useSelector();

  // (state) => state.family.primaryParent || state.family.parents[0]
  //getbranch
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  const family = useSelector((state) => state.family);

  // Methods

  async function fetchParentsForCurrentBranch() {
    if (branch) {
      const result = await axios(parentsUrl + branch.branch_id);
      setParents(result.data.results);
    } else {
      setParents([]);
    }
  }

  //get the duplicate students in the db
  const duplicateCheck = async (
    values,
    setStatus,
    setSubmitting,
    studentChosen
  ) => {
    if (duplicateKnown === false) {
      const parentResult = await axios(
        duplicateCheckUrl +
          "/ " +
          values.firstname +
          "/ " +
          values.lastname +
          "/" +
          values.email
      );

      if (
        parentResult.data.results &&
        parentResult.data.results.length > 0 &&
        values.parentType != "existing"
      ) {
        setShowDuplicateDialog(true);
        setDuplicates(parentResult.data.results);
      } else {
        saveParent(values, setStatus, setSubmitting, studentChosen);
      }
    } else {
      saveParent(values, setStatus, setSubmitting, studentChosen);
    }
    //
  };

  async function fetchParentsExistingStudents() {
    if (parent && id) {
      const result = await axios(parentExistingStudentsUrl + parent.user_id);
      let combinedStudents = [...family.students];

      setExistingStudents(result.data.results);

      result.data.results &&
        result.data.results.map((potentialStudent) => {
          combinedStudents.filter((s) => {
            return s.user_id == potentialStudent.user_id;
          }).length <= 0
            ? combinedStudents.push(potentialStudent)
            : (potentialStudent = potentialStudent);
        });
      setStudents(combinedStudents);
    } else {
      setExistingStudents([]);
      setStudents(family.students);
    }
  }
  useEffect(() => {
    fetchParentsForCurrentBranch();
  }, [branch]);

  //if parent edit mode then get info
  useEffect(() => {
    async function getParent() {
      const res = await axios(getParentUrl + "parent/" + id);

      let values = res
        ? {
            user_id: res.data[0].parent_id,
            firstname: res.data[0].firstname,
            lastname: res.data[0].lastname,
            gender: res.data[0].gender,
            mobile: getFormattedNumber(res.data[0].mobile),
            homephone: getFormattedNumber(res.data[0].homephone),
            address: res.data[0].address,
            city: res.data[0].city,
            state: res.data[0].state,
            country: res.data[0].country,
            zip: res.data[0].zip,
            email: res.data[0].email,
            alternate_email: res.data[0].alternate_email,
            parentType: "existing",
            //zebra123 encrypted
            password:
              "$2a$10$9marzvVOoF99fqhWuwOeWuDxBbE4glOm6dbGfXj6TM0rDvtISqBea",
            refferal: res.data[0].refferal,
            alt_refferal: res.data[0].alt_refferal
              ? res.data[0].alt_refferal
              : "",
          }
        : {};
      setExistingValues(values);
      setParent(values);
    }
    id && getParent();
  }, [id]);

  useEffect(() => {
    fetchParentsExistingStudents();
  }, [id, parent]);

  const saveParent = async (
    values,
    setStatus,
    setSubmitting,
    studentChosen
  ) => {
    let finalValues = id
      ? {
          ...values,
          branch: branch.branch_id,
          active_id: 1,
          students: studentChosen.map((student) => {
            return student.value;
          }),
        }
      : values.parentType == "existing"
      ? {
          ...parent,
          branch: branch.branch_id,
          active_id: 1,
          students: studentChosen.map((student) => {
            return student.value;
          }),
        }
      : {
          ...values,
          branch: branch.branch_id,
          active_id: 1,
          students: studentChosen.map((student) => {
            return student.value;
          }),
        };

        finalValues.mobile = getVanillaPhoneNumber(finalValues.mobile);
        finalValues.homephone = getVanillaPhoneNumber(finalValues.homephone)
        
        finalValues.firstname = checkAndReplace(finalValues.firstname);
        finalValues.lastname = checkAndReplace(finalValues.lastname);
        finalValues.zip = checkAndReplace(finalValues.zip);
        finalValues.alt_refferal = checkAndReplace(finalValues.alt_refferal);
        finalValues.address = checkAndReplace(finalValues.address);
        finalValues.city = checkAndReplace(finalValues.city);
    if (values.parentType == "new") {
      //save new parent post
      console.log("saving new parent");
      const batchesUrl = "api/user/parent";
      const result = await axios.post(batchesUrl, finalValues);
      if (result.data.res === "success") {
        //setSuccessOpen(true);
        setTimeout(() => {
          history.push({
            pathname: `/family-profile/${result.data.id}/family-overview`,
          });
        }, 2000);
      } else {
        //setErrorOpen(true);
      }
    } else {
      //edit batch post
      const addParentUrl = `api/user/parent/${id ? id : finalValues.user_id}`;
      let addingStudents = studentChosen.filter((chosen) => {
        return (
          existingStudents.filter((existing) => {
            return existing.user_id == chosen.value;
          }).length == 0
        );
      });
      let add = [];
      addingStudents.map((student) => {
        add.push(student.value);
      });

      let removingStudents = existingStudents.filter((existing) => {
        return (
          studentChosen.filter((chosen) => {
            return existing.user_id == chosen.value;
          }).length <= 0
        );
      });

      let remove = [];
      removingStudents.map((student) => {
        remove.push(student.user_id);
      });

      finalValues = { ...finalValues, adding: add, removing: remove };

      const result = await axios.patch(addParentUrl, finalValues);
      if (result.data.res === "success") {
        //setEditSuccessOpen(true);
        setTimeout(() => {
          history.push(`/family-profile/${result.data.id}/family-overview`);
        }, 3000);
      } else {
        //setEditErrorOpen(true);
      }
    }
  };

  return id && existingValues ? (
    <div>
      {console.log("id: ", id)}
      <AddParentForm
        id={id}
        initialValues={existingValues}
        setParent={setParent}
        duplicateCheck={duplicateCheck}
        saveParent={saveParent}
        parents={parents}
        students={students}
        existingStudents={existingStudents}
        parent={parent}
        isNew={isNew}
      />
    </div>
  ) : (
    <div>
      <AddParentForm
        id={id}
        initialValues={initialValues}
        setParent={setParent}
        duplicateCheck={duplicateCheck}
        saveParent={saveParent}
        parents={parents}
        students={students}
        existingStudents={existingStudents}
        parent={parent}
        isNew={isNew}
      />
      <ParentDuplicateDialog
        parents={duplicates}
        duplicateKnown={duplicateKnown}
        setDuplicateKnown={setDuplicateKnown}
        onHide={() => {
          setShowDuplicateDialog(!showDuplicateDialog);
        }}
        show={showDuplicateDialog && !duplicateKnown}
      />
    </div>
  );
};
export default connect(null, auth.actions)(AddParent);
