/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Snackbar } from "@material-ui/core";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { InstructorProfileEditForm } from "./Components/InstructorProfileEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { SnackbarWrapper } from "../../../Snackbar/SnackbarWrapper";
import { checkAndReplace } from "../../../../helpers/Formatter";
import moment from "moment";

import { shallowEqual, useSelector } from "react-redux";


const initProfile = {
  name: "",
  position: "",
  bio: "",
  image_link: "",
  branch_id: 0,
  active_id: "1",
};

export function InstructorProfileEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [instructorProfilesToAdd, setInstructorProfilesToAdd] = useState([]);
  const [values, setValues] = useState();
  const btnRef = useRef();

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  initProfile.branch_id =branch.branch_id; 

  useEffect(() => {
    const getInstructorProfileInfo = async () => {
      const profiles_url = `api/coaches-profile/edit/${id}`;
      const res = await axios(profiles_url);
      setData(res.data.results[0]);

    };
    id && getInstructorProfileInfo();
  }, [id]);

  useEffect(() => {
    const newValues = data && id ? data : initProfile;
    
    setValues(newValues);
  }, [id, data]);

  useEffect(() => {
    let _title = id ? "" : "New Profile";
    if (id && duplicate) {
      _title = `Duplicate Profile`;
    } else if (id) {
      _title = `Edit Profile - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [id]);

  const saveInstructorProfile = async (values) => {
    values.name = checkAndReplace(values.name.trim())
    values.position = checkAndReplace(values.position.trim())
    values.bio = checkAndReplace(values.bio.trim())
    if (!id) {
      //save new profile post
      const profile = "api/coaches-profile";
      
      const result = await axios.post(profile, values);
      if (result.data !== "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/branch-maintenance/instructor-profiles/instructor-profiles-list`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else {
      
      //edit profile patch
      const editProfile = `api/coaches-profile/`;
      const result = await axios.patch(editProfile + id, values);
      console.log("got",result)
      if (result.data === "success") {
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/branch-maintenance/instructor-profiles/instructor-profiles-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveInstructorProfileClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveInstructorProfile(null);
    }
  };

  const backToInstructorProfileList = () => {
    history.push(`/branch-maintenance/instructor-profiles`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the Profile successfully"
              : editSuccessOpen
              ? "Edited the Profile successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToInstructorProfileList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveInstructorProfileClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Instructor Profile info
              </a>
            </li>
            
          </ul>
          <div className="mt-5">
            {tab === "basic" && values && (
              <InstructorProfileEditForm
                profile={values}
                btnRef={btnRef}
                saveInstructorProfile={saveInstructorProfile}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
