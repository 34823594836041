import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import SubmissionRow from "./SubmissionRow";

let headRows = [
  {
    id: "dropdown",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "10%",
    colSpan: 1,
  },
  {
    id: "date",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Date",
    width: "20%",
    colSpan: 1,
  },
  {
    id: "name",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
    colSpan: 1,
  },
  {
    id: "total_hours",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Total Hours",
    width: "20%",
    colSpan: 1,
  },
  {
    id: "status",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "20%",
    colSpan: 1,
  },
  {
    id: "actions",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "30%",
    colSpan: 1,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    overflowX: "auto",
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  table: {
    minWidth: 750,
  },
}));

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export default function EnhancedTimesheetTable({
  history,
  isLoading,
  setLoading,
  rows,
  addRow,
  acceptRow,
  declineRow,
  setFieldValue,
  values,
  errors,
  refreshClick,
}) {
  const timesheetStates = useTimesheetContext().states;
  const classes = useStyles();
  const [approved, setApproved] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [accordianReset, setAccordianReset] = useState(false);

  function handleChangePage(e_user, newPage) {
    setPage(newPage);
    setAccordianReset(true);
  }

  function handleChangeRowsPerPage(e_user) {
    setRowsPerPage(+e_user.target.value);
  }

  useEffect(() => {
    setPage(0);
  }, [rows]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {/* BEGIN TABLE HEAD */}
            {Array.isArray(rows) && rows.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    {headRows.map((row, index) => (
                      <TableCell
                        key={`header_${index}`}
                        align={row.align}
                        colSpan={row.colSpan}
                        style={{ width: row.width }}
                      >
                        <span>{row.label}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* END TABLE HEAD */}
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <LinearProgress color="secondary" styles="width:100%" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody style={{ zIndex: "100" }}>
                    {rows &&
                      stableSort(rows, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <SubmissionRow
                            history={history}
                            key={index}
                            row={row}
                            index={index}
                            acceptRow={acceptRow}
                            declineRow={declineRow}
                            setFieldValue={setFieldValue}
                            setLoading={setLoading}
                          />
                        ))}
                  </TableBody>
                )}
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <span
                      style={{
                        fontSize: "1.1rem",
                        color: "#181c32",
                        fontWeight: "500",
                      }}
                    >
                      No more submissions to review
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Button
                    className="btn btn-secondary font-weight-bolder font-size-sm"
                    onClick={() => {
                      timesheetStates.setRefetch(true);
                    }}
                    style={{
                      cursor: isLoading ? "not-allowed" : "pointer",
                    }}
                    disabled={isLoading}
                  >
                    <i className="ki ki-refresh icon-sm"></i>
                    <span>Refresh</span>
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          reset={accordianReset}
          setReset={setAccordianReset}
        />
      </Paper>
    </div>
  );
}
