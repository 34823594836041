/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { CourseListSelection } from "../../SelectionWidgets/CourseListSelection";
import { InstructorListSelection } from "../../SelectionWidgets/InstructorListSelection";
import { SnackbarWrapper } from "../../Snackbar/SnackbarWrapper";
import { BatchEditForm } from "./Components/BatchEditForm";

const initBatch = {
  start_time: "",
  end_time: "",
  day: "Monday",
  maximum_student: "",
  branch_id: "",
  active_id: "1",
  delivery_type_id: "1",
  enrolled_student_count: 0,
  program_type_id: "1",
  batch_full_ind: "0",
};

export function BatchEdit({
  history,
  match: {
    params: { id, duplicate, dest_id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  //batch data
  const [data, setData] = useState();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [batchCourses, setBatchCourses] = useState([]);
  const [batchMissingCourses, setBatchMissingCourses] = useState([]);
  const [coursesToRemove, setCoursesToRemove] = useState([]);
  const [batchInstructors, setBatchInstructors] = useState([]);
  const [batchMissingInstructors, setBatchMissingInstructors] = useState([]);
  const [coursesToAdd, setCoursesToAdd] = useState([]);
  const [coachesToRemove, setCoachesToRemove] = useState([]);
  const [coachesToAdd, setCoachesToAdd] = useState([]);
  const btnRef = useRef();
  //console.log(id);

  //getbranch
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );

  initBatch.branch_id = branch.branch_id;

  useEffect(() => {
    //TODO: fetch details for editing batch here
    const getBatchInfo = async () => {
      const batch_info_url = `api/batches/${id}`;
      const res = await axios(batch_info_url);
      // console.log("resultz:", res.data.results[0]);
      setData(res.data.results[0]);

      // console.log(res);
    };
    id && getBatchInfo();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "New Batch";
    if (id && duplicate) {
      _title = `Duplicate Batch`;
    } else if (id) {
      _title = `Edit Batch - ${id}`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //Get List of courses not in batch
  useEffect(() => {
    const getMissingCourses = async () => {
      try {
        const courses_url = `api/batches/courses/missing/${id}`;
        const res = await axios(courses_url);
        setBatchMissingCourses(res.data.results);
        // console.log(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getMissingCourses();
  }, [id]);

  //Get list of courses in Batch
  useEffect(() => {
    const getCourses = async () => {
      try {
        const courses_url = `api/batches/courses/${id}`;
        const res = await axios(courses_url);
        setBatchCourses(res.data.results);
        dest_id
          ? setCoursesToAdd(
              res.data.results.map((c) => {
                return { id: c.course_id, show_ind: c.show_ind };
              })
            )
          : setCoursesToAdd(coursesToAdd);
        // console.log(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getCourses();
  }, [id, dest_id]);

  //Get List of instructors not in batch
  useEffect(() => {
    const getMissingInstructors = async () => {
      try {
        const instructors_url = `api/batches/instructors/missing/${id}`;
        const res = await axios(instructors_url);
        setBatchMissingInstructors(res.data.results);
        // console.log(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getMissingInstructors();
  }, [id]);

  //Get list of instructors in Batch
  useEffect(() => {
    const getInstructors = async () => {
      try {
        const instructors_url = `api/batches/instructors/${id}`;
        const res = await axios(instructors_url);
        setBatchInstructors(res.data.results);
        dest_id
          ? setCoachesToAdd(
              res.data.results.map((c) => {
                return c.instructor_id;
              })
            )
          : setCoursesToAdd(coursesToAdd);
        // console.log(res.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    id && getInstructors();
  }, [id, dest_id]);

  const saveBatch = async (values) => {
    values = values ? values : data;
    if (!id) {
      //save new batch post
      const batchesUrl = "api/batches";
      // const info = JSON.parse(localStorage.getItem("batch-form"));
      const result = await axios.post(batchesUrl, values);
      if (result.data != "failed") {
        // console.log("batch result 1: ", result);
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/batches/${result.data}/edit-batch`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else if (id && duplicate && !dest_id) {
      //save new duplicated batch post w/ coaches and courses
      const batchesUrl = "api/batches/duplicate";
      // const info = JSON.parse(localStorage.getItem("batch-form"));
      const result = await axios.post(batchesUrl, values);
      if (result.data != "failed") {
        setSuccessOpen(true);
        setTimeout(() => {
          history.push(`/batches/${result.data}/edit-batch`);
        }, 1000);
      } else {
        setErrorOpen(true);
      }
    } else {
      // console.log("Remove Courses", coursesToRemove);
      // console.log("Add Courses", coursesToAdd);
      // console.log("Remove Inst", coachesToRemove);
      // console.log("Add Inst", coachesToAdd);

      // const info = JSON.parse(localStorage.getItem("batch-form"));
      // console.log("values", info);
      const send_data = {
        remove_courses: coursesToRemove,
        edit_courses: batchCourses,
        add_courses: coursesToAdd,
        remove_inst: coachesToRemove,
        add_inst: coachesToAdd,
        values: values,
      };
      // console.log(send_data);
      //edit batch post
      const editBatchUrl = `api/batches/`;
      const result = await axios.patch(editBatchUrl, send_data);
      // console.log(result)
      if (result.data === "success") {
        console.log("batch result 4: ", result);
        setEditSuccessOpen(true);
        setTimeout(() => {
          history.push("/batches/batch-list");
        }, 1000);
      } else {
        setEditErrorOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const saveBatchClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    } else {
      saveBatch(null);
    }
  };

  const backToBatchList = () => {
    history.push(`/batches`);
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={
          successOpen
            ? successOpen
            : editSuccessOpen
            ? editSuccessOpen
            : errorOpen
            ? errorOpen
            : editErrorOpen
        }
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarWrapper
          onClose={handleClose}
          variant={successOpen || editSuccessOpen ? "success" : "error"}
          message={
            successOpen
              ? "Added the batch successfully"
              : editSuccessOpen
              ? "Edited the batch successfully"
              : "Something went wrong"
          }
        />
      </Snackbar>
      <Card>
        {/* {actionsLoading && <ModalProgressBar />} */}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToBatchList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveBatchClick}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("basic")}>
              <a
                className={`nav-link ${tab === "basic" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "basic").toString()}
              >
                Basic info
              </a>
            </li>
            {((id && !duplicate) || (id && duplicate && dest_id)) && (
              <>
                {" "}
                <li className="nav-item" onClick={() => setTab("coaches")}>
                  <a
                    className={`nav-link ${tab === "coaches" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "coaches").toString()}
                  >
                    Coaches
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("courses")}>
                  <a
                    className={`nav-link ${tab === "courses" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "courses").toString()}
                  >
                    Streams
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="mt-5">
            {tab === "basic" && (
              <BatchEditForm
                //actionsLoading={actionsLoading}
                //Pass in the edit batch OR new batch
                batch={data ? data : initBatch}
                btnRef={btnRef}
                saveBatch={saveBatch}
              />
            )}
            {tab === "coaches" && id && (
              <div>
                {" "}
                <InstructorListSelection
                  id={id}
                  type={"batch"}
                  setCoachesToAdd={setCoachesToAdd}
                  coachesToAdd={coachesToAdd}
                  coachesToRemove={coachesToRemove}
                  setCoachesToRemove={setCoachesToRemove}
                  batchInstructors={batchInstructors}
                  setBatchInstructors={setBatchInstructors}
                  batchMissingInstructors={batchMissingInstructors}
                  setBatchMissingInstructors={setBatchMissingInstructors}
                />
              </div>
            )}
            {tab === "courses" && id && (
              <div>
                {" "}
                <CourseListSelection
                  id={id}
                  type={"batch"}
                  setCoursesToAdd={setCoursesToAdd}
                  coursesToAdd={coursesToAdd}
                  setCoursesToRemove={setCoursesToRemove}
                  coursesToRemove={coursesToRemove}
                  batchCourses={batchCourses}
                  setBatchCourses={setBatchCourses}
                  batchMissingCourses={batchMissingCourses}
                  setBatchMissingCourses={setBatchMissingCourses}
                />{" "}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
}
