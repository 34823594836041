import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { getFormattedNumber } from "../../../helpers/Formatter";
import { useWebTransactionsContext } from "./WebTransactionsDataContext";

const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function WebTransactionsRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [transactionOpen, setTransactionOpen] = useState("");
  const [transactionDetails, setTransactionDetails] = useState();
  const WebTransactionsContext = useWebTransactionsContext();
  const {
    headRows,
    URL,
    openEnrollPage,
    triggerEmail,
    manuallyEntered,
  } = WebTransactionsContext;

  const handleRowClick = (transaction_id) => {
    setOpen(!open);
    setTransactionOpen(transaction_id);

    reset && setReset(false);
  };

  const fetchTransactionOpen = async () => {
    const result = await axios(
      URL.transactionURL + "details/" + transactionOpen
    );

    setTransactionDetails(result.data.results);
  };

  useEffect(() => {
    transactionOpen && fetchTransactionOpen();
  }, [transactionOpen]);

  return (
    <Fragment>
      <TableRow key={row.web_transaction_parent_id} hover tabIndex={-1}>
        {headRows.map((rowHead, index) => {
          return (
            <TableCell key={index} align="center" component="th" scope="row">
              {rowHead.id == "web_transaction_parent_id" ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      handleRowClick(row.web_transaction_parent_id)
                    }
                  >
                    {open && !reset ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                  {row.web_transaction_parent_id}
                </>
              ) : rowHead.id == "parent_name" ? (
                <>
                  {row.user_id && <b>{row.user_id}</b>} {row.parent_name}
                </>
              ) : rowHead.id == "parent_phone" ? (
                <>{getFormattedNumber(row[rowHead.id])}</>
              ) : rowHead.id == "status" ? (
                <>
                  {row.status === "Abandoned" ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="products-edit-tooltip">
                          Abandoned Cart
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-times text-danger"></i>
                    </OverlayTrigger>
                  ) : row.status === "New" ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="products-edit-tooltip">New</Tooltip>
                      }
                    >
                      <i className="fa fa-cart-plus text-primary"></i>
                    </OverlayTrigger>
                  ) : row.status === "Enrolled" ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="products-edit-tooltip">Enrolled</Tooltip>
                      }
                    >
                      <i className="fa fa-users text-success"></i>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="products-edit-tooltip">Error</Tooltip>
                      }
                    >
                      <i className="fa fa-exclamation-circle text-danger"></i>
                    </OverlayTrigger>
                  )}
                </>
              ) : (
                <>
                  {rowHead.date
                    ? row[rowHead.id] && row[rowHead.id] != ""
                      ? moment(row[rowHead.id], "YYYY-MM-DD").format(
                          "ddd MMM DD, YYYY"
                        )
                      : ""
                    : row[rowHead.id]}
                </>
              )}
            </TableCell>
          );
        })}
        {showActions && ["New", "Partial"].includes(row.status) ? (
          <TableCell align="center">
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Enroll</Tooltip>}
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
                onClick={() => {
                  openEnrollPage(row.web_transaction_parent_id);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="fa fa-user-plus text-primary"></i>
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Trigger Email</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
                onClick={() => {
                  triggerEmail(row.web_transaction_parent_id);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="fa fa-envelope text-primary"></i>
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Manually Entered</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-success btn-sm mr-1"
                onClick={() => {
                  manuallyEntered(row.web_transaction_parent_id);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-success">
                  <i className="fa fa-check-circle text-success"></i>
                </span>
              </a>
            </OverlayTrigger>
          </TableCell>
        ) : showActions && row.status == "Enrolled" ? (
          <TableCell align="center">
            <Badge pill variant="success" className="text-light">
              {row.enrollment_status === 2 ? "Manually Entered" : "Registered"}
            </Badge>
          </TableCell>
        ) : (
          <TableCell align="center">
            <Badge pill variant="danger" className="text-light">
              ERROR
            </Badge>
          </TableCell>
        )}
      </TableRow>

      <TableRow key={"details" + row.web_transaction_parent_id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={2}
              margin={3}
              style={{ backgroundColor: "#f5f5f5", borderRadius: "0.5rem" }}
            >
              <Container>
                {transactionDetails &&
                  transactionDetails.batch &&
                  transactionDetails.batch.map((student) => {
                    return (
                      <Row
                        className="mb-3"
                        key={student.web_transaction_student_course_id}
                      >
                        <p>
                          {student.student_firstname} {student.student_lastname}{" "}
                          @ {student.day} {student.start_time} -{" "}
                          {student.end_time} [{student.course_abbr}{" "}
                          {student.course_code}]
                        </p>
                      </Row>
                    );
                  })}
                {transactionDetails &&
                  transactionDetails.camp &&
                  transactionDetails.camp.map((student) => {
                    return (
                      <Row
                        className="mb-3"
                        key={student.web_transaction_student_camp_id}
                      >
                        <p>
                          {student.student_firstname} {student.student_lastname}{" "}
                          @{student.startdate} - {student.enddate} [
                          {student.course_abbr} {student.course_code}]
                        </p>
                      </Row>
                    );
                  })}
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
