import React, { useEffect, useState } from "react";
import { useField } from "formik";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const getOptionLabel = (option) => {
  if (Array.isArray(option.props.children)) {
    return option.props.children.find((value) => {
      if (value.trim().length > 0) {
        return value;
      }
    });
  } else {
    return option.props.children;
  }
};

export function SelectCreatable({ label, labelClasses, options, ...props }) {
  const [ currentOptions, setCurrentOptions ] = useState();
  const [field, meta, { setValue, setTouched }] = useField(props);
  
  const style = {
    control: (base) => ({
      ...base,
      border: meta.touched ? meta.error ? "1px solid #F64E60" : "1px solid #A0B432" : "1px solid #E4E6EF",
      borderRadius: "0.42rem",
      "&:hover": {
        borderColor: "none"
      },
      boxShadow: "none"
    }),
    option: (base, { isDisabled }) => ({
      ...base,
      backgroundColor: "#FFF",
      color: isDisabled ? "#C0C0C0" : "#212529",
      "&:hover": {
        backgroundColor: isDisabled ? "#FFF" : "#F0F0F0",
        color: isDisabled ? "#C0C0C0" : "#212529",
        cursor: isDisabled ? "not-allowed" : "pointer",
      }
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0"
    }),
  };

  const setFieldValues = () => {
    if (currentOptions && field.value) {
      if (Array.isArray(field.value)) {
        let selectedOptions = [];
        field.value.forEach((item) => {
          selectedOptions.push(currentOptions.find((option) => option.value == item.value));
        })
        return selectedOptions;
      } else {
        return currentOptions.find((option) => option.value == field.value);
      }
    }
    return [];
  };

  useEffect(() => {
    const getOptionElements = (options) => {
      let res_options = [];

      if (Array.isArray(options) && options.length > 0) {
        options.map((option) => {
          if (option.type === "option") {
            // console.log("Select.js > getOptionElements ==>", field.name, option);
            res_options.push ({
              value: option.props.value.toString(),
              label: getOptionLabel(option).toString(),
              isDisabled: option.props.disabled
            })
          } else if (Array.isArray(option) && option.length > 0) {
            res_options.push(...getOptionElements(option));
          }
        });
      };
      return res_options;
    }

    if (options === undefined && Array.isArray(props.children) && props.children.length > 0) {
      setCurrentOptions(getOptionElements(props.children));
    } else if (Array.isArray(options)) {
      setCurrentOptions(options.map((option) => ({
          value: option.value.toString(),
          label: option.label.toString(),
          isDisabled: option.disabled
        }
      )));
    } else {
      setCurrentOptions([]);
    }

  }, [options, props.children]);

  const onChange = (options) => {
    if (options) {
      let values = [];
      options.forEach((option) => {
        values.push({value: option.value});
      });
      setValue(values);
    } else {
      setValue([]);
    }
  };

  return (
    <>
      {label && <label className={labelClasses}>Select {label}</label>}
      <CreatableSelect
        value={setFieldValues()}
        defaultValue={setFieldValues()}
        options={currentOptions}
        styles={style}
        onChange={onChange}
        onBlur={() => {
          setTouched(true);
        }}
        components={animatedComponents}
        {...props}
      />
      {meta.touched && meta.error && <div className="form-text text-danger">{meta.error}</div>}
    </>
  );
}