import React, { Suspense, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../../_metronic/layout";
import { WebsiteAnnouncementsEdit } from "./WebsiteAnnouncementsEdit";
import { WebsiteAnnouncementsList } from "./WebsiteAnnouncementsList";
import { WebsiteAnnouncementsContextProvider } from "./Components/WebsiteAnnouncementsDataContext";

export default function WebsiteAnnouncementsPage({ history }) {
  const [refetch, setRefetch] = useState(false);
  const WebsiteAnnouncementsUIEvents = {
    newWebsiteAnnouncementsButtonClick: () => {
      history.push("/branch-maintenance/website-announcements/add-website-announcements");
    },
    openEditWebsiteAnnouncementsPage: (id) => {
      history.push(`/branch-maintenance/website-announcements/${id}/edit-website-announcements`);
    },
    
    refetch: refetch,
    setRefetch: setRefetch,
  };
  return (
    <WebsiteAnnouncementsContextProvider websiteAnnouncementsUIEvents={WebsiteAnnouncementsUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact={true} from="/branch-maintenance/website-announcements" to="/branch-maintenance/website-announcements/website-announcements-list" />}
          <ContentRoute path="/branch-maintenance/website-announcements/add-website-announcements" component={WebsiteAnnouncementsEdit} />

          <ContentRoute
            path="/branch-maintenance/website-announcements/:id/edit-website-announcements"
            component={WebsiteAnnouncementsEdit}
          />

          <ContentRoute path="/branch-maintenance/website-announcements/website-announcements-list" component={WebsiteAnnouncementsList} />

        </Switch>
      </Suspense>
    </WebsiteAnnouncementsContextProvider>
  );
}
