/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useEventContext } from "./EventDataContext";

export function EventDeleteDialog({
  history,
  id,
  active_value,
  show,
  onHide,
}) {
  const context = useEventContext();
  

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);

  const deactivateEvent = async () => {
    const deactivateUrl = "api/workshops/deactivate/";

    //edit event post
    const result = await axios.patch(deactivateUrl + id);
    if (result.data === "success") {
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/events/event-list");
      }, 1000);
    }
    
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Event De-Activate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to de-activate this event?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deactivateEvent}
            className="btn btn-delete btn-elevate"
          >
            Deactivate
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
