/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function AddStudentNavigation() {
  return (
    <>
      <div
        className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
        id="kt_profile_aside"
      >
        <div className="card card-custom card-stretch">
          {/* begin::Body */}
          <div className="card-body pt-4">
            {/* begin::Nav */}
            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
              <div className="navi-item mb-2">
                <NavLink
                  to="/new-registration/parent-info"
                  className="navi-link py-4"
                  activeClassName="active"
                  disabled
                >
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      ></SVG>{" "}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">
                    Parent Information
                  </span>
                </NavLink>
              </div>
              {/* <div className="navi-item mb-2">
                <NavLink
                  to="/new-registration/student-info"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                      ></SVG>{" "}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">
                    Student Information
                  </span>
                </NavLink>
              </div> */}
            </div>
            {/* end::Nav */}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  );
}
