import { ErrorMessage } from "formik";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useReportContext } from "../ReportDataContext";

export function CourseEnrollmentVsDobReportForm({
  reportResults,
  setReportResults,
  setReportTypeId,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  // udpate Report data Context
  const DROPDOWN_TYPE = useReportContext().TYPES;
  const userAuth = useReportContext().AUTH.userAuth;

  return (
    <>
      <div className="form-group row">
        <div className={"col-lg-3"}>
          <label className={"required-field pr-2"}>Enrollment Date</label>
          <OverlayTrigger
            overlay={<Tooltip>The date the student was enrolled on</Tooltip>}
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
          </OverlayTrigger>
          <input
            type="date"
            className={"form-control" + (errors.startdate ? " is-invalid" : "")}
            value={values.startdate ? values.startdate.split("T")[0] : ""}
            onChange={(e) => {
              setFieldValue("startdate", e.target.value);
            }}
          ></input>
          <ErrorMessage
            name={`startdate`}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </>
  );
}
