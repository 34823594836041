import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const DELIVERY_URL = "api/batches/delivery-type";
const COURSE_URL = "api/customer-leads/course/";
const getCoursesUrl = `api/programs/`;

const transactionURL = "api/web-transactions/";

const WebTransactionsContext = createContext();

export function useWebTransactionsContext() {
  return useContext(WebTransactionsContext);
}

export const WebTransactionsConsumer = WebTransactionsContext.Consumer;

export function WebTransactionsContextProvider({
  trasnactionUIWebTransactions,
  children,
}) {
  const [DELIVERYTYPE, SETDELIVERYTYPE] = useState([]);
  const [COURSE, SETCOURSE] = useState([]);
  const [AUTOSELECTCOURSE, SETAUTOSELECTCOURSE] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseData, setSelectedCourseData] = useState();
  const [warnings, setWarnings] = useState({});

  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  // const [refetch, setRefetch] = useState(false);

  let headRows = [
    {
      //edit
      id: "web_transaction_parent_id",
      numeric: false,
      date: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "date",
      numeric: true,
      date: true,
      disablePadding: true,
      label: "Transaction Date",
    },
    {
      id: "parent_name",
      numeric: false,
      date: false,
      disablePadding: true,
      label: "Parent Name",
    },
    {
      id: "parent_email",
      numeric: false,
      date: false,
      disablePadding: true,
      label: "Parent Email",
    },
    {
      id: "parent_phone",
      numeric: false,
      date: false,
      disablePadding: true,
      label: "Parent Phone",
    },
    {
      id: "status",
      numeric: false,
      date: false,
      disablePadding: true,
      label: "Status",
    },
  ];

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_delivery_type = await axios(DELIVERY_URL);
        SETDELIVERYTYPE(res_delivery_type.data.results);
        let res_course = await axios(COURSE_URL + branch.branch_id);
        let sortedData = res_course.data.results.map((c) => {
          return {
            label: c.abbr,
            value: c.id,
            category: c.category,
            name: c.name,
            code: c.code,
          };
        });
        SETCOURSE(sortedData);

        const result_course_data = await axios(getCoursesUrl, {
          params: { branch_id: branch.branch_id, type: "programs" },
        });
        SETAUTOSELECTCOURSE(result_course_data.data.results);
      } catch (e) {
        console.error(e);
      }
    };

    INIT_HELPERS();
  }, [branch]);

  const getCourseBatches = async () => {
    if (selectedCourse) {
      //{label: 'Rob. Design', value: 20, category: 'Robotics', name: 'Robot Design & Navigation ', code: 'R400'}
      const result = await axios(
        getCoursesUrl + selectedCourse.id + `?branchId=${branch.branch_id}`
      );

      setSelectedCourseData(result.data.results);
    }
  };

  useEffect(() => {
    selectedCourse && getCourseBatches();
  }, [selectedCourse]);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const TransactionsContext = {
    openEnrollPage: trasnactionUIWebTransactions.openEnrollPage,
    triggerEmail: trasnactionUIWebTransactions.triggerEmail,
    manuallyEntered: trasnactionUIWebTransactions.manuallyEntered,
    TYPES: {},
    headRows: headRows,
    defaultSelectValue: "Select...",
    newSelectValue: "New",
    URL: {
      transactionURL: transactionURL,
    },
    defaultWarnings: { parents: [], students: [] },
    warnings,
    setWarnings,
  };

  return (
    <WebTransactionsContext.Provider value={TransactionsContext}>
      {children}
    </WebTransactionsContext.Provider>
  );
}
