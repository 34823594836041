import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { Fragment, useEffect, useState } from "react";
import { convertFromTimeStampTToDate } from "../../../helpers/TimeConvert";
import { useEventContext } from "../Components/EventDataContext";
import { EventTableActions } from "./ActionFormatter/EventTableActions";
import moment from "moment";
import { Badge } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  greyRatio: {
    color: "black",
    background: "#D3D3D3",
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function EventRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const eventContext = useEventContext();


  return (
    <Fragment>
      <TableRow key={row.workshop_id} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.workshop_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.workshop_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Badge pill className={classes.successRatio}>
            ${row.fee}
          </Badge>
        </TableCell>
        <TableCell align="center">
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.startdate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>{" "}
          -{" "}
          <Badge pill className={classes.greyRatio}>
            {moment(
              convertFromTimeStampTToDate(row.enddate),
              "YYYY-MM-DD"
            ).format("MMM D, YYYY")}
          </Badge>
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <EventTableActions
              row={row}
              openEditEventPage={eventContext.openEditEventPage}
              openDeleteEventDialog={eventContext.openDeleteEventDialog}
              openActivateEventDialog={eventContext.openActivateEventDialog}
              openEventFullDialog={eventContext.openEventFullDialog}
              openDuplicateEventPage={eventContext.openDuplicateEventPage}
              openEventNotFullDialog={eventContext.openEventNotFullDialog}
            />
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
}
