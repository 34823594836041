export const campAttendanceHeaders = [
  {
    key: "camp_type",
    label: "Camp Type",
  },
  {
    key: "course_abbr",
    label: "Stream (Abbr)",
  },
  {
    key: "student_id",
    label: "Student ID",
  },
  {
    key: "student_name",
    label: "Student Name",
  },
  {
    key: "parent_name",
    label: "Parent Name",
  },
  {
    key: "phone",
    label: "Phone Number",
  },
  {
    key: "mon",
    label: "Mon - IN",
  },
  {
    key: "mon",
    label: "Mon - OUT",
  },
  {
    key: "tue",
    label: "Tue - IN",
  },
  {
    key: "tue",
    label: "Tue - OUT",
  },
  {
    key: "wed",
    label: "Wed - IN",
  },
  {
    key: "wed",
    label: "Wed - OUT",
  },
  {
    key: "thu",
    label: "Thu - IN",
  },
  {
    key: "thu",
    label: "Thu - OUT",
  },
  {
    key: "fri",
    label: "Fri - IN",
  },
  {
    key: "fri",
    label: "Fri - OUT",
  },
   
  ];


  