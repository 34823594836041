/* eslint eqeqeq: "off" */
import PanToolIcon from "@material-ui/icons/PanTool";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { InputWithLimit } from "../../../../../_metronic/_partials/controls/forms/InputWithLimit";
import { checkAndReplace } from "../../../../helpers/Formatter";
import { useBatchContext } from "./BatchDataContext";

export function LeadAttendanceDialog({
  history,
  trialId,
  show,
  onHide,
  setLeadAttendaceSuccessOpen,
  setLeadAttendaceErrorOpen,
}) {
  const [studentName, setStudentName] = useState("");
  const [presentInd, setPresentInd] = useState(0);
  const [coachComment, setCoachComment] = useState("");
  const context = useBatchContext();

  useEffect(() => {
    const getTrial = async () => {
      // if !trialId we should close modal
      if (!trialId) {
        onHide();
      } else {
        const LeadAttendanceUrl = `api/customer-leads/lead-attendance/${trialId}`;
        const res = await axios(LeadAttendanceUrl);
        console.log(res.data.results);
        setPresentInd(res.data.results[0].present_ind);
        setCoachComment(res.data.results[0].coach_comment);
        setStudentName(res.data.results[0].student_name);
      }
    };
    getTrial(trialId);
    // eslint-disable-next-line
  }, [trialId]);

  const handleSubmit = async () => {
    const LeadAttendanceUrl = `api/customer-leads/lead-attendance/${trialId}`;
    let localCoachComment = coachComment;
    const result = await axios.patch(LeadAttendanceUrl, {
      present_ind: presentInd,
      coach_comment: checkAndReplace(localCoachComment),
    });
    if (result.data === "success") {
      setLeadAttendaceSuccessOpen(true);
      context.setRefetch(true);
      setTimeout(() => {
        history.push("/batches/batch-list");
      }, 1000);
    } else {
      setLeadAttendaceErrorOpen(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        {/* FIX ME */}
        <Modal.Title id="example-modal-sizes-title-lg">
          Attendance for - {trialId} {studentName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group row">
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
              <label>Attendance:</label>
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">Student Present</Tooltip>
                }
              >
                <Button
                  variant={`${presentInd == 1 ? "success" : "secondary"}`}
                  className="mr-2 btn-sm align-text"
                  onClick={() => {
                    let newValue = !presentInd;
                    console.log(newValue);
                    setPresentInd(newValue);
                  }}
                >
                  <PanToolIcon fontSize="small" />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="form-group row">
            <div className={"col-lg-12"}>
              <InputWithLimit
                className={`form-control form-control-lg form-control-solid`}
                label="Coach's Comment"
                name="coach_comment"
                id="coach_comment"
                type="textarea"
                maxLength={225}
                displaylimit={"true"}
                rows="6"
                value={coachComment}
                onChange={(e) => {
                  setCoachComment(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-delete btn-elevate"
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
