import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useUserContext } from "./UserDataContext";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import { Field } from "formik";
import { Button } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { values } from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  campIcon: {
    color: "green",
  },
  workshopIcon: {
    color: "yellow",
  },
  regularClassIcon: {
    color: "#9f7431",
  },
  inPersonClass: {
    color: "#06b0d6",
  },
  virtualClass: {
    color: "#7c06d6",
  },
  hybridClass: {
    color: "orange",
  },
  table: {
    minWidth: 750,
  },
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));
export function CertificationTableRow({
  i,
  values,
  touched,
  error,
  setFieldValue,
}) {
  const [editting, setEditting] = useState(false);
  const DROPDOWN_CERTIFICATES = useUserContext().CERTIFICATE;
  const DROPDOWN_STATUS = useUserContext().STATUS;
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell align="center">
        <Select
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name={`certificate[${i}].course`}
          isClearable={false}
          isMulti={false}
          validationColorScheme={false}
          placeholder="Select Stream..."
          value={
            DROPDOWN_CERTIFICATES.CERTIFICATES &&
            DROPDOWN_CERTIFICATES.CERTIFICATES.filter((cert) => {
              return cert.value == values.certificate[i].course;
            })[0]
          }
          onChange={(e) => {
            setFieldValue(`certificate[${i}].course`, e.value);
          }}
        >
          {DROPDOWN_CERTIFICATES.CERTIFICATES &&
            DROPDOWN_CERTIFICATES.CERTIFICATES.filter((cert) => {
              return values.certificate
                ? values.certificate.filter((vCert) => {
                    return vCert.course == cert.value;
                  }).length <= 0
                : true;
            }).map((cert) => (
              <option key={cert.value} value={cert.value}>
                {cert.label}
              </option>
            ))}
        </Select>
      </TableCell>
      <TableCell align="center">
        <Select
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name={`certificate[${i}].status`}
          isClearable={false}
          isMulti={false}
          validationColorScheme={false}
          placeholder="Select a Status..."
          onChange={(e) => {
            if (
              values.certificate[i].status == "Certified" &&
              e.value != "Certified"
            ) {
              setFieldValue(`certificate[${i}].certified_date`, "");
              setFieldValue(`certificate[${i}].proctor`, "");
            }
            if (e.value != "Certified" && e.value != "Complete") {
              setFieldValue(`certificate[${i}].trained_date`, "");
              setFieldValue(`certificate[${i}].certified_date`, "");
              setFieldValue(`certificate[${i}].proctor`, "");
            }
            setFieldValue(`certificate[${i}].status`, e.value);
          }}
          options={DROPDOWN_STATUS}
        />
      </TableCell>
      <TableCell align="center">
        {values.certificate[i].status == "Certified" ||
        values.certificate[i].status == "Complete" ? (
          <Field
            name={`certificate[${i}].trained_date`}
            labelClasses="required-field"
            type="date"
            onChange={(e) => {
              setFieldValue(`certificate[${i}].trained_date`, e.target.value);
            }}
            component={Input}
          />
        ) : (
          <p>
            {values.certificate[i].trained_date != "" &&
            values.certificate[i].trained_date != "Invalid date"
              ? values.certificate[i].trained_date
              : "N/A"}
          </p>
        )}
      </TableCell>
      <TableCell align="center">
        {values.certificate[i].status == "Certified" ? (
          <Field
            name={`certificate[${i}].certified_date`}
            labelClasses="required-field"
            type="date"
            onChange={(e) => {
              setFieldValue(`certificate[${i}].certified_date`, e.target.value);
            }}
            component={Input}
          />
        ) : (
          <p>
            {values.certificate[i].certified_date
              ? values.certificate[i].certified_date
              : "N/A"}
          </p>
        )}
      </TableCell>
      <TableCell align="center">
        {values.certificate[i].status == "Certified" ? (
          <Field
            name={`certificate[${i}].proctor`}
            labelClasses="required-field"
            type="text"
            component={Input}
          />
        ) : (
          <p>
            {values.certificate[i].proctor
              ? values.certificate[i].proctor
              : "N/A"}
          </p>
        )}
      </TableCell>
    </TableRow>
  );
}
