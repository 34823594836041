import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const ACTIVE_URL = "api/workshops/active";


const InstructorProfileContext = createContext();

export function useInstructorProfileContext() {
  return useContext(InstructorProfileContext);
}

export const InstructorProfileConsumer = InstructorProfileContext.Consumer;

export function InstructorProfileContextProvider({ instructorProfileUIEvents, children }) {
  const [ACTIVE, SETACTIVE] = useState([]);

  // const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const INIT_HELPERS = async () => {
      try {
        let res_active = await axios(ACTIVE_URL);
        SETACTIVE(res_active.data.results);
      } catch (e) {
        console.error(e);
      }
    };
    INIT_HELPERS();
  }, []);

  // const setRefetchHandler = (value) => {
  //   setRefetch(value);
  // };

  // const getRefetch = () => {
  //   return refetch;
  // };

  const instructorProfileContext = {
    newInstructorProfileButtonClick: instructorProfileUIEvents.newInstructorProfileButtonClick,
    openEditInstructorProfilePage: instructorProfileUIEvents.openEditInstructorProfilePage,
    openActivateInstructorProfileDialog: instructorProfileUIEvents.openActivateInstructorProfileDialog,
    openDeactivateInstructorProfileDialog: instructorProfileUIEvents.openDeactivateInstructorProfileDialog,
    refetch: instructorProfileUIEvents.refetch,
    setRefetch: instructorProfileUIEvents.setRefetch,
    TYPES: {
      ACTIVE,
      SETACTIVE,
    },
  };

  return (
    <InstructorProfileContext.Provider value={instructorProfileContext}>
      {children}
    </InstructorProfileContext.Provider>
  );
}
