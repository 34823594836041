export const couponHeaders = [
    {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "Transaction ID"
    },
    {
      id: "course_name",
      numeric: false,
      disablePadding: true,
      label: "Stream Name"
  },
    {
        id: "parent_name",
        numeric: false,
        disablePadding: true,
        label: "Parent Name"
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email"
    },
    {
        id: "phone",
        numeric: false,
        disablePadding: true,
        label: "Phone"
    },
    {
        id: "total",
        numeric: false,
        disablePadding: true,
        label: "total"
    },
    {
        id: "coupon_name",
        numeric: false,
        disablePadding: true,
        label: "Coupon Name"
    },
]