import React, { Suspense, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import { BranchCourseList } from "./BranchCourseList";
import { BranchCourseContextProvider } from "./Components/BranchCourseDataContext";
import { NoAccessError } from "../../../pages/Error/NoAccessError";

export default function BranchCoursePage({ history }) {
  const access = useSelector((state) => state.auth.access, shallowEqual);
  const branchCourseUIEvents = {
    openDeleteBranchCourseDialog: (id) => {
      history.push(`/branch-course/branch-course-list/${id}/delete-branch`);
    },
    openActivateBranchCourseDialog: (id) => {
      history.push(`/branch-course/branch-course-list/${id}/activate-branch`);
    },
    openCallOnBranchCourseDialog: (id) => {
      history.push(`/branch-course/branch-course-list/${id}/enroll-on`);
    },
    openCallOffBranchCourseDialog: (id) => {
      history.push(`/branch-course/branch-course-list/${id}/enroll-off`);
    },
    openEditBranchCoursePage: (id) => {
      history.push(`/branch-course/branch-course-list/${id}/edit`);
    },
  };
  return (
    <BranchCourseContextProvider branchCourseUIEvents={branchCourseUIEvents}>
      <Suspense fallback={<LayoutSplashScreen />}>
        {access["branch_course"] != undefined ? (
          <Switch>
            <Redirect
              exact={true}
              from="/branch-course"
              to="/branch-course/branch-course-list"
            />
            <ContentRoute
              path="/branch-course/branch-course-list"
              component={BranchCourseList}
            />
          </Switch>
        ) : (
          <ContentRoute
            exact={true}
            from="/branch-course"
            component={NoAccessError}
          />
        )}
      </Suspense>
    </BranchCourseContextProvider>
  );
}
