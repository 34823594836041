
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

export default function OOOReportRow(props) {
  const { row, reset, setReset, showActions, index } = props;



  return (
    <Fragment>
      <TableRow key={`${index}`} hover tabIndex={-1}>
        <TableCell align="center" component="th" scope="row">
          {row.user_id}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
        {row.hours}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.user_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
            {row.date}
        </TableCell>
        
      </TableRow>
    </Fragment>
  );
}
