export const courseEnrollmentVsDobHeaders = [
    {
        key: "student_id",
        label: "Student ID",
      },
      {
          key:"Name",
          label:"Name",
      },
      {
          key:"dob",
          label:"DOB"
  
      },
      {
          key:"age",
          label:"Age"
      },
      {
          key:"calculated_grade",
          label: "Grade"
      },
      {
          key:"registration_date",
          label:"Registration date",
      },
  ];