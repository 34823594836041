/* eslint eqeqeq: "off" */
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { shallowEqual, useSelector } from "react-redux";

import moment from "moment";
import { ClassTableActions } from "./ActionFormatter/ClassTableActions";
import { TimedCampStudentTable } from "./CampCoachAndStudentTables";
import { useCampContext } from "./CampDataContext";

const useStyles = makeStyles((theme) => ({
  warningRatio: {
    color: "white",
    background: "#eed202",
  },
  dangerRatio: {
    color: "white",
    background: "#F32013",
  },
  successRatio: {
    color: "white",
    background: "#4BB543",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

let icons = {
  1: {
    icon: "fas fa-school",
    value: "Regular Class",
    css: "svg-icon-muted",
    icon_css: "text-muted",
  },
  2: {
    icon: "fas fa-sun",
    value: "Summer Camp",
    css: "svg-icon-muted",
    icon_css: "text-muted",
  },
  3: {
    icon: "fas fa-running",
    value: "Trackout Camp",
    css: "svg-icon-danger",
    icon_css: "text-danger",
  },
  5: {
    icon: "fas fa-snowflake",
    value: "Winter Camp",
    css: "svg-icon-primary",
    icon_css: "text-primary",
  },
  6: {
    icon: "fas fa-seedling",
    value: "Spring Break Camp",
    css: "svg-icon-success",
    icon_css: "text-success",
  },
};

export default function CampRow(props) {
  const classes = useStyles();
  const { row, reset, setReset, showActions } = props;
  const [open, setOpen] = useState(false);
  const [campOpen, setCampOpen] = useState("");
  const [studentList, setStudentList] = useState([]);
  const token = useSelector((state) => state.auth.authToken, shallowEqual);
  const branch = useSelector(
    (state) => state.auth.branches.currentBranch,
    shallowEqual
  );
  const campListUrl = "/api/classes-report/camp-student-list/";
  const classRatio = (studentCount) => {
    return studentCount
      ? studentCount / row.maximum_student
      : 0 / row.maximum_student;
  };
  const campContext = useCampContext();

  const getTypes = (types, am_full_ind, pm_full_ind, full_day_full_ind) => {
    if (types) {
      var typeObjects = [];
      types.forEach((t, index) => {
        if (t == "AM") {
          // console.log("pushing ", t);
          typeObjects.push({ type: t, full_ind: am_full_ind });
        } else if (t == "PM") {
          // console.log("pushing ", t);
          typeObjects.push({ type: t, full_ind: pm_full_ind });
        } else if (t == "FD") {
          // console.log("pushing ", t);
          typeObjects.push({ type: t, full_ind: full_day_full_ind });
        }
      });
      // console.log(typeObjects);
      // typeObjects.map((t, index) => {
      //   text +=
      //   index % 3 == 0 && index != 0 ?
      //   t + "\n" :
      //       index == types.length - 1 ?
      //       t :
      //       t + ", ";
      // });
    }
    // console.log(text);
    return typeObjects;
    //text.split("\n");
  };

  const getCourses = (courses) => {
    let text = "";
    if (courses) {
      courses.forEach((c, index) => {
        text +=
          index % 3 == 0 && index != 0
            ? c.course_code + "\n"
            : index == courses.length - 1
            ? c.course_code
            : c.course_code + ", ";
      });
    }

    return text.split("\n");
  };

  async function fetchStudentsInCamp() {
    const headers = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    };
    const result = await axios(
      campListUrl + campOpen + "/" + branch.branch_id,
      headers
    );
    setStudentList(result.data.results);
  }

  useEffect(() => {
    campOpen && fetchStudentsInCamp();
    // eslint-disable-next-line
  }, [campOpen]);

  const handleRowClick = (camp_id) => {
    setOpen(!open);
    setCampOpen(camp_id);
    //console.log("here" + camp_id);
    reset && setReset(false);
  };

  return (
    <Fragment>
      <TableRow key={row.camp_id} hover tabIndex={-1}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(row.camp_id)}
          >
            {open && !reset ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.camp_id}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <Tooltip title={row.delivery_type_value}>
            {row.delivery_type_value.toLowerCase().includes("hybrid") ? (
              <i className="fas fa-laptop-house"></i>
            ) : row.delivery_type_value.toLowerCase().includes("virtual") ? (
              <i className="fas fa-laptop"></i>
            ) : (
              <i className="fas fa-user-friends"></i>
            )}
          </Tooltip>
          <span
            className={`svg-icon svg-icon-md pl-1 mr-2 ${
              icons[row.batch_type].css
            }`}
          >
            <Tooltip title={icons[row.batch_type].value}>
              <i
                className={`${icons[row.batch_type].icon} ${
                  icons[row.batch_type].icon_css
                }`}
              ></i>
            </Tooltip>
          </span>

          {row.program_name}
        </TableCell>
        <TableCell align="center">
          <p>
            <strong>AM - </strong>
            {classRatio(row.am_count) < 0.25 ? (
              <Badge pill className={classes.successRatio}>
                {row.am_count
                  ? row.am_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            ) : classRatio(row.am_count) < 0.75 ? (
              <Badge pill className={classes.warningRatio}>
                {row.am_count
                  ? row.am_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            ) : (
              <Badge pill className={classes.dangerRatio}>
                {row.am_count
                  ? row.am_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            )}
          </p>
          <p>
            <strong>PM - </strong>
            {classRatio(row.pm_count) < 0.25 ? (
              <Badge pill className={classes.successRatio}>
                {row.pm_count
                  ? row.pm_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            ) : classRatio(row.pm_count) < 0.75 ? (
              <Badge pill className={classes.warningRatio}>
                {row.pm_count
                  ? row.pm_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            ) : (
              <Badge pill className={classes.dangerRatio}>
                {row.pm_count
                  ? row.pm_count + "/" + row.maximum_student
                  : "0/" + row.maximum_student}
              </Badge>
            )}
          </p>
        </TableCell>
        {/* <TableCell align="center">
          {row.day.includes(",")
            ? row.day.split(",").map((d, i) => <p key={i}>{d}</p>)
            : row.day}
        </TableCell> */}
        <TableCell align="center">
          {moment(row.startdate, "YYYY-MM-DD").format("MMM D, YYYY")} -{" "}
          {moment(row.enddate, "YYYY-MM-DD").format("MMM D, YYYY")}
        </TableCell>

        <TableCell align="center" component="th" scope="row">
          {getTypes(
            row.types,
            row.am_full_ind,
            row.pm_full_ind,
            row.full_day_full_ind
          ).map((type, index) => (
            <p key={index + row.camp_id}>
              {type.full_ind == 1 ? (
                <strong>{type.type + " - (Full)"}</strong>
              ) : (
                type.type
              )}
            </p>
          ))}
        </TableCell>

        <TableCell align="center" component="th" scope="row">
          {getCourses(row.courses).map((course, index) => (
            <p key={index + row.camp_id}>{course}</p>
          ))}
        </TableCell>
        {showActions && (
          <TableCell align="center">
            <ClassTableActions
              row={row}
              openEditCampPage={campContext.openEditCampPage}
              openDeleteCampDialog={campContext.openDeleteCampDialog}
              openActivateCampDialog={campContext.openActivateCampDialog}
              openDuplicateCampPage={campContext.openDuplicateCampPage}
              openPointsDialog={campContext.openPointsDialog}
              openEmailDialog={campContext.openEmailDialog}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow key={"details" + row.camp_family}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && !reset} timeout="auto" unmountOnExit>
            <Box
              padding={6}
              margin={3}
              className="bg-light-primary rounded p-5"
            >
              <Container>
                <Row>
                  {/* <Col>
                    <CampCoachTable instructors={row.instructor} />
                  </Col> */}
                  <Col>
                    <TimedCampStudentTable students={studentList} />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
