import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, { useState } from "react";
import { useBranchCourseContext } from "../BranchCourseDataContext";
import { BranchCampFeeEditTableRow } from "./BranchCampFeeEditTableRow";

export const BranchCampFeeEditTable = ({
  values,
  errors,
  touched,
  deleted,
  setDeleted,
}) => {
  const DROPDOWN_TYPE = useBranchCourseContext().TYPES;
  const [open, setOpen] = useState(false);
  const [batchTypeOpen, setBatchTypeOpen] = useState("");
  const TYPE = values.fees[0].batch_type;
  const empty_row = {
    frequency_unit: values.fees[0].frequency_unit,
    virtual_course_fee: values.fees[0].virtual_course_fee,
    price_range_ind: 0,
    branch_course_fee_id: null,
  };
  const removeRow = (branch_course, remove, index) => {
    if (values.fees.length > 1) {
      remove(index);
      if (branch_course.branch_course_fee_id != null) {
        deleted
          ? setDeleted([...deleted, branch_course.branch_course_fee_id])
          : setDeleted([branch_course.branch_course_fee_id]);
      }
    }
  };

  const handleRowClick = (id) => {
    setOpen(!open);
    setBatchTypeOpen(id);
  };
  return (
    <span>
      <Table>
        <TableBody>
          {DROPDOWN_TYPE.BATCH_TYPE.filter((bt) => {
            return TYPE == 1 ? bt.camp_ind == 0 : bt.camp_ind == 1;
          }).map((bt) => {
            return (
              <BranchCampFeeEditTableRow
                values={values}
                errors={errors}
                touched={touched}
                deleted={deleted}
                setDeleted={setDeleted}
                bt={bt}
                TYPE={TYPE}
              />
            );
          })}
        </TableBody>
      </Table>
    </span>
  );
};
