import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useFormik, FormikProvider } from "formik";
import { date } from "yup";
import { useTimesheetContext } from "../Components/TimesheetDataContext";
import moment from "moment";

export function DownloadTimesheetDialog({
  origData,
  show,
  setShow,
  id,
  setRefetchPaymentHistory,
  setSuccessMessage,
  setErrorMessage,
  onHide,
  branchAccessList,
  TIMESHEET_URL,
}) {
  const [timesheetData, setTimesheetData] = useState([]);
  const downloadBtnRef = useRef();

  const timesheetStates = useTimesheetContext().states;
  const timesheetFunctions = useTimesheetContext().functions;

  const initialValues = {
    startDate: timesheetStates.startDate,
    endDate: timesheetStates.endDate,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("values", values);
    }
  });

  const csvHeaders = [
    { label: "Timesheet Week", key: "week" },
    { label: "Day", key: "date" },
    { label: "Name", key: "name" },
    { label: "Branch", key: "branch" },
    { label: "Task", key: "task" },
    { label: "Hours", key: "hours" },
    { label: "Status", key: "status" },
  ];

  const parseCSVData = (timesheets) => {
    const csvRows = [];
    
    for (const timesheet of timesheets) {
      for (const task of timesheet.timesheet_tasks) {
        for (const entry of Object.values(task.timesheet_entries).filter(e => e.hours > 0)) {
          csvRows.push({
            week: `${moment(timesheet.startDate).format("MMMM DD")} - ${moment(timesheet.endDate).format("MMMM DD")}`,
            date: moment(entry.date).format("ddd MMM DD"),
            name: timesheet.user_name,
            branch: timesheetFunctions.getBranchNameById(task.branch_id),
            task: timesheetFunctions.getTaskNameById(task.task_id),
            hours: entry.hours,
            status: timesheet.approval_status ? "Approved" : "Pending",
          });
        }
      }
    }
    return csvRows;
  }

  const handleDownload = async () => {
    const response = await axios.get(`${TIMESHEET_URL}/submissions?branchIds=${branchAccessList.map(b => b.branch_id).join(",")}&startDate=${formik.values.startDate}&endDate=${formik.values.endDate}`);
    if (response.status === 200) {
      setSuccessMessage("Timesheet data downloaded successfully");
    } else {
      setErrorMessage("Error downloading timesheet data");
    }
    setTimesheetData(parseCSVData(response.data));
  }

  useEffect(() => {
    if (timesheetData.length > 0) {
      downloadBtnRef.current.link.click();
    }
  }, [timesheetData]);

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Download Timesheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <p>
            Select the start and end dates for the timesheet submissions you wish
            to download.
          </p>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Date"
                  name="startDate"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (
                      moment(e.target.value).isAfter(formik.values.endDate)
                    ) {
                      let monday = moment(formik.values.endDate)
                        .day(-6)
                        .format("yyyy-MM-DD");
                      formik.setFieldValue("startDate", monday);
                    } else {
                      let monday =
                        moment(e.target.value).day() == 0
                          ? moment(e.target.value)
                              .day(-6)
                              .format("yyyy-MM-DD")
                          : moment(e.target.value)
                              .day(1)
                              .format("yyyy-MM-DD");
                      formik.setFieldValue("startDate", monday);
                    }
                  }}
                  value={formik.values.startDate}
                />
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  className={`form-control`}
                  placeholder="Date"
                  name="endDate"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (
                      moment(e.target.value).isBefore(formik.values.startDate)
                    ) {
                      let sunday = moment(formik.values.startDate)
                        .startOf("week")
                        .day(7)
                        .format("yyyy-MM-DD");
                      formik.setFieldValue("endDate", sunday);
                    } else {
                      let sunday =
                        moment(e.target.value).day() == 0
                          ? moment(e.target.value).format("yyyy-MM-DD")
                          : moment(e.target.value)
                              .day(7)
                              .format("yyyy-MM-DD");
                      formik.setFieldValue("endDate", sunday);
                    }
                  }}
                  value={formik.values.endDate}
                />
              </div>
            </form>
          </FormikProvider>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleDownload}
        >
          <span>
            <i className="fa fa-solid fa-download"></i>
            Download
          </span>
        </Button>
        <CSVLink
            filename={`timesheet_submissions_(${formik.values.startDate}-${formik.values.endDate}_${branchAccessList.length > 1 ? 'All_Branches' : branchAccessList[0].branch_name}}.csv`}
            headers={csvHeaders}
            ref={downloadBtnRef}
            data={timesheetData}
          >
          </CSVLink>
      </Modal.Footer>
    </Modal>
  );
}
