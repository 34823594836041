/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import userTable from "../../../../../app/modules/Auth/__mocks__/userTableMock";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function UserProfileDropdown() {
  // const { user } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user && user[0].firstname + " " + user[0].lastname}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
              {user && user[0].firstname[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-primary mr-3 flex-shrink-0">
                  <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
                    {user && user[0].firstname[0]}
                  </span>
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user && user[0].firstname + " " + user[0].lastname}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user && user[0].firstname[0]}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user && user[0].firstname + " " + user[0].lastname}
              </div>
              <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span>
            </div>
          )}
        </>

        <div className="circle-row">
          <a target="_blank" href="https://lms.zebrarobotics.com/login/canvas">
            <div className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
              <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">LMS</Tooltip>}>
                <div className="circle">
                  <span className="svg-icon svg-icon-md svg-icon-light">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}
                    ></SVG>
                  </span>
                </div>
                </OverlayTrigger>
                <span className="d-block text-center font-weight-bold font-size-h9 mt-2 mb-0">LMS</span>
            
              </div>
            </div>
          </a>
          <a target="_blank" href="https://zebrarobotics.awsapps.com/mail">
            <div className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
              <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Mail</Tooltip>}>
                <div className="circle">
                <span className="svg-icon svg-icon-md svg-icon-light">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                    ></SVG>
                  </span>
                </div>
                </OverlayTrigger>
                <span className="d-block text-center font-weight-bold font-size-h9 mt-2 mb-0">Mail</span>
              </div>
            </div>
          </a>
          <a target="_blank" href="https://s3.console.aws.amazon.com/s3/buckets/zebrafranchisee?region=us-east-2&tab=objects">
            <div className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
              <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">S3</Tooltip>}>
                <div className="circle">
                  <i className="fab fa-aws text-white" />
                </div>
                </OverlayTrigger>
                <span className="d-block text-center font-weight-bold font-size-h9 mt-2 mb-0">S3</span>
              </div>
            </div>
          </a>
        </div>
        <div className="separator separator-solid"></div>

        <div className="navi-separator mt-3">
          {/* <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">Account settings and more</div>
              </div>
            </div>
          </Link>
          btn btn-block btn-light-primary font-weight-bold text-left 
          btn btn-block btn-light-warning btn-hover-warning text-dark-50 text-left*/}

        <div className="d-grid gap-2 col-12 mx-auto">
          <Link to="/help" className="btn btn-block btn-light-primary font-weight-bold text-left">
            <div className="navi-link">
              <div className="d-flex align-items-right">
                <div className="navi-icon mr-2">
                  <i className="fas fa-question-circle text-success-light" />
                </div>            
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">Help</div>
                  <div className="text-muted">How-To videos and more</div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="navi navi-spacer-x-0 pt-5"></div>

        <div className="d-grid gap-2 col-12 mx-auto">
          <Link to="/request-form" className="btn btn-block btn-light-primary font-weight-bold text-left">
            <div className="navi-link">
            <div className="d-flex align-items-right">
              <div className="navi-icon mr-2">
                  <span className="svg-icon svg-icon-md svg-icon">                
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}></SVG>
                  </span>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Support Form</div>
                <div className="text-muted">
                  Form for change requests, to report bugs in system, request
                  t-shirt, merchandise robot, or robot parts etc.
                </div>
              </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="navi-separator mt-3"></div>
        <div className="separator separator-solid"></div>

        <div className="end-row">
          <div className="navi-footer px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-block btn-light-danger btn-hover-danger"
            >
              Sign Out
            </Link>
          </div>
        </div>   
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}



