export const attendanceHeaders = [
  {
    id: "student_firstname",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "student_lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "batch_date",
    numeric: false,
    disablePadding: false,
    label: "Batch Date",
  },
  {
    id: "course_abbr",
    numeric: false,
    disablePadding: false,
    label: "Stream Abbr",
  },
  {
    id: "attendance_date",
    numeric: false,
    disablePadding: false,
    label: "Attendance Date",
  },
  {
    id: "attendance_value",
    numeric: false,
    disablePadding: false,
    label: "Attendance",
  },
  {
    id: "make_up_value",
    numeric: false,
    disablePadding: false,
    label: "Make Up Student",
  },
];