import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import { BranchMaintenanceNav } from "./Components/BranchMaintenanceNav";
import { Snackbar } from "@material-ui/core";
import { SnackbarWrapper } from "../Snackbar/SnackbarWrapper";
import { HoursOfOperations } from "./Widgets/HoursOfOperations";
import { PriceFooter } from "./Widgets/PriceFooterMessages";
import { BranchMaintenanceDash } from "./Widgets/BranchMaintenanceDash";
import FeaturePage from "./Components/FeaturedIn/FeaturePage";
import InstructorProfilePage from "./Components/InstructorProfile/InstructorProfilePage";
import WebsiteAnnouncementsPage from "./Components/WebsiteAnnouncements/WebsiteAnnouncementsPage";

export default function BranchMaintenancePage({}) {
  // Family Redux state
  const dispatch = useDispatch();
  const searchid = useSelector((state) => state.family.searchid);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Website Management Page");

  return (
    <div className="d-flex flex-row">
      <BranchMaintenanceNav></BranchMaintenanceNav>
      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Route
            path="/branch-maintenance/price-footer"
            component={PriceFooter}
          />
          <Route
            path="/branch-maintenance/branch-hours"
            component={HoursOfOperations}
          />
          <Route
            path="/branch-maintenance/instructor-profiles"
            component={InstructorProfilePage}
          />
          <Route
            path="/branch-maintenance/features"
            component={FeaturePage}
          />
          <Route
            path="/branch-maintenance/website-announcements"
            component={WebsiteAnnouncementsPage}
          />
          <Route
            path="/branch-maintenance/"
            component={BranchMaintenanceDash}
          />
        </Switch>
      </div>
    </div>
  );
}
