export const campEnrollmentVsDobHeaders = [
    {
        key: "student_id",
        label: "Student ID"
    },
    {
        key:"Name",
        label:"Name",
    },
    {
        key:"dob",
        label:"DOB"

    },
    {
        key:"age",
        label:"Age"
    },
    {
        key:"calculated_grade",
        label: "Grade"
    },
    {
        key: "stream_name",
        label: "Stream"
    },
    {
        key:"stream_grade_min",
        label:" Stream Grade"
    },
    {
        key:"camp_week",
        label:"Camp Week",
    }
  ];